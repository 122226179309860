import React, {ReactNode, FC, useReducer, useEffect, useCallback, useRef, HTMLAttributes} from 'react'
import styled from '@emotion/styled'
import {Separator} from 'src/components/separator'
import {ShadowContainer, ContainerProps} from 'src/components/shadowContainer/ShadowContainer'
import {ListItemProps as ListItem} from 'src/components/list/ListItem'
import {color} from 'src/color/color'
import {Input} from '../input/Input'
import {makeStyles, createStyles} from '@material-ui/core'

export interface ListProps extends Omit<HTMLAttributes<HTMLDivElement>, 'id' | 'onSelect'> {
	// eslint-disable-next-line
	data: any[]
	listItem: (item: ListItem) => ReactNode | string
	// eslint-disable-next-line
	onSelect?: (items: any) => void
	allowMultipleSelection?: boolean
	shiftSelectEnabled?: boolean
	disableSelection?: boolean
	searchKey?: string
	dragEnabled?: boolean
	// eslint-disable-next-line
	dragComplete?: (ev: {target: any; dragIndex: number; dropIndex?: number; id: string}) => void
	id?: string
	// eslint-disable-next-line
	selected?: any[]
	height?: string
	width?: string
	selectKey?: string
	placeholder?: string
	containerProps?: ContainerProps
}

const ListContainer = styled.div`
	overflow: auto;
	width: 100%;
`

const ListUL = styled.ul`
	display: list-item;
	width: 100%;
	height: 100%;
	list-style-type: none;
	padding: 0;
	margin: 0;
	user-select: none;
	overflow: 'auto';
`

const NoDataView = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

interface ItemProps {
	selected?: boolean
	disabled?: boolean
}

const Item = styled.div<ItemProps>`
	background: ${props => (props.selected ? color.grey100 : props.theme?.palette?.background?.paper)};
	color: ${props => (props.disabled && color.grey700) || color.blue900};
`

interface IState {
	// eslint-disable-next-line
	items: any[]
	selectedItems: string[]
	selectedItemsSet: Set<string>
	isShiftDown: boolean
	lastSelectedItem?: string
	dragOverIndex?: number
}

const reducer = (state: IState, payload: Partial<IState>) => {
	return {
		...state,
		...payload,
	}
}

const defaultState: IState = {
	items: [],
	selectedItems: [],
	selectedItemsSet: new Set(),
	isShiftDown: false,
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			fontStyle: 'normal',
			fontWeight: 400,
			color: color.blue900,
			fontSize: '16px !important',
			width: '100%',
			'& .MuiInputBase-root': {
				margin: '12px 8px 0 16px !important',
				fontSize: '16px',
			},
		},
	}),
)

export const List: FC<ListProps> = (props: ListProps) => {
	const {
		data = [],
		onSelect,
		allowMultipleSelection,
		shiftSelectEnabled,
		disableSelection,
		searchKey,
		dragEnabled,
		dragComplete,
		selected,
		listItem,
		id,
		selectKey,
		placeholder = 'Search here',
		containerProps,
		...rest
	} = props
	const classes = useStyles()
	const selectedIds = (Array.isArray(selected) && selected.map(item => selectKey && item[selectKey])) || []

	const [state, setState] = useReducer(reducer, {
		...defaultState,
		items: [...data],
		selectedItems: [...selectedIds],
		selectedItemsSet: new Set([...selectedIds]),
	})
	const listRef = useRef(null)

	const handleKeyUp = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Shift' && state.isShiftDown) {
				setState({isShiftDown: false})
			}
		},
		[state.isShiftDown],
	)

	const handleKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Shift' && !state.isShiftDown) {
				setState({isShiftDown: true})
			}
		},
		[state.isShiftDown],
	)

	useEffect(() => {
		if (allowMultipleSelection && shiftSelectEnabled) {
			document.addEventListener('keyup', handleKeyUp)
			document.addEventListener('keydown', handleKeyDown)
		}
		return () => {
			if (allowMultipleSelection && shiftSelectEnabled) {
				document.removeEventListener('keyup', handleKeyUp)
				document.removeEventListener('keydown', handleKeyDown)
			}
		}
	}, [allowMultipleSelection, shiftSelectEnabled, handleKeyDown, handleKeyUp])

	useEffect(() => {
		const selectedIds = (Array.isArray(selected) && selected.map(item => selectKey && item[selectKey])) || []
		setState({
			selectedItems: [...selectedIds] || [],
			selectedItemsSet: new Set([...selectedIds] || []),
		})
	}, [selected, selectKey])

	useEffect(() => {
		setState({items: [...data]})
	}, [data])

	const getNewSelectedItems = (id: string) => {
		const {lastSelectedItem, items = []} = state
		// eslint-disable-next-line
		const currentSelectedIndex = items.findIndex((item: any) => selectKey && item[selectKey] === id)
		// eslint-disable-next-line
		const lastSelectedIndex = items.findIndex((item: any) => selectKey && item[selectKey] === lastSelectedItem)

		const selectedItems = items?.slice(
			Math.min(lastSelectedIndex, currentSelectedIndex),
			Math.max(lastSelectedIndex, currentSelectedIndex) + 1,
		)

		// eslint-disable-next-line
		return selectedItems?.map((item: any) => selectKey && item[selectKey])
	}

	const getNextValues = (id: string) => {
		const {isShiftDown, selectedItems, selectedItemsSet} = state
		const hasBeenSelected = selectedItemsSet.has(id)

		if (isShiftDown) {
			const newSelectedItems = getNewSelectedItems(id)
			const selections = Array.from(new Set([...selectedItems, ...newSelectedItems]))
			const newSelectedItemsSet = new Set(newSelectedItems)

			if (hasBeenSelected) {
				return selections.filter(item => !newSelectedItemsSet.has(item))
			}

			return selections
		}

		return hasBeenSelected ? selectedItems.filter((item: string) => item !== id) : [...selectedItems, id]
	}

	// eslint-disable-next-line
	const handleSelection = (item: any) => {
		const id = selectKey && item[selectKey]
		const {selectedItemsSet} = state
		let selectedItemsSetCopy = new Set(selectedItemsSet)
		if (shiftSelectEnabled) {
			const nextValues = getNextValues(id)
			selectedItemsSetCopy = new Set(nextValues)
		} else {
			if (selectedItemsSetCopy.has(id)) {
				selectedItemsSetCopy.delete(id)
			} else {
				if (allowMultipleSelection) {
					selectedItemsSetCopy.add(id)
				} else {
					selectedItemsSetCopy = new Set([id])
				}
			}
		}

		const newSelectedItems = Array.from(selectedItemsSetCopy)
		setState({
			selectedItems: newSelectedItems,
			lastSelectedItem: id,
			selectedItemsSet: selectedItemsSetCopy,
		})

		return selectedItemsSetCopy
	}

	// eslint-disable-next-line
	const handleClick = (item: any) => {
		if (!disableSelection && !item.disabled && selectKey) {
			const selectedItemsSet = handleSelection(item)
			// eslint-disable-next-line
			const selectedItems = data.filter((item: any) => selectedItemsSet.has(item[selectKey]))
			if (onSelect) {
				const items = allowMultipleSelection || shiftSelectEnabled ? selectedItems : selectedItems[0]
				onSelect(items)
			}
		}
	}

	const handleDragStart = (event: React.DragEvent<HTMLDivElement>, index: number) => {
		event.dataTransfer.setData('index', '' + index)
		event.dataTransfer.setData('id', props.id || '')
	}
	const handleDragOver = (event: React.DragEvent<HTMLDivElement>, index: number) => {
		setState({dragOverIndex: index})
	}
	const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		setState({dragOverIndex: undefined})
	}
	const handleDrop = (event: React.DragEvent<HTMLDivElement>, index: number) => {
		event.preventDefault()
		event.stopPropagation()
		if (dragComplete) {
			dragComplete({
				target: listRef?.current,
				dragIndex: parseInt(event.dataTransfer.getData('index')),
				dropIndex: index,
				id: event.dataTransfer.getData('id'),
			})
		}
		setState({dragOverIndex: undefined})
		return false
	}

	const listItems = () => {
		const {items = [], selectedItemsSet} = state
		return items.length ? (
			items.map((item: any, index: number) => {
				return (
					<li key={index} onClick={() => handleClick(item)}>
						<div
							style={{
								borderBottom: state.dragOverIndex === index ? `5px solid ${color.success}` : undefined,
							}}
							draggable={dragEnabled}
							onDragStart={e => handleDragStart(e, index)}
							onDragOver={e => handleDragOver(e, index)}
							onDragLeave={handleDragLeave}
							onDrop={e => handleDrop(e, index)}
						>
							<Item
								selected={selectedItemsSet.has(selectKey && item[selectKey])}
								disabled={disableSelection || item.disabled}
							>
								{listItem(item)}
							</Item>
						</div>
						{items.length - 1 !== index && <Separator />}
					</li>
				)
			})
		) : (
			<NoDataView>No data to show</NoDataView>
		)
	}

	// eslint-disable-next-line
	const handleSearch = (e: any, value: string) => {
		if (value) {
			const filteredList = data.filter((item: ListItem) => item[searchKey as keyof ListItem].includes(value))
			setState({items: filteredList})
		} else {
			setState({items: [...data]})
		}
	}

	const drop = (event: React.DragEvent<HTMLUListElement>) => {
		event.preventDefault()
		event.stopPropagation()
		if (props.dragComplete) {
			props.dragComplete({
				target: listRef?.current,
				dragIndex: parseInt(event.dataTransfer.getData('index')),
				id: event.dataTransfer.getData('id'),
			})
		}
		return false
	}

	const dragOver = (event: React.DragEvent<HTMLUListElement>) => {
		event.preventDefault()
	}

	return (
		<ShadowContainer card={true} {...containerProps}>
			{searchKey && (
				<>
					<Input
						onChange={handleSearch}
						placeholder={placeholder}
						type='text'
						containerstyle={{marginBottom: '8px', width: '100%'}}
						disableClose={true}
						disableShadow
						classes={{root: classes.root}}
					/>
					<Separator orientation='horizontal' thickness={2} style={{width: '100%'}} />
				</>
			)}
			<ListContainer {...rest}>
				<ListUL id={id} onDrop={drop} onDragOver={dragOver} ref={listRef}>
					{listItems()}
				</ListUL>
			</ListContainer>
		</ShadowContainer>
	)
}
