import styled from '@emotion/styled'
import {useTheme} from '@material-ui/core'
import {ThemeOptions} from '@material-ui/core'
import {FC, HTMLAttributes} from 'react'
import {Icons} from 'src'
import React from 'react'

const PoweredByDiv = styled.div`
	margin-top: 24px;
	color: ${props => props?.theme?.palette?.text?.background || '#231f20'};
	text-decoration: none;
	display: flex;
	align-items: center;
	margin-bottom: 0;
	font-size: 8px;

	@media (min-width: 720px) {
		color: ${props => props?.theme?.palette?.text?.externalBackground || '#000000'};
	}

	.backedByText {
		margin-top: 5.5px;
	}

	.logo {
		margin: 0;
		margin-top: 1px;
		margin-left: 5px;

		@media (min-width: 720px) {
			fill: ${props => props?.theme?.palette?.text?.externalBackground || '#000000'};
		}
	}

	.plusSign {
		fill: ${props => props?.theme?.palette?.text?.background || '#000000'};
		stroke: ${props => props?.theme?.palette?.text?.background || '#000000'};
		margin: 2px 4px 0 0;

		@media (min-width: 720px) {
			fill: ${props => props?.theme?.palette?.text?.externalBackground || '#000000'};
			stroke: ${props => props?.theme?.palette?.text?.externalBackground || '#000000'};
		}
	}
`

type PoweredByProps = {
	text?: string
	usePlusSign?: boolean
} & HTMLAttributes<HTMLDivElement>

export const PoweredBy: FC<PoweredByProps> = ({text, usePlusSign, ...props}) => {
	const theme = useTheme() as ThemeOptions
	const logoUrl = theme.logoUrl

	return (
		<PoweredByDiv className={'poweredBy'} {...props}>
			<div className={'backedByText'}>
				{logoUrl && usePlusSign ? (
					<Icons.PlusLogo className={'plusSign'} role='img' aria-label='Plus' />
				) : (
					text || 'BACKED BY'
				)}
			</div>
			<Icons.BakktLogo
				className={'logo'}
				width={58}
				height={22}
				fill={theme?.palette?.text?.background || '#000000'}
				role='img'
				aria-label='Bakkt Logo'
			/>
		</PoweredByDiv>
	)
}
