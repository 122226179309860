import {IDBPDatabase, openDB} from 'idb'

class IndexedDb {
	private database: string
	private db: any

	constructor(database: string) {
		this.database = database
	}

	public async initialize(tables: {id: string; keyPath: string}[]) {
		try {
			this.db = await openDB(this.database, 1, {
				upgrade(db: IDBPDatabase) {
					for (const table of tables) {
						if (db.objectStoreNames.contains(table.id)) {
							continue
						}
						db.createObjectStore(table.id, {
							autoIncrement: true,
							keyPath: table.keyPath,
						})
					}
				},
			})
		} catch (error) {
			return false
		}
	}

	public async get(tableName: string, id: number) {
		const tx = this.db.transaction(tableName, 'readonly')
		const store = tx.objectStore(tableName)
		const result = await store.get(id)
		return result
	}

	public async getAll(tableName: string) {
		if (this.db == null) return []
		const tx = this.db.transaction(tableName, 'readonly')
		const store = tx.objectStore(tableName)
		const result = await store.getAll()
		return result
	}

	public async put(tableName: string, value: any) {
		const tx = this.db.transaction(tableName, 'readwrite')
		const store = tx.objectStore(tableName)
		const result = await store.put(value)
		return result
	}

	public async putBatch(tableName: string, values: any[]) {
		const tx = this.db.transaction(tableName, 'readwrite')
		const store = tx.objectStore(tableName)
		for (const value of values) {
			const result = await store.put(value)
		}
		return this.getAll(tableName)
	}

	public async remove(tableName: string, id: string) {
		const tx = this.db.transaction(tableName, 'readwrite')
		const store = tx.objectStore(tableName)
		const result = await store.get(id)
		if (!result) {
			return result
		}
		await store.delete(id)
		return id
	}

	public async removeBatch(tableName: string, ids: string[]) {
		const tx = this.db.transaction(tableName, 'readwrite')
		const store = tx.objectStore(tableName)
		const processed = []
		for (const id in ids) {
			const result = await store.get(id)
			if (!result) {
				continue
			}
			await store.delete(id)
			processed.push(id)
		}
		return processed
	}
}

export default IndexedDb
