import {ThemeOptions} from '@material-ui/core'
import {FKGrotesk} from 'src'

export const portalTheme: ThemeOptions = {
	name: 'Portal Theme',
	palette: {
		type: 'light',
		background: {
			paper: '#FFFFFF',
		},
		primary: {
			main: '#0F2B29',
			contrastText: '#3FD899',
		},
		secondary: {
			main: '#0F2B29',
			contrastText: '#3FD899',
		},
		tertiary: {
			main: '#3FD899',
			contrastText: '#0F2B29',
		},
		text: {
			primary: '#100722',
			secondary: '#40394E',
		},
		success: {
			main: '#039F7E', // color for crypto percent change up
		},
		warning: {
			main: '#D93C30', // color for crypto percent change down
		},
	},
	typography: {
		fontFamily: 'FKGrotesk',
	},
}

export const embeddedTheme: ThemeOptions = {
	name: 'Embedded Theme',
	palette: {
		type: 'light',
		background: {
			paper: 'white',
		},
		primary: {
			main: '#004879',
			contrastText: 'white',
		},
		secondary: {
			main: '#004879',
			contrastText: 'white',
		},
		tertiary: {
			main: '#004879',
			contrastText: 'white',
		},
		text: {
			primary: '#100722',
			secondary: '#40394E',
		},
		success: {
			main: '#039F7E', // color for crypto percent change up
		},
		warning: {
			main: '#000000', // color for crypto percent change down
		},
	},
	typography: {
		fontFamily: 'FKGrotesk',
	},
	logoUrl: 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
	disableShadow: true,
}

export const defaultLightTheme: ThemeOptions = {
	name: 'light',
	palette: {
		type: 'light',
		background: {
			default: '#D9D9D9',
			paper: '#ffffff',
		},
		primary: {
			main: '#000000',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#000000',
			contrastText: '#FFFFFF',
		},
		tertiary: {
			main: '#FFFFFF',
			contrastText: '#000000',
		},
		text: {
			background: '#000000',
			externalBackground: '#100722',
			primary: '#FFFFFF',
			secondary: '#FFFFFF',
			tertiary: '#000000',
			newLink: '#6A5EFF',
			visitedLink: '#AA55AA',
		},
		success: {
			main: '#039F7E', // color for crypto percent change up
		},
		warning: {
			main: '#000000', // color for crypto percent change down
		},
	},
	typography: {
		fontFamily: 'FKGrotesk',
	},
	// logoUrl: 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
	disableShadow: true,
}

export const defaultDarkTheme: ThemeOptions = {
	name: 'dark',
	palette: {
		type: 'dark',
		background: {
			default: '#333333',
			paper: '#000000',
		},
		primary: {
			main: '#FFFFFF',
			contrastText: '#000000',
		},
		secondary: {
			main: '#FFFFFF',
			contrastText: '#000000',
		},
		tertiary: {
			main: '#000000',
			contrastText: '#FFFFFF',
		},
		text: {
			background: '#FFFFFF',
			externalBackground: '#FFFFFF',
			primary: '#000000',
			secondary: '#000000',
			tertiary: '#FFFFFF',
			newLink: '#6A5EFF',
			visitedLink: '#AA55AA',
		},
		success: {
			main: '#039F7E', // color for crypto percent change up
		},
		warning: {
			main: '#FFFFFF', // color for crypto percent change down
		},
	},
	typography: {
		fontFamily: 'FKGrotesk',
	},
	disableShadow: true,
}

export const sampleFont: ThemeOptions = {
	name: 'Sample Font',
	palette: {
		type: 'light',
		background: {
			paper: '#FFFFFF',
		},
		primary: {
			main: '#0F2B29',
			contrastText: '#3FD899',
		},
		secondary: {
			main: '#0F2B29',
			contrastText: '#3FD899',
		},
		tertiary: {
			main: '#3FD899',
			contrastText: '#0F2B29',
		},
		text: {
			primary: '#100722',
			secondary: '#40394E',
		},
		success: {
			main: '#039F7E', // color for crypto percent change up
		},
		warning: {
			main: '#D93C30', // color for crypto percent change down
		},
	},
	typography: {
		fontFamily: 'serif',
	},
	disableShadow: true,
}
