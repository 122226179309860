import {useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {ApiAmountCurrencyEnum, ApiEmbeddedPartnerPartyAccount, BasicPartnerInfoCryptoCurrenciesEnum} from '@bakkt/api'
import {useStore} from 'store'
import {useNavigate} from 'hooks'
import {RoutesName} from 'constants/routes'

const {fetchAccounts} = embeddedPartnerApi

export const useCryptoAccount = (navigateToOverview?: boolean) => {
	const {
		cryptoAccounts,
		setCryptoAccounts,
		setSelectedCryptoAccount,
		setSelectedCryptoAccountCurrency,
		selectedCryptoAccountCurrency,
		selectedCryptoAccount,
		partner,
	} = useStore()
	const navigate = useNavigate()
	const {request, response, loading} = ApiHook(() => fetchAccounts())

	const formatCryptoAccount = () => {
		let result: ApiEmbeddedPartnerPartyAccount[] = []
		if (response?.length) {
			result = response.map(cryptoItem => {
				return {
					...cryptoItem,
					isDisabled: !partner?.cryptoCurrencies?.some(
						(currency: BasicPartnerInfoCryptoCurrenciesEnum | ApiAmountCurrencyEnum) =>
							currency === cryptoItem?.accountBalance?.currency,
					),
				}
			})
		}
		setCryptoAccounts(result || [])
	}

	useEffect(() => {
		formatCryptoAccount()
	}, [response])

	useEffect(() => {
		const cryptoAccountsLength = (cryptoAccounts || []).length

		if (cryptoAccountsLength > 0) {
			if (cryptoAccountsLength === 1) {
				// if no of crypto accounts is equal to 1 then select the first one and redirect to the overview page
				setSelectedCryptoAccount(cryptoAccounts?.[0])
				setSelectedCryptoAccountCurrency(cryptoAccounts?.[0]?.accountBalance?.currency as string)
				if (navigateToOverview) navigate(RoutesName.crypto.overview, {replace: true})
				return
			}
			if (selectedCryptoAccountCurrency && !selectedCryptoAccount) {
				const selectedAccount = cryptoAccounts.find(
					account => account.accountBalance?.currency === selectedCryptoAccountCurrency,
				)
				if (selectedAccount) setSelectedCryptoAccount(selectedAccount)
			}
		}
	}, [cryptoAccounts])

	useEffect(() => {
		request()
	}, [])
	return {
		loading: (cryptoAccounts || []).length === 0 ? loading : false,
	}
}
