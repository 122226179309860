import {Dispatch, SetStateAction} from 'react'
import {hideDialog, showDialog} from 'components/Dialog'
import {t} from 'i18next'
import {Icons, DialogText} from '@bakkt/components'
import {EmbeddedWebState, useStore} from 'store'
import {returnToPartner} from 'utils/returnToPartner'
import {setSessionStorageKey} from 'utils/sessionStorage'
import {SessionStorageKeys} from 'utils/sessionStorageProperties'

export const showSpeedbumpDialog = async (
	partner?: EmbeddedWebState['partner'],
	setNavigate?: Dispatch<SetStateAction<boolean>>,
) => {
	const {isPartnerBakkt} = useStore.getState()

	await showDialog({
		title: `${partner?.speedbumpMessage?.speedbumpMessageTitle}`,
		WrappedComponent: () => (
			<DialogText data-testid='alert-dialog-description' style={{textAlign: 'center'}}>
				{partner?.speedbumpMessage?.speedbumpMessage}
			</DialogText>
		),
		saveText: partner?.speedbumpMessage?.saveText || 'Continue',
		onSave: () => hideDialog(),
		onCancel: () => returnToPartner(true, 'user_cancelled', partner),
		icon: Icons.DialogInfo,
		cancelText: partner?.speedbumpMessage?.cancelText || 'Cancel',
		hideCancel: isPartnerBakkt,
		preventHideDialog: true,
	})
	setSessionStorageKey(SessionStorageKeys.ACCEPTED_SPEEDBUMP_MESSAGE, 'true')
}
