import {
	Alert,
	BakktLogoThemed,
	BottomBox,
	Button,
	Checkbox,
	H1,
	HandWithTheme,
	HBox,
	InlineBtn,
	Input,
	Light,
	Main,
	VBox,
	Progress,
} from '@bakkt/components'
import {embeddedBakktAuthApi} from 'api'
import Page from 'components/Page'
import {RoutesName} from 'constants/routes'
import {useNavigate} from 'hooks'
import {usePassword} from 'hooks/auth/usePassword'
import {useUserId} from 'hooks/auth/useUserId'
import React, {useEffect, useState} from 'react'
import {useStore} from 'store'
import {useTranslation} from 'react-i18next'
import {getErrorMessage} from 'utils/ErrorMessageFormatter'
import {handleKeyPress} from 'utils/handleKeyPress'
import {displayBakktTermsOfUseDialog, displayPrivacyPolicyDialog} from '../onboarding/pages/Consent'
import {Link} from '../../components/Link'
import {cleanPhoneNumber} from 'validate'
import {getSessionStorageKey} from "../../utils/sessionStorage";
import {SessionStorageKeys} from "../../utils/sessionStorageProperties";

const LoginPage = () => {
	const [userId, setUserId] = useState('')
	const [password, setPassword] = useState('')
	const [showAlert, setShowAlert] = useState(false)
	const [serverError, setServerError] = useState<string | null>(null)
	const [termsAccepted, setTermsAccepted] = useState(false)
	const {setBakktLoginRequired, bakktLoginRequired} = useStore()
	const navigate = useNavigate()
	const {t} = useTranslation()
	const [showSpinner, setShowSpinner] = useState(true)

	const {
		isMobileNumber: isUserIdMobileNumber,
		isValid: isUserIdValid,
		error: userIdError,
		setError: setUserIdError,
	} = useUserId(userId)

	const {error: passwordError, isValid: isPasswordValid} = usePassword(password)
	const handleUserIdChanged = (event: { target: { value: string } }) => {
		setUserId(event.target.value || '')
	}
	const partner = getSessionStorageKey(SessionStorageKeys.PARTNER)
	const localStorageTermsAcceptedKey = 'access-login-tnc-accepted'
	const isLocalStorageTermsAccepted = localStorage.getItem(localStorageTermsAcceptedKey) === 'true' ? true : false
	const sessionExpiredErrorStr = localStorage.getItem('sessionExpired')
	const sessionExpiredError = JSON.parse(sessionExpiredErrorStr || '{}')
	const displaySessionExpiredError = sessionExpiredError?.sessionExpired
	useEffect(() => {
		if (!bakktLoginRequired && !displaySessionExpiredError) {
			navigate(RoutesName.home, {ignore: true})
		} else if ((window as any).token_initiated || displaySessionExpiredError) {
			navigate('/' + RoutesName.auth.session_expired, {ignore: true})
		}
		setTimeout(() => setShowSpinner(false), 100)
	}, [bakktLoginRequired])

	const handlePasswordChanged = (event: { target: { value: string } }) => {
		setPassword(event.target.value || '')
	}

	let hideResetPassword = (partner !== 'bakkt');

	const handleLogin = async () => {
		setShowAlert(false)
		try {
			const response = await embeddedBakktAuthApi.loginParty({
				userName: isUserIdMobileNumber ? `+1${cleanPhoneNumber(userId)}` : userId,
				password,
			})
			if (response?.data?.success) {
				localStorage.setItem(localStorageTermsAcceptedKey, 'true')
				setBakktLoginRequired(!response?.data?.success)
				setTimeout(() => window.location.reload())
			} else {
				setServerError(t(`${getErrorMessage(response?.data?.error?.code!, 'loginError')}`))
				setShowAlert(true)
				setBakktLoginRequired(true)
			}
		} catch (error: any) {
			if (error?.response?.data?.message! !== 'PARTY_SUSPENDED') {
				setServerError(t(`${getErrorMessage(error?.response?.data?.error?.code!, 'loginError')}`))
				setShowAlert(true)
				setBakktLoginRequired(true)
			}
		}
	}

	const handleResetPassword = async () => {
		navigate(`/${RoutesName.auth.resetPassword}`)
	}

	const renderAcceptTermsCheckbox = () => {
		if (!isLocalStorageTermsAccepted) {
			return (
				<Checkbox
					checked={termsAccepted}
					inputProps={{
						'aria-label':
							'I am at least 18 years old and agree to Bakkt’s Terms of Use and Privacy Policy.',
					}}
					onChange={() => {
						setTermsAccepted(!termsAccepted)
					}}
					label={
						<Light style={{textAlign: 'left'}}>
							{`${t('crypto.accountActivate.enough18')} `}
							<InlineBtn
								style={{fontSize: '14px', textDecoration: 'underline'}}
								data-testid={'terms-of-use-link'}
								aria-label='Terms of Use'
								onClick={() => displayBakktTermsOfUseDialog()}
							>
								{t('more.termsOfUse')}
							</InlineBtn>
							{' and '}
							<InlineBtn
								style={{fontSize: '14px', textDecoration: 'underline'}}
								data-testid={'privacy-policy-link'}
								aria-label='Privacy Policy'
								onClick={() => displayPrivacyPolicyDialog()}
							>
								{t('more.privacyPolicy')}
							</InlineBtn>
							.
						</Light>
					}
					name='accept-terms'
					className={'checkbox'}
				/>
			)
		}
	}

	const handleLoginKeyPress = (event: any) => {
		if (termsAccepted || isLocalStorageTermsAccepted) {
			handleKeyPress(event, handleLogin)
		}
	}

	if (showSpinner) {
		return (<Progress title='' />)
	}
	else {
		return (
			<Page loading={false} titleHeader='Login' showJointLogo={false} isLoginScreen={true}>
				<Main>
					<VBox verticalGap={15}>
						<HBox style={{justifyContent: 'center'}}>
							{showAlert && (
								<Alert
									severity='error'
									icon={false}
									onClose={() => setShowAlert(false)}
									style={{position: 'absolute'}}
									data-testid='signin-error-alert'
								>
									{serverError}
								</Alert>
							)}
						</HBox>
						<HBox>
							<div style={{flexGrow: 0.5}}/>
							<BakktLogoThemed width={'33%'}/>
							<div style={{flexGrow: 0.5}}/>
						</HBox>
						<HBox style={{margin: 'auto'}}>
							<HandWithTheme/>
						</HBox>
						<HBox style={{margin: 'auto'}}>
							<H1 data-testid='main-header'>Your Account</H1>
						</HBox>
						<Input
							type={'text'}
							label='Mobile or Email'
							value={userId}
							role={'userId'}
							error={!!userIdError}
							helperText={userIdError}
							onChange={handleUserIdChanged}
							onKeyUp={handleLoginKeyPress}
							InputProps={{'aria-describedby': 'userId-error'}}
							FormHelperTextProps={{role: 'alert', id: 'userId-error'}}
						/>
						<Input
							type={'password'}
							label='Password'
							value={password}
							role={'password'}
							error={!!passwordError}
							helperText={passwordError}
							onChange={handlePasswordChanged}
							onKeyUp={handleLoginKeyPress}
							InputProps={{'aria-describedby': 'password-error'}}
							FormHelperTextProps={{role: 'alert', id: 'password-error'}}
						/>
					</VBox>
				</Main>
				<BottomBox style={{paddingTop: 30}}>
					{renderAcceptTermsCheckbox()}
					<Button
						size='large'
						disabled={!isUserIdValid || !isPasswordValid || (!termsAccepted && !isLocalStorageTermsAccepted)}
						onClick={handleLogin}
						fullWidth
						aria-label='Sign In'
					>
						Sign In
					</Button>
					{!hideResetPassword && (
						<Link aria-label='Forgot Password' onClick={handleResetPassword}>
							Forgot Password
						</Link>
					)}
				</BottomBox>
			</Page>
		)
	}
}

export default LoginPage
