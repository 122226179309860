import {ApiEmbeddedFundingAccount} from '@bakkt/api'
import {VBox, HBox, Light, color, useTheme, Link} from '@bakkt/components'

import {Theme} from '@material-ui/core'
import WarningBasic from './icons/WarningBasic'

export type FundingSourceProps = {
	source: ApiEmbeddedFundingAccount
	onClick?: () => void
}

export const LinkedAccount = ({source, onClick}: FundingSourceProps) => {
	const theme = useTheme() as Theme
	const accountType = source.accountType.toLowerCase()
	const institutionName = source?.name && source?.name !== 'N/A' ? source?.name : 'Account ending in'

	const accountNumber = source?.formattedAccountNumber?.match(/\d{4}/)

	function getAccountStatus() {
		if (source?.externalAccountRef?.startsWith('BANK')) {
			switch (source.status) {
				case 'ACTIVE':
					return 'Unlink'
				case 'BLACKLIST':
					return 'Link New Account'
				case 'PENDING':
					return 'Verifying'
				case 'PENDING_EXPIRATION':
				case 'REFRESH_REQUIRED':
				case 'KYC_FAILED':
					return 'Relink'
				default:
					return 'Link'
			}
		} else {
			return accountType === 'pending_account'
				? 'pending account'
				: accountType === 'link_account'
				? 'Link'
				: 'Unlink'
		}
	}

	return (
		<HBox
			style={{
				justifyContent: 'space-between',
				padding: 10,
				backgroundColor: theme?.palette.type === 'dark' ? theme?.palette?.text?.primary : color.grey20,
				marginTop: 5,
				borderLeft: 4,
				borderLeftColor: 'black',
				borderLeftStyle: 'solid',
			}}
		>
			<VBox>
				<Light data-testid={`funding-source-institution-name-${accountType}`} style={{margin: 0}}>
					<span>{accountType === 'debit' ? 'Debit card ending in' : institutionName}</span>
					{accountNumber && source.status !== 'ACTIVE' && (
						<span style={{marginLeft: 5}}>
							<WarningBasic size={13} color={'#FFFFFF'} />
						</span>
					)}
				</Light>
				{accountNumber && (
					<Light data-testid={`funding-source-account-balance-${accountType}`} style={{margin: 0}}>
						<span>••••{accountNumber}</span>
					</Light>
				)}
			</VBox>
			{source.status === 'PENDING' && <Light>{getAccountStatus()}</Light>}
			{source.status !== 'PENDING' && (
				<Link style={{margin: 0, textDecoration: 'none'}} onClick={onClick}>
					{getAccountStatus()}
				</Link>
			)}
		</HBox>
	)
}
