import {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {Large, Medium, H2, H3, Input, NumberedList, Main, BottomBox} from '@bakkt/components'
import Button from 'components/Button'
import Page from 'components/Page'
import {useStore} from 'store'
import {usePartnerPartyEnroll, useNavigate} from 'hooks'
import {TaxpayerIdList} from 'components/onboarding/taxpayer/TaxpayerIdList'
import {TaxpayerInformationList} from 'components/onboarding/taxpayer/TaxpayerInformationList'
import {nameFormatter} from 'utils/formatters'
import {logger} from 'utils/logger/datadogInit'
import ExitOnboardingButton from 'components/ExitOnboardingButton'
import {useUpdateParty} from 'hooks/useUpdateParty'
import {useUpdatePartyInfo} from 'hooks/useUpdatePartyInfo'
import {usePartyDetails} from 'hooks/usePartyDetails'
import {ApiParty} from '@bakkt/api'
import {RoutesName} from 'constants/routes'

const TaxpayerCertification = () => {
	const {t} = useTranslation()
	const {partyProfile, missingProfileFields, party, partyUpdateProfileInfo} = useStore()
	const [isValidSignature, setIsValidSignature] = useState<boolean>(false)
	const [ew9Signature, setEw9Signature] = useState<string>('')
	const {enrollPartnerParty, loading} = usePartnerPartyEnroll(partyProfile, missingProfileFields, ew9Signature)
	const {getParty, partyLoading, partyResponse} = usePartyDetails()
	const [partyDetails, setPartyDetails] = useState<ApiParty>({})

	const {updateParty, loading: loadingUpdate} = useUpdateParty(ew9Signature)
	const {updateParty: updatePartyInfo} = useUpdatePartyInfo(ew9Signature)
	const [disableButton, setDisableButton] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		if (party?.ew9CertificationRequired) {
			getParty()
		}

		logger.info('Activate Crypto IRS Taxpayer Certification - initilized', {filename: 'TaxpayerCertification.tsx'})
	}, [])

	useEffect(() => {
		if (partyResponse && Object.keys(partyResponse).length) setPartyDetails(partyResponse)
	}, [partyResponse])

	const validateSignature = (text: string | number): boolean => {
		setDisableButton(false)

		const concatedName = nameFormatter(
			partyUpdateProfileInfo?.newFirstName! ||
				partyProfile?.Party?.firstName ||
				missingProfileFields?.firstName ||
				partyDetails?.firstName,
			partyUpdateProfileInfo?.newLastName! ||
				partyProfile?.Party?.lastName ||
				missingProfileFields?.lastName ||
				partyDetails?.lastName,
		)

		const isValid = text.toString().toLowerCase().trim() === concatedName.toLowerCase().trim()
		setIsValidSignature(isValid)
		return isValid
	}

	function handleClick() {
		logger.info('Activate Crypto IRS Taxpayer Certification - complete activation click', {
			isSignature: isValidSignature,
		})
		enrollPartnerParty()
		setDisableButton(true)
	}
	const handleRecertification = async () => {
		logger.info('EW-9 Recertification complete click', {isSignature: isValidSignature})
		updateParty()
		setDisableButton(true)
	}
	const handleRecertificationAndUpdatePartyProfile = async () => {
		logger.info('EW-9 Recertification complete click', {isSignature: isValidSignature})
		updatePartyInfo()
		setDisableButton(true)
	}
	const handleCertifyComplete = () => {
		const region: string =
			partyProfile?.Party?.address?.region ||
			missingProfileFields?.address?.region ||
			partyDetails?.address?.region ||
			''

		if (partyUpdateProfileInfo !== null) {
			handleRecertificationAndUpdatePartyProfile()
		} else if (region && (region === 'HI' || region.toLocaleLowerCase() === 'hawaii')) {
			logger.info('The current state is Hawaii, this region cryptocurrency will provide soon', {
				isSignature: isValidSignature,
			})
			navigate(`${RoutesName.onboarding.home}${RoutesName.onboarding.hawaiiError}`, {ignore: true})
		} else if (!party?.ew9CertificationRequired) {
			handleClick()
		} else {
			handleRecertification()
		}
	}

	return (
		<Page
			titleHeader={t('crypto.accountActivate.activateCrypto')}
			title={t('titles.onboarding.taxpayerCertification')}
			withSubHeaderLogo={false}
			loading={loading || loadingUpdate || partyLoading}
		>
			<Main>
				<H2 data-testid={'section-title'}>{t('crypto.accountActivate.payerCertificationTitle')}</H2>
				<Large data-testid={'section-description-1'}>
					{t('crypto.accountActivate.payerCertificationDes1')}
				</Large>
				<Large data-testid={'section-description-2'}>
					{t('crypto.accountActivate.payerCertificationDes2')}
				</Large>

				<H3 underline>{t('crypto.accountActivate.payerInfo')}</H3>
				<TaxpayerInformationList
					partyProfile={partyProfile}
					party={partyDetails}
					missingProfileFields={missingProfileFields}
					partyUpdatedDetails={partyUpdateProfileInfo}
				/>

				<H3 underline>{t('crypto.accountActivate.payerIdentification')}</H3>
				<TaxpayerIdList
					partyProfile={partyProfile}
					party={partyDetails}
					missingProfileFields={missingProfileFields}
				/>

				<H3 underline>{t('crypto.accountActivate.certification')}</H3>
				<Medium>{t('crypto.accountActivate.underPenalties')}</Medium>
				<NumberedList
					data={[
						t('crypto.accountActivate.numberShowCorrect'),
						t('crypto.accountActivate.notSubject'),
						t('crypto.accountActivate.amUs'),
					]}
				/>

				<H3 underline>{t('crypto.accountActivate.signAndCertify')}</H3>
				<Medium>{t('crypto.accountActivate.internalRevenue')}</Medium>
				<Input
					id='TaxpayerCertification_DigitalSignature'
					label={t('inputLabel.digitalSignature')}
					name='digital-signature'
					type='text'
					helperText={t('label.digitalSignatureHelper')}
					fullWidth
					onChange={(_, value) => {
						setEw9Signature(value)
						setDisableButton(true)
					}}
					validate={validateSignature}
					validateOnInputDelay={2000}
					containerstyle={{marginBottom: '30px'}}
					FormHelperTextProps={{role: 'alert'}}
				/>
			</Main>
			<BottomBox>
				<Button
					id='EnrollButton'
					aria-label={t('crypto.accountActivate.certifyAndComplete')}
					onClick={handleCertifyComplete}
					disabled={!isValidSignature || disableButton}
					style={{fontSize: '16px'}}
				>
					{partyUpdateProfileInfo != null
						? 'Re-Certify to Update Profile'
						: t('crypto.accountActivate.certifyAndComplete')}
				</Button>
				{!party?.ew9CertificationRequired && <ExitOnboardingButton style={{marginBottom: '5px'}} />}
			</BottomBox>
		</Page>
	)
}
export default TaxpayerCertification
