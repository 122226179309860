import {Suspense, useEffect} from 'react'
import {Routes, Route, Navigate, useLocation, useNavigate} from 'react-router-dom'
import {BasicPartyInfoLevelEnum} from '@bakkt/api'
import {Progress} from '@bakkt/components'
import {useStore} from 'store'
import Consent from 'apps/onboarding/pages/Consent'
import ExistingAccountErrorPage from 'apps/onboarding/pages/ExistingAccountErrorPage'
import {CoinOverview} from 'apps/main/pages/crypto/overview'
import {CryptoTransactionInput} from 'apps/main/pages/crypto/transaction/CryptoTransactionInput'
import {CryptoTransactionSuccess} from 'apps/main/pages/crypto/transaction/CryptoTransactionSuccess'
import CryptoAccountActivationSuccess from 'apps/onboarding/pages/ActivationSuccess/ActivationSuccess'
import {HawaiiStateErrorPage} from 'apps/onboarding/ErrorPage/HawaiiStateError'
import TaxpayerCertification from 'apps/onboarding/pages/Taxpayer/TaxpayerCertification'
import {PersonalInformationInput} from 'apps/onboarding/pages/PersonalInformation/PersonalInformationInput'
import {RoutesName} from 'constants/routes'
import {CryptoTransactionConfirm} from 'apps/main/pages/crypto/transaction/CryptoTransactionConfirm'
import {CryptoDashboard} from 'apps/main/pages/crypto/cryptoAccounts/CryptoDashboard'
import {TermsAndConditionsAcceptance} from 'apps/main/pages/TermsAndConditionsAcceptance'
import {useSetupSardine, useSift} from 'hooks'
import {TransactionDetails} from 'apps/main/pages/crypto/transaction/detail/TransactionDetails'
import PartnerTermsOfUse from 'apps/main/pages/Speedbump/PartnerTermsOfUse'
import {IdleTimerProvider} from 'react-idle-timer'
import {showErrorDialog} from 'components/ErrorDialog'
import {logout} from 'api'
import {useTranslation} from 'react-i18next'
import {logger} from 'utils/logger/datadogInit'
import Login from 'apps/auth/Login'
import ResetPassword from 'apps/auth/ResetPassword'
import VerifyOTP from 'apps/auth/VerifyOTP'
import ResetNewPassword from 'apps/auth/ResetNewPassword'
import LinkDebitCard from 'apps/funding/debit/LinkDebitCard'
import VerifyTransactions from 'apps/funding/debit/LinkedDebitCardVerification'
import CashInput from 'apps/funding/CashInput'
import CashConfirm from 'apps/funding/CashConfirm'
import CashPage from 'apps/funding/CashPage'
import ViewAccountDetails from 'apps/main/pages/account/ViewAccountDetails'
import {BakktTermsOfUse} from './apps/onboarding/pages/Consent/BakktTermsOfUse'
import {Taxes} from 'apps/main/pages/taxes/Taxes'
import TransactionActivity from 'apps/main/pages/crypto/transaction/activity/TransactionActivity'
import EditAccountEmail from './apps/main/pages/account/EditAccountEmail'
import EditAccountPhone from './apps/main/pages/account/EditAccountPhone'
import EditAccountAddress from './apps/main/pages/account/EditAccountAddress'
import EditAccountName from './apps/main/pages/account/EditAccountName'
import PayoutConfirm from './apps/main/pages/crypto/payout/PayoutConfirm'
import FundingTermsAndCondition from 'apps/funding/FundingTermsAndCondition'
import {TokenSessionExpired} from 'apps/onboarding/ErrorPage/TokenSessionExpired'

const defaultIdleTimeOutInSeconds = 1800
const milliSeconds = 1000

const App = () => {
	const {loadOnStartup, party, partner} = useStore()
	const {t} = useTranslation()
	const navigate = useNavigate()

	useSetupSardine(party?.sardinePartyRef)
	useSift(party?.siftPartyRef)
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search)

		if (urlParams.get('requestId')) {
			;(window as any)['requestId'] = urlParams.get('requestId')
			navigate(RoutesName.payout.confirm, {})
		} else {
			navigate(RoutesName.home, {})
		}
		// reload and pass empty object to clear state
		// we can also use replace option: ..., {replace: true}
	}, [])

	useEffect(() => {
		logger.info('App initilized', {filename: 'App.tsx'})
		loadOnStartup()
	}, [])

	const onIdle = () => {
		showErrorDialog({
			message: t('errors.idleMessage') || undefined,
			saveText: 'Continue',
			partner,
			navigate,
			errorCode: 'idle',
		})
		logout()
	}

	const BUY = 'BUY' as any
	const SELL = 'SELL' as any

	return (
		<Suspense fallback={<p>Loading...</p>}>
			<IdleTimerProvider
				timeout={(partner?.inactivityTimeoutInSeconds ?? defaultIdleTimeOutInSeconds) * milliSeconds}
				onIdle={onIdle}
			>
				<Routes>
					<Route path={RoutesName.auth.login} element={<Login />} />
					<Route path={RoutesName.auth.resetPassword} element={<ResetPassword />} />
					<Route path={RoutesName.auth.verifyOtp} element={<VerifyOTP />} />
					<Route path={RoutesName.auth.resetNewPassword} element={<ResetNewPassword />} />
					<Route element={<TokenSessionExpired />} path={RoutesName.auth.session_expired} />

					<Route
						path={`${RoutesName.onboarding.home}*`}
						element={
							<RequireNotEnrolled>
								<Routes>
									<Route element={<Consent />} path='' />
									<Route element={<PersonalInformationInput />} path={RoutesName.onboarding.kyc} />
									<Route
										element={<TaxpayerCertification />}
										path={RoutesName.onboarding.taxpayerCertification}
									/>
									<Route
										element={<CryptoAccountActivationSuccess />}
										path={RoutesName.onboarding.success}
									/>
									<Route
										element={<ExistingAccountErrorPage />}
										path={RoutesName.onboarding.accountExists}
									/>
									<Route
										element={<HawaiiStateErrorPage />}
										path={RoutesName.onboarding.hawaiiError}
									/>
								</Routes>
							</RequireNotEnrolled>
						}
					/>
					<Route element={<TransactionDetails />} path={RoutesName.crypto.transaction.detail} />
					<Route element={<BakktTermsOfUse />} path={RoutesName.bakktTermsOfUse} />
					<Route element={<PartnerTermsOfUse />} path={RoutesName.partnerTermsOfUse} />
					<Route
						path={`${RoutesName.home}*`}
						element={
							<RequireAuth>
								<Routes>
									<Route element={<CryptoDashboard />} path='/' />
									<Route element={<PayoutConfirm />} path={RoutesName.payout.confirm} />
									<Route element={<TermsAndConditionsAcceptance />} path='/termsAndCondtions' />
									<Route element={<TransactionActivity />} path={RoutesName.crypto.transaction.all} />
									<Route
										element={<TaxpayerCertification />}
										path={RoutesName.onboarding.taxpayerCertification}
									/>
									<Route element={<CoinOverview />} path={RoutesName.crypto.overview} />
									<Route path='buy'>
										<Route element={<CryptoTransactionInput transactionType={BUY} />} path='' />
										<Route
											element={<CryptoTransactionConfirm transactionType={BUY} />}
											path='confirm'
										/>
										<Route
											element={<CryptoTransactionSuccess transactionType={BUY} />}
											path='success'
										/>
									</Route>
									<Route path='sell'>
										<Route element={<CryptoTransactionInput transactionType={SELL} />} path='' />
										<Route
											element={<CryptoTransactionConfirm transactionType={SELL} />}
											path='confirm'
										/>
										<Route
											element={<CryptoTransactionSuccess transactionType={SELL} />}
											path='success'
										/>
									</Route>
									<Route path={RoutesName.debitCard.link} element={<LinkDebitCard />} />
									<Route
										path={RoutesName.debitCard.verifyTransactions}
										element={<VerifyTransactions />}
									/>
									<Route element={<CashPage />} path={RoutesName.bakktFunding.cashPage} />
									<Route element={<CashInput />} path={RoutesName.bakktFunding.cashInput} />
									<Route element={<CashConfirm />} path={RoutesName.bakktFunding.cashConfirm} />
									<Route
										element={<FundingTermsAndCondition />}
										path={RoutesName.bakktFunding.fundingTermsAndCondition}
									/>
									<Route element={<Taxes />} path={RoutesName.taxes} />
									<Route element={<ViewAccountDetails />} path={RoutesName.viewAccountDetails} />
									<Route element={<EditAccountEmail />} path={RoutesName.editAccountEmail} />
									<Route element={<EditAccountPhone />} path={RoutesName.editAccountPhone} />
									<Route element={<EditAccountName />} path={RoutesName.editAccountName} />
									<Route element={<EditAccountAddress />} path={RoutesName.editAccountAddress} />
								</Routes>
							</RequireAuth>
						}
					/>
				</Routes>
				<div id='popupArea' />
			</IdleTimerProvider>
		</Suspense>
	)
}

export default App

const RequireAuth = ({children}: {children: JSX.Element}) => {
	const {isEnrolled, partnerPartyLink, isSuspended, party, bakktLoginRequired, changePasswordRequired, partner} =
		useStore()
	const location = useLocation()

	if (changePasswordRequired) {
		return <Navigate to={RoutesName.auth.verifyOtp} state={{from: location}} replace />
	}
	if (bakktLoginRequired && !location.pathname.includes(RoutesName.auth.login)) {
		return <Navigate to={RoutesName.auth.login} state={{from: location}} replace />
	}

	if (party?.ew9CertificationRequired && !location.pathname.includes(RoutesName.onboarding.taxpayerCertification)) {
		return <Navigate to={RoutesName.onboarding.taxpayerCertification} state={{from: location}} replace />
	}

	if (!partnerPartyLink) {
		// Not Authenticated SSO yet
		return <Progress title='' />
	}

	if (!isEnrolled) {
		// Authenticated but not Enroll
		return <Navigate to={RoutesName.onboarding.home} state={{from: location}} replace />
	}

	if (location.pathname == RoutesName.home && partner?.featureFlag?.fundingAccessOnly) {
		return <Navigate to={RoutesName.bakktFunding.fundingTermsAndCondition} state={{from: location}} replace />
	}

	if (isSuspended && location.pathname !== RoutesName.home) {
		return <Navigate to={RoutesName.home} state={{from: location}} replace />
	}

	return children
}

export const RequireNotEnrolled = ({children}: {children: JSX.Element}) => {
	const {isEnrolled, partnerPartyLink, party, isPartnerBakkt} = useStore()
	const location = useLocation()
	const enrolled = isEnrolled && (!isPartnerBakkt || party?.level == BasicPartyInfoLevelEnum.LEVEL_4)

	if (!partnerPartyLink) {
		// Not Authenticated SSO yet
		return <Progress title='' />
	}
	if (enrolled) {
		// Authenticated but partner is Enrolled
		return <Navigate to={RoutesName.home} state={{from: location}} replace />
	}
	return children
}
