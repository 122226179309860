import {RemoteConfigFlags, RemoteConfigValueTypes} from './remoteConfigProperties'
import {RemoteConfig} from 'firebase/remote-config'

type RemoteConfigDefaultTypes = RemoteConfig['defaultConfig'] & {
	[P in keyof RemoteConfigValueTypes]?: RemoteConfigValueTypes[P] | string
}

export const RemoteConfigDefaults: RemoteConfigDefaultTypes = {
	[RemoteConfigFlags.SHOW_TEST_DIALOG]: false,
	[RemoteConfigFlags.TEST_DIALOG_STRING]: 'default string',
	[RemoteConfigFlags.TEST_DIALOG_NUMBER]: 123,
}
