import {useState, useMemo} from 'react'
import {isValidName} from 'validate'

import {useDelayedValidation} from '../useDelayedValidation'

export const usePartyName = (value: string, type: string) => {
	const [error, setError] = useState('')
	const isValid = useMemo(() => isValidName(value), [value])

	useDelayedValidation({
		input: value,
		validate: () => {
			if (value && !isValid) {
				setError(`Invalid ${type}`)
			}
		},
		clearError: () => setError(''),
	})

	return {
		isValid,
		error,
		setError,
	}
}
