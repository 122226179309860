import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import CryptoAccountsItem from './CryptoAccountItem'
import {useNavigate} from 'react-router-dom'
import {RoutesName} from 'constants/routes'
import {useStore} from 'store'
import {H3, VBox, Large, Main, Light, Button, HBox, Small, Info} from '@bakkt/components'
import {TestDialog} from 'components/TestDialog'
import Page from 'components/Page'
import {useCryptoAccount, useSpeedbumpDialog} from 'hooks'
import {showSuspendedErrorDialog} from 'components/ErrorDialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {handleKeyPress} from 'utils/handleKeyPress'
import {logger} from 'utils/logger/datadogInit'
import {formatUSD} from '../../../../../utils/currencyFormatters'
import {FundingAccount} from 'components/FundingAccount'
import {BasicPartyInfoLevelEnum} from '@bakkt/api'
import Footer from '../../../../../components/Footer'
import {useActivityInformation} from 'hooks/useActivityInformation'
import {renderPartnerActivityItem} from '../transaction/activity/utils'

export const CryptoDashboard = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {
		setSelectedCryptoAccount,
		partner,
		party,
		setSelectedCryptoAccountCurrency,
		cryptoAccounts: accounts,
		isSuspended,
		fundingAccounts,
		isPartnerBakkt,
		partnerServiceName,
		loadProfile,
		loadParty,
		setActivePartnerTransactionId,
		setSelectedPartnerTransaction,
		isAnyCryptoDown,
		isCryptoPriceDown,
	} = useStore()
	const {transactionSummaries} = useActivityInformation()
	const {loading} = useCryptoAccount(true)
	const onMobileScreen = useMediaQuery('(max-width:719px)')
	useSpeedbumpDialog()
	function handleSelectCryptoAccount(currency: string) {
		const selectedAccount = accounts.filter(acc => acc.accountBalance?.currency === currency)[0]
		if (accounts) {
			logger.info('Crypto dashboard - select crypto account', {
				currencyType: selectedAccount?.accountBalance?.currency,
				currency: selectedAccount?.indicativeBalance?.currency,
			})
			setSelectedCryptoAccount(selectedAccount)
			setSelectedCryptoAccountCurrency(selectedAccount?.accountBalance?.currency as string)
			navigate(RoutesName.crypto.overview)
		}
	}

	function getTotalIndicativeBalance(): number {
		let total = 0
		for (let i = 0; i < accounts.length; i++) {
			total = total + (accounts[i]?.indicativeBalance?.amount || 0)
		}
		return total
	}

	useEffect(() => {
		logger.info('Crypto dashboard - initilized', {filename: 'selectCryptoAccounts.tsx'})
		loadParty()
		isPartnerBakkt && loadProfile()
		if (party?.termsAndConditionAcceptanceRequired) {
			navigate(RoutesName.termsAndConditions)
		}
	}, [])

	useEffect(() => {
		logger.info('Crypto dashboard - suspend', {suspend: isSuspended})
		if (isSuspended) {
			showSuspendedErrorDialog(partner)
		}
	}, [partner, isSuspended])

	const cryptoAccountsInUse = (accounts || []).filter(
		account => !account.isDisabled && (account?.accountBalance?.amount || 0) != 0,
	)
	const availableAccounts = (accounts || []).filter(
		account => account.isDisabled || (account?.accountBalance?.amount || 0) == 0,
	)
	const noCryptoAccounts = (accounts || []).length == 0

	const cashAccount = (fundingAccounts || []).find(account => account.accountType == 'CASH')
	const handleCashAccountSelect = () => {
		logger.info('Crypto dashboard select page - Cash Funding account clicked')
		if (cashAccount) {
			navigate(RoutesName.bakktFunding.cashPage)
		}
	}

	const handleActivityRowClick = (transactionRecord?: any) => {
		logger.info('Overview page, Transaction activity - Select activity item')
		if (isPartnerBakkt) {
			setSelectedPartnerTransaction(transactionRecord)
			setActivePartnerTransactionId(transactionRecord?.principalActivityDetail?.transactionId)
		} else {
			setActivePartnerTransactionId(transactionRecord.transactionId)
		}
		navigate(`/${RoutesName.crypto.transaction.detail}`)
	}

	const handleSeeAllActivity = () => {
		navigate(`/${RoutesName.crypto.transaction.all}`)
	}

	return (
		<Page
			withBackIcon={!isPartnerBakkt && !partnerServiceName?.toLocaleLowerCase().includes('fiserv')}
			onIconPress={() => {
				logger.info('Crypto dashboard - back button clicked', {navTo: -1})
				window.history.go(-1)
			}}
			titleHeader={onMobileScreen ? '' : t('crypto.cryptoAccounts')}
			loading={loading}
			withSubHeaderLogo={false}
			showJointLogo={true}
			Footer={Footer}
		>
			<TestDialog />
			<VBox style={{paddingTop: '0'}}>
				<Main>
					<Large style={{fontFamily: 'Grafier', fontSize: 50, margin: '0 0 30px 0'}}>Welcome!</Large>

					{isAnyCryptoDown && (
						<Info
							style={{marginTop: '-32px'}}
							text={'Some crypto pricing is offline. Check back later for updated values.'}
						></Info>
					)}

					{cryptoAccountsInUse.length > 0 && (
						<>
							<H3 data-testid={'section-title'} style={{marginBottom: 24}}>
								Your Crypto
							</H3>

							<Small style={{textAlign: 'right'}}>Balance</Small>

							{cryptoAccountsInUse.map((item, index) => (
								<CryptoAccountsItem
									key={'accounts-' + index}
									disabled={item.isDisabled}
									onClick={() => handleSelectCryptoAccount(item.accountBalance?.currency!)}
									accountBalance={item?.accountBalance}
									indicativeBalance={item?.indicativeBalance}
									style={index === 0 ? {paddingTop: 0} : {}}
									tabIndex={0}
									isCryptoDown={isCryptoPriceDown(item.accountBalance?.currency!)}
									onKeyPress={event =>
										handleKeyPress(event, handleSelectCryptoAccount(item.accountBalance?.currency!))
									}
								/>
							))}
						</>
					)}

					<HBox style={{minHeight: '30px'}}>
						{cryptoAccountsInUse.length > 1 && (
							<>
								<Large
									data-testid={`crypto-balance-label`}
									style={{margin: '24px auto 24px 0', fontWeight: 500}}
								>
									Total
								</Large>
								<Large
									data-testid={`crypto-balance-total`}
									style={{margin: '24px 0 24px auto', fontWeight: 500}}
								>
									{getTotalIndicativeBalance() && !isAnyCryptoDown
										? formatUSD(
												getTotalIndicativeBalance(),
												accounts[0]?.indicativeBalance?.currency,
										  )
										: 'N/A'}
								</Large>
							</>
						)}
					</HBox>

					{availableAccounts.length > 0 && (
						<>
							<H3 data-testid={'section-title'} style={{marginBottom: 0}}>
								Explore Crypto
							</H3>
							<Light>Buy, sell and hold crypto in real-time.</Light>

							<Small style={{textAlign: 'right'}}>Market Value</Small>

							{availableAccounts.map((item, index) => (
								<CryptoAccountsItem
									key={'accounts2-' + index}
									disabled={item.isDisabled}
									onClick={() => handleSelectCryptoAccount(item.accountBalance?.currency!)}
									accountBalance={item?.accountBalance}
									indicativeBalance={item?.indicativeBalance}
									style={index === 0 ? {paddingTop: 0} : {}}
									tabIndex={0}
									isCryptoDown={isCryptoPriceDown(item.accountBalance?.currency!)}
									onKeyPress={event =>
										handleKeyPress(event, handleSelectCryptoAccount(item.accountBalance?.currency!))
									}
								/>
							))}
						</>
					)}

					{noCryptoAccounts && <Large> No Crypto Accounts Found</Large>}

					{/* TODO: See All Crypto button is out of scope for now */}
					{/*<HBox style={{marginTop: 24, justifyContent: 'center'}}>*/}
					{/*	<Button style={{fontSize: 15, padding: '0 32px'}}>See All Crypto</Button>*/}
					{/*</HBox>*/}

					{cashAccount && isPartnerBakkt && party?.level !== BasicPartyInfoLevelEnum.LEVEL_1 && (
						<>
							<div style={{height: 30}} />

							<H3 data-testid={'section-title'} style={{marginBottom: 0}}>
								Your Cash
							</H3>
							<FundingAccount
								key={'fundingacct-' + cashAccount.externalAccountRef}
								source={cashAccount}
								onSelect={handleCashAccountSelect}
							/>
						</>
					)}

					{transactionSummaries && (
						<>
							<H3 data-testid={'section-title'} style={{fontWeight: 700, marginBottom: 0}}>
								Latest Activity
							</H3>
							<VBox>
								{transactionSummaries.map((element, index) => (
									<div
										className='transactionHistory'
										style={{cursor: 'pointer', paddingLeft: 3, paddingRight: 3}}
										onClick={() => handleActivityRowClick(element)}
										key={element?.transactionId + element?.created}
										tabIndex={0}
										onKeyPress={event => handleKeyPress(event, handleActivityRowClick(element))}
									>
										{index < 4 && renderPartnerActivityItem(element)}
									</div>
								))}
							</VBox>
						</>
					)}
					<>
						{(transactionSummaries?.length || 0) >= 4 && (
							<HBox style={{marginTop: 24, justifyContent: 'center'}}>
								<Button onClick={handleSeeAllActivity}>See All Activity</Button>
							</HBox>
						)}
					</>
				</Main>
			</VBox>
		</Page>
	)
}
