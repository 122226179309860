import {getRemoteConfig, RemoteConfig as RemoteConfigType} from 'firebase/remote-config'
import {RemoteConfigDefaults} from './remoteConfigDefaults'
import Firebase from './firebase'

const ONE_MINUTE = 60000

export default class RemoteConfig {
	static remoteConfig: RemoteConfigType
	static activated = false

	static getRemoteConfig = () => {
		if (!this.remoteConfig) this.initializeRemoteConfig()
		return this.remoteConfig
	}

	static initializeRemoteConfig = () => {
		const remoteConfig = getRemoteConfig(Firebase.getApp())

		remoteConfig.settings.minimumFetchIntervalMillis = ONE_MINUTE
		remoteConfig.defaultConfig = RemoteConfigDefaults
		this.remoteConfig = remoteConfig
	}
}
