import {ColumnApi, GridApi} from 'ag-grid-community'
import React from 'react'
import {CellContextMenuEvent, DataGridProps, Utils, Menu} from '../..'
import {MenuItem} from '../menu/MenuItem'

const renderDefaultMenus = (e: CellContextMenuEvent): any => {
	return (
		<Menu>
			<MenuItem
				onClick={() => {
					navigator.clipboard.writeText(e.value)
				}}
			>
				Copy
			</MenuItem>
		</Menu>
	)
}
export default (
	props: DataGridProps,
	apiRef: React.MutableRefObject<GridApi | undefined>,
	columnApiRef: React.MutableRefObject<ColumnApi | undefined>,
) => {
	const {renderPopupMenu = renderDefaultMenus} = props
	const onCellContextMenu = (e: CellContextMenuEvent) => {
		if (!renderPopupMenu) return
		if (e.event) {
			e.event.preventDefault()
			e.event.stopPropagation()
			e.event.cancelBubble = true
		}
		const Menu1 = renderPopupMenu(e)
		const mouseEvent = e.event as MouseEvent
		if (mouseEvent == null) return null
		const propsOverride = {anchorPosition: {top: mouseEvent.clientY + 2, left: mouseEvent.clientX - 2}}

		Utils.showPopupMenu(<Menu {...propsOverride}>{Menu1.props.children}</Menu>, e.event as MouseEvent)
		return false
	}

	const onContextMenu = (e: any) => {
		const api = apiRef.current
		const columnApi = columnApiRef.current
		if (api === undefined || columnApi === undefined) return

		// @ts-ignore
		if (props.onCellContextMenu || renderPopupMenu) {
			let target = e.target as Element
			let colId = null
			let rowId = null
			while (target != null && (colId === null || rowId === null)) {
				if (colId === null && target.hasAttribute('col-id')) {
					colId = target.getAttribute('col-id')
				}
				if (rowId === null && target.hasAttribute('row-id')) {
					rowId = target.getAttribute('row-id')
				}
				if (rowId === null && target.hasAttribute('row-index')) {
					rowId = target.getAttribute('row-index')
				}
				target = target.parentElement as Element
			}
			if (rowId !== null) {
				const column = colId && columnApi?.getColumn(colId)
				const colDef = colId && api?.getColumnDef(colId as string)
				const rowNode =
					rowId && (api?.getModel().getRowNode(rowId) || api?.getModel().getRow(parseInt(rowId || '')))
				const data = rowNode && rowNode.data
				const value = column && rowNode && api.getValue(column, rowNode)
				const context: any = {
					event: e,
					api,
					columnApi,
					column,
					colDef,
					node: rowNode,
					data,
					value,
				}
				if (props.onCellContextMenu) {
					props.onCellContextMenu(context)
				} else {
					onCellContextMenu(context)
				}
			}
		}
	}

	return onContextMenu
}
