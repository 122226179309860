import {useState, useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {ApiEmbeddedCryptoPriceResponse} from '@bakkt/api'
import {useStore} from 'store'

const INTERVAL_TIME = 30000 // milliseconds

const {getCryptoCurrencyPrices} = embeddedPartnerApi

export const useCryptoCurrencyPrices = (currency: any) => {
	const {selectedCryptoAccount, fiatCurrencyPreference, cryptoStates, addCryptoState, setAnyCryptoDown} = useStore()
	const [cryptoCurrencyPrice, setCryptoCurrencyPrice] = useState<ApiEmbeddedCryptoPriceResponse | null>(null)

	const {request, response, error} = ApiHook(() =>
		getCryptoCurrencyPrices(currency || 'BTC', fiatCurrencyPreference || 'USD'),
	)

	useEffect(() => {
		if (error) {
			addCryptoState(currency, false)
			setAnyCryptoDown(true)
		}
	}, [error])

	useEffect(() => {
		setCryptoCurrencyPrice(response)
		addCryptoState(currency, true)

		if (cryptoStates !== undefined && !cryptoStates.find(crypto => crypto.status == false)) {
			setAnyCryptoDown(false)
		}
	}, [response])

	useEffect(() => {
		request()
		const interval = setInterval(() => {
			request()
		}, INTERVAL_TIME)
		return () => {
			clearInterval(interval)
		}
	}, [selectedCryptoAccount])

	return {
		cryptoCurrencyPrice,
		request,
	}
}
