export enum SessionStorageKeys {
	ACCEPTED_SPEEDBUMP_MESSAGE = 'Accepted_Speedbump_Message',
	FAKE_ERROR = 'X-Fake-Error',
	FAKE_ERROR_CODE = 'X-Fake-Error-Code',
	FAKE_SSN = 'fake.ssnNumber',
	IsLoggedIn = 'IS_LOGGED_IN',
	PARTNER = 'partner',
	URL_THEME = 'url_theme',
	PARTY_DEBIT_CARD_ENABLED = 'party_debit_card_enabled',
	PARTY_BANK_LINK_ENABLED = 'party_bank_link_enabled',
}

export const SessionStorageKeysDefaults: {[Key in SessionStorageKeys]?: string} = {
	[SessionStorageKeys.ACCEPTED_SPEEDBUMP_MESSAGE]: 'false',
	[SessionStorageKeys.PARTNER]: 'finastra-sandbox',
}

export enum SessionStorageDataType {
	STRING = 'string',
	JSON = 'json',
}

export type SessionStorageProperties = {
	[Key in SessionStorageKeys]: SessionStorageDataType
}

export const SessionStorageValues: SessionStorageProperties = {
	[SessionStorageKeys.ACCEPTED_SPEEDBUMP_MESSAGE]: SessionStorageDataType.JSON,
	[SessionStorageKeys.FAKE_ERROR]: SessionStorageDataType.STRING,
	[SessionStorageKeys.FAKE_ERROR_CODE]: SessionStorageDataType.STRING,
	[SessionStorageKeys.FAKE_SSN]: SessionStorageDataType.JSON,
	[SessionStorageKeys.IsLoggedIn]: SessionStorageDataType.JSON,
	[SessionStorageKeys.PARTNER]: SessionStorageDataType.JSON,
	[SessionStorageKeys.URL_THEME]: SessionStorageDataType.JSON,
	[SessionStorageKeys.PARTY_DEBIT_CARD_ENABLED]: SessionStorageDataType.JSON,
	[SessionStorageKeys.PARTY_BANK_LINK_ENABLED]: SessionStorageDataType.JSON,
}

export enum ActivatedCryptoDebitCard {
	Activated = 'ACTIVATED_CRYPTO_DEBIT_CARD',
}
