import {useCallback, useState} from 'react'

/*
A hook for using with the CustomNumPad (will work with a text input too, but you might want
smarter formatting) to allow the user to enter a decimal number.

This hook stores the entered value as a string, allowing the user to type '.' and not lose it
like they would if we stored it as a number.  You could roll your own implementation of this
where you need it but more than likely you'll end up tracking more than one state value, or
playing with hooks - each of which will lead to more renders
*/

const makeDecimalRegex = (maxDecimalPoints: number) => {
	if (maxDecimalPoints <= 0) {
		return /^-?\d*/
	}
	return new RegExp(`^-?\\d*\\.?\\d{0,${maxDecimalPoints}}`)
}

export const useDecimalNumberInputting = (startValue: number | null = null, maxDecimalPoints = 2) => {
	const parseAmount = useCallback(
		(newAmount: string | number | null) => {
			if (newAmount === '.') {
				return '0.'
			}
			if (newAmount === null) {
				return '0'
			}

			return newAmount.toLocaleString('fullwide', {
				useGrouping: false,
				maximumFractionDigits: maxDecimalPoints,
			})
		},
		[maxDecimalPoints],
	)
	const [amountString, setStringAmount] = useState(parseAmount(startValue))
	const amount = Number(amountString)

	// only allow the input to go up to the specified number of decimals (variable to support bitcoin)
	// max character count = 11 (including decimal)
	const setAmount = (newAmount: string) => {
		if (Number(newAmount) >= Number.MAX_SAFE_INTEGER) {
			// Number type is precise till 9007199254740991 after than Number() will round number weird way
			return amount
		}
		const parsedAmount = parseAmount(newAmount)
		setStringAmount(parsedAmount)
		return Number(parsedAmount)
	}

	return {
		amount,
		amountString,
		setAmount,
	}
}

export default useDecimalNumberInputting
