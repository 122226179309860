import * as React from 'react'
import * as ag from 'ag-grid-community'
import Utils from '../../utils/Utils'
import {Dialog, HBox, VBox, List} from '../..'
import {ListItem} from '../list/ListItem'
import './ColumnPreferencesPopupStyles.scss'
interface Column {
	dataField: string
	headerText: string
}
interface ColumnPreferencesPopUpProps {
	callback: (columns: Column[]) => void
	available: Column[]
	visibles: Column[]
}
interface ColumnPreferencesPopUpStates {
	visibleColumns: Column[]
	availableColumns: Column[]
	visibleSelected: Column | null
}
export default class ColumnPreferencesPopUp extends React.Component<
	ColumnPreferencesPopUpProps,
	ColumnPreferencesPopUpStates
> {
	constructor(props: ColumnPreferencesPopUpProps) {
		super(props)
		this.state = {
			visibleColumns: this.props.visibles,
			availableColumns: this.props.available,
			visibleSelected: null,
		}
	}
	private close() {
		Utils.hideDialog()
	}

	labelForCol1(item: any) {
		const listItemProps = {
			id: item.dataField,
			primaryText: item.dataField,
		}
		return <ListItem {...listItemProps} />
	}
	private labelForCol(item: any) {
		const listItemProps = {
			id: item.dataField,
			primaryText: item.dataField + ' : ' + item.headerText,
		}
		return <ListItem {...listItemProps} />
	}

	// private updateLabel(headerText: string) {
	//   const visibleSelected = { ...this.state.visibleSelected, headerText };
	//   const newAvailable = this.setState({
	//     visibleColumns: this.state.visibleColumns.map(
	//       c => (c.dataField == visibleSelected.dataField ? visibleSelected : c)
	//     ),
	//     visibleSelected
	//   });
	// }

	private save() {
		this.props.callback(this.state.visibleColumns)
		Utils.hideDialog()
	}

	doDrop(ev: {target: any; dragIndex: number; dropIndex?: number; id: string}) {
		let available = this.state.availableColumns
		let displayed = this.state.visibleColumns
		if (ev === null || available === null || displayed === null) return

		let itemDropped = null

		if (ev.id === 'available') {
			itemDropped = available[ev.dragIndex]
			available[ev.dragIndex] = null as any
		} else {
			itemDropped = displayed[ev.dragIndex]
			displayed[ev.dragIndex] = null as any
		}
		if (ev.target.id === 'available') {
			if (ev.dropIndex != null) {
				available.splice(ev.dropIndex + 1, 0, itemDropped)
			} else {
				available = [itemDropped, ...available]
			}
		} else {
			if (ev.dropIndex != null) {
				displayed.splice(ev.dropIndex + 1, 0, itemDropped)
			} else {
				displayed = [itemDropped, ...displayed]
			}
		}
		this.setState({
			availableColumns: available.filter(c => c),
			visibleColumns: displayed.filter(c => c),
			visibleSelected: null,
		})
	}

	render() {
		return (
			<Dialog
				title='Column Preferences'
				open={true}
				saveText='Save'
				onCancel={this.close}
				onSave={() => this.save()}
				fullWidth={true}
				size={'sm'}
			>
				<VBox>
					<HBox>
						<VBox style={{width: '50%', margin: 5}}>
							<div>Available Columns</div>
							<List
								className='grabbable'
								data={this.state.availableColumns}
								dragEnabled={true}
								listItem={this.labelForCol1}
								style={{height: 300, width: '100%'}}
								dragComplete={event => this.doDrop(event)}
								id='available'
							/>
						</VBox>

						<VBox style={{width: '50%', margin: 5}}>
							<div>Displayed Columns</div>
							<List
								className='grabbable'
								data={this.state.visibleColumns}
								listItem={this.labelForCol}
								dragEnabled={true}
								style={{height: 300, width: '100%'}}
								dragComplete={event => this.doDrop(event)}
								allowMultipleSelection={false}
								onSelect={(e: any) => this.setState({visibleSelected: e})}
								id='displayed'
							/>
						</VBox>
					</HBox>
				</VBox>
			</Dialog>
		)
	}

	static openColumnPreference(e: ag.CellContextMenuEvent, viewId?: string) {
		const selected = e.columnApi
			.getAllGridColumns()
			.filter(c => c.isVisible())
			.map(c => ({
				dataField: c.getColId(),
				headerText: c.getColDef().headerName,
			}))
		const available = e.columnApi
			.getAllGridColumns()
			.filter(c => !c.isVisible())
			.sort((c1, c2) => c1.getColId().localeCompare(c2.getColId()))
			.map(c => ({
				dataField: c.getColId(),
				headerText: c.getColDef().headerName,
			}))
		Utils.showPopup(
			<ColumnPreferencesPopUp
				available={available as any}
				visibles={selected as any}
				callback={list => {
					const columnState = e.columnApi.getColumnState()
					const visibleColumns = list.map(c => {
						const column = columnState.filter(c1 => c.dataField === c1.colId)
						return {...column[0], hide: false}
					})
					const invisibleColumns = columnState.filter(c => {
						return !list.filter(c1 => c1.dataField === c.colId).length
					})
					const modifiedInvisiblesColmns = invisibleColumns.map(c => {
						return {...c, hide: true}
					})
					const allColumns = [...visibleColumns, ...modifiedInvisiblesColmns]
					e.columnApi.setColumnState(allColumns)
					viewId && localStorage.setItem(`${viewId + '.columnPreference'}`, JSON.stringify(allColumns))
				}}
			/>,
		)
	}
}
