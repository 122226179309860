import React from 'react'
import './splitpane.css'
import SplitPane, {Pane, SplitPaneProps as ISplitPaneProps, PaneProps as IPaneProps} from 'react-split-pane'
import {HBox} from '../container/HBox'
import {VBox} from '../container/VBox'
export type SplitPaneProps = ISplitPaneProps & {id?: string; disable?: boolean} & {children: any}
export type PaneProps = IPaneProps
export {Pane}

export default (props: SplitPaneProps) => {
	if (props.disable) {
		if (props.split == 'vertical') {
			return <HBox style={{flexGrow: 1, ...props.style}}>{props.children}</HBox>
		} else {
			return <VBox style={{flexGrow: 1, ...props.style}}>{props.children}</VBox>
		}
	}
	const SplitPane1: any = SplitPane
	return (
		<SplitPane1
			defaultSize={props.id && parseInt(localStorage.getItem(`${props.id}.splitPos`) || '0', 10)}
			onChange={(size: any) => {
				props.id && localStorage.setItem(`${props.id}.splitPos`, '' + size)
			}}
			{...props}
			style={{position: 'relative', ...props.style}}
		>
			{props.children}
		</SplitPane1>
	)
}
