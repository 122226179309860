import {Currency, CurrencyEnum} from 'store'

type CurrencyOptions = Omit<Intl.NumberFormatOptions, 'currency'>

export const formatUSD = (
	value: number | undefined | null,
	currency: CurrencyEnum,
	options?: CurrencyOptions,
	useDash?: boolean,
) => {
	if (!currency) return '-'
	const numberFormat = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency,
		...options,
	})
	return value || (value === 0 && !useDash) ? numberFormat.format(value) : '-'
}

export const formatCrypto = (
	value: number | undefined | null,
	currency: CurrencyEnum,
	precision?: number | undefined | null,
	options?: CurrencyOptions,
	useDash?: boolean,
) => {
	const formatter = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 0,
		maximumFractionDigits: precision || 8,
		...options,
	})
	const newVal = value || (value === 0 && !useDash) ? formatter.format(value) : '-'
	return `${newVal} ${currency}`
}

// Format a large number to include commas
const formatLargeNumber = (value: number, maximumFractionDigits = 10) => {
	const numberFormat = new Intl.NumberFormat('en-US', {
		maximumFractionDigits,
	})
	// NOTE:  Wallet used lodash to floor with precision of maximumFractionDigits
	return value || value === 0 ? numberFormat.format(Math.floor(value)) : '-'
}

export const formatCurrency = (
	value: number,
	currency: CurrencyEnum,
	precision?: number | undefined | null,
	options?: CurrencyOptions,
	useDash?: boolean,
) => {
	switch (currency) {
		case Currency.USD:
			return formatUSD(value, currency, options, useDash)
		case Currency.BTC:
		case Currency.ETH:
		case Currency.LTC:
		case Currency.SHIB:
		case Currency.BCH:
		case Currency.DOGE:
		case Currency.ETC:
		case Currency.USDC:
			return formatCrypto(value, currency, precision, options, useDash)
		default:
			return formatLargeNumber(value, options?.maximumFractionDigits || 0)
	}
}

// take an input string that represents a (possibly partially complete) currency value, and return
// it formatted for currency.  Eg:  1234.5 = $1,234.5

export const formatInputStringAsCurrency = (
	value: string,
	currency: CurrencyEnum,
	precision: number | undefined | null,
	maximumDecimalPlaces?: number,
) => {
	// whole === wholeAndDecimal[0], decimal === wholeAndDecimal[1]
	const wholeAndDecimal = value.split('.')

	return (
		formatCurrency(Number(wholeAndDecimal[0] || 0), currency, precision, {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}) +
		(wholeAndDecimal.length > 1
			? '.' + wholeAndDecimal[1].slice(0, maximumDecimalPlaces || wholeAndDecimal[1]?.length || 0)
			: '')
	)
}
