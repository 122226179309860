import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Large, Light, H2, Checkbox, Main, BottomBox, Icons, useTheme, VBox} from '@bakkt/components'
import Button from 'components/Button'
import Page from 'components/Page'
import {showWarning} from 'components/Dialog'
import {useStore} from 'store'
import {returnToPartner} from 'utils/returnToPartner'
import {ThemeOptions} from '@material-ui/core'
import {Link} from 'components/Link'
import {useNavigate} from 'react-router-dom'

const ExistingAccountErrorPage = () => {
	const {t} = useTranslation()
	const {partner, lastError} = useStore()
	const [termsAccepted, setTermsAccepted] = useState(false)
	const theme = useTheme() as ThemeOptions
	const navigate = useNavigate()

	const handleClick = () => {
		returnToPartner(true, 'bakkt_bank_link_failure', partner, navigate)
	}

	return (
		<Page titleHeader={t('crypto.accountActivate.activateCrypto')} withSubHeaderLogo={false}>
			<Main style={{marginBottom: '90px'}}>
				<div style={{textAlign: 'center'}}>
					<Icons.BakktLogo
						className={'logo'}
						width={131.58}
						height={52}
						fill={theme?.palette?.text?.background || '#231f20'}
						aria-label='Bakkt Logo'
					/>
				</div>
				<H2
					data-testid={'existing-account-error-page-title'}
					aria-live={'polite'}
					aria-label={t('crypto.existingAccountErrorPage.title', {firstName: lastError?.message})}
					style={{marginTop: '1.5em'}}
				>
					{t('crypto.existingAccountErrorPage.title', {firstName: lastError?.message})}
				</H2>
				<Large data-testid={'existing-account-error-page-description-section-1'}>
					{t('crypto.existingAccountErrorPage.description1')} <b>{partner?.name}</b>
					{t('crypto.existingAccountErrorPage.description2')}
				</Large>
				<Large data-testid={'existing-account-error-page-description-section-2'}>
					{t('crypto.existingAccountErrorPage.description3', {linkedPartnerName: partner?.name})}
				</Large>
			</Main>
			<BottomBox>
				<div style={{marginBottom: '40px', alignSelf: 'flex-start'}}>
					<Checkbox
						style={{textAlign: 'left'}}
						checked={termsAccepted}
						inputProps={{'aria-label': t('ariaLabels.accountErrorPage.checkbox')}}
						onChange={() => setTermsAccepted(!termsAccepted)}
						label={
							<Light style={{textAlign: 'left'}}>{`${t(
								'crypto.existingAccountErrorPage.checkBox',
							)}`}</Light>
						}
						name='accept-terms'
						className={'checkbox'}
					/>
				</div>
				<Button
					role='button'
					aria-label={`${t('ariaLabels.accountErrorPage.button')} ${t(
						'crypto.existingAccountErrorPage.returnToButton',
						{linkedPartnerName: partner?.name},
					)}`}
					className={'sharePersonalInfoBtn'}
					onClick={handleClick}
					data-dma='Close'
				>
					{t('crypto.existingAccountErrorPage.returnToButton', {linkedPartnerName: partner?.name})}
				</Button>
				<Link
					style={{
						marginTop: '28px',
						textDecoration: 'none',
						fontSize: '16px',
						padding: '2px',
					}}
					onEvent={() =>
						showWarning({
							title: t('crypto.existingAccountErrorPage.contactCustomerSupport'),
							WrappedComponent: () => (
								<VBox data-testid={'alert-dialog-description'}>
									{partner?.contactInfo?.phone && partner?.contactInfo?.phone !== 'none' && (
										<Link
											data-testid={'alert-dialog-phone'}
											href={`tel:${partner?.contactInfo?.phone}`}
										>
											{t('errors.contactByPhone', {phone: partner?.contactInfo?.phone})}
										</Link>
									)}
									{partner?.contactInfo?.email && partner?.contactInfo?.email !== 'none' && (
										<Link
											data-testid={'alert-dialog-email'}
											href={`mailto:${partner?.contactInfo?.email}`}
										>
											{t('errors.contactByEmail', {email: partner?.contactInfo?.email})}
										</Link>
									)}
								</VBox>
							),
							skipText: t('btnOkGotIt'),
						})
					}
					aria-label={t('crypto.existingAccountErrorPage.contactCustomerSupport')}
					data-testid='contact-customer-support-link'
				>
					{t('crypto.existingAccountErrorPage.contactCustomerSupport')}
				</Link>
			</BottomBox>
		</Page>
	)
}
export default ExistingAccountErrorPage
