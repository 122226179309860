import {useMemo} from 'react'
import {ApiAmountCurrencyEnum, ApiEmbeddedFundingAccount} from '@bakkt/api'
import {formatUSD} from 'utils/currencyFormatters'
import {VBox, HBox, Large, Light, Separator, Icons, color, toTitleCase, useTheme, CircleIcon} from '@bakkt/components'
import {handleKeyPress} from 'utils/handleKeyPress'
import {logger} from 'utils/logger/datadogInit'

import {useStore} from 'store'
import {Theme} from '@material-ui/core'

export type FundingSourceProps = {
	source: ApiEmbeddedFundingAccount
	onSelect?: () => void
	transactionType?: string
}

export const FundingAccount = ({source, onSelect, transactionType}: FundingSourceProps) => {
	const {setSelectedFundingAccount, setSelectedFundingAccountId, selectedFundingAccount} = useStore()
	const theme = useTheme() as Theme
	const accountType = source.accountType.toLowerCase()
	const institutionName = source?.name && source?.name !== 'N/A' ? source?.name : 'Account ending in'

	const isSelected = source?.externalAccountRef === selectedFundingAccount?.externalAccountRef
	const availableBalance = formatUSD(source?.availableBalance?.amount, ApiAmountCurrencyEnum.USD)
	const currentBalance = formatUSD(source?.currentBalance?.amount, ApiAmountCurrencyEnum.USD)
	const isCashAccount = useMemo(() => accountType === 'cash', [accountType])

	const accountNumber = source?.formattedAccountNumber?.match(/\d{4}/)
	const pendingDepositAmount = formatUSD(source?.pendingDepositAmount?.amount, ApiAmountCurrencyEnum.USD)

	const onSourceSelected = () => {
		if (onSelect) {
			return onSelect()
		}
		logger.info('Crypto transaction input page - Funding account clicked', {selected: isSelected})
		setSelectedFundingAccount(source)
		setSelectedFundingAccountId(source?.externalAccountRef)
	}

	return (
		<div
			id={`funding-source-${source.externalAccountRef}`}
			style={{cursor: 'pointer', padding: '0 3px 1px 3px', margin: '2px'}}
			className='accountRow'
			data-testid={`${accountType}-row`}
			onClick={onSourceSelected}
			tabIndex={0}
			onKeyPress={event => handleKeyPress(event, onSourceSelected)}
		>
			<VBox style={{marginRight: onSelect ? 0 : 15}}>
				<HBox style={{alignItems: 'center', padding: '15px 0px 15px', justifyContent: 'space-between'}}>
					{source.accountType === 'CASH' && (
						<Large
							data-testid={`funding-source-account-type-${accountType}`}
							id={`funding-source-account-type-${source.externalAccountRef}`}
							style={{color: theme?.palette?.text?.background || color.blue900, fontWeight: 500}}
						>
							{source.currentBalance?.currency}
						</Large>
					)}
					{source.accountType !== 'CASH' && transactionType && (
						<Large
							data-testid={`funding-source-account-type-${accountType}`}
							id={`funding-source-account-type-${source.externalAccountRef}`}
							style={{color: theme?.palette?.text?.background || color.blue900, fontWeight: 500}}
						>
							{toTitleCase(source.accountType)}
						</Large>
					)}
					<VBox
						data-testid={`funding-source-account-info-${accountType}`}
						id={`funding-source-account-info-${source.externalAccountRef}`}
						style={{
							color: theme?.palette?.text?.background || color.grey700,
						}}
					>
						{!transactionType && (
							<VBox>
								{institutionName && accountType.toLowerCase() !== 'cash' && (
									<Light
										data-testid={`funding-source-institution-name-${accountType}`}
										style={{margin: 0}}
									>
										<span>{accountType === 'debit' ? 'Debit card' : institutionName}</span>
									</Light>
								)}
								{accountNumber && !isCashAccount && (
									<Light
										data-testid={`funding-source-account-balance-${accountType}`}
										style={{margin: 0}}
									>
										<span>••••{accountNumber}</span>
									</Light>
								)}
							</VBox>
						)}
						<Light
							data-testid={`funding-source-account-number-${accountType}`}
							style={{margin: 0, fontSize: '12px'}}
						>
							{isCashAccount && pendingDepositAmount != '$0.00' ? 'Pending: ' + pendingDepositAmount : ''}
						</Light>
					</VBox>
					<HBox style={{alignItems: 'center'}}>
						{transactionType && (
							<VBox style={{textAlign: 'right'}}>
								{institutionName && accountType.toLowerCase() !== 'cash' && (
									<Light
										data-testid={`funding-source-institution-name-${accountType}`}
										style={{margin: 0}}
									>
										<span>{institutionName}</span>
									</Light>
								)}
								{accountNumber && !isCashAccount && (
									<Light
										data-testid={`funding-source-account-balance-${accountType}`}
										style={{margin: 0}}
									>
										<span>••••{accountNumber}</span>
									</Light>
								)}
							</VBox>
						)}

						{accountType.toLowerCase() === 'cash' && (
							<Light data-testid={`funding-source-account-balance-${accountType}`} style={{margin: 0}}>
								<span>{availableBalance}</span>
							</Light>
						)}
						{!onSelect && (
							<CircleIcon
								className={`check ${isSelected ? 'selected' : 'unselected'}`}
								id={`selected-funding-source-check-${source.externalAccountRef}`}
								data-testid={`funding-source-checkbox-${accountType}`}
								icon={Icons.Check}
								iconColor={
									isSelected ? theme?.palette?.secondary?.contrastText || 'white' : 'transparent'
								}
								style={{
									background: isSelected
										? theme?.palette?.secondary?.main || color.blueKolibri
										: 'transparent',
									border: isSelected
										? `1px ${theme?.palette?.background?.paper} solid`
										: `1px ${theme?.palette?.text?.background} solid`,
									margin: 'auto 0px auto 25px',
								}}
							/>
						)}
					</HBox>
				</HBox>
				<Separator />
			</VBox>
		</div>
	)
}
