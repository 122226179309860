import {useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {useStore} from 'store'
import {ApiAmountCurrencyEnum} from '@bakkt/api'

const INTERVAL_TIME = 30000 // milliseconds

const {fetchAccount} = embeddedPartnerApi
export const useCryptoAccountBalance = () => {
	const {selectedCryptoAccount, selectedCryptoAccountCurrency, setSelectedCryptoAccount} = useStore()

	const {request, response} = ApiHook(() =>
		fetchAccount(selectedCryptoAccount?.accountBalance?.currency as ApiAmountCurrencyEnum),
	)

	useEffect(() => {
		if (!selectedCryptoAccountCurrency) return
		request()
		const interval = setInterval(() => {
			request()
		}, INTERVAL_TIME)
		return () => {
			clearInterval(interval)
		}
	}, [selectedCryptoAccount, selectedCryptoAccountCurrency])

	useEffect(() => {
		if (response) setSelectedCryptoAccount(Object.assign(selectedCryptoAccount || {}, response))
	}, [response])
}
