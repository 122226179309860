import {Alert, Light, makeStyles, Link, HBox, useTheme} from '@bakkt/components'
import {useFetchPartnerLimits} from 'hooks'
import {formatUSD} from 'utils/currencyFormatters'
import {ApiEmbeddedPartnerTransactionOperationTypeEnum as TransactionType} from '@bakkt/api'

import {useStore} from 'store'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useSelectedCryptoAccountInfo, useCryptoCurrencyExecutePrices, InputType} from 'hooks'
import {useTranslation} from 'react-i18next'
import {ThemeOptions} from '@material-ui/core'
import {shallow} from 'zustand/shallow'

export interface CryptoLimitsProps {
	transactionType: TransactionType
	setButtonDisabled: Dispatch<SetStateAction<boolean>>
	fiatAmount: number
	cryptoAmount: number
	handlerOnSellAllCrypto: () => void
}

const useStyles = makeStyles(() => ({
	root: {
		marginBottom: '10px',
	},
	message: {
		textAlign: 'center',
		width: '100%',
	},
}))

const CryptoLimits = ({
	transactionType,
	fiatAmount,
	cryptoAmount,
	setButtonDisabled,
	handlerOnSellAllCrypto,
}: CryptoLimitsProps) => {
	const classes = useStyles()
	const {selectedCryptoAccount, selectedFundingAccount, syncExecutePrices} = useStore(
		state => ({
			selectedCryptoAccount: state.selectedCryptoAccount,
			selectedFundingAccount: state.selectedFundingAccount,
			syncExecutePrices: state.syncExecutePrices,
		}),
		shallow,
	)
	const partnerLimits = useFetchPartnerLimits()
	const {selectedCryptoCurrencyPrecision} = useSelectedCryptoAccountInfo()
	const [alertText, setAlertText] = useState<string>('')
	const {t} = useTranslation()
	const theme = useTheme() as ThemeOptions

	const indicativeBalanceCurrency = selectedCryptoAccount?.indicativeBalance?.currency
	const availableBalance = selectedFundingAccount?.availableBalance?.amount
	const {accountBalance} = selectedCryptoAccount || {}
	const inputType = InputType.Crypto

	const {fiatAmount: maxFiatAccountBalance, setCryptoAmount} = useCryptoCurrencyExecutePrices({
		transactionType,
		inputType,
		forceTrigger: syncExecutePrices,
		allowUpdate: false,
	})

	useEffect(() => {
		setCryptoAmount(accountBalance?.amount?.toString() || '0')
	}, [syncExecutePrices])

	const getMin = () => {
		switch (transactionType) {
			case TransactionType.BUY:
				return partnerLimits?.minimumBuyLimit
			case TransactionType.SELL:
				return partnerLimits?.minimumSellLimit
		}
	}

	const getMax = () => {
		switch (transactionType) {
			case TransactionType.BUY:
				return formatUSD(partnerLimits?.buyDailyLimit, indicativeBalanceCurrency)
			case TransactionType.SELL:
				return formatUSD(maxFiatAccountBalance, indicativeBalanceCurrency)
		}
	}

	const isAmountAboveMinimum = () => {
		const isFiatAboveMinimum = fiatAmount && fiatAmount >= (getMin() || 0.01)
		const isCryptoAboveMinimum = cryptoAmount && cryptoAmount >= 1 / 10 ** (selectedCryptoCurrencyPrecision || 2)
		return isFiatAboveMinimum && isCryptoAboveMinimum
	}

	const MAX_TRANSACTION_AMOUNT = 999.99
	const isAmountLessThanMaximum = () => {
		switch (transactionType) {
			case TransactionType.BUY:
				const maxAmount = Math.min(
					availableBalance || MAX_TRANSACTION_AMOUNT,
					partnerLimits?.buyDailyLimit || MAX_TRANSACTION_AMOUNT,
				)
				return fiatAmount && maxAmount && fiatAmount <= maxAmount
			case TransactionType.SELL:
				return cryptoAmount && cryptoAmount <= (selectedCryptoAccount?.accountBalance?.amount || 0)
		}
	}

	useEffect(() => {
		if (!isAmountAboveMinimum() && fiatAmount !== 0) {
			setAlertText(t('errors.minimumTransaction', {min: formatUSD(getMin(), indicativeBalanceCurrency)}))
			setButtonDisabled(true)
		} else if (!isAmountLessThanMaximum() && fiatAmount !== 0) {
			if (fiatAmount && partnerLimits?.buyDailyLimit && fiatAmount > partnerLimits?.buyDailyLimit) {
				setAlertText(t('errors.maximumTransaction', {max: getMax()}))
			} else {
				// is above funding source amount and below max daily
				setAlertText('')
			}
			setButtonDisabled(true)
		} else {
			setAlertText('')
			setButtonDisabled(false)
		}
	}, [fiatAmount, cryptoAmount, partnerLimits, availableBalance])

	return (
		<>
			<HBox
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'row',
					margin: 0,
				}}
			>
				<Light style={{display: 'inline-block'}}>
					{t('crypto.transaction.dailyLimits', {
						min: formatUSD(getMin(), indicativeBalanceCurrency),
						max: getMax(),
					})}
				</Light>
				{transactionType === TransactionType.SELL && (
					<>
						<span
							style={{
								height: '14px',
								borderRight: `1px solid ${theme?.palette?.text?.background}`,
								paddingLeft: '6px',
							}}
						></span>
						<Link
							style={{
								textDecoration: 'none',
								paddingLeft: '5px',
								paddingRight: '2px',
							}}
							onClick={() => handlerOnSellAllCrypto()}
							tabIndex={0}
						>
							{t('crypto.sell.sellAll')}
						</Link>
					</>
				)}
			</HBox>
			{alertText && (
				<Alert
					severity='error'
					icon={false}
					onClose={undefined}
					classes={{root: classes.root, message: classes.message}}
					data-testid='crypto-limit-alert'
				>
					{alertText}
				</Alert>
			)}
		</>
	)
}

export default CryptoLimits
