import {CryptoCurrencyConfig, useStore} from 'store'

export const useCryptoCurrencyDetails = (
	currency: string | undefined | null,
): CryptoCurrencyConfig | undefined | null => {
	const {cryptoCurrencyDetails} = useStore()

	if (currency === undefined || currency === null) {
		return currency
	}
	return cryptoCurrencyDetails?.find(item => currency === item.currency)
}
