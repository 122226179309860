import {useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {RoutesName} from 'constants/routes'
import {useNavigate} from 'hooks'
import {showOnboardingErrorDialog} from 'components/ErrorDialog'
import {useStore} from 'store'

const {updateParty} = embeddedPartnerApi

export const useUpdateParty = (ew9Signature: string) => {
	const navigate = useNavigate()
	const {partner, party} = useStore()

	const {request, response, loading, error} = ApiHook(() => updateParty({ew9Signature: ew9Signature}))

	useEffect(() => {
		if (error) showOnboardingErrorDialog(partner)
	}, [error])

	useEffect(() => {
		if (response) {
			party && (party.ew9CertificationRequired = false)
			navigate(`${RoutesName.onboarding.home}${RoutesName.onboarding.success}`, {ignore: true})
		}
	}, [response])

	return {
		loading,
		updateParty: request,
	}
}
