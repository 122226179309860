import React, {useState, useEffect} from 'react'
import {Spinner, BottomBox, Main, Checkbox, Light, VBox, HBox, H1} from '@bakkt/components'
import Button from 'components/Button'
import Page from 'components/Page'
import {useStore} from 'store'
import {formatCurrency} from 'utils/currencyFormatters'
import {logger} from 'utils/logger/datadogInit'
import {ReceiptItem} from 'components/ReceiptItem'
import {Receipt} from 'components/Receipt'
import {apiCall, embeddedPartnerApi} from 'api'
import {RoutesName} from 'constants/routes'
import {useCryptoCurrencyPrices, useNavigate} from 'hooks'
import {showErrorDialog} from 'components/ErrorDialog'
import {ApiError, ApiPartnerRequestResponse} from '@bakkt/api'
import {formatDate} from '../../../../../utils/formatters'

const PayoutConfirm = () => {
	const payoutRequestId = (window as any).requestId
	const {onShowToast} = useStore()
	const [payoutRequest, setPayoutRequest] = useState<ApiPartnerRequestResponse>()
	const [isAutoApprove, setIsAutoApprove] = useState(false)
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const requestData =
		payoutRequest?.partnerRequest?.requestData && JSON.parse(payoutRequest?.partnerRequest?.requestData)
	const partnerPartyLinkId = payoutRequest?.partnerPartyLink?.id || ''
	const isAutoConfirmEnabled = payoutRequest?.partnerPartyLink?.asyncbuyAutoconfirm || false
	const {cryptoCurrencyPrice} = useCryptoCurrencyPrices(requestData?.currency)
	const cryptoAmount = (requestData?.price?.amount || 0) / (cryptoCurrencyPrice?.indicativePrice?.amount || 0)

	useEffect(() => {
		logger.info('Pay Request Confirm page - initialized', {
			filename: 'PayoutConfirm.tsx',
		})

		async function fetchPartnerPayoutRequest() {
			const {response, error} = await apiCall(() => embeddedPartnerApi.fetchPartnerRequest(payoutRequestId))
			if (response) {
				setPayoutRequest(response)
			} else if (error) {
				showErrorDialog({
					message: 'Unable to fetch payout request details at this time. Please try again later.',
				})
			}
		}
		fetchPartnerPayoutRequest()
	}, [payoutRequestId])

	const approvePayout = async () => {
		logger.info(`Payout Request Confirm page - confirm submitted`, {})

		setLoading(true)
		const {response, error} = await apiCall(() =>
			embeddedPartnerApi.acceptPartnerRequest(partnerPartyLinkId, payoutRequestId),
		)
		let autoApproveError: ApiError | undefined
		if (isAutoApprove) {
			const {response, error} = await apiCall(() =>
				embeddedPartnerApi.updatePartnerPartyAutoconfirm(partnerPartyLinkId, isAutoApprove),
			)
			autoApproveError = error
		}
		setLoading(false)

		if (error) {
			showErrorDialog({
				message: 'Unable to process payout approval at this time. Please try again later.',
			})
		} else if (autoApproveError) {
			showErrorDialog({
				message: 'Unable to change payout auto approval preferences at this time. Please try again later.',
			})
		} else {
			onShowToast({
				toastMessage: 'Payout confirmed',
				toastType: 'success',
			})
		}
		navigate(RoutesName.home)
	}

	const renderAutoApproveCheckbox = () => {
		return (
			<Checkbox
				checked={isAutoApprove}
				inputProps={{
					'aria-label': 'Auto approve future crypto payouts',
				}}
				onChange={() => {
					setIsAutoApprove(!isAutoApprove)
				}}
				label={
					<VBox>
						<Light style={{textAlign: 'left', fontWeight: 'bold', margin: 0, paddingTop: '2vh'}}>
							Auto approve future crypto payouts
						</Light>
						<Light style={{textAlign: 'left', margin: 0}}>
							I agree to have all future individual payouts automatically processed.
						</Light>
					</VBox>
				}
				name='accept-autoapprove'
				className={'checkbox'}
			/>
		)
	}

	const handleCancel = () => {
		navigate(RoutesName.home)
	}

	return (
		<Page withBackIcon title={'Pending Payout'}>
			{loading ? (
				<div style={{marginTop: 'auto', marginLeft: 'auto', marginRight: 'auto', marginBottom: '-50px'}}>
					<Spinner />
				</div>
			) : (
				<Main>
					<VBox verticalGap={15}>
						<HBox>
							<H1>Pending Payout</H1>
						</HBox>
					</VBox>
					<Light style={{margin: 0}}>
						These amounts represent market value at time of initiation for payment with{' '}
						{payoutRequest?.partnerBasicInfo?.name}. This could change at the time of execution.
					</Light>
					<Receipt>
						<ReceiptItem
							name={`${requestData?.currency} Payout`}
							value={formatCurrency(requestData?.price?.amount || 0, requestData?.price?.currency)}
							subName={`Issued ${formatDate(payoutRequest?.partnerRequest?.created).formattedDate}`}
							subValue={formatCurrency(cryptoAmount, requestData?.currency)}
						/>
						<ReceiptItem name={'Payer'} value={payoutRequest?.partnerBasicInfo?.name} />
						<ReceiptItem
							name={'Partner Reference'}
							value={payoutRequest?.partnerPartyLink?.partnerPartyRef}
						/>
						<ReceiptItem
							name={`${requestData?.currency} Amount`}
							value={formatCurrency(cryptoAmount, requestData?.currency)}
						/>
					</Receipt>
					<Light>This payout is an estimate. Actual amount may change upon payment.</Light>
					{!isAutoConfirmEnabled && renderAutoApproveCheckbox()}
				</Main>
			)}
			<BottomBox>
				<Button
					id='payout-approve-confirm-button'
					style={{marginTop: '3vh'}}
					onClick={approvePayout}
					loading={loading}
					disabled={loading}
					hidden={loading}
				>
					Approve Payout
				</Button>
				<Button
					intent='link'
					size='small'
					style={{marginTop: '3vh'}}
					onClick={handleCancel}
					loading={loading}
					disabled={loading}
					hidden={loading}
				>
					Cancel
				</Button>
			</BottomBox>
		</Page>
	)
}

export default PayoutConfirm
