import {useState, useMemo} from 'react'
import {isValidZipCode} from 'validate'

import {useDelayedValidation} from '../useDelayedValidation'
import {OPTIONS_STATE} from '../../apps/main/pages/account/EditAccountAddress'

export const useAddress = (value: string, type: string) => {
	console.log('Va;ue ', value)
	const [error, setError] = useState('')
	const isValid = useMemo(() => isValidZipCode(value), [value])
	const stateDetails = OPTIONS_STATE.find(stateObj => stateObj.value === value)

	const isValidState = useMemo(() => stateDetails?.value !== 'HI', [value])

	useDelayedValidation({
		input: value,
		validate: () => {
			if (value && !isValid && type === 'Zip Code') {
				setError(`Invalid ${type}`)
			}
			if (value && !isValidState && type === 'state') {
				setError(`${stateDetails?.label} is not currently supported`)
			}
		},
		clearError: () => setError(''),
	})

	return {
		isValid,
		isValidState,
		error,
		setError,
	}
}
