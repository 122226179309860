import React from 'react'
import {HBox, Light, Link, Medium, VBox} from '@bakkt/components'
import {RoutesName} from '../constants/routes'
import {useNavigate} from 'react-router-dom'
import {
	displayBakktTermsOfUseDialog,
	displayPrivacyPolicyDialog,
	displayLicensesAndDisclosuresDialog,
} from '../apps/onboarding/pages/Consent'
import {useStore} from '../store'
import {logout} from '../api'
import {returnToPartner} from '../utils/returnToPartner'

const Footer = () => {
	const navigate = useNavigate()
	const {partner} = useStore()
	const {isPartnerBakkt} = useStore.getState()

	const linkStyle = {
		margin: 0,
		textDecoration: 'none',
	}

	const handleLogout = () => {
		logout().then(() => returnToPartner(false, null, partner))
	}

	const gotoTaxes = () => {
		navigate(RoutesName.taxes)
	}

	return (
		<VBox style={{marginTop: '10px'}} verticalGap={10}>
			<HBox style={{margin: 'auto'}} horizontalGap={10}>
				<Link size={'small'} style={linkStyle} onClick={() => navigate(RoutesName.viewAccountDetails)}>
					Your Info
				</Link>
				<Light style={{backgroundColor: 'inherit', margin: 0}}>|</Light>
				<Link size={'small'} style={linkStyle} onClick={handleLogout} data-dma='Close'>
					Log Out
				</Link>
			</HBox>
			<HBox style={{margin: 'auto'}} horizontalGap={10}>
				<Link size={'small'} style={linkStyle} onClick={gotoTaxes}>
					Crypto Transaction Report
				</Link>
				<Light style={{backgroundColor: 'inherit', margin: 0}}>|</Light>
				<Link size={'small'} style={linkStyle} onClick={displayBakktTermsOfUseDialog}>
					Terms of Service
				</Link>
				<Light style={{backgroundColor: 'inherit', margin: 0}}>|</Light>
				<Link size={'small'} style={linkStyle} onClick={displayPrivacyPolicyDialog}>
					Privacy Policy
				</Link>
			</HBox>
			{isPartnerBakkt && (
				<div style={{margin: 'auto'}}>
					<Link size={'small'} style={linkStyle} onClick={displayLicensesAndDisclosuresDialog}>
						Licenses &amp; Disclosures
					</Link>
				</div>
			)}
			<>&nbsp;</>
			{(partner?.contactInfo?.email || partner?.contactInfo?.phone) && (
				<div style={{margin: 'auto'}}>
					<Medium style={{textAlign: 'center', fontWeight: 550, margin: 0}}>Contact Customer Care</Medium>
					{partner?.contactInfo?.phone && (
						<Medium style={{textAlign: 'center', margin: 0}}>{partner?.contactInfo.phone}</Medium>
					)}
					{partner?.contactInfo?.email && (
						<Medium style={{textAlign: 'center', margin: 0}}>{partner?.contactInfo.email}</Medium>
					)}
				</div>
			)}
			<>&nbsp;</>
			<HBox style={{margin: 'auto', marginBottom: '10px'}}>
				<Medium style={{textAlign: 'center', margin: '0'}}>© 2023 Bakkt, LLC All Rights Reserved</Medium>
			</HBox>
		</VBox>
	)
}

export default Footer
