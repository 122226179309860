import {PlaidLinkOptions, usePlaidLink, PlaidLinkOnSuccess, PlaidLinkOnExit, PlaidLinkOnEvent} from 'react-plaid-link'
import {useMemo, useState} from 'react'
import {ApiCreateBankAccountRequestVerificationStatusEnum} from '@bakkt/api'
import {RoutesName} from 'constants/routes'

export type PlaidAccount = {
	accountRef: string
	publicToken: string
	requestRef: string
	institutionName: string
	institutionRef: string
	mask: string
	verificationStatus: ApiCreateBankAccountRequestVerificationStatusEnum
	linkSessionRef: string
}

type UseSetupPlaid = (
	token: string,
	handleSuccess: (plaidAccount: PlaidAccount) => void,
	onPlaidExit: Function,
) => {
	open: Function
	exit: Function
	ready: boolean
	error: ErrorEvent | null
}

export const hasOAuthState = () => window.location.href.includes('oauth_state_id')

export const useSetupPlaid: UseSetupPlaid = (token, handleSuccess, onPlaidExit) => {
	const onSuccess: PlaidLinkOnSuccess = (publicToken, plaidSuccess) => {
		const bankAccount = plaidSuccess?.accounts?.[0]
		const bakktPlaidAccount = {
			accountRef: bankAccount?.id ?? '',
			requestRef: plaidSuccess?.link_session_id ?? '',
			institutionName: plaidSuccess?.institution?.name ?? '',
			institutionRef: plaidSuccess?.institution?.institution_id ?? '',
			mask: bankAccount?.mask ?? '',
			verificationStatus: bankAccount?.verification_status?.toString()?.toUpperCase() as any,
			publicToken,
			linkSessionRef: plaidSuccess?.link_session_id ?? '',
		}
		handleSuccess(bakktPlaidAccount)
	}
	const onExit: PlaidLinkOnExit = (error, metadata) => {
		console.log('PlaidLinkOnExit', error, metadata)
		onPlaidExit()
	}
	const onEvent: PlaidLinkOnEvent = (eventName, metadata) => {
		console.log('PlaidLinkOnEvent', eventName, metadata)
	}
	const plaidConfig: PlaidLinkOptions = useMemo(() => {
		return {
			...(hasOAuthState() && {receivedRedirectUri: window.location.href}),
			token,
			onSuccess,
			onExit,
			onEvent,
		} as PlaidLinkOptions
	}, [token])

	const {open, exit, ready, error} = usePlaidLink(plaidConfig)
	return {open, exit, ready, error}
}

export default useSetupPlaid
