import React, {ReactElement} from 'react'
import {VBox} from '@bakkt/components'
import {ReceiptItemProps} from './ReceiptItem'

export const Receipt = ({
	children,
	style = {},
}: {
	style?: React.CSSProperties
	children: Array<ReactElement<ReceiptItemProps>>
}) => {
	return <VBox style={{marginTop: '1.2rem', ...style}}>{children || [].map((child, i) => child)}</VBox>
}
