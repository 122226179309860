import {useEffect, useState} from 'react'
import axios from 'axios'

export const useGetTrackingId = () => {
	const [xTrackingId, setXTrackingId] = useState('')
	useEffect(() => {
		axios.interceptors.response.use(
			function (response) {
				const newXTrackingId = response?.headers?.['x-tracking-id']
				if (newXTrackingId) {
					setXTrackingId(newXTrackingId)
				}
				return response
			},
			error => {
				const newXTrackingId = error?.response?.headers?.['x-tracking-id']
				if (newXTrackingId) {
					setXTrackingId(newXTrackingId)
				}
				return error
			},
		)
	})
	return xTrackingId
}
