import React from 'react'
import styled from '@emotion/styled'
import {color} from 'src/color/color'

const DefaultLabel = styled.span`
	font-family: ${props => props?.theme?.typography?.fontFamily || 'BrownPro'};
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 150%;
	flex: none;
	color: ${props => props?.theme?.palette?.text?.background || color.grey700};
	order: 0;
	align-self: center;
	flex-grow: 0;
	margin: 10px 0px;
`

export const Label = DefaultLabel
