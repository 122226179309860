import {DialogProps, showDialog} from 'components/Dialog'
import {t} from 'i18next'
import {Icons, DialogText, Link, VBox, Utils} from '@bakkt/components'
import {EmbeddedWebState} from 'store'
import {returnToPartner} from 'utils/returnToPartner'
import {useStore} from 'store'

interface ErrorDialogProps {
	partner?: EmbeddedWebState['partner']
	WrappedComponent?: React.ComponentClass<any> | (() => JSX.Element) | any
	navigate?: any
	errorCode?: string
}

export const showErrorDialog = async ({
	title,
	message,
	saveText,
	partner,
	navigate,
	...props
}: ErrorDialogProps & Partial<DialogProps>) => {
	const {isPartnerBakkt} = useStore.getState()
	await showDialog({
		title: title || t('errors.modalTitle'),
		message,
		saveText:
			partner?.name && !isPartnerBakkt
				? t('crypto.returnToPartnerButton', {partnerName: partner?.name})
				: t('bntContinue'),
		onSave: () => {
			console.log('errorCode', props?.errorCode)
			returnToPartner(true, 'bakkt_bank_link_failure', partner, navigate, props?.errorCode)
		},
		icon: Icons.DialogWarning,
		hideCancel: true,
		preventHideDialog: true,
		saveButtonStyle: {
			marginBottom: '30px',
		},
		...props,
	})
}

export const showSuspendedErrorDialog = (partner?: EmbeddedWebState['partner']) => {
	const phone = partner?.contactInfo?.phone
	const email = partner?.contactInfo?.email

	showErrorDialog({
		title: t('errors.suspendedTitle'),
		WrappedComponent: () => (
			<VBox>
				<DialogText>{t<string>('errors.suspendedMessage')}</DialogText>
				{phone && phone !== 'none' && (
					<Link href={`tel:${phone}`}>{t<string>('errors.contactByPhone', {phone})}</Link>
				)}
				{email && email !== 'none' && (
					<Link href={`mailto:${email}`}>{t<string>('errors.contactByEmail', {email})}</Link>
				)}
			</VBox>
		),
		partner,
	})
}

export const showOnboardingErrorDialog = (partner?: EmbeddedWebState['partner']) => {
	showErrorDialog({
		message: t('errors.onboardingError'),
		partner,
	})
}

export const showTransactionErrorDialog = (partner?: EmbeddedWebState['partner']) => {
	showErrorDialog({
		message: t('errors.transactionError'),
		partner,
	})
}

export const showTransactionCryptoErrorDialog = async (currency?: string) => {
	await showErrorDialog({
		message: t('errors.transactionCryptoError'),
		saveText: currency
			? t('crypto.returnToCurrencyOverviewButton', {currencyName: currency})
			: t('crypto.returnButton'),
		onSave: () => {},
		preventHideDialog: false,
	})
}

export const showUpdateProfileErrorDialog = () => {
	showErrorDialog({
		message: t('errors.profileUpdateError'),
	})
}
