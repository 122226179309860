import React, {useState} from 'react'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import MTabs from '@material-ui/core/Tabs'
import MaterialUITab from '@material-ui/core/Tab'
import {createTheme, MuiThemeProvider} from '@material-ui/core'
import {color} from 'src/color/color'
import {Separator} from 'src/components/separator'
import {HBox, Icons, Utils} from 'src'
import Menu from '@material-ui/core/Menu'
import Tooltip from '@material-ui/core/Tooltip'

const LightTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: `${color.white}`,
		color: `${color.grey800}`,
		boxShadow: theme.shadows[1],
		fontSize: 13,
		maxWidth: 'none',
		borderRadius: 0,
	},
}))(Tooltip)
interface TabItem {
	label: string
	id: string
	isClosable?: boolean
}

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: color.white,
		color: color.blue900,
	},
	indicator: {
		background: color.blue900,
	},
	tab: {
		fontFamily: 'BrownPro',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '18px',
		lineHeight: '150%',
		color: color.blue900,
		textTransform: 'none',
		minWidth: 100,
	},
}))

const theme = createTheme({
	props: {
		MuiButtonBase: {
			disableRipple: true,
		},
	},
})

export interface TabContextMenuEvent {
	item: TabItem | string
	mouseEvent: MouseEvent
}

export interface TabProps {
	data: (TabItem | string)[]
	value?: number
	onChange?: (event: React.ChangeEvent<any>, newValue: number) => void
	onTabClosed?: (item: TabItem | string) => void
	renderPopupMenu?: (e: TabContextMenuEvent) => JSX.Element
	isMainTab?: boolean
}

export const Tabs = (props: TabProps) => {
	const {isMainTab = false} = props
	const classes = useStyles()

	const handleContextMenu = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: TabItem | string) => {
		if (props.renderPopupMenu) {
			e.preventDefault()
			e.stopPropagation()
			if (e.nativeEvent) {
				e.nativeEvent.cancelBubble = true
			}

			const Parent = props.renderPopupMenu({item, mouseEvent: e.nativeEvent})
			Utils.showPopupMenu(
				<Menu open anchorPosition={{top: e.clientY, left: e.clientX}} anchorReference='anchorPosition'>
					{Parent.props.children}
				</Menu>,
				e.nativeEvent,
			)
			return false
		}
	}

	const renderTab = (input: TabItem | string) => {
		const id1 = input as string
		const item = input as TabItem
		const id = item.id || item.label || id1
		const label = item.label || item.id || id1
		const tabLabelToShow = isMainTab && label.length > 20 ? `${label.substring(0, 16)}~${label.length - 16}` : label
		return (
			<MaterialUITab
				label={
					<HBox>
						{isMainTab && (
							<LightTooltip title={<span>{label}</span>} placement='bottom'>
								<span aria-controls='simple-menu' onContextMenu={e => handleContextMenu(e, input)}>
									{tabLabelToShow}
								</span>
							</LightTooltip>
						)}
						{!isMainTab && (
							<span aria-controls='simple-menu' onContextMenu={e => handleContextMenu(e, input)}>
								{tabLabelToShow}
							</span>
						)}

						{(input as TabItem).isClosable && (
							<Icons.XSmll
								style={{transform: 'scale(0.5)', paddingTop: 3}}
								onClick={() => props.onTabClosed && props.onTabClosed(input)}
							/>
						)}
					</HBox>
				}
				key={id}
				classes={{root: classes.tab}}
				style={{outline: 'none'}}
			/>
		)
	}

	return (
		<MuiThemeProvider theme={theme}>
			<div className={classes.root}>
				<MTabs
					variant='scrollable'
					scrollButtons='auto'
					aria-label='scrollable auto tabs'
					classes={{
						indicator: classes.indicator,
					}}
					{...props}
				>
					{props.data && props.data.map(t => renderTab(t))}
				</MTabs>
				<Separator color={color.grey100} orientation='horizontal' thickness={1} />
			</div>
		</MuiThemeProvider>
	)
}

export interface TabPanelProps {
	viewId?: string
	tabNames: string[]
	selectedIndex?: number
	tabRightAligned?: boolean
	onChange?: (evt: any) => void
	disposable?: boolean
	children: React.ReactNode
	navStyle?: React.CSSProperties | undefined
	renderToolbar?: () => JSX.Element
}

export const TabPanel: React.FC<TabPanelProps> = (props: any) => {
	const {children, renderToolbar = () => {}, ...other} = props
	const childrens = React.Children.toArray(children)
	const initIndex = props.viewId && localStorage.getItem(props.viewId + '.selectedIndex')
	const [selectedIndex, setSelectedIndex] = useState(props.viewId && initIndex ? parseInt(initIndex) : 0)
	const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
		setSelectedIndex(newValue)
		localStorage.setItem(props.viewId + '.selectedIndex', '' + newValue)
		props.onChange &&
			props.onChange({
				prevSelectIndex: selectedIndex,
				selectedIndex: newValue,
				event,
			})
	}
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'stretch',
				flexGrow: 1,
				...(other.style || {}),
			}}
		>
			<HBox>
				<Tabs data={props.tabNames} value={selectedIndex} onChange={handleChange} />
				{renderToolbar()}
			</HBox>

			<div
				style={{
					padding: '10px',
					flex: '1 1 100%',
					...props.containerStyle,
				}}
			>
				{childrens.map((e, index) => {
					if (selectedIndex === index) {
						if (props.disposable !== false) {
							return e
						} else {
							return (
								<div key={index} style={{width: '100%'}}>
									{e}
								</div>
							)
						}
					} else {
						if (props.disposable !== false) {
							return null
						} else {
							return (
								<div key={index} style={{display: 'none'}}>
									{e}
								</div>
							)
						}
					}
				})}
			</div>
		</div>
	)
}
