import React, {ButtonHTMLAttributes} from 'react'
import {Theme} from '@emotion/react'
import styled from '@emotion/styled'
import {CircularProgress} from '@material-ui/core'
import {color} from 'src/color/color'
import * as helper from 'src/components/button/helper'
import {BrownPro} from 'src'

export interface ExtraButtonStyles {
	style?: {
		focus?: {
			underline?: boolean
		}
	}
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
	ExtraButtonStyles & {
		size?: 'small' | 'medium' | 'large'
		intent?: 'link' | 'toolbar' | 'dialog'
		minimal?: boolean
		fullWidth?: boolean
		outline?: boolean
		loading?: boolean
		color?: string
		backgroundColor?: string
		theme?: Theme
	}

// outline, fullWidth and backgroundColor defined but not used to prevent DOM warnings
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DefaultButton: React.FC<ButtonProps> = ({children, loading, outline, fullWidth, backgroundColor, ...rest}) => (
	<button aria-busy={loading} {...rest}>
		{loading ? <CircularProgress size={24} /> : children}
	</button>
)

export const Button = styled(DefaultButton)`
	font-family: ${(props: ButtonProps) => props?.theme?.typography?.fontFamily || BrownPro};
	background: ${(props: ButtonProps) => helper.getBackgroundColor(props)};
	color: ${(props: ButtonProps) => (props.outline ? color.blue900 : color.success)};
	border-radius: ${(props: ButtonProps) => helper.getBorderRadius(props)};
	padding: ${(props: ButtonProps) => helper.getPadding(props)};
	cursor: pointer;
	text-align: center;
	align-items: center;
	line-height: ${(props: ButtonProps) => (props.intent == 'toolbar' ? '85%' : '150%')};
	height: ${(props: ButtonProps) => helper.getButtonHeight(props)};
	min-width: ${(props: ButtonProps) => helper.getButtonWidth(props)};
	width: ${(props: ButtonProps) => (props.fullWidth ? '100%' : 'auto')};
	max-width: ${(props: ButtonProps) => (props.fullWidth ? '400px' : 'auto')};
	font-size: ${(props: ButtonProps) =>
		props.size ? helper.buttonSizeProps[props.size].fontSize : helper.buttonSizeProps.medium.fontSize};
	text-decoration: ${(props: ButtonProps) =>
		props.intent == 'link' || props.intent == 'toolbar' ? 'underline' : 'none'};
	width: ${(props: ButtonProps) => (props.fullWidth ? '100%' : 'auto')};
	transition: background 200ms, color 200ms;
	&:disabled {
		opacity: 1;
		background-color: ${(props: ButtonProps) => (props.intent || props.minimal ? color.white : color.grey100)};
		color: ${color.grey700};
		cursor: not-allowed;
		border: ${(props: ButtonProps) => (props.outline ? `1px solid ${color.grey400}` : 'none')};
	}
	,
	&:hover:not(:disabled),
	&:active,
	&.active {
		color: ${(props: ButtonProps) => helper.getColor(props)};
		border: ${(props: ButtonProps) => (props.outline ? `1px solid ${color.success}` : 'none')};
		outline: 0 !important;
		box-shadow: none !important;
	}
	&:focus {
		box-shadow: 0 0 0 3pt ${color.blue700} !important;
		text-decoration: ${(props: ButtonProps) => (props?.style?.focus?.underline ? 'underline' : 'none')};
	}
`
