import {MinimalList} from 'components/List'
import {NA} from 'components/Text'
import {nameFormatter} from 'utils/formatters'
import {useTranslation} from 'react-i18next'
import {EmbeddedWebState} from 'store'
import {ApiParty} from '@bakkt/api'
import {PartyUpdateInformationProps} from 'store'

export interface TaxpayerInformationListProps {
	partyProfile: EmbeddedWebState['partyProfile']
	party: ApiParty
	missingProfileFields: EmbeddedWebState['missingProfileFields']
	partyUpdatedDetails: PartyUpdateInformationProps | null
}

export const TaxpayerInformationList = ({
	partyProfile,
	missingProfileFields,
	party,
	partyUpdatedDetails,
}: TaxpayerInformationListProps) => {
	const {t} = useTranslation()
	const concatedName = nameFormatter(
		partyUpdatedDetails?.newFirstName! ||
			partyProfile?.Party?.firstName ||
			missingProfileFields?.firstName ||
			party?.firstName,
		partyUpdatedDetails?.newLastName! ||
			partyProfile?.Party?.lastName ||
			missingProfileFields?.lastName ||
			party?.lastName,
	)

	return (
		<MinimalList
			data={[
				{
					primaryText: t('inputLabel.name'),
					contentRight: concatedName || '',
				},
				{
					primaryText: t('inputLabel.businessName'),
					contentRight: '',
				},
				{
					primaryText: t('inputLabel.federal'),
					contentRight: 'Individual',
				},
				{
					primaryText: t('inputLabel.exemptions'),
					contentRight: '',
				},
				{
					primaryText: t('inputLabel.addressLine', {number: 1}),
					contentRight:
						partyUpdatedDetails?.streetLine1! ||
						partyProfile?.Party?.address?.streetLine1 ||
						missingProfileFields?.address?.streetLine1 ||
						party?.address?.streetLine1 ||
						'',
				},
				{
					primaryText: t('inputLabel.addressLine', {number: 2}),
					contentRight:
						partyProfile?.Party?.address?.streetLine2 ||
						missingProfileFields?.address?.streetLine2 ||
						party?.address?.streetLine2 ||
						'',
				},
				{
					primaryText: t('inputLabel.city'),
					contentRight:
						partyUpdatedDetails?.locality! ||
						partyProfile?.Party?.address?.locality ||
						missingProfileFields?.address?.locality ||
						party?.address?.locality ||
						'',
				},
				{
					primaryText: t('inputLabel.state'),
					contentRight:
						partyUpdatedDetails?.region! ||
						partyProfile?.Party?.address?.region ||
						missingProfileFields?.address?.region ||
						party?.address?.region ||
						'',
				},
				{
					primaryText: t('inputLabel.zipCode'),
					contentRight:
						partyUpdatedDetails?.postalCode! ||
						partyProfile?.Party?.address?.postalCode ||
						missingProfileFields?.address?.postalCode ||
						party?.address?.postalCode ||
						'',
				},
				{
					primaryText: t('inputLabel.requesterNameAddress'),
					contentRight: '',
				},
				{
					primaryText: t('inputLabel.accountNumbers'),
					contentRight: '',
				},
			]}
			capitalize
		/>
	)
}
