import {Icons, toTitleCase, useTheme} from '@bakkt/components'
import {formatFundingSourceInfo} from 'utils/formatters'
import {useStore} from 'store'

import {FundingRow} from './FundingRow'
import {Theme} from '@material-ui/core'

type Props = {
	label: string
	onClick?: () => void
	style?: React.CSSProperties
}

export const FundingAccountRow = ({label, onClick, style = {}}: Props) => {
	const {selectedFundingAccount} = useStore()
	const theme = useTheme() as Theme
	const fundingSourceInfo = formatFundingSourceInfo(selectedFundingAccount)
	const accountName = toTitleCase(selectedFundingAccount?.accountType || '')
	return (
		<FundingRow
			label={label}
			fundingName={accountName || '-'}
			fundingDetails={fundingSourceInfo}
			onClick={onClick}
			icon={
				accountName
					? Icons.BankCircle({
							fill: theme?.palette?.primary?.contrastText,
							stroke: theme?.palette?.primary?.main,
					  })
					: undefined
			}
			style={style}
			dataTestId={'destination'}
		/>
	)
}
