import {HTMLAttributes} from 'react'
import styled from '@emotion/styled'
import {color as c} from 'src'

interface MainProps extends HTMLAttributes<HTMLDivElement> {
	center?: boolean
	centerText?: boolean
}

export const Main = styled.div<MainProps>`
	padding-left: 16px;
	padding-right: 16px;
	width: calc(100% - 32px);
	max-width: 660px;
	margin: 0 auto;
	text-align: ${props => (props.centerText || props.center ? 'center' : 'initial')};
	flex-grow: ${props => (props.center ? 1 : 0)};
	display: ${props => (props.center ? 'flex' : 'block')};
	justify-content: ${props => (props.center ? 'center' : 'flex-start')};
	flex-direction: ${props => (props.center ? 'column' : 'row')};
`

export const BottomBox = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	margin-top: auto;
	margin-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 7%;
	padding-right: 7%;
	width: 86%;
	max-width: 660px;
	text-align: center;
	align-items: center;
	justify-content: center;
`

export const SmallCircleButton = styled.button`
	border-radius: 50%;
	width: 24px;
	height: 24px;
	background: #fff;
	border: 1px solid ${c.grey40};
	display: flex;
	justify-content: center;
	align-items: center;
`
