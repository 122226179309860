import React, {useEffect} from 'react'
import {showDialog} from './Dialog'
import {useRemoteConfigValue} from 'hooks'
import {RemoteConfigFlags} from '../utils/firebase/remoteConfigProperties'

export const TestDialog = () => {
	const showTestDialog = useRemoteConfigValue(RemoteConfigFlags.SHOW_TEST_DIALOG)
	const dialogNumber = useRemoteConfigValue(RemoteConfigFlags.TEST_DIALOG_NUMBER)
	const dialogString = useRemoteConfigValue(RemoteConfigFlags.TEST_DIALOG_STRING)
	const dialogObject = useRemoteConfigValue(RemoteConfigFlags.TEST_DIALOG_OBJECT)

	useEffect(() => {
		if (showTestDialog) {
			showDialog({
				title: `Dialog: Test #${dialogNumber}`,
				WrappedComponent: () => (
					<>
						Dialog Test String: {dialogString}
						<br />
						Dialog Test Object: {dialogObject?.testValue}
					</>
				),
				hideSave: true,
				hideCancel: true,
				skip: true,
				skipText: 'OK',
			})
		}
	}, [showTestDialog, dialogNumber, dialogString, dialogObject])

	return null
}
