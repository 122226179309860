import React from 'react'
import {makeStyles, MenuItem as OMenuItem, MenuItemProps as OMenuItemProps} from '@material-ui/core'
export type MenuItemProps = OMenuItemProps
const useStyles = makeStyles(theme => ({
	root: {
		fontFamily: 'BrownPro',
	},
}))
export const MenuItem: React.FC<MenuItemProps> = (props: MenuItemProps) => {
	const classes = useStyles()
	return <OMenuItem {...(props as any)} classes={{root: classes.root}} />
}
