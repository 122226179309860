import React, {Children} from 'react'
import {Alert as AlertLab, AlertProps as AlertLabProps} from '@material-ui/lab'

interface AlertProps extends AlertLabProps {}

export const Alert = (props: AlertProps) => {
	const {children, ...rest} = props
	return (
		<AlertLab onClose={() => {}} {...rest}>
			{children}
		</AlertLab>
	)
}
