import {Alert, Button, H1, HBox, Input, Main, VBox} from '@bakkt/components'
import {embeddedBakktAuthApi} from 'api'

import Page from 'components/Page'
import {RoutesName} from 'constants/routes'
import {usePassword} from 'hooks/auth/usePassword'
import {useCallback, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useStore} from 'store'
import {useTranslation} from 'react-i18next'
import {getErrorMessage} from 'utils/ErrorMessageFormatter'

const ResetNewPassword = () => {
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [serverError, setServerError] = useState<string | null>(null)
	const {identifier, setIdentifier, setBakktLoginRequired} = useStore()
	const {error: passwordError, isValid} = usePassword(password)
	const {error: confirmPasswordError, isValid: isConfirmValid} = usePassword(confirmPassword)
	const navigate = useNavigate()
	const {t} = useTranslation()
	const handlePasswordChanged = useCallback((event: {target: {value: string}}) => {
		setPassword(event.target.value)
	}, [])

	const handleConfirmPasswordChanged = useCallback((event: {target: {value: string}}) => {
		setConfirmPassword(event.target.value)
	}, [])

	const handleSubmit = async () => {
		try {
			const response = await embeddedBakktAuthApi.resetPasswordFinalize({
				identifierToken: identifier?.identifierToken || '',
				password,
			})
			if (response.data.success) {
				if (window.location.search.indexOf('code=') >= 0) {
					window.location.href = '/'
				}
				setBakktLoginRequired(!response?.data?.success)
				setTimeout(() => window.location.reload())
			} else {
				setServerError(t(`${getErrorMessage(response?.data?.error?.code!, 'resetPasswordError')}`))
			}
		} catch (error: any) {
			setServerError(t(`${getErrorMessage(error?.response?.data?.error?.code!, 'resetPasswordError')}`))
		}
	}

	const handleCancel = () => {
		setIdentifier(undefined)
		navigate(`/${RoutesName.auth.login}`)
	}

	return (
		<Page titleHeader='Create Password' withSubHeaderLogo={false} showJointLogo={false}>
			<Main className={'boxMain'}>
				{serverError && (
					<Alert
						severity='error'
						icon={false}
						onClose={() => setServerError(null)}
						data-testid='passwordchange-error-alert'
					>
						{serverError}
					</Alert>
				)}
				<VBox verticalGap={15}>
					<HBox>
						<div style={{flexGrow: 0.5}} />
					</HBox>
					<HBox style={{margin: 'auto'}}>
						<H1>Let’s make sure your new password works</H1>
					</HBox>
					<Input
						error={!!passwordError}
						helperText={passwordError}
						type={'password'}
						label='Password'
						value={password}
						onChange={handlePasswordChanged}
					/>
					<Input
						error={!!confirmPasswordError}
						helperText={confirmPasswordError}
						type={'password'}
						label='Confirm'
						value={confirmPassword}
						onChange={handleConfirmPasswordChanged}
					/>
					<HBox>
						<div style={{flex: 1}} />
					</HBox>
					<Button
						disabled={confirmPassword !== password || !(isValid && isConfirmValid)}
						size='large'
						onClick={handleSubmit}
					>
						Continue
					</Button>
					<Button intent='link' size='large' onClick={handleCancel}>
						Cancel
					</Button>
				</VBox>
			</Main>
		</Page>
	)
}

export default ResetNewPassword
