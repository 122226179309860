import {ThemeProvider as EmotionThemeProvider} from '@emotion/react'
import {
	ClassNameMap,
	Styles,
	WithStylesOptions,
	useTheme as useMuiTheme,
	makeStyles as makeMuiStyles,
} from '@material-ui/styles'
import {ThemeProvider as MUIThemeProvider, ThemeOptions, Theme as BakktTheme} from '@material-ui/core'
import {createTheme} from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

export function useTheme<BakktTheme>(): BakktTheme {
	return useMuiTheme()
}

export function makeStyles<Props extends object, ClassKey extends string = string>(
	styles: Styles<BakktTheme, Props, ClassKey>,
	options?: Omit<WithStylesOptions<BakktTheme>, 'withTheme'>,
): keyof Props extends never // `makeStyles` where the passed `styles` do not depend on props
	? (props?: any) => ClassNameMap<ClassKey> // `makeStyles` where the passed `styles` do depend on props
	: (props: Props) => ClassNameMap<ClassKey> {
	return makeMuiStyles(styles, options)
}

export interface ThemeApi {
	backgroundColor?: string | null
	backgroundFontColor?: string | null
	externalBackgroundColor?: string | null
	externalBackgroundFontColor?: string | null
	font?: string | null
	primaryColor?: string | null
	primaryFontColor?: string | null
	secondaryColor?: string | null
	secondaryFontColor?: string | null
	tertiaryColor?: string | null
	tertiaryFontColor?: string | null
	unvisitedLinkColor?: string | null
	visitedLinkColor?: string | null
	logoUrl?: string | null
	disableShadow?: boolean
}

export const createThemeFromApi = (apiSource?: ThemeApi, fallbackTheme: ThemeOptions = {}): ThemeOptions => {
	const theme = apiSource
		? {
				palette: {
					background: {
						...(apiSource.externalBackgroundColor && {default: apiSource.externalBackgroundColor}),
						...(apiSource.backgroundColor && {paper: apiSource.backgroundColor}),
					},
					primary: {
						...(apiSource.primaryColor && {main: apiSource.primaryColor}),
						...(apiSource.primaryFontColor && {contrastText: apiSource.primaryFontColor}),
					},
					secondary: {
						...(apiSource.secondaryColor && {main: apiSource.secondaryColor}),
						...(apiSource.secondaryFontColor && {contrastText: apiSource.secondaryFontColor}),
					},
					tertiary: {
						...(apiSource.tertiaryColor && {main: apiSource.tertiaryColor}),
						...(apiSource.tertiaryFontColor && {contrastText: apiSource.tertiaryFontColor}),
					},
					text: {
						...(apiSource.backgroundFontColor && {background: apiSource.backgroundFontColor}),
						...(apiSource.externalBackgroundFontColor && {
							externalBackground: apiSource.externalBackgroundFontColor,
						}),
						...(apiSource.primaryFontColor && {primary: apiSource.primaryFontColor}),
						...(apiSource.secondaryFontColor && {secondary: apiSource.secondaryFontColor}),
						...(apiSource.tertiaryFontColor && {tertiary: apiSource.tertiaryFontColor}),
						...(apiSource.unvisitedLinkColor && {newLink: apiSource.unvisitedLinkColor}),
						...(apiSource.visitedLinkColor && {visitedLink: apiSource.visitedLinkColor}),
					},
				},
				typography: {
					...(apiSource.font && {fontFamily: apiSource.font}),
				},
				...(apiSource.logoUrl && {logoUrl: apiSource.logoUrl}),
			}
		: {}

	return _.merge({}, fallbackTheme, theme)
}

export interface ThemeProviderProps {
	children: React.ReactNode
	theme: ThemeOptions
}

export let CurrentTheme = {} as BakktTheme
export const BakktThemeProvider = ({theme, children}: ThemeProviderProps) => {
	CurrentTheme = createTheme(theme)
	return (
		<MUIThemeProvider theme={CurrentTheme}>
			<EmotionThemeProvider theme={CurrentTheme}>{children}</EmotionThemeProvider>
		</MUIThemeProvider>
	)
}
