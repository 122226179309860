import {useState, useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {ApiEmbeddedPartnerPartyTaxIdResponse} from '@bakkt/api'
import {useTimer} from '@bakkt/components'
import {showOnboardingErrorDialog} from 'components/ErrorDialog'
import {useStore} from 'store'

const {fetchFullTaxId} = embeddedPartnerApi

const RETENTION_TIME = 30000

export const useTaxIdentifier = () => {
	const [taxIdentifier, setTaxIdentifier] = useState<ApiEmbeddedPartnerPartyTaxIdResponse['taxIdentifier'] | null>(
		null,
	)
	const {request, response, error, loading} = ApiHook(() => fetchFullTaxId())
	const [setTimer] = useTimer(RETENTION_TIME, () => setTaxIdentifier(''))
	const {partner} = useStore()

	useEffect(() => {
		if (response) {
			setTaxIdentifier(response?.taxIdentifier)
			setTimer()
		}
	}, [response])

	useEffect(() => {
		if (error) showOnboardingErrorDialog(partner)
	}, [error, partner])

	return {
		taxIdentifier,
		loading,
		getTaxIdentiferResponse: response,
		getTaxIdentifier: request,
		resetTaxIdentifier: () => setTaxIdentifier(''),
	}
}
