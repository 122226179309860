import {ApiAmountCurrencyEnum} from '@bakkt/api'
import {
	Main,
	BottomBox,
	ResponsiveInput,
	Scrollbar,
	Button,
	Large,
	HBox,
	VBox,
	H2,
	Alert,
	Medium,
} from '@bakkt/components'
import {FundingAccount} from 'components/FundingAccount'
import Page from 'components/Page'
import {RoutesName} from 'constants/routes'
import {useNavigate} from 'hooks'
import {t} from 'i18next'
import {useEffect, useState} from 'react'
import {useStore} from 'store'
import {formatUSD} from 'utils/currencyFormatters'
import {SessionStorageKeys} from 'utils/sessionStorageProperties'
import {useLocation} from 'react-router-dom'

const CashInOutInput = () => {
	const navigate = useNavigate()
	const state: any = useLocation().state
	const {selectedFundingAccount, setSelectedFundingAccountId, setSelectedFundingAccount} = useStore()
	const isCashIn = state?.type === 'cash_in'
	const [amount, setAmount] = useState('')
	const {fundingAccounts, partner} = useStore()
	const cashFundingAccount = fundingAccounts.find(account => account.accountType == 'CASH')
	const [message, setMessage] = useState<{message: string; error: boolean} | null>(null)
	const partyDebitCardEnabled = localStorage.getItem(SessionStorageKeys.PARTY_DEBIT_CARD_ENABLED) === 'true'
	const debitFundingEnabled = partner?.featureFlag?.debitCardLinkEnabled! || partyDebitCardEnabled
	const achFundingEnabled = partner?.featureFlag?.achLinkEnabled
	const linkedAccounts =
		fundingAccounts?.filter(
			account =>
				account.accountType != 'CASH' &&
				((debitFundingEnabled && account.accountType === 'DEBIT') ||
					(achFundingEnabled && account.accountType !== 'DEBIT')),
		) || []
	const linkedAccountsExists = linkedAccounts.length > 0
	const cashInCashOutDisabled = !linkedAccountsExists || amount == '' || parseFloat(amount) < 10

	useEffect(() => {
		if (linkedAccounts.length > 0) {
			let filter = linkedAccounts.filter(
				linkedAccount => linkedAccount.externalAccountRef === selectedFundingAccount?.externalAccountRef,
			)
			if (filter && filter.length > 0) {
				setSelectedFundingAccountId(filter[0].externalAccountRef)
				setSelectedFundingAccount(filter[0])
			} else {
				setSelectedFundingAccountId(linkedAccounts[0].externalAccountRef)
				setSelectedFundingAccount(linkedAccounts[0])
			}
		} else {
			setSelectedFundingAccountId(null)
			setSelectedFundingAccount(null)
		}
	}, [])

	const handleNext = () => {
		navigate(RoutesName.bakktFunding.cashConfirm, {state: {type: state?.type, amount}})
	}

	const handleCancel = () => {
		navigate(`/${RoutesName.bakktFunding.cashPage}`)
	}
	return (
		<Page
			withBackIcon
			onIconPress={() => navigate(`/${RoutesName.bakktFunding.cashPage}`)}
			titleHeader={'Manage your cash balance'}
		>
			<Main>
				<VBox>
					{message && (
						<Alert
							severity={message.error ? 'error' : 'info'}
							icon={false}
							onClose={() => setMessage(null)}
						>
							{message.message}
						</Alert>
					)}
				</VBox>
				<HBox>
					<div style={{flexGrow: 1}} />
					<ResponsiveInput
						value={amount}
						inputType='text'
						preLabel='$'
						style={{marginBottom: 0}}
						onValueChange={newValue => {
							if (!/^\d*\.?\d*$/.test('' + newValue)) {
								newValue = amount
							}
							const value = '' + (newValue || '')
							const actualNum = parseFloat(value)
							if (actualNum < 10) {
								setMessage({message: 'Enter a minimum of $10.', error: true})
							} else if (actualNum > 999 && isCashIn) {
								// TODO: Fetch maximum value from API in the future
								setMessage({message: 'The daily maximum deposit limit is $999.', error: true})
							} else if (actualNum > 999 && !isCashIn) {
								// TODO: Fetch maximum value from API in the future
								setMessage({message: 'The daily maximum withdraw limit is $999.', error: true})
							} else if (message?.error) {
								setMessage(null)
							}
							const decimalIndex = value.indexOf('.')
							if (decimalIndex > 0 && value.length > decimalIndex + 3) {
								return value.substring(0, decimalIndex + 3)
							}

							setAmount(value)
							return value
						}}
					/>
					<div style={{flexGrow: 1}} />
				</HBox>
				<Medium
					style={{
						textAlign: 'center',
						fontSize: 12,
					}}
				>
					{formatUSD(cashFundingAccount?.availableBalance?.amount, ApiAmountCurrencyEnum.USD)} Cash Available
				</Medium>

				{linkedAccountsExists && (
					<Scrollbar height={(fundingAccounts?.length || 0) > 3 ? '250px' : '100vh'}>
						{linkedAccounts.map(account => (
							<FundingAccount key={account.externalAccountRef} source={account} />
						))}
					</Scrollbar>
				)}
				{!isCashIn && linkedAccountsExists && selectedFundingAccount?.accountType !== 'DEBIT' && (
					<Medium>Your transaction will process within 3-5 business days.</Medium>
				)}
				{!linkedAccountsExists && (
					<Large>No funding accounts linked. Please link a bank account to add cash or cash out.</Large>
				)}
			</Main>
			<BottomBox style={{marginTop: '50px', marginBottom: '30px'}}>
				{linkedAccountsExists && (
					<VBox verticalGap={10} style={{width: '-webkit-fill-available'}}>
						<Button size='large' disabled={cashInCashOutDisabled} onClick={handleNext}>
							{isCashIn ? 'Add Cash' : 'Cash Out'}
						</Button>
						<Button intent='link' size='large' onClick={handleCancel}>
							Cancel
						</Button>
					</VBox>
				)}
			</BottomBox>
		</Page>
	)
}

export default CashInOutInput
