import React from 'react'

type Props = {
	backgroundColor?: string
	color?: string
	size?: number
}

const WarningBasic = ({backgroundColor, color, size = 20}: Props) => {
	return (
		<svg
			width={size || 20}
			height={size || 20}
			fill={backgroundColor || '#D93C30'}
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle cx={'50%'} cy={'50%'} r={'50%'} />
			<path
				d='M11.266 14.94h1.495l.345-5.75V3.9H10.92v5.29l.345 5.75zm-.874 3.68a1.6 1.6 0 001.61 1.61 1.6 1.6 0 001.61-1.61 1.6 1.6 0 00-1.61-1.61 1.6 1.6 0 00-1.61 1.61z'
				transform='scale(0.55)'
				fill={color || '#FFFFFF'}
			/>
		</svg>
	)
}

export default WarningBasic
