import {initializeApp, FirebaseApp} from 'firebase/app'
import {getEnv} from 'utils/config'

export default class Firebase {
	static app: FirebaseApp

	static getApp = () => {
		if (!this.app) this.initializeApp()
		return this.app
	}

	static initializeApp = () => {
		const REACT_APP_FIREBASE_API_KEY = getEnv('FIREBASE_API_KEY')
		const REACT_APP_FIREBASE_PROJECT_ID = getEnv('FIREBASE_PROJECT_ID')
		const REACT_APP_FIREBASE_APP_ID = getEnv('FIREBASE_APP_ID')

		const firebaseConfig = {
			apiKey: REACT_APP_FIREBASE_API_KEY,
			authDomain: `${REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
			projectId: REACT_APP_FIREBASE_PROJECT_ID,
			storageBucket: `${REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
			appId: REACT_APP_FIREBASE_APP_ID,
		}

		this.app = initializeApp(firebaseConfig)
	}
}
