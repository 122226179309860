import {useEffect, useState} from 'react'
import {apiCall, embeddedPartnerApi} from 'api'
import {DetailSection} from './DetailSection'
import {useStore} from 'store'
import {ActivityHeader, Main} from '@bakkt/components'
import Page from 'components/Page'
import {formatUSD} from 'utils/currencyFormatters'
import {logger} from 'utils/logger/datadogInit'
import {useCryptoCurrencyDetails} from 'hooks/useCryptoCurrencyDetails'
import {
	conversionAmountFor,
	headerFor,
	iconFor,
	PartnerTransaction,
	textFor,
	toCryptoAmount,
	toFiatAmount,
} from '../activity/utils'

export const TransactionDetails = () => {
	const {activePartnerTransactionId, partnerTransaction, isPartnerBakkt} = useStore()
	const [transactionInfo, setTransactionInfo] = useState<PartnerTransaction | null>(partnerTransaction)
	const [loading, setLoading] = useState(partnerTransaction ? false : true)
	const transactionType = transactionInfo?.operationType as any
	const crypto = toCryptoAmount(transactionInfo)
	const fiatAmount = toFiatAmount(transactionInfo)
	const fiatAmountString =
		fiatAmount?.currency && fiatAmount.amount && formatUSD(Math.abs(fiatAmount.amount) || 0, fiatAmount.currency)
	const currencyDetails = useCryptoCurrencyDetails(crypto?.currency)

	useEffect(() => {
		logger.info('Over view page, Transaction details - initilized', {filename: 'TransactionDetails.tsx'})
	}, [])

	useEffect(() => {
		async function fetchTransactionDetails() {
			const {response} = await apiCall(() =>
				embeddedPartnerApi.getTransactionDetails(activePartnerTransactionId || ''),
			)
			setLoading(false)
			if (isPartnerBakkt) {
				setTransactionInfo(prevState => {
					const prevTransactionInfo = {...prevState}
					prevTransactionInfo.fundingSource = response?.fundingSource
					prevTransactionInfo.formattedFundingAccountNumber = response?.formattedFundingAccountNumber
					return prevTransactionInfo as any
				})
			} else {
				setTransactionInfo(response || null)
			}
			logger.info('Over view page, Transaction details - api call success', {
				status: response?.status,
				currencyType: response?.operationType,
				sourceCurrency: response?.source?.currency,
				targetCurrency: response?.target?.currency,
			})
		}
		if (activePartnerTransactionId) {
			fetchTransactionDetails()
		}
	}, [activePartnerTransactionId])

	return (
		<Page
			withBackIcon
			titleHeader={`${headerFor(transactionInfo as any)} ${currencyDetails?.name || ''}`}
			loading={loading}
		>
			<Main>
				<ActivityHeader
					icon={iconFor(transactionType)}
					activityText={textFor(transactionInfo as any)}
					amount={crypto ? `${crypto?.amount} ${crypto?.currency}` : ''}
					conversionAmount={conversionAmountFor(transactionInfo as any, fiatAmountString)}
				/>
				<DetailSection
					currencyDetails={currencyDetails}
					transactionInfo={transactionInfo}
					fiatAmount={fiatAmountString}
					crypto={crypto}
				/>
			</Main>
		</Page>
	)
}
