import {HBox, color, Icons, Light, InputLabel, useTheme, FKGrotesk, VBox} from '@bakkt/components'
import {Theme} from '@material-ui/core'
import {ReactElement, ReactNode} from 'react'
import {handleKeyPress} from 'utils/handleKeyPress'

export interface ReceiptItemProps {
	style?: React.CSSProperties
	name?: string
	subName?: string
	value?: ReactElement | ReactNode | JSX.Element
	subValue?: ReactElement | ReactNode | JSX.Element
	children?: ReactElement[]
	onInfoClicked?: () => void
}

export const ReceiptItem = ({
	name,
	value,
	subName,
	subValue,
	children,
	onInfoClicked,
	style = {},
}: ReceiptItemProps) => {
	const theme = useTheme() as Theme
	const containsSubRow = subName || subValue

	if (!containsSubRow) {
		return renderMainRow()
	} else {
		return renderMainAndSubRow()
	}

	function renderMainRow() {
		return (
			<HBox
				style={{
					borderBottom: containsSubRow ? '0px' : '1px solid',
					borderBottomColor: theme?.palette?.text?.background || color.grey100,
					paddingTop: '0.4rem',
					paddingBottom: containsSubRow ? '0' : '0.8rem',
					marginTop: '0.3rem',
					marginBottom: '0.3rem',
					alignItems: 'center',
					...style,
				}}
			>
				{name && (
					<Light data-testid={`${name}-label`} style={{margin: 0}}>
						{name}
					</Light>
				)}
				{onInfoClicked && (
					<Icons.InfoCircle
						id={name ? `${name.toLowerCase()}-info-button` : 'info-button'}
						onClick={onInfoClicked}
						style={{marginLeft: 5, transform: 'scale(0.7, 0.7)', cursor: 'pointer'}}
						fill={theme?.palette?.text?.background || color.grey700}
						tabIndex={0}
						onKeyPress={event => handleKeyPress(event, onInfoClicked)}
					/>
				)}
				{value && (
					<div style={{marginLeft: 'auto'}}>
						<div
							data-testid={`${name}-value`}
							style={{
								fontWeight: 400,
								margin: 0,
								color: theme?.palette?.text?.background || color.grey500,
								fontSize: '14px',
								fontFamily: theme?.typography?.fontFamily || FKGrotesk,
								textAlign: 'end',
							}}
						>
							{value}
						</div>
					</div>
				)}
				{children || [].map((child, i) => child)}
			</HBox>
		)
	}

	function renderSubRow() {
		return (
			<HBox
				style={{
					borderBottom: '1px solid',
					borderBottomColor: theme?.palette?.text?.background || color.grey100,
					padding: '0 0 0.8rem 0',
					marginTop: '0.1rem',
					marginBottom: '0.3rem',
					alignItems: 'center',
				}}
			>
				{subName && (
					<InputLabel data-testid={`${subName}-sublabel`} style={{margin: 0}}>
						{subName}
					</InputLabel>
				)}
				{subValue && (
					<div style={{marginLeft: 'auto'}}>
						<InputLabel data-testid={`${subValue}-subvalue`} style={{margin: 0, textAlign: 'end'}}>
							{subValue}
						</InputLabel>
					</div>
				)}
			</HBox>
		)
	}

	function renderMainAndSubRow() {
		return (
			<VBox>
				{renderMainRow()}
				{renderSubRow()}
			</VBox>
		)
	}
}
