import {Alert, Button, H1, HBox, Input, Light, Main, VBox} from '@bakkt/components'
import {useStore} from 'store'
import React, {useEffect, useState} from 'react'
import Page from '../../../../components/Page'
import {embeddedPartnerApi} from '../../../../api'
import {cleanPhoneNumber} from '../../../../validate'
import {RoutesName} from '../../../../constants/routes'
import {useNavigate} from 'react-router-dom'
import {useUserId} from '../../../../hooks/auth/useUserId'

const EditAccountEmail = () => {
	const [newPhone, setNewPhone] = useState('')
	const {lastError, setIdentifier, setUserIdentity, partyProfile, setRedirectUrlAfterOtpValidation} = useStore()
	const [errorMessage, setErrorMessage] = useState('')
	const [isValidIdentifier, setIsValidIdentifier] = useState(true)
	const {isMobileNumber: isMobileNumber, isValid: isNewPhoneValid, error: newPhoneError} = useUserId(newPhone)
	const navigate = useNavigate()

	useEffect(() => {
		setNewPhone(partyProfile?.Party?.phone!)
	}, [partyProfile])

	const validateIdentifier = () => {
		if (isNewPhoneValid && newPhone !== partyProfile?.Party?.phone) {
			return false
		}
		return true
	}

	const updateIdentifier = async () => {
		if (newPhone !== partyProfile?.Party?.phone) {
			const response = await embeddedPartnerApi.updateIdentifier({
				identifier: {
					phoneNumber: cleanPhoneNumber(newPhone),
				},
			})
			if (response?.data?.success) {
				setIdentifier(response.data.payload)
				setUserIdentity(cleanPhoneNumber(newPhone))
				setRedirectUrlAfterOtpValidation(RoutesName.home)
				navigate(`/${RoutesName.auth.verifyOtp}`)
			} else if (response?.data.error?.code === 'INVALID_FIELD_VALUE') {
				setIsValidIdentifier(false)
				setErrorMessage(response?.data.error?.message!)
			} else {
				navigate(`/${RoutesName.auth.verifyOtp}`)
			}
		}
	}

	return (
		<Page withBackIcon loading={!partyProfile?.Party && !lastError}>
			{!isValidIdentifier && (
				<Alert
					severity='error'
					icon={false}
					onClose={() => setIsValidIdentifier(true)}
					data-testid='update-identifier-error-alert'
				>
					{errorMessage}
				</Alert>
			)}
			<Main>
				<VBox verticalGap={15}>
					<HBox style={{paddingLeft: '10px'}}>
						<H1>Edit Profile</H1>
					</HBox>
				</VBox>
				<HBox style={{paddingLeft: '15px'}}>
					<Light>Enter your new phone number</Light>
				</HBox>
				<VBox verticalGap={15}>
					<Input
						type={'text'}
						label='Mobile'
						value={partyProfile?.Party?.phone}
						disableClose={true}
						helperText={newPhoneError}
						error={!!newPhoneError}
						onChange={(_, value) => setNewPhone(value)}
					/>
				</VBox>

				<HBox style={{marginTop: 50, justifyContent: 'center'}}>
					<div style={{width: '91%', textAlign: 'center'}}>
						<Button
							size={'large'}
							fullWidth={true}
							disabled={validateIdentifier()}
							onClick={updateIdentifier}
						>
							Continue
						</Button>
					</div>
					)
				</HBox>
			</Main>
		</Page>
	)
}

export default EditAccountEmail
