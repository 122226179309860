import {Theme} from '@material-ui/core'
import {H1, H6, Small, useTheme} from '@bakkt/components'

export const BakktLicensesAndDisclosures = () => {
	const theme = useTheme() as Theme
	const textColor = theme?.palette?.primary?.main
	const isLightMode = theme?.palette?.type === 'light'

	const pageStyle = {
		color: `${textColor}`,
	}

	const paragraphStyle = {
		fontWeight: '400',
	}

	const tableStyle = {
		border: `1px solid ${textColor}`,
	}

	return (
		<div className='fullScreenModalContent' style={pageStyle}>
			<H1 className='title'>Bakkt Licenses &amp; Disclosures</H1>

			<H6>Bakkt Licenses</H6>

			<Small style={paragraphStyle}>
				<strong>NMLS ID #1890144</strong>
			</Small>

			<H6>Bakkt Marketplace, LLC. is licensed in the following US jurisdictions:</H6>

			<table cellSpacing='0' cellPadding='4'>
				<thead>
					<tr>
						<td style={tableStyle}>State</td>
						<td style={tableStyle}>License</td>
						<td style={tableStyle}>State Agency</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td style={tableStyle}>Alabama</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Sale of Checks License, #803</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Alabama Securities Commission 445 Dexter Avenue, Suite 12000 Montgomery, AL 36104 (334)
								242-2984
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Alaska</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #AKMT-012286</Small>
							<Small style={paragraphStyle}>
								<a href='#alaska'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								State of Alaska, Division of Banking &amp; Securities PO Box 110807 Juneau, AK
								99811-0807550 (907) 465-2521
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Arizona</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #1003995</Small>
							<Small style={paragraphStyle}>
								<a href='#arizona'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Arizona Department of Financial Institutions at: 100 N 15th Avenue, Suite 261 Phoenix,
								AZ 85007 (602) 771-2800
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Arkansas</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #117493</Small>
							<Small style={paragraphStyle}>
								<a href='#arkansas'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Arkansas Securities Department Heritage West Building 201 East Markham Street, Suite 300
								Little Rock, AR 72201-1692 (501) 324-9260
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Colorado</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #500265</Small>
							<Small style={paragraphStyle}>
								<a href='#colorado'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Colorado Division of Banking 1560 Broadway, Suite 975 Denver, CO 80202 (303) 894-7570
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Connecticut</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Connecticut Money Transmission License, #MT-1890144</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Connecticut Department of Banking 260 Constitution Plaza #1 Hartford, CT 06103-1800
								(860) 240-8299
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Delaware</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Sale of Checks and Transmission of Money License, #28820
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Office of the State Bank Commissioner Compliance 1110 Forrest Avenue Dover, DE 19904
								(302) 739-4235
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>District of Columbia</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								District of Columbia Money Transmitter License, #MTR1890144
							</Small>
							<Small style={paragraphStyle}>
								<a href='#district-of-columbia'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								District of Columbia Department of Insurance, Securities, and Banking 1050 First Street
								NE, Suite 801 Washington, District of Columbia 20002 (202) 727-8000
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Florida</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitters Part II License, #FT230000277</Small>
							<Small style={paragraphStyle}>
								<a href='#florida'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Florida Office of Financial Regulation 200 E. Gaines Street Tallahassee, FL 32399-0376
								(800) 848-3792
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Georgia</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Seller of Payment Instruments License, #68004</Small>
							<Small style={paragraphStyle}>
								<a href='#georgia'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								2990 Brandywine Rd, Suite 200 Atlanta, Georgia 30341 (888) 986-1633
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Idaho</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #MTL-259</Small>
							<Small style={paragraphStyle}>
								<a href='#idaho'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Idaho Department of Finance at: P.O. Box 83720, Boise, ID 83720-0031 (208) 332-8004
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Illinois</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #MT.0000364</Small>
							<Small style={paragraphStyle}>
								<a href='#illinois'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Illinois Department of Financial and Professional Regulation Division of Financial
								Institutions 320 W. Washington Springfield, IL 62786 (888) 473-4858
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Indiana</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #43320</Small>
							<Small style={paragraphStyle}>
								<a href='#indiana'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Indiana Department of Financial Institutions 30 S Meridian St #300, Indianapolis, IN
								46204 (317) 232-3955
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Iowa</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Services License, #2019-0128</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								State of Iowa Division of Banking 200 E. Grand Avenue, Suite 300 Des Moines, IA 50309
								(515) 281-4014
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Kansas</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Kansas Money Transmitter License, #MT.0000163</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Kansas Office of the State Bank Commissioner 700 SW Jackson Street, Suite 300 Topeka, KS
								66603 (785) 296-2266
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Kentucky</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #SC711117</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Kentucky Department of Financial Institutions 500 Mero Street, 2SW19 Frankfort, KY 40601
								(800) 223-2579
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Louisiana</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License</Small>
							<Small style={paragraphStyle}>
								<a href='#louisiana'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Louisiana Office of Financial Institutions PO Box 94095 Baton Rouge, LA 70804-9095 (225)
								925-4660
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Maine</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Department of Professional &amp; Financial Regulation Bureau of Consumer Credit
								Protection 76 Northern Avenue Gardiner, ME 04345 (207) 624-8603
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Maryland</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #1890144</Small>
							<Small style={paragraphStyle}>
								<a href='#maryland'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Maryland Commissioner of Financial Regulation 1100 N. Eutaw Street, Suite 611,
								Baltimore, MD 21201 (888) 784-0136
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Michigan</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #MT0022590</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Michigan Department of Insurance and Financial Institutions PO Box 30220 Lansing MI
								48909-7720 (877) 999-6442
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Minnesota</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #MN-MT-1890144</Small>
							<Small style={paragraphStyle}>
								<a href='#minnesota'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								The Minnesota Department of Commerce Golden Rule Building, 85 7th Place East Suite 280
								Saint Paul, Minnesota 55101 (651) 539-1500
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Mississippi</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #1890144</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Mississippi Department of Banking and Consumer Finance Post Office Box 12129 Jackson, MS
								39236 (601) 321-6901
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Missouri</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Sale of Checks and Money Transmitter License, #MO-22-8642
							</Small>
							<Small style={paragraphStyle}>
								<a href='#missouri'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Missouri Division of Finance Truman State Office Building Room 630 Jefferson City, MO
								65102 (573) 751-3242
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Nebraska</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Nebraska Money Transmitter License</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Nebraska Department of Banking &amp; Finance 1526 K Street, Suite 300 Lincoln, NE
								68508-2732 (402) 471-3445
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Nevada</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #MT11121</Small>
							<Small style={paragraphStyle}>
								<a href='#nevada'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Nevada Department of Business and Industry 1830 College Parkway, Suite 100 Carson City,
								NV 89706 (775) 684-2970
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>New Hampshire</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #23230-MT</Small>
							<Small style={paragraphStyle}>
								<a href='#new-hampshire'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								New Hampshire Banking Department 53 Regional Drive, Suite 200 Concord NH 03301 (603)
								271-3561
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>New Jersey</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #1904215</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								New Jersey Department of Banking and Insurance 20 West State Street, PO Box 325 Trenton,
								NJ 08625 (800) 446-7467
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>New Mexico</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Financial Institutions Division P.O. Box 25101 Santa Fe, NM 87504 (505) 476-4885
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>New York</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #104834</Small>
							<Small style={paragraphStyle}>Virtual Currency Business Activity, #0000021</Small>
							<Small style={paragraphStyle}>
								<a href='#new-york'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								New York State Department of Financial Services 1 State Street New York, NY 10004 (800)
								342-3736
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>North Carolina</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #186894</Small>
							<Small style={paragraphStyle}>
								<a href='#north-carolina'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								North Carolina Commissioner of Banks 316 W. Edenton Street Raleigh, NC 27699-4309 (919)
								733-3016
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>North Dakota</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>North Dakota Money Transmitter License, #MT103534</Small>
							<Small style={paragraphStyle}>
								<a href='#north-dakota'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								North Dakota Department of Financial Institutions at: 200 Schafer Street, Suite G
								Bismark, ND 58501-1204 (701) 328-9933
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Ohio</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #OHMT178</Small>
							<Small style={paragraphStyle}>
								<a href='#ohio'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Ohio Division of Financial Institutions 77 South High Street, 21st Floor Columbus, Ohio
								43215-6120 (866) 278-0003
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Oklahoma</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Oklahoma – DOB Money Transmission License</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Oklahoma Department of Banking 2900 North Lincoln Boulevard Oklahoma City, OK 73105
								(918) 295-3649
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Oregon</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #30245</Small>
							<Small style={paragraphStyle}>
								<a href='#oregon'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Oregon Division of Financial Regulation P.O. Box 14480 Salem, OR 97309-0405 (866)
								814-9710
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Pennsylvania</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Pennsylvania Money Transmitter License, #74089</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Pennsylvania Department of Banking and Securities 17 North Second Street, Suite 1300
								Harrisburg, Pennsylvania 17101 (800) 722.2657
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Puerto Rico</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #TM-095</Small>
							<Small style={paragraphStyle}>
								<a href='#puerto-rico'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Puerto Rico Office of the Commissioner of Financial Institutions at: Office of the
								Commissioner of Financial Institutions 1492 Ponce de Leon Avenue, Suite 600 Centro
								Europa Building San Juan, PR 00907 (307) 777-6605
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Rhode Island</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Currency Transmitter License, #20204030CT</Small>
							<Small style={paragraphStyle}>
								<a href='#rhode-island'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								State of Rhode Island Department of Business Regulation Division of Banking 1511 Pontiac
								Avenue, Bldg 68-2 Cranston, RI 02920 (401) 462-9503
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>South Carolina</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License</Small>
							<Small style={paragraphStyle}>
								<a href='#south-carolina'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								South Carolina Money Service Division Rembert C. Dennis Building Columbia, SC 29201
								(573) 751-3242
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>South Dakota</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #MT.2180</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								South Dakota Department of Labor and Regulation Division of Banking 1601 N. Harrison
								Avenue, Suite 1 Pierre, South Dakota 57501 (605) 773-3421
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Tennessee</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, 1890144</Small>
							<Small style={paragraphStyle}>
								<a href='#tennessee'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Tennessee Department of Financial Institutions 312 Rosa L. Parks Avenue, 26th Floor
								Nashville, TN 37243 (615) 741-2236
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Texas</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #3194</Small>
							<Small style={paragraphStyle}>
								<a href='#texas'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Texas Department of Banking, 2601 North Lamar Boulevard, Austin, Texas 78705, (877)
								276-5554
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Vermont</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #100-153</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Vermont Department of Financial Regulation 89 Main Street Montpelier, VT 05620 (802)
								828-3301
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Virginia</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #MO-380</Small>
							<Small style={paragraphStyle}>
								<a href='#virginia'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Bureau of Financial Institutions 1300 East Main Street, Suite 800 or P.O. Box 640
								Richmond, Virginia 23218-0640 (800) 552-7945 (Toll-Free)
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Washington</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #550-MT-121091</Small>
							<Small style={paragraphStyle}>
								<a href='#washington'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								State of Washington - Department of Financial Institutions Division of Consumer Services
								150 Israel Road, S.W. Tumwater, WA 98501 (360) 902-8700
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>West Virginia</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #WVMT-1890144</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								West Virginia Division of Financial Institutions 900 Pennsylvania Avenue, Suite 306
								Charleston, WV 25302 (304) 558-2294
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Wisconsin</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Seller of Checks, #203-SOC</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Wisconsin Department of Financial Institutions 4822 Madison Yards Way, North Tower
								Madison, WI 53705 (608) 261-7578
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>Wyoming</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>Money Transmitter License, #7271</Small>
							<Small style={paragraphStyle}>
								<a href='#wyoming'>View Disclosure</a>
							</Small>
						</td>
						<td style={tableStyle}>
							<Small style={paragraphStyle}>
								Wyoming Department of Audit Hathaway Building 2300 Capitol Ave., 2nd Floor Cheyenne, WY
								82002 (307) 777-6605
							</Small>
						</td>
					</tr>
				</tbody>
			</table>

			<H6 style={{paddingTop: '30px'}}>Disclosures</H6>

			<table cellSpacing='0' cellPadding='4'>
				<tbody>
					<tr>
						<td style={tableStyle}>
							<h4>
								Alaska<span id='alaska'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								Please note that this license does not cover the transmission of virtual currency.
							</Small>
							<Small style={paragraphStyle}>For Alaska Residents Only:</Small>
							<Small style={paragraphStyle}>
								If your issue is unresolved by Bakkt at help@bakkt.com or (800) 322-1719, please submit
								formal complaints with the State of Alaska, Division of Banking &amp; Securities.
							</Small>
							<Small style={paragraphStyle}>
								Please download the form here:<span>&nbsp;</span>
								<a
									href='https://www.commerce.alaska.gov/web/portals/3/pub/DBSGeneralComplaintFormupdated.pdf'
									target='_blank'
									rel='noopener noreferrer'
								>
									DBSGeneralComplaintFormupdated.pdf
								</a>
							</Small>
							<Small style={paragraphStyle}>
								Submit formal complaint form with supporting documents: Division of Banking &amp;
								Securities PO Box 110807 Juneau, AK 99811-0807
							</Small>
							<Small style={paragraphStyle}>
								If you are an Alaska resident with questions regarding formal complaints, please email
								us at dbs.licensing@alaska.gov or call Nine Zero Seven Four Six Five Two Five Two One
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Arizona<span id='arizona'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company’s money transmission activity in the State
								of Arizona, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the Arizona Department of Financial
								Institutions at:
							</Small>
							<Small style={paragraphStyle}>100 N 15th Avenue, Suite 261Phoenix, AZ 85007</Small>
							<Small style={paragraphStyle}>Telephone: (602) 771-2800 Fax: (602) 381-1225</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Arkansas<span id='arkansas'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								Bakkt, is a licensed money transmitter under Title 23, Chapter 55 of the Arkansas Code.
								Questions or concerns should be directed to Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For any unresolved consumer complaints, please contact: Arkansas Securities Department,
								Heritage West Building, 201 East Markham Street, Suite 300, Little Rock, AR 72201-1692,
								Telephone #: (501) 324-9260.
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Colorado<span id='colorado'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>Colorado State Banking Commissioner</Small>
							<Small style={paragraphStyle}>
								CUSTOMER NOTICE Entities other than FDIC insured financial institutions that conduct
								money transmission activities in Colorado, including the sale of money orders, transfer
								of funds, and other instruments for the payment of money or credit, are required to be
								licensed by the Colorado Division of Banking pursuant to the Money Transmitters Act,
								Title 11, Article 110, Colorado Revised Statutes.
							</Small>
							<Small style={paragraphStyle}>
								If you have a Question about or Problem with YOUR TRANSACTION - THE MONEY YOU SENT You
								must contact the Money Transmitter who processed your transaction for assistance. The
								Division of Banking does not have access to this information.
							</Small>
							<Small style={paragraphStyle}>
								If you are a Colorado Resident and have a Complaint about THE MONEY TRANSMITTER – THE
								COMPANY THAT SENT YOUR MONEY ALL complaints must be submitted in writing. Please fill
								out the Complaint Form provided on the Colorado Division of Banking’s website and return
								it and any documentation supporting the complaint via mail or email to the Division of
								Banking at:
							</Small>
							<Small style={paragraphStyle}>
								Colorado Division of Banking 1560 Broadway, Suite 975 Denver, CO 80202 email:
								DORA_BankingWebsite@state.co.us website:<span>&nbsp;</span>
								<a
									href='https://banking.colorado.gov/industry/money-transmitters'
									target='_blank'
									rel='noopener noreferrer'
								>
									https://banking.colorado.gov/industry/money-transmitters
								</a>
							</Small>
							<Small style={paragraphStyle}>
								Section 11-110-120, C.R.S. requires that money transmitters and money order companies
								post this notice in a conspicuous, well-lighted location visible to customers.
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								District of Columbia<span id='district-of-columbia'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company’s money transmission activity in the
								District of Columbia, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the District of Columbia Department of
								Insurance, Securities, and Banking at:
							</Small>
							<Small style={paragraphStyle}>
								Telephone: (202) 727-8000 Fax: (202) 354-1058 Email: disb.complaints@dc.gov
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Florida<span id='florida'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>NOTICE: By the Florida Office of Financial Regulation</Small>
							<Small style={paragraphStyle}>
								BY GRANTING BAKKT A LICENSE, THE FLORIDA OFFICE OF FINANCIAL REGULATION IS NOT ENDORSING
								THE USE OF DIGITAL OR VIRTUAL CURRENCIES.
							</Small>
							<ul>
								<li>U.S. currency is legal tender backed by the U.S. government.</li>
								<li>
									Digital and virtual currencies are not issued or backed by the U.S. government, or
									related in any way to U.S. currency, and have fewer regulatory protections.
								</li>
								<li>
									The value of digital and virtual currencies is derived from supply and demand in the
									global marketplace which can rise or fall independently of any fiat (government)
									currency.
								</li>
								<li>
									Holding digital and virtual currencies carries exchange rate and other types of
									risk.
								</li>
							</ul>
							<Small style={paragraphStyle}>
								POTENTIAL USERS OF DIGITAL OR VIRTUAL CURRENCIES, INCLUDING BUT NOT LIMITED TO BITCOIN,
								SHOULD BE FOREWARNED OF A POSSIBLE FINANCIAL LOSS AT THE TIME THAT SUCH CURRENCIES ARE
								EXCHANGED FOR FIAT CURRENCY DUE TO AN UNFAVORABLE EXCHANGE RATE. A FAVORABLE EXCHANGE
								RATE AT THE TIME OF EXCHANGE CAN RESULT IN A TAX LIABILITY. PLEASE CONSULT YOUR TAX
								ADVISOR REGARDING ANY TAX CONSEQUENCES ASSOCIATED WITH YOUR HOLDING OR USE OF DIGITAL OR
								VIRTUAL CURRENCIES.
							</Small>
							<Small style={paragraphStyle}>
								If you have a question or complaint, please contact the consumer assistance division of
								Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								Florida residents may contact the Florida Office of Financial Regulation with any
								unresolved questions or complaints about Bakkt at 200 E. Gaines Street, Tallahassee, FL
								32399-0376, telephone number: (800) 848-3792 (toll free).
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Georgia<span id='georgia'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								Bakkt is a licensed money transmitter in the state of GA. If you have a complaint or
								concern regarding its money transmission services, you may contact Bakkt at
								help@bakkt.com or (800) 322-1719. For unresolved complaints, you may contact:
							</Small>
							<Small style={paragraphStyle}>
								DEPARTMENT OF BANKING AND FINANCE FOR THE STATE OF GEORGIA 2990 Brandywine Rd, Suite 200
								Atlanta, Georgia 30341 (888) 986-1633
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Idaho<span id='idaho'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company’s money transmission activity in the State
								of Idaho, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the Idaho Department of Finance at:
							</Small>
							<Small style={paragraphStyle}>
								P.O. Box 83720, Boise, ID 83720-0031 Telephone: (208) 332-8004 Email:
								securities@finance.idaho.com
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Illinois<span id='illinois'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								Illinois residents may contact the Illinois Department of Financial Institutions,
								Consumer Credit Section with any unresolved questions or complaints about Bakkt at
								Illinois Department of Financial and Professional Regulation Division of Financial
								Institutions 320 W. Washington Springfield, IL 62786 Tel: 1-888-473-4858 (toll-free).
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Indiana<span id='indiana'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								Bakkt holds a Money Transmitter license issued by the Indiana Department of Financial
								Institutions. This license does not cover virtual currency transactions.
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Louisiana<span id='louisiana'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								Please note the license issued to Bakkt by the Louisiana Office of Financial
								Institutions does not cover the exchange or transmission of virtual currency.
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Maryland<span id='maryland'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								The Commissioner of Financial Regulation for the State of Maryland will accept all
								questions or complaints from Maryland residents regarding Bakkt (License No. 12-1890144
								and NMLS ID: 1890144) at 1100 N. Eutaw Street, Suite 611, Baltimore, MD 21201 or by
								phone at (888) 784-0136
							</Small>
							<Small style={paragraphStyle}>
								Verify Bakkt at: www.NMLSConsumerAccess.org, unique ID 1890144
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Minnesota<span id='minnesota'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								The Minnesota Department of Commerce (www.commerce.state.mn.us) will accept complaints
								at 651-539-1500 or by mail (Main Office, Golden Rule Building, 85 7th Place East Suite
								280 Saint Paul, Minnesota 55101)
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Missouri<span id='missouri'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company’s money transmission activity in the State
								of Missouri, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the Missouri Division of Finance at: Missouri
								Division of Finance Truman State Office Building Room 630 Jefferson City, MO 65102
								Telephone: (573) 751-3242
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Nevada<span id='nevada'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								Bakkt is licensed by the Nevada Department of Business and Industry as a money
								transmitter. At this time, the Nevada Department of Business and Industry does not
								license or regulate services related to virtual currency, including but not limited to
								virtual currency transmission or exchange which may be conducted by Bakkt.
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								New Hampshire<span id='new-hampshire'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company’s money transmission activity in the State
								of New Hampshire, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the New Hampshire Banking Department at:
							</Small>
							<Small style={paragraphStyle}>
								New Hampshire Banking Department 53 Regional Drive, Suite 200 Concord NH 03301
							</Small>
							<Small style={paragraphStyle}>Telephone: (603) 271-3561 Email: nhbd@banking.nh.gov</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								New York<span id='new-york'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>New York Department of Financial Services</Small>
							<Small style={paragraphStyle}>
								Bakkt Marketplace, LLC is licensed and regulated as a money transmitter and virtual
								currency business activity provider by the New York State Department of Financial
								Services. New York customers can direct unresolved complaints to:
							</Small>
							<Small style={paragraphStyle}>
								Consumer Assistance Unit NYS Department of Financial Services 1 State Street New York,
								NY 10004 Tel: (800) 342-3736 Website:<span>&nbsp;</span>
								<a href='https://www.dfs.ny.gov/complaint' target='_blank' rel='noopener noreferrer'>
									https://www.dfs.ny.gov/complaint
								</a>
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								North Carolina<span id='north-carolina'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding Bakkt’s money transmission activity in the State of
								North Carolina, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the North Carolina Commissioner of Banks at:
							</Small>
							<Small style={paragraphStyle}>
								North Carolina Commissioner of Banks 316 W. Edenton Street Raleigh, NC 27699-4309
								Telephone: (919) 733-3016
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								North Dakota<span id='north-dakota'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company’s money transmission activity in the State
								of North Dakota, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the North Dakota Department of Financial
								Institutions at:
							</Small>
							<Small style={paragraphStyle}>
								200 Schafer Street, Suite G Bismark, ND 58501-1204 Telephone: (701) 328-9933 Email:
								dfi@nd.gov
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Ohio<span id='ohio'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company’s money transmission activity in the State
								of Ohio, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the Ohio Department of Commerce at:
							</Small>
							<Small style={paragraphStyle}>
								Division of Financial Institutions 77 South High Street, 21st Floor Columbus, Ohio
								43215-6120
							</Small>
							<Small style={paragraphStyle}>Telephone: (866) 278-0003</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Oregon<span id='oregon'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company's money transmission activity in Oregon,
								please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the Oregon Division of Financial Regulation
								at:
							</Small>
							<Small style={paragraphStyle}>
								Oregon Division of Financial Regulation P.O. Box 14480 Salem, OR 97309-0405
							</Small>
							<Small style={paragraphStyle}>
								Telephone: (866) 814-9710 Email: DCBS.DFCSMAIL@oregon.gov
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Puerto Rico<span id='puerto-rico'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company's money transmission activity in Puerto
								Rico, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the Puerto Rico Office of the Commissioner of
								Financial Institutions at:
							</Small>
							<Small style={paragraphStyle}>
								Office of the Commissioner of Financial Institutions 1492 Ponce de Leon Avenue, Suite
								600 Centro Europa Building San Juan, PR 00907 Telephone: (307) 777-6605
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Rhode Island<span id='rhode-island'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company’s money transmission activity in the State
								of Rhode Island, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the Rhode Island Department of Business
								Regulation at:
							</Small>
							<Small style={paragraphStyle}>
								State of Rhode Island Department of Business Regulation Division of Banking 1511 Pontiac
								Avenue, Bldg 68-2 Cranston, RI 02920
							</Small>
							<Small style={paragraphStyle}>Telephone: (401) 462-9503</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								South Carolina<span id='south-carolina'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company’s money transmission activity in the State
								of South Carolina, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the South Carolina Money Service Division at:
							</Small>
							<Small style={paragraphStyle}>
								Money Service Division Rembert C. Dennis Building Columbia, SC 29201 Telephone: (573)
								751-3242
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Tennessee<span id='tennessee'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								Please note that this license and the required surety bond do not cover the transmission
								of virtual currency. Bakkt is licensed by the Tennessee Department of Financial
								Institutions as a money transmitter. The Tennessee Department of Financial Institutions
								does not regulate virtual currency.
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Texas<span id='texas'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint, first contact the consumer assistance division of Bakkt at
								help@bakkt.com or (800) 322-1719. If you still have an unresolved complaint regarding
								the company's money transmission or currency exchange activity, please direct your
								complaint to: Texas Department of Banking, 2601 North Lamar Boulevard, Austin, Texas
								78705, www.dob.texas.gov.
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Virginia<span id='virginia'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								Bakkt is licensed by the Virginia State Corporation Commission as a money transmitter,
								but such license does not cover the transmission of virtual currency (Bitcoin).
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Washington<span id='washington'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint, first contact the consumer assistance division of Bakkt at
								help@bakkt.com or (800) 322-1719, and if you still have an unresolved complaint
								regarding the company’s money transmission activity, please contact the Washington State
								Department of Financial Institutions, Division of Consumer Services using one of the
								following methods:
							</Small>
							<Small style={paragraphStyle}>
								<a
									href='https://dfi.wa.gov/consumers/loan-complaints'
									target='_blank'
									rel='noopener noreferrer'
								>
									File a complaint online
								</a>
								, mail or fax: Call us: 1-877-RING DFI (1-877-746-4334) Email us:
								CSEnforceComplaints@dfi.wa.gov
							</Small>
						</td>
					</tr>
					<tr>
						<td style={tableStyle}>
							<h4>
								Wyoming<span id='wyoming'>&nbsp;</span>
							</h4>
							<Small style={paragraphStyle}>
								If you have a complaint regarding the company’s money transmission activity in the State
								of Wyoming, please contact Bakkt at help@bakkt.com or (800) 322-1719.
							</Small>
							<Small style={paragraphStyle}>
								For unresolved complaints, please contact the Wyoming Department of Audit at:
							</Small>
							<Small style={paragraphStyle}>
								Wyoming Department of Audit Hathaway Building 2300 Capitol Ave., 2nd Floor Cheyenne, WY
								82002 Telephone: (307) 777-6605
							</Small>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}
