import {Main, VBox, Button, Link, BottomBox, H2, HistoryContext, Alert, useTheme, Spinner} from '@bakkt/components'
import {embeddedPartnerApi, tokenizeAuth, linkDebitCard, fetchProfileForDebitLinking, fakeWebhook} from 'api'
import Page from 'components/Page'
import {RoutesName} from 'constants/routes'
import {useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useStore} from 'store'
import {IPaymentForm, getStateConfig, CustomEventName} from './Tokenizer'
import {Theme} from '@material-ui/core'

export default () => {
	const navigate = useNavigate()
	const history = useContext(HistoryContext)
	const [showAlert, setShowAlert] = useState(false)
	const [valid, setValid] = useState(false)
	const {setFundingAccounts} = useStore()
	const [loading, setLoading] = useState(false)
	const [form, setForm] = useState<IPaymentForm>()
	const theme = useTheme() as Theme

	useEffect(() => {
		if (!form) {
			;(window as any).firstdata.createPaymentForm(
				getStateConfig(theme),
				{preFlowHook: tokenizeAuth, submitFormHook: fetchProfileForDebitLinking},
				(paymentForm: IPaymentForm) => {
					setForm(paymentForm)
					paymentForm.on(CustomEventName.CHANGE, () => {
						setValid(paymentForm.isValid())
					})
				},
				(cat: string, message: string) => console.log(`${cat}=>${message}`),
			)
		}
	}, [form])

	const inputLabelStyle = {
		color: theme?.palette?.text?.background,
		fontSize: '12px',
	}

	const inputBoxStyle = {
		height: 30,
		marginTop: '-6px',
		borderBottom: `1px solid ${theme?.palette?.text?.background}`,
	}

	function delay(milliseconds: number) {
		return new Promise(resolve => {
			setTimeout(resolve, milliseconds)
		})
	}

	const onError = () => {
		console.log('onError')
		setShowAlert(true)
		if (form) {
			form.reset(() => {
				console.log('reset')
			})
		}
	}

	const handleContinue = async () => {
		setLoading(true)
		form?.onSubmit(
			async clientToken => {
				await fakeWebhook(clientToken)
				let success = false
				try {
					for (let i = 0; i < 3; i++) {
						console.log('attempt' + i)
						const webhookFound = await linkDebitCard(clientToken)
						console.log('webhookFound', webhookFound)
						if (webhookFound) {
							success = !webhookFound.error
							break
						}
						await delay(1000)
					}

					if (success) {
						const fundingAccountsResponse = await embeddedPartnerApi.fetchFundingAccounts('USD')
						setFundingAccounts(fundingAccountsResponse?.data?.payload || [])
						navigate(`/${RoutesName.debitCard.verifyTransactions}`)
					}
				} finally {
					if (!success) {
						onError()
					}
					setLoading(false)
				}
			},
			error => {
				console.log(error)
				setLoading(false)
				onError()
			},
		)
	}

	const handleCancel = () => {
		history?.go(-1)
	}

	return (
		<Page titleHeader='Link a Debit Card' withSubHeaderLogo={true} showJointLogo={false} withBackIcon>
			<Main>
				<VBox verticalGap={15} style={{padding: '30px'}}>
					<H2>Enter your card details</H2>
					{showAlert && (
						<Alert severity='error' icon={false} onClose={() => setShowAlert(false)}>
							Failed to link debit card. Please try again !!!
						</Alert>
					)}
					{loading && (
						<div
							style={{
								marginTop: 'auto',
								marginLeft: 'auto',
								marginRight: 'auto',
								marginBottom: '-50px',
								height: 100,
							}}
						>
							<Spinner />
						</div>
					)}
					<form id='form'>
						<div style={{marginBottom: '24px'}}>
							<label htmlFor='first-data-payment-field-card' style={inputLabelStyle}>
								Card
							</label>
							<div id='cc-card' data-cc-card style={inputBoxStyle}></div>
						</div>

						<div style={{marginBottom: '24px'}}>
							<label htmlFor='first-data-payment-field-name' style={inputLabelStyle}>
								Name
							</label>
							<div id='cc-name' data-cc-name style={inputBoxStyle}></div>
						</div>

						<div style={{marginBottom: '24px'}}>
							<label htmlFor='first-data-payment-field-exp' style={inputLabelStyle}>
								Exp
							</label>
							<div id='cc-exp' data-cc-exp style={inputBoxStyle}></div>
						</div>

						<div style={{marginBottom: '24px'}}>
							<label htmlFor='first-data-payment-field-cvv' style={inputLabelStyle}>
								CVV
							</label>
							<div id='cc-cvv' data-cc-cvv style={inputBoxStyle}></div>
						</div>
						<div>
							<div style={{marginBottom: '24px'}}>
								<label htmlFor='first-data-payment-field-postalCode' style={inputLabelStyle}>
									Zip Code
								</label>
								<div id='cc-postalCode' data-cc-postalCode style={inputBoxStyle}></div>
							</div>
						</div>
					</form>
				</VBox>
			</Main>
			<BottomBox style={{paddingTop: 50}}>
				<Button size='large' onClick={handleContinue} fullWidth disabled={!valid}>
					Continue
				</Button>
				<Link onClick={handleCancel}>Cancel</Link>
			</BottomBox>
		</Page>
	)
}
