import {isMobile} from 'react-device-detect'
import {EmbeddedWebState} from 'store'
import {RoutesName} from '../constants/routes'
import {Utils} from '@bakkt/components'

const toUrl = (
	failed: boolean,
	failureReasonCode: string | null,
	partner?: EmbeddedWebState['partner'],
	detail_error_code?: string,
	bankStatus?: string,
) => {
	const urls = [
		{mobile: true, success: true, url: partner?.mobileUrls?.successUrl},
		{mobile: true, success: false, url: partner?.mobileUrls?.failureUrl},
		{mobile: false, success: false, url: partner?.desktopUrls?.failureUrl},
		{mobile: false, success: true, url: partner?.desktopUrls?.successUrl},
	]

	const url =
		urls.find(r => r.mobile == isMobile && failed == !r.success && !!r.url) ||
		urls.find(r => r.mobile == isMobile && !!r.url) ||
		urls.find(r => (failed == !r.success && !!r.url) || urls.find(r => r.url != null))
	if (!url) {
		return null
	} else if (bankStatus === 'pending-auto' || bankStatus === 'pending') {
		const pendignUrl = url?.url
			?.replace('successful', bankStatus || '')
			.replace('<detailErrorCode>', detail_error_code + '_' + bankStatus || '')
		return pendignUrl
	} else {
		return url?.url
			?.replace('<reasonCode>', failureReasonCode || '')
			.replace('<detailErrorCode>', detail_error_code || '')
	}
}

export const returnToPartner = (
	failed: boolean,
	failureReasonCode: string | null,
	partner?: EmbeddedWebState['partner'],
	navigate?: any,
	detail_error_code?: string,
	bankStatus?: string,
) => {
	const url = toUrl(failed, failureReasonCode, partner, detail_error_code, bankStatus)
	if (url === '#') {
		// do nothing
		return
	}

	if (!url) {
		if (navigate) {
			navigate(RoutesName.home, {})
			Utils.hideDialog()
		} else {
			window.location.href = '/'
		}
	} else {
		window.location.href = url
	}
}
