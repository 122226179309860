import {useCallback, useState} from 'react'

export const useTimer = (delay?: number, func?: any) => {
	const [timerTimeout, setTimerTimeout] = useState<NodeJS.Timeout>()

	const clearTimer = () => {
		if (timerTimeout) {
			clearTimeout(timerTimeout)
			setTimerTimeout(undefined)
		}
	}

	const setTimer = useCallback(() => {
		if (!delay || !func) return

		clearTimer()

		setTimerTimeout(
			setTimeout(() => {
				if (func) func()
				clearTimer()
			}, delay) as unknown as NodeJS.Timeout,
		)
	}, [delay, func])

	return [setTimer, clearTimer]
}
