import moment from 'moment-timezone'

export function appendTimeZoneToDatetime(datetime: moment.MomentInput, timezone: string) {
	return moment.tz(datetime, timezone)
}

export function getTimeZoneAbbr(timezone: string) {
	return moment.tz(timezone).zoneAbbr()
}

export function formatDateTimeWithTimeZone(datetime: string, timezone: string) {
	if (datetime && moment(datetime).isValid()) {
		const dateWithTimeZone = appendTimeZoneToDatetime(datetime, timezone)
		const timeZoneAbbr = getTimeZoneAbbr(timezone)
		const formatedDate = moment(dateWithTimeZone).format('MM-DD-YYYY')
		const time = moment(dateWithTimeZone).format('HH:mm')
		return `${formatedDate} | ${time} ${timeZoneAbbr}`
	}

	return datetime
}
