import {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {BottomBox, Main} from '@bakkt/components'
import {ApiEmbeddedPartnerTransactionOperationTypeEnum as TransactionType} from '@bakkt/api'
import {useNavigate, useSelectedCryptoAccountInfo, useCryptoCurrencyExecutePrices, InputType} from 'hooks'
import Button from 'components/Button'
import {CurrencyFlipper} from 'components/CurrencyFlipper'
import {CryptoAccountRow} from 'components/fundingRows'
import {TransactTypeEnum, useStore} from 'store'
import {SelectFundingAccount} from 'components/SelectFundingAccount'
import Page from 'components/Page'
import {RoutesName} from 'constants/routes'
import CryptoLimits from 'components/CryptoLimits'
import {logger} from 'utils/logger/datadogInit'
import {shallow} from 'zustand/shallow'

type Props = {
	transactionType: TransactionType
}

export const CryptoTransactionInput = ({transactionType}: Props) => {
	const {selectedFundingAccount, selectedCryptoAccount, setSyncExecutePrices} = useStore(
		state => ({
			selectedFundingAccount: state.selectedFundingAccount,
			selectedCryptoAccount: state.selectedCryptoAccount,
			setSyncExecutePrices: state.setSyncExecutePrices,
		}),
		shallow,
	)
	const {selectedCryptoCurrencyName} = useSelectedCryptoAccountInfo()

	const [inputType, setInputType] = useState<InputType>(
		transactionType === TransactionType.SELL ? InputType.Crypto : InputType.Fiat,
	)
	const [sellAllEnabled, setSellAllEnabled] = useState<boolean>(false)
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

	const {fiatAmount, setFiatAmount, cryptoAmount, setCryptoAmount, approximate, setApproximate} =
		useCryptoCurrencyExecutePrices({
			transactionType,
			inputType,
			forceTrigger: sellAllEnabled,
			allowUpdate: true,
		})

	const {t} = useTranslation()
	const navigate = useNavigate()

	useEffect(() => {
		logger.info('Crypto transaction input page - initilized', {
			filename: 'CryptoTransactionInput.tsx',
			currencyName: selectedCryptoCurrencyName,
			transactionType,
		})
		if ( transactionType === 'SELL') {
			sessionStorage.setItem('transactionType', JSON.stringify({'isSell': true}))
		}
	}, [])

	useEffect(() => {
		if (sellAllEnabled && !buttonDisabled && !approximate) {
			navigate(RoutesName.crypto.confirm, {ignore: true})
		}
	}, [sellAllEnabled, approximate])

	const onChangeValue = (value: string) => {
		inputType === InputType.Fiat ? setFiatAmount(value) : setCryptoAmount(value)
		setSyncExecutePrices(true)
	}

	const handlerOnSellAllCrypto = () => {
		logger.info('Crypto transaction input page - Click sell all button', {inputType: InputType.Crypto})
		setApproximate(true)
		setInputType(InputType.Crypto)
		setCryptoAmount(String(selectedCryptoAccount?.accountBalance?.amount))
		setSellAllEnabled(true)
	}

	return (
		<Page
			withBackIcon
			titleHeader={
				transactionType === TransactionType.SELL
					? `Sell ${selectedCryptoCurrencyName}`
					: `Buy ${selectedCryptoCurrencyName}`
			}
			loading={sellAllEnabled}
		>
			<Main>
				<CurrencyFlipper
					fiat={fiatAmount}
					crypto={cryptoAmount}
					approximate={approximate}
					onChange={onChangeValue}
					focusedCurrency={inputType}
					onFlipPressed={() => {
						logger.info('Crypto transaction input page - Currency flip clicked', {inputType})
						setInputType(inputType === InputType.Fiat ? InputType.Crypto : InputType.Fiat)
					}}
				/>
				<CryptoLimits
					transactionType={transactionType as any}
					setButtonDisabled={setButtonDisabled}
					fiatAmount={fiatAmount}
					cryptoAmount={cryptoAmount}
					handlerOnSellAllCrypto={handlerOnSellAllCrypto}
				/>
				{transactionType === TransactionType.SELL && <CryptoAccountRow isSellView={true} />}
				<SelectFundingAccount transactionType={transactionType} />
				<div style={{height: 20}}></div>
			</Main>
			<BottomBox style={{marginTop: '50px', marginBottom: '30px'}}>
				<Button
					disabled={
						!selectedFundingAccount ||
						approximate ||
						buttonDisabled ||
						(transactionType === 'BUY' && selectedFundingAccount?.availableBalance?.amount === 0)
					}
					onClick={() => {
						logger.info('Crypto transaction input page - Continue button clicked', {
							routeTo: RoutesName.crypto.confirm,
						})
						navigate(RoutesName.crypto.confirm, {ignore: true})
					}}
					id='buy-sell-continue-button'
				>
					{t('bntContinue')}
				</Button>
			</BottomBox>
		</Page>
	)
}
