import {useTheme} from '@material-ui/core'
import styled from '@emotion/styled'
import {color} from 'src/color/color'
import {Label} from './Label'
import {CircleIcon} from 'src'
import React from 'react'

const LabelContainer = styled.div`
	display: flex;
	align-items: center;
`

export type LabelProps = {
	children?: React.ReactNode
	iconStyle?: React.CSSProperties
	style?: React.CSSProperties
	icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}

export const IconLabel = ({icon, children, iconStyle, style}: LabelProps) => {
	const theme = useTheme()

	return (
		<LabelContainer style={style}>
			{icon && (
				<CircleIcon
					style={{
						background: theme?.palette?.tertiary?.main || color.blueKolibri,
						marginRight: 5,
						...iconStyle,
					}}
					icon={icon}
					iconColor={theme?.palette?.tertiary?.contrastText}
				/>
			)}
			<Label>{children}</Label>
		</LabelContainer>
	)
}
