export interface ICssStyle {
	[index: string]: string | ICssStyle
}

export interface ICssStyleList {
	[index: string]: ICssStyle
}

export interface IField {
	selector: string
	placeholder?: string
	allowedBrands?: string[]
}
export interface ICssClassList {
	empty: string
	focus: string
	invalid: string
	valid: string
}
export interface IFields {
	[FieldName.CARD]: IField
	[FieldName.CVV]?: IField
	[FieldName.EXP]: IField
	[FieldName.NAME]: IField
	[FieldName.COMPANY]?: IField
	[FieldName.ADDRESS1]?: IField
	[FieldName.ADDRESS2]?: IField
	[FieldName.CITY]?: IField
	[FieldName.REGION]?: IField
	[FieldName.COUNTRY]?: IField
	[FieldName.POSTAL_CODE]: IField
}

export interface IStateConfig {
	form?: {
		selector: string
	}
	fields: IFields
	classes?: ICssClassList
	styles?: ICssStyleList
}

interface IResult {
	error: boolean
}
export interface ISuccessResult extends IResult {
	error: false
}

export interface IFailureResult extends IResult {
	reason: string
	error: true
}

export interface ITokenizeSuccess extends ISuccessResult {
	clientToken: string
}

export type TokenizeResult = ITokenizeSuccess | IFailureResult

export type Consumer<T> = (data: T) => void

export type Callback = () => void

export interface ISessionAuth {
	clientToken: string
	publicKeyBase64: string
}

export const enum FieldName {
	NAME = 'name',
	CARD = 'card',
	CVV = 'cvv',
	EXP = 'exp',
	COMPANY = 'company',
	ADDRESS1 = 'address1',
	ADDRESS2 = 'address2',
	CITY = 'city',
	REGION = 'region',
	COUNTRY = 'country',
	POSTAL_CODE = 'postalCode',
}

export interface IFieldValidity<F extends FieldName = FieldName> {
	empty: boolean
	field: F
	length: number
	potentiallyValid: boolean
	touched: boolean
	valid: boolean
}

export interface ICardFieldValidity extends IFieldValidity<FieldName.CARD> {
	brand: string
	brandNiceType: string
}

export interface ICvvFieldValidity extends IFieldValidity<FieldName.CVV> {
	maxLength: number
}

export type FormValidity = {
	[F in FieldName]: IFieldValidity<F>
} & {
	card: ICardFieldValidity
	cvv: ICvvFieldValidity
}
export type FieldEventHandlerCallback = Consumer<
	| {field: string; selector: string}
	| {
			brand: string
			brandNiceType: string
			code: string | {}
			field: FieldName.CARD
			potentiallyValid: boolean
			selector: string
			valid: boolean
	  }
>

export const enum CustomEventName {
	BLUR = 'blur',
	CARD_TYPE = 'cardType',
	CHANGE = 'change',
	FOCUS = 'focus',
}

export interface IPaymentForm {
	/**
	 * @deprecated
	 */
	tokenize(callback: Consumer<TokenizeResult>): void

	onSubmit(resolve: Consumer<string>, reject?: Consumer<Error>): void
	createFields(resolve: Callback, reject?: Consumer<Error>): void
	reset(resolve: Callback, reject?: Consumer<Error>): void
	destroyFields(resolve: Callback, reject?: Consumer<Error>): void
	validate(resolve: Callback, reject?: Consumer<Error>): void
	authenticate(resolve: Consumer<ISessionAuth>, reject?: Consumer<Error>): void
	submit(auth: ISessionAuth, resolve: Consumer<string>, reject?: Consumer<Error>): void
	isSupported(): boolean
	isValid(): boolean
	getState(cb: Consumer<FormValidity>): void
	on(type: CustomEventName, callback: FieldEventHandlerCallback): void

	// NOT YET RELEASED
	//setFieldFocus(field: FieldName, resolve: Callback, reject?: Consumer<Error>): void;
}

export const getStateConfig = (theme: any): IStateConfig => {
	const styles: ICssStyleList = {
		input: {
			'font-size': '16px',
			color: theme?.palette?.text?.background,
			'font-family': `${theme?.typography.fontFamily}, sans-serif`,
			border: '1px solid black',
			'letter-spacing': '1px',
		},
		':focus': {
			color: theme?.palette?.text?.background,
		},
		'.valid': {
			color: theme?.palette?.text?.background,
		},
		'.invalid': {
			color: '#C01324',
		},
		'@media screen and (max-width: 700px)': {
			input: {
				'font-size': '16px',
			},
		},
		'input:-webkit-autofill': {
			'-webkit-box-shadow': '0 0 0 50px white inset',
		},
		'input:focus:-webkit-autofill': {
			'-webkit-text-fill-color': theme?.palette?.text?.background,
		},
		'input.valid:-webkit-autofill': {
			'-webkit-text-fill-color': '#43B02A',
		},
		'input.invalid:-webkit-autofill': {
			'-webkit-text-fill-color': '#C01324',
		},
		'input::placeholder': {
			color: '#b4b5b6',
		},
	}

	const classes: ICssClassList = {
		empty: 'empty',
		focus: 'focus',
		invalid: 'invalid',
		valid: 'valid',
	}

	const fields: IFields = {
		card: {
			selector: '[data-cc-card]',
			placeholder: '• • • •  • • • •  • • • •  • • • •',
			allowedBrands: ['visa', 'mastercard'],
		},
		cvv: {
			selector: '[data-cc-cvv]',
		},
		exp: {
			selector: '[data-cc-exp]',
		},
		name: {
			selector: '[data-cc-name]',
			placeholder: 'Full Name',
		},
		postalCode: {
			selector: '[data-cc-postalCode]',
			placeholder: '• • • • •',
		},
	}

	return {classes, fields, styles}
}
