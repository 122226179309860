import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {BottomBox, H3, Large, Main, RoundCheckedIcon} from '@bakkt/components'
import Button from 'components/Button'
import Page from 'components/Page'
import {useStore} from 'store'
import {logger} from 'utils/logger/datadogInit'
import {useNavigate} from '../../../../hooks'
import {RoutesName} from '../../../../constants/routes'

const CryptoAccountActivationSuccess = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {loadOnStartup, partner, isPartnerBakkt, loadParty, loadProfile} = useStore()

	useEffect(() => {
		logger.info('Activate Crypto Success - initilized', {
			filename: 'ActivationSuccess.tsx',
			message: t('crypto.accountActivate.activationSuccess'),
		})
	}, [])

	const transactionTypeStr = sessionStorage.getItem('transactionType')
	const transactionType = JSON.parse(transactionTypeStr || '{}')

	return (
		<Page
			titleHeader={t('crypto.accountActivate.activateCrypto')}
			title={t('titles.onboarding.success')}
			withSubHeaderLogo={false}
		>
			<Main style={{flexGrow: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
				<div style={{position: 'relative', margin: 0, display: 'flex', justifyContent: 'center'}}>
					<RoundCheckedIcon height='104px' width='104px' />
				</div>
				<div style={{textAlign: 'center', margin: '26px 0px 0px 0px', maxWidth: '100%', paddingBottom: '1em'}}>
					<H3 style={{marginBottom: '0px', marginTop: '0px'}}>
						{t('crypto.accountActivate.activationSuccess')}
					</H3>
					<Large style={{marginTop: '0.5em', marginBottom: '36px'}}>
						{t('crypto.accountActivate.canSellBuy', {partnerName: partner?.name})}
					</Large>
				</div>
			</Main>
			<BottomBox className='activationSuccessBottomBox' style={{paddingTop: '30px', marginBottom: '30px'}}>
				<Button
					data-testid='view-my-crypto-button'
					aria-label='Activation Success'
					style={{marginBottom: '10px'}}
					onClick={() => {
						isPartnerBakkt && loadProfile()
						loadParty()
						logger.info('Activate Crypto Success - view crypto button clicked', {call: 'loadOnStartup'})
						if (transactionType?.isSell) {
							isPartnerBakkt ? navigate(`/${RoutesName.crypto.sell}`, {ignore: true}) : loadOnStartup()
							sessionStorage.setItem('transactionType', '')
						}
						else {
							isPartnerBakkt ? navigate(`/${RoutesName.crypto.buy}`, {ignore: true}) : loadOnStartup()
						}
					}}  
				>
					{t(isPartnerBakkt ? 'bntContinue' : 'bntViewCrypto')}
				</Button>
			</BottomBox>
		</Page>
	)
}
export default CryptoAccountActivationSuccess
