import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import styled from '@emotion/styled'
import {TextField, TextFieldProps, ThemeOptions, useTheme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {BrownPro, InlineBtn} from '../text/Text'
import {XSmll} from 'src/components/icons'
import {color} from '../../color/color'
import {ShadowContainer} from '../shadowContainer/ShadowContainer'
import {useTimer} from 'src/hooks/useTimer'
import {formatCamelCase} from 'src/utils/formatters'

export interface InputProps extends Omit<TextFieldProps, 'onChange'> {
	endAdornment?: JSX.Element
	label?: string
	name?: string
	type: string
	helperText?: string
	disableClose?: boolean
	// eslint-disable-next-line
	onChange?: (event: any, value: string) => void
	// eslint-disable-next-line
	onBlur?: (event: any) => void
	// eslint-disable-next-line
	onFocus?: (event: any) => void
	containerstyle?: React.CSSProperties
	readOnly?: boolean
	disableShadow?: boolean
	enableUnderLine?: boolean
	min?: number | string
	max?: number | string
	error?: boolean
	value?: number | string
	validate?: (text: string | number) => boolean
	validateOnInputDelay?: number
	maxLength?: number | string
	fullWidth?: boolean
	ariaLabel?: string
}

export const useBakktStyles = makeStyles(theme => ({
	root: {
		margin: 0,
		fontFamily: theme?.typography?.fontFamily || BrownPro,
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		color: theme?.palette?.text?.background || color.grey700,
		'& label.Mui-focused': {
			color: theme?.palette?.text?.background || color.blackLight,
		},
		'& label.Mui-focused .MuiInputLabel-asterisk': {
			color: theme?.palette?.text?.background || color.blackLight,
		},
		'& .MuiInput-underline:before': {
			borderBottomColor: color.white,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: color.white,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottomColor: color.white,
		},
		'& input:-webkit-autofill': {
			'-webkit-text-fill-color': `${theme?.palette?.text?.background || color.blackLight} !important`,
		},
		'& .MuiInputBase-input:-webkit-autofill': {
			WebkitBoxShadow: `0 0 0 1000px ${theme?.palette?.background?.paper || color.white} inset`,
		},
		'& .MuiInputBase-input::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
		'& .MuiInputBase-input::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
		'& .MuiFormLabel-root': {
			fontFamily: theme?.typography?.fontFamily || BrownPro,
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '16px',
			color: theme?.palette?.text?.background || color.grey600,
		},
		'& .MuiFormLabel-root.Mui-error': {
			color: color.error,
		},
		'& .MuiFormHelperText-root': {
			fontFamily: theme?.typography?.fontFamily || BrownPro,
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '11px',
			lineHeight: '13px',
			letterSpacing: '0.005em',
			color: theme?.palette?.text?.background || color.grey600,
		},
		'& .MuiFormHelperText-root.Mui-error': {
			color: color.error,
		},
		'& .MuiInputLabel-shrink': {
			marginTop: '4px !important',
		},
		'& .MuiInputBase-root': {
			fontFamily: theme?.typography?.fontFamily || BrownPro,
			fontStyle: 'normal',
			fontWeight: 400,
			color: theme?.palette?.text?.background || color.blue900,
			fontSize: '16px',
		},
	},
	otp: {
		'& .MuiInputBase-input': {
			fontSize: '34px',
			letterSpacing: '0.2em',
			textAlign: 'center',
		},
	},
	textLink: {
		'& .MuiButton-label': {
			fontFamily: theme?.typography?.fontFamily || BrownPro,
			color: color.institutions,
			fontWeight: 400,
			letterSpacing: '0.05em',
		},
	},
	errorIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: color.error,
		color: color.white,
		height: 15,
		width: 15,
		minWidth: 15,
		borderRadius: '50%',
		fontSize: '14px',
		fontWeight: 400,
		marginRight: 5,
	},
}))

const handleKeyPress = (event: any, value: any) => {
	if (event.key === 'Enter') {
		value()
	}
}

const EndAdornmentWrapper = styled.span`
	height: 24px;
	width: 24px;
	margin-right: 16px;
	margin-top: -12px;
	color: ${color.grey500};
	cursor: pointer;

	&:focus {
		// TODO: get Theme color for focus highlight
		box-shadow: 0 0 0 3pt #71b3ff !important;
	}

	svg path {
		fill: ${props => props.theme?.palette?.text?.background || color.grey500};
	}
`

export const Input: React.FC<InputProps> = (props: InputProps) => {
	const {
		value = '',
		disableClose,
		onChange,
		onFocus,
		onBlur,
		containerstyle,
		name,
		label,
		type = 'text',
		readOnly,
		helperText,
		disableShadow,
		enableUnderLine,
		min,
		max,
		validate,
		validateOnInputDelay,
		maxLength,
		fullWidth,
		ariaLabel,
		...rest
	} = props
	const theme = useTheme() as ThemeOptions
	const inputRef = useRef<HTMLInputElement>(null)
	const [v, setValue] = useState(value)
	const [passwordVisible, setPasswordVisible] = useState(false)
	const [focus, setFocus] = useState(false)
	const [error, setError] = useState(props.error)
	const classes = useBakktStyles()

	const [setValidateTimer, clearTimer] = useTimer(validateOnInputDelay, () => {
		handleValidate()
	})

	useEffect(() => {
		setError(props.error)
	}, [props.error])

	useEffect(() => {
		clearTimer()
		if (v) setValidateTimer()
	}, [v])

	const handleCancel = (e: any) => {
		setValue('')
		inputRef?.current?.focus()
		onChange && onChange(e, '')
	}

	const togglePassword = useCallback(() => {
		setPasswordVisible(prev => !prev)
	}, [])

	const closeIcon =
		disableClose || !v ? null : (
			<EndAdornmentWrapper data-testid={`clear-${formatCamelCase(name || label || 'value')}-field`}>
				<XSmll
					onClick={handleCancel}
					onKeyPress={event => handleKeyPress(event, handleCancel)}
					role={'button'}
					tabIndex={0}
					aria-label={`Clear ${formatCamelCase(label || 'value')}`}
				/>
			</EndAdornmentWrapper>
		)

	const eyeIcon = (
		<EndAdornmentWrapper data-testid={`clear-${formatCamelCase(name || label || 'value')}-field`}>
			<InlineBtn onClick={togglePassword}>{passwordVisible ? 'Hide' : 'Show'}</InlineBtn>
		</EndAdornmentWrapper>
	)

	// eslint-disable-next-line
	const handleFocus = (event: any) => {
		setFocus(true)
		onFocus && onFocus(event)
	}

	// eslint-disable-next-line
	const handleBlur = (event: any) => {
		setFocus(false)
		onBlur && onBlur(event)
		handleValidate()
	}

	// eslint-disable-next-line
	const handleChange = (event: any) => {
		const value = event?.target?.value
		setValue(value)
		onChange && onChange(event, value)
	}

	const handleValidate = () => {
		if (validate) setError(!validate(v))
	}

	const inputType = useMemo(
		() => (type !== 'password' ? type : passwordVisible ? 'text' : 'password'),
		[type, passwordVisible],
	)

	return (
		<ShadowContainer
			hideShadow={disableShadow}
			style={{
				padding: '4px 16px',
				width: fullWidth ? 'calc(100% - 32px)' : 'auto',
				backgroundColor: theme?.palette?.background?.paper || 'white',
				...(theme?.disableShadow &&
					disableShadow !== false && {
						boxShadow: 'none',
						borderRadius: 0,
					}),
				...containerstyle,
			}}
		>
			<TextField
				{...rest}
				inputRef={inputRef}
				className={classes.root}
				helperText={
					helperText && (
						<span style={{display: 'flex', alignItems: 'center'}}>
							{error ? <span className={classes.errorIcon}>!</span> : null} {helperText}
						</span>
					)
				}
				fullWidth={false}
				value={v}
				onChange={handleChange}
				label={label}
				name={name}
				type={inputType}
				onFocus={handleFocus}
				onBlur={handleBlur}
				style={{
					width: '100%',
					...props.style,
					height: (focus || v) && helperText ? 'auto' : 48,
					...(theme?.disableShadow &&
						disableShadow !== false && {
							borderBottom: `1px solid ${theme?.palette?.text?.background || '#000000'}`,
						}),
				}}
				variant='standard'
				error={error}
				InputProps={{
					...props.InputProps,
					classes: {input: classes.root},
					endAdornment: props.endAdornment ? (
						<EndAdornmentWrapper>{props.endAdornment}</EndAdornmentWrapper>
					) : type === 'password' ? (
						eyeIcon
					) : (
						closeIcon
					),
					readOnly: readOnly,
					disableUnderline: !enableUnderLine,
					inputProps: {
						min: min,
						max: max,
						maxLength: maxLength,
						'aria-label': ariaLabel ? ariaLabel : label,
					},
				}}
				InputLabelProps={{
					...props.InputLabelProps,
					style: {marginTop: helperText ? (!focus && !v ? -16 : 0) : -8, ...props.InputLabelProps?.style},
				}}
				FormHelperTextProps={{
					...props.FormHelperTextProps,
					style: {marginTop: !focus && !v ? -20 : 0, ...props.FormHelperTextProps?.style},
				}}
				inputProps={{
					...props.inputProps,
					style: {marginTop: !label ? 8 : 0, ...props.inputProps?.style},
				}}
			/>
		</ShadowContainer>
	)
}
