import React, {HTMLAttributes} from 'react'
import styled from '@emotion/styled'

export interface ScrollbarProps extends HTMLAttributes<HTMLDivElement> {
	color?: string
	height?: string
}

const DefaultScrollbar = styled.div((props: ScrollbarProps) => ({
	overflow: 'auto',
	maxHeight: props.height || '100vh',
	background: props.color,
}))

export const Scrollbar: React.FC<ScrollbarProps> = ({children, ...prop}) => (
	<DefaultScrollbar {...prop}>{children}</DefaultScrollbar>
)
