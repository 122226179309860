import {H1, HBox, InlineBtn, Input, Main, VBox} from '@bakkt/components'
import {useStore} from 'store'
import Page from '../../../../components/Page'
import {RoutesName} from '../../../../constants/routes'
import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {logger} from '../../../../utils/logger/datadogInit'

const ViewAccountDetails = () => {
	const {lastError, loadProfile, partyProfile, isPartnerBakkt} = useStore()
	const navigate = useNavigate()

	useEffect(() => {
		logger.info('ViewAccountDetails - initilized', {filename: 'ViewAccountDetails.tsx'})
		if (!partyProfile) {
			loadProfile()
		}
	}, [])

	const editButton = (link: string, label: string) => {
		if (isPartnerBakkt) {
			return (
				<InlineBtn onClick={() => navigate(link)} data-testid={`Edit-${label}`}>
					Edit
				</InlineBtn>
			)
		}
	}

	const fullName = () => {
		if (partyProfile?.Party?.firstName != null && partyProfile?.Party?.lastName != null) {
			return `${partyProfile?.Party?.firstName} ${partyProfile?.Party?.lastName}`
		}
	}

	return (
		<Page withBackIcon loading={!partyProfile?.Party && !lastError}>
			<Main>
				<VBox verticalGap={15}>
					<HBox style={{paddingLeft: '10px'}}>
						<H1>Your Info</H1>
					</HBox>
				</VBox>
				<VBox verticalGap={15}>
					<Input
						type={'text'}
						label='Name'
						value={fullName()}
						disableClose={true}
						readOnly={true}
						endAdornment={editButton(RoutesName.editAccountName, 'Name')}
					/>
					<Input
						type={'text'}
						label='Street'
						value={partyProfile?.Party?.address?.streetLine1}
						disableClose={true}
						readOnly={true}
						endAdornment={editButton(RoutesName.editAccountAddress, 'Street')}
					/>
					<Input
						type={'text'}
						label='City'
						value={partyProfile?.Party?.address?.locality}
						disableClose={true}
						readOnly={true}
					/>
					<div style={{display: 'flex', flexDirection: 'row'}}>
						<Input
							type={'text'}
							label='State'
							value={partyProfile?.Party?.address?.region}
							containerstyle={{width: '50%'}}
							disableClose={true}
							readOnly={true}
						/>
						<Input
							type={'text'}
							label='Area Code'
							value={partyProfile?.Party?.address?.postalCode}
							containerstyle={{width: '50%'}}
							disableClose={true}
							readOnly={true}
						/>
					</div>
					<Input
						type={'text'}
						label='Email'
						value={partyProfile?.Party?.email}
						readOnly={true}
						disableShadow={true}
						enableUnderLine={true}
						disableClose={true}
						endAdornment={editButton(RoutesName.editAccountEmail, 'Email')}
					/>
					<Input
						type={'text'}
						label='Mobile'
						value={partyProfile?.Party?.phone}
						readOnly={true}
						disableShadow={true}
						enableUnderLine={true}
						disableClose={true}
						endAdornment={editButton(RoutesName.editAccountPhone, 'Mobile')}
					/>
				</VBox>
			</Main>
		</Page>
	)
}

export default ViewAccountDetails
