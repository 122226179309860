import {useEffect, useState} from 'react'

import {useIsMounted} from './useIsMounted'

interface Props {
	input?: string
	validate: () => void | Promise<void>
	clearError: () => void
	timeout?: number
}

export const useDelayedValidation = ({input, validate, clearError, timeout = 500}: Props) => {
	const [timeoutId, setTimeoutId] = useState<number | null>(null)
	const useMounted = useIsMounted()

	useEffect(() => {
		clearError()

		if (timeoutId) {
			clearTimeout(timeoutId)
		}

		//Apply error with a delay so that if the user is typing, the error will appear only when they finished.
		const id = setTimeout(() => {
			if (useMounted.current) {
				validate()
			}
		}, timeout)
		// @ts-ignore
		setTimeoutId(id)

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId)
			}
		}
	}, [input])
}
