import * as React from 'react'
export type FooterBoxProps = {
	lineHeight?: string
	fontSize?: string
} & React.HTMLProps<any>
export const FooterBox: React.FC<FooterBoxProps> = (props: FooterBoxProps) => {
	return (
		<div
			style={{
				...props.style,
				height: '38px',
				background: '#E7E6E9',
				borderBottomRightRadius: '8px',
				alignItems: 'center',
				display: 'flex',
				borderBottomLeftRadius: '8px',
			}}
			className={props.className}
		>
			{React.Children.map(props.children, (child, i) => {
				return (
					<>
						<div style={{lineHeight: props.lineHeight, fontSize: props.fontSize}} />
						{child}
					</>
				)
			})}
		</div>
	)
}
