export const getErrorMessage = (errorCode: string, defaultErrorCode: string) => {
	let matchedErrorCode = Object.values(BakktErrorCodes).filter(error => error === errorCode)

	return matchedErrorCode.length > 0 ? `errors.codes.${matchedErrorCode[0]}` : `errors.${defaultErrorCode}`
}

export enum BakktErrorCodes {
	EXISTING_PASSWORD_ERROR,
	BREACHED_PASSWORD,
	INSECURE_PASSWORD,
	INVALID_USERNAME_OR_PASSWORD,
	UNSUPPORTED_FINANCIAL_INSTITUTION,
}
