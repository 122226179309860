import {ApiAmountCurrencyEnum} from '@bakkt/api'
import {Main, VBox, Input, Button, Link, BottomBox, H2, Light, Large, Alert, HBox, H6} from '@bakkt/components'
import {RoutesName} from 'constants/routes'
import {embeddedDebitLinkingApi, fetchVerifyDebitAmount, embeddedPartnerApi} from 'api'
import Page from 'components/Page'
import {useNavigate} from 'hooks'
import {useVerifyDebitCard as useValidateVerifyDebitCards} from 'hooks/useVerifyDebitCard'
import {useEffect, useState} from 'react'
import {useStore} from 'store'
type VerificationAmountResp = {verificationAmounts: {amount: number}[]}

export default () => {
	const {cardVerifyDetails, cardVerifyError, isValid, validateVerifyDebitCard} = useValidateVerifyDebitCards()
	const {fundingAccounts, onShowToast, setFundingAccounts} = useStore()
	const [showAlert, setShowAlert] = useState(false)
	const navigate = useNavigate()
	const debitAccount = fundingAccounts.find(account => account.externalAccountRef.startsWith('DEBIT:'))
	const [tangoVerificationAmountResp, setTangoVerificationAmountResp] = useState<VerificationAmountResp | null>(null)
	const [loading, setLoading] = useState(false)
	const fetchVerification = async () => {
		const response = await fetchVerifyDebitAmount()
		setTangoVerificationAmountResp(response?.data?.payload)
	}
	useEffect(() => {
		fetchVerification()
	}, [])

	const navigateToPage = () => {
		navigate(`/${RoutesName.bakktFunding.cashPage}`)
	}

	const handleContinue = async () => {
		setLoading(true)
		try {
			const response = await embeddedDebitLinkingApi.verifyDebitCard({
				debitCardId: debitAccount?.externalAccountRef?.split(':')?.[1] || '',
				verificationAmounts: [
					{
						amount: parseFloat(cardVerifyDetails.amount1.replace('$', '')),
						currency: ApiAmountCurrencyEnum.USD,
					},
					{
						amount: parseFloat(cardVerifyDetails.amount2.replace('$', '')),
						currency: ApiAmountCurrencyEnum.USD,
					},
				],
			})
			if (response?.data?.success) {
				const fundingAccountsResponse = await embeddedPartnerApi.fetchFundingAccounts('USD')
				setFundingAccounts(fundingAccountsResponse?.data?.payload || [])
				onShowToast({
					toastMessage: (
						<span style={{textAlign: 'center'}}>
							<H6 style={{fontWeight: 'bold'}}>Debit Card linked</H6>
						</span>
					) as any,
					toastType: 'success',
				})
				navigate(`/${RoutesName.home}`)
			} else {
				setShowAlert(true)
			}
		} finally {
			setShowAlert(true)
			setLoading(false)
		}
	}

	const handleUnlink = async () => {
		setLoading(true)
		const response = await embeddedDebitLinkingApi.expireDebitCardByPartyId(debitAccount?.externalAccountRef || '')
		if (response?.data?.success) {
			const fundingAccountsResponse = await embeddedPartnerApi.fetchFundingAccounts('USD')
			setFundingAccounts(fundingAccountsResponse?.data?.payload || [])
			setLoading(false)
			navigateToPage()
		}
		setLoading(false)
		navigateToPage()
	}
	const handleInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		validateVerifyDebitCard(e)
	}

	return (
		<Page loading={loading} titleHeader='Debit Card Linking' withSubHeaderLogo={true} showJointLogo={false}>
			<Main>
				<VBox verticalGap={15}>
					<H2>Verify Transactions</H2>
					<Large>
						In your bank account’s debit card activity, you’ll see two small transactions from Bakkt. Enter
						those amounts here.
					</Large>
					{showAlert && (
						<Alert severity='error' icon={false} onClose={() => setShowAlert(false)}>
							Failed to link debit card. Please try again !!!
						</Alert>
					)}
					<Input
						type='text'
						label='Amount1'
						name='amount1'
						error={!!cardVerifyError.amount1}
						helperText={cardVerifyError.amount1}
						onChange={handleInputChanged}
					/>
					<Input
						type='text'
						label='Amount2'
						name='amount2'
						error={!!cardVerifyError.amount2}
						helperText={cardVerifyError.amount2}
						onChange={handleInputChanged}
					/>
					{tangoVerificationAmountResp && (
						<Light>
							Verification Amount $
							{tangoVerificationAmountResp?.verificationAmounts?.map(amount => amount.amount).join(',')}
						</Light>
					)}
					<BottomBox>
						<Button size='large' onClick={handleContinue} fullWidth disabled={!isValid}>
							Continue
						</Button>
						<HBox>
							<Link onClick={handleUnlink}>Unlink</Link>
							<div style={{width: 15}} />
							<Link onClick={navigateToPage}>Cancel</Link>
						</HBox>
					</BottomBox>
				</VBox>
			</Main>
		</Page>
	)
}
