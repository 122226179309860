import {embeddedBankLinkingApi, embeddedPartnerApi} from 'api'
import {showErrorDialog} from 'components/ErrorDialog'
import {H6} from '@bakkt/components'
import usePlaidLinking, {PlaidAccount} from 'hooks/usePlaidLinking'
import {useEffect, useState} from 'react'
import {useStore} from 'store'
import {getErrorMessage} from 'utils/ErrorMessageFormatter'
import {useTranslation} from 'react-i18next'
import {hideDialog} from 'components/Dialog'

type PlaidBankLinkProps = {
	callBackSuccess?: (bankStatus: string) => void
	onPlaidExit?: () => void
	pendingAccount?: boolean
}

const PlaidBankLink = ({callBackSuccess, onPlaidExit, pendingAccount}: PlaidBankLinkProps) => {
	const {partner} = useStore()
	const [linkToken, setLinkToken] = useState('')
	const {fiatCurrencyPreference, setFundingAccounts, onShowToast} = useStore()
	const {t} = useTranslation()
	let bankStatus: string = ''

	const handlePlaidSuccess: any = async (plaidAccount: PlaidAccount) => {
		if (plaidAccount.verificationStatus === 'PENDING_AUTOMATIC_VERIFICATION') {
			bankStatus = 'pending-auto'
		}
		if (plaidAccount.verificationStatus === 'PENDING_MANUAL_VERIFICATION') {
			bankStatus = 'pending'
		}
		try {
			let response = pendingAccount
				? await embeddedBankLinkingApi.verifyAndActivatePendingBankAccount({
						accountRef: plaidAccount.accountRef,
				  })
				: await embeddedBankLinkingApi.createOrUpdateBankAccount(plaidAccount)
			if ((response as any).response) {
				response = (response as any).response
			}
			if (!response?.data?.success) {
				showErrorDialog({
					title: `Bank Linking Error`,
					message: `${t(getErrorMessage(response?.data?.error?.code!, 'linkBankAccountError'))}`,
					partner,
					errorCode: response?.data?.error?.code,
				})
			} else {
				onShowToast({
					toastMessage: (
						<span style={{textAlign: 'center'}}>
							<H6 style={{fontWeight: 'bold'}}>Bank account linked</H6>
							<p>You’ll be able to buy crypto when your cash posts in 3-5 business days</p>
						</span>
					) as any,
					toastType: 'success',
				})
				callBackSuccess && callBackSuccess(bankStatus)
			}
		} catch (ex) {
			showErrorDialog({
				title: `Bank Linking Error`,
				message: `${t(getErrorMessage('', 'linkBankAccountError'))}`,
				partner,
				errorCode: 'unknown_error',
			})
		} finally {
			const response = await embeddedPartnerApi.fetchFundingAccounts(fiatCurrencyPreference as any)
			if (response.data.success) {
				setFundingAccounts(response.data.payload || [])
			}
		}
	}

	const onPlaidExit0 = () => {
		if (onPlaidExit) {
			onPlaidExit()
		} else {
			hideDialog()
		}
	}

	const handleBankLinking = async () => {
		if (window.location.href.indexOf('webhook') < 0) {
			sessionStorage.removeItem('bankLinkingToken')
		} else {
			setLinkToken(sessionStorage.getItem('bankLinkingToken') as string)
			return
		}
		const response = await embeddedBankLinkingApi.getLinkToken({
			redirectUri:
				window.location.origin.indexOf('localhost') >= 0
					? window.location.origin
					: window.location.origin + '/webhook.html',
			forUpdate: pendingAccount,
		})
		if (response.data?.payload?.linkToken) {
			sessionStorage.setItem('bankLinkingToken', response.data?.payload?.linkToken)
			setLinkToken(response.data?.payload?.linkToken)
		}
	}

	const {open, ready, error} = usePlaidLinking(linkToken, handlePlaidSuccess, onPlaidExit0)
	useEffect(() => {
		if (ready && linkToken) {
			open()
			hideDialog()
		}
	}, [ready, linkToken])

	useEffect(() => {
		handleBankLinking()
	}, [])

	return <></>
}
export default PlaidBankLink
