import {useState, useEffect} from 'react'
import {t} from 'i18next'
import moment from 'moment'

import {isValidEmail} from 'utils/formatters'

type FieldValidationProps = {
	defaultError?: string
	isOptional?: boolean
	minimumLength?: number
	initialValue?: string
	additionalValidation?: (value: string) => string
}

type FieldValidationOutput = [
	value: string,
	setValue: (value: string) => void,
	validate: (value: string, showError?: boolean) => boolean,
	errorMessage: string | undefined,
]

export const useValidateField = ({
	defaultError,
	isOptional,
	minimumLength,
	additionalValidation,
	initialValue,
}: FieldValidationProps): FieldValidationOutput => {
	const [value, setValue] = useState<string>(initialValue || '')
	const [errorMessage, setErrorMessage] = useState<string>()

	const validate = (value: string, showError?: boolean) => {
		const valueMissing = !isOptional && !value.trim()
		const lessThanMinimum = minimumLength && value && value.trim().length < minimumLength
		const additionalError = additionalValidation?.(value)

		const isValid = !valueMissing && !lessThanMinimum && !additionalError

		if (showError) setErrorMessage(isValid ? '' : additionalError || defaultError)
		return isValid
	}

	useEffect(() => {
		setErrorMessage('')
	}, [value])

	useEffect(() => {
		if (initialValue) {
			validate(initialValue, true)
		}
	}, [initialValue])

	return [value, setValue, validate, errorMessage]
}

export const streetValidator = (street: string) =>
	street.toLowerCase().includes('po box') ? t('errors.poBoxError') : ''

export const emailValidator = (email: string) => (isValidEmail(email) ? '' : t('errors.invalidEmail'))

export const isRegionDenied = (region: string) => {
	return ['hi'].includes(region.toLowerCase())
}

export const regionValidator = (region: string) => {
	if (!/[a-zA-Z]{2}/.test(region)) return t('errors.missingRegion')
	else if (isRegionDenied(region)) return t('errors.restrictedStateError')
	return ''
}

export const dateOfBirthValidator = (dobInput: string) => {
	const DOB_FORMAT_INPUT = 'MMDDYYYY'
	const DOB_FORMAT_OUTPUT = 'MM/DD/YYYY'
	const MIN_AGE = 18
	const MAX_AGE = 120

	let errorMessage = ''

	// Remove spaces and special characters from date
	// We do this because to ensure date input is the same across different sources
	const rawDate = dobInput.replace(/[ -/]/gi, '')

	// Check if user has input the correct amount of digits for the date
	if (rawDate.length === DOB_FORMAT_INPUT.length) {
		// Manually inject separator into date for moment to format
		const month = rawDate.slice(0, 2)
		const day = rawDate.slice(2, 4)
		const year = rawDate.slice(4, 8)
		const formattedDob = `${month}/${day}/${year}`

		// Finally, use moment to validate formatted date of birth
		const dateOfBirth = moment(formattedDob, DOB_FORMAT_OUTPUT)
		const dateIsValid = dateOfBirth.isValid()
		const age = moment().diff(dateOfBirth, 'years')

		if (!dateIsValid || Number.isNaN(age) || age > MAX_AGE) {
			errorMessage = t('errors.invalidDobError')
		} else if (age < MIN_AGE) {
			errorMessage = t('errors.mustBe18Error')
		}
	} else {
		errorMessage = t('errors.invalidDobError')
	}

	return errorMessage
}
