import React, {useState} from 'react'
import Page from '../../../../components/Page'
import {Alert, Button, H1, HBox, Input, Main, VBox, BottomBox} from '@bakkt/components'
import {useStore} from 'store'
import {usePartyName} from '../../../../hooks/auth/usePartyName'
import {useNavigate} from 'react-router-dom'
import {RoutesName} from '../../../../constants/routes'

const EditAccountName = () => {
	const {partyProfile, setPartyUpdateProfileInfo, partyUpdateProfileInfo} = useStore()
	const [newFirstName, setNewFirtName] = useState(partyProfile?.Party?.firstName!)
	const [newLastName, setNewLastName] = useState(partyProfile?.Party?.lastName!)
	const {isValid: isNewFirstNameValid, error: newFirstNameError} = usePartyName(newFirstName, 'First Name')
	const {isValid: isNewLastNameValid, error: newLastNameError} = usePartyName(newLastName, 'Last Name')
	const [serverError, setServerError] = useState<string | null>(null)
	const navigate = useNavigate()

	const validatePartyName = () => {
		if (
			isNewFirstNameValid &&
			isNewLastNameValid &&
			(newFirstName.toLowerCase() !== partyProfile?.Party?.firstName?.toLowerCase() ||
				newLastName.toLocaleLowerCase() !== partyProfile?.Party?.lastName?.toLowerCase())
		) {
			return false
		}
		return true
	}

	const updatePartyName = async () => {
		setPartyUpdateProfileInfo({
			newFirstName: newFirstName,
			newLastName: newLastName,
			locality: null,
			country: null,
			postalCode: null,
			region: null,
			streetLine1: null,
		})
		navigate(`/${RoutesName.onboarding.taxpayerCertification}`)
	}
	return (
		<Page withBackIcon>
			<Main>
				{serverError && (
					<Alert severity='error' icon={false} onClose={() => setServerError(null)}>
						{serverError}
					</Alert>
				)}
				<VBox verticalGap={15}>
					<HBox style={{paddingLeft: '10px'}}>
						<H1>Edit Profile</H1>
					</HBox>
				</VBox>
				<div style={{display: 'flex', flexDirection: 'row'}}>
					<Input // @ts-ignore
						type={'text'}
						label='First Name'
						value={partyProfile?.Party?.firstName}
						containerstyle={{width: '50%'}}
						disableClose={true}
						readOnly={false}
						error={!!newFirstNameError}
						helperText={newFirstNameError}
						onChange={(_, value) => setNewFirtName(value)}
					/>
					<Input // @ts-ignore
						type={'text'}
						label='Last Name'
						value={partyProfile?.Party?.lastName}
						containerstyle={{width: '50%'}}
						disableClose={true}
						readOnly={false}
						helperText={newLastNameError}
						error={!!newLastNameError}
						onChange={(_, value) => setNewLastName(value)}
					/>
				</div>
				<BottomBox style={{marginTop: '50px', marginBottom: '30px'}}>
					<VBox verticalGap={10} style={{width: '-webkit-fill-available'}}>
						<Button size='large' disabled={validatePartyName()} onClick={updatePartyName}>
							Continue
						</Button>
					</VBox>
				</BottomBox>
			</Main>
		</Page>
	)
}

export default EditAccountName
