import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {
	H2,
	H3,
	H4,
	H6,
	Medium,
	VBox,
	HBox,
	Large,
	Main,
	Link,
	Separator,
	Icons,
	ShadowContainer,
} from '@bakkt/components'
import Page from 'components/Page'
import {embeddedPartnerApi, ApiHook} from 'api'

type ReportProps = {
	fileName: string
	reportYear: number
}

const CurrentReport = ({fileName, reportYear: year}: ReportProps) => {
	const {t} = useTranslation()
	const {request, response} = ApiHook(() => embeddedPartnerApi.getTaxReportDownloadLink(fileName))
	useEffect(() => {
		if (fileName) {
			request()
		}
	}, [])
	return (
		<VBox style={{marginBottom: 10}}>
			<Large>{fileName ? t('tax.ready', {year}) : t('tax.notReady', {year})}</Large>
			<ShadowContainer
				card={true}
				borderRadius={10}
				style={{
					textAlign: 'center',
					padding: 20,
					alignItems: 'center',
				}}
			>
				<Icons.Tax />
				<VBox>
					<H4>{t('tax.taxYear', {year})}</H4>
					{fileName ? (
						<span>
							<Link
								style={{
									textDecoration: 'none',
								}}
								target='_blank'
								href={response as string}
							>
								{t('tax.downloadTax')}
							</Link>
							<Icons.ArrowRight style={{marginLeft: 5, verticalAlign: 'middle'}} />
						</span>
					) : (
						<Medium>{t('tax.notAvailable')}</Medium>
					)}
				</VBox>
			</ShadowContainer>
		</VBox>
	)
}

const PastReport = ({reportYear, fileName}: ReportProps) => {
	const {t} = useTranslation()
	const {request, response} = ApiHook(() => embeddedPartnerApi.getTaxReportDownloadLink(fileName))
	useEffect(() => {
		request()
	}, [])
	return (
		<VBox>
			<HBox
				style={{
					justifyContent: 'space-between',
					padding: '5px 0',
				}}
			>
				<H6>{`${reportYear} Taxes`}</H6>
				<HBox
					style={{
						alignItems: 'center',
					}}
				>
					<Link
						style={{
							textDecoration: 'none',
						}}
						target='_blank'
						href={response as string}
					>
						{t('tax.download')}
					</Link>
					<Icons.ArrowRight style={{marginLeft: 5}} />
				</HBox>
			</HBox>
			<Separator />
		</VBox>
	)
}

export const Taxes = () => {
	const {t} = useTranslation()
	const year = moment().year()
	const [currentReport, setCurrentReport] = useState<ReportProps>()
	const [pastReport, setPastReport] = useState<ReportProps[]>([])
	const {request, response, loading} = ApiHook(() => embeddedPartnerApi.getTaxReportList())

	useEffect(() => {
		request()
	}, [])

	useEffect(() => {
		setPastReport(response?.filter(itm => itm.reportYear !== year) as ReportProps[])
		const currentReport = response?.find(itm => itm.reportYear === year)
		if (currentReport) {
			setCurrentReport(currentReport as ReportProps)
		}
	}, [response])

	return (
		<Page withBackIcon titleHeader={t('tax.header')} title={t('tax.title')} loading={loading}>
			<Main>
				<VBox>
					<H2 style={{margin: 0}}>{t('tax.title')}</H2>
				</VBox>
				<CurrentReport {...(currentReport as ReportProps)} reportYear={year} />
				<VBox>
					<H3>{t('tax.pastYear')}</H3>
					{pastReport?.map(item => {
						return <PastReport {...(item as ReportProps)} />
					})}
				</VBox>
			</Main>
		</Page>
	)
}
