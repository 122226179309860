import {useEffect, useMemo, FormEvent} from 'react'
import {t} from 'i18next'
import {
	Input,
	H2,
	Large,
	DateFormatter,
	SsnFormatter,
	NumberFormatter,
	PostalCodeFormatter,
	formatSsnForServer,
	formatPhoneNumberForServer,
	formatDobForServer,
	formatDobForFrontend,
	FormContainer,
	Icons,
	color,
	Main,
	BottomBox,
} from '@bakkt/components'
import {
	ApiAddressCountryEnum,
	ApiEmbeddedPartnerPartyProfileResponseMissingFieldsEnum as MissingField,
	ApiPartnerPartyEnrollmentData,
} from '@bakkt/api'

import {
	useValidateField,
	streetValidator,
	emailValidator,
	isRegionDenied,
	regionValidator,
	dateOfBirthValidator,
} from 'hooks/useValidateField'
import Button from 'components/Button'
import {showWarning} from 'components/Dialog'
import Page from 'components/Page'
import {useStore} from 'store'
import {RoutesName} from 'constants/routes'
import {useNavigate} from 'hooks'
import ExitOnboardingButton from 'components/ExitOnboardingButton'
import {logger} from 'utils/logger/datadogInit'

export const PersonalInformationInput = () => {
	const navigate = useNavigate()
	const {updateMissingProfileFields, missingProfileFields, partyProfile} = useStore()

	const [firstName, setFirstName, validateFirstName, firstNameError] = useValidateField({
		defaultError: t('errors.missingFirstName'),
		initialValue: missingProfileFields?.firstName,
	})

	const [lastName, setLastName, validateLastName, lastNameError] = useValidateField({
		defaultError: t('errors.missingLastName'),
		initialValue: missingProfileFields?.lastName,
	})

	const [email, setEmail, validateEmail, emailError] = useValidateField({
		defaultError: t('errors.invalidEmail'),
		additionalValidation: emailValidator,
		initialValue: missingProfileFields?.email,
	})

	const [phone, setPhone, validatePhone, phoneError] = useValidateField({
		minimumLength: 10,
		defaultError: t('errors.invalidPhone'),
		initialValue: missingProfileFields?.phone?.replace('+1', ''),
	})

	const [streetLine1, setStreetLine1, validateStreetLine1, streetLine1Error] = useValidateField({
		defaultError: t('errors.missingStreetLine1'),
		additionalValidation: streetValidator,
		initialValue: missingProfileFields?.address?.streetLine1,
	})

	const [streetLine2, setStreetLine2, validateStreetLine2, streetLine2Error] = useValidateField({
		isOptional: true,
		additionalValidation: streetValidator,
		initialValue: missingProfileFields?.address?.streetLine2,
	})

	const [locality, setLocality, validateLocality, localityError] = useValidateField({
		defaultError: t('errors.missingLocality'),
		initialValue: missingProfileFields?.address?.locality,
	})

	const [region, setRegion, validateRegion, regionError] = useValidateField({
		minimumLength: 2,
		defaultError: t('errors.missingRegion'),
		additionalValidation: regionValidator,
		initialValue: missingProfileFields?.address?.region,
	})

	const [postalCode, setPostalCode, validatePostalCode, postalCodeError] = useValidateField({
		minimumLength: 5,
		defaultError: t('errors.postalCodeError'),
		initialValue: missingProfileFields?.address?.postalCode,
	})

	const [dateOfBirth, setDateOfBirth, validateDateOfBirth, dateOfBirthError] = useValidateField({
		additionalValidation: dateOfBirthValidator,
		initialValue: formatDobForFrontend(missingProfileFields?.dateOfBirth || ''),
	})

	const [taxIdentifier, setTaxIdentifier, validateTaxIdentifier, taxIdentifierError] = useValidateField({
		minimumLength: 9,
		defaultError: t('errors.invalidSsnError'),
		initialValue: missingProfileFields?.taxIdentifier,
	})

	const [occupation, setOccupation, validateOccupation, occupationError] = useValidateField({
		defaultError: t('errors.missingOccupation'),
		initialValue: missingProfileFields?.occupation,
	})

	const isMissing = (field: MissingField) => {
		return partyProfile?.missingFields?.includes(field)
	}

	const validateForm = (showError?: boolean) => {
		const missingFields: Partial<ApiPartnerPartyEnrollmentData> = {}
		let hasAnyErrors = false

		if (isMissing(MissingField.FIRST_NAME)) {
			if (validateFirstName(firstName, showError)) {
				missingFields.firstName = firstName
			} else {
				hasAnyErrors = true
			}
		}

		if (isMissing(MissingField.LAST_NAME)) {
			if (validateLastName(lastName, showError)) {
				missingFields.lastName = lastName
			} else {
				hasAnyErrors = true
			}
		}

		if (isMissing(MissingField.EMAIL)) {
			if (validateEmail(email, showError)) {
				missingFields.email = email
			} else {
				hasAnyErrors = true
			}
		}

		if (isMissing(MissingField.PHONE)) {
			const formattedPhone = formatPhoneNumberForServer(phone) || ''
			// Strip the country code from server formatted phone number as
			// we just want to verify that the user supplied 10 digits for their number
			// logic will need to be more robust when we support more country codes
			if (validatePhone(formattedPhone.replace('+1', ''), showError)) {
				missingFields.phone = formattedPhone
			} else {
				hasAnyErrors = true
			}
		}

		if (isMissing(MissingField.ADDRESS)) {
			// Validate each address field independently to ensure all are highlighted at once
			const validStreetLine1 = validateStreetLine1(streetLine1)
			const validStreetLine2 = validateStreetLine2(streetLine2)
			const validLocality = validateLocality(locality)
			const validRegion = validateRegion(region)
			const validPostalCode = validatePostalCode(postalCode)

			if (validStreetLine1 && validStreetLine2 && validLocality && validRegion && validPostalCode) {
				missingFields.address = {
					streetLine1: streetLine1,
					streetLine2: streetLine2,
					locality: locality,
					region: region.toUpperCase(),
					postalCode: postalCode,
					country: ApiAddressCountryEnum.USA,
				}
			} else {
				hasAnyErrors = true
			}
		}

		if (isMissing(MissingField.DATE_OF_BIRTH)) {
			if (validateDateOfBirth(dateOfBirth, showError)) {
				missingFields.dateOfBirth = formatDobForServer(dateOfBirth || '')
			} else {
				hasAnyErrors = true
			}
		}

		if (isMissing(MissingField.TAX_ID_NUMBER)) {
			const formattedTaxIdentifier = formatSsnForServer(taxIdentifier) || ''
			if (validateTaxIdentifier(formattedTaxIdentifier, showError)) {
				missingFields.taxIdentifier = formattedTaxIdentifier
			} else {
				hasAnyErrors = true
			}
		}

		if (isMissing(MissingField.OCCUPATION)) {
			if (validateOccupation(occupation, showError)) {
				missingFields.occupation = occupation
			} else {
				hasAnyErrors = true
			}
		}

		return {hasAnyErrors, missingFields}
	}

	const submitKyc = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const {hasAnyErrors, missingFields} = validateForm(true)
		logger.info('Activate Crypto Personal info page - submit KYC', {error: hasAnyErrors})

		if (!hasAnyErrors) {
			// TODO:  make validation against Tango (API not ready)
			updateMissingProfileFields(missingFields)
			navigate(`${RoutesName.onboarding.home}${RoutesName.onboarding.taxpayerCertification}`, {ignore: true})
		}
	}

	const formHasErrors = useMemo(() => {
		const {hasAnyErrors} = validateForm()
		return hasAnyErrors
	}, [
		firstName,
		lastName,
		email,
		phone,
		streetLine1,
		streetLine2,
		locality,
		region,
		postalCode,
		dateOfBirth,
		taxIdentifier,
		occupation,
	])

	useEffect(() => {
		logger.info('Activate Crypto Personal info page - initilized', {filename: 'PersonalInformationInput.tsx'})
	}, [])

	useEffect(() => {
		if (isRegionDenied(region)) {
			logger.info('Activate Crypto Personal info page - warning', {
				flag: isRegionDenied(region),
				message: t('errors.restrictedStateError'),
			})
			showWarning({
				title: '',
				message: t('errors.restrictedStateError'),
				icon: Icons.DialogWarning,
				hideSave: true,
				hideCancel: true,
				skip: true,
				skipText: t('btnOkGotIt'),
				skipButtonStyle: {color: color.blue700},
			})
		}
	}, [region])

	return (
		<Page
			titleHeader={t('auth.activateCrypto')}
			title={t('titles.onboarding.personalInformation')}
			withSubHeaderLogo={false}
		>
			<Main>
				<H2 data-testid={'section-title'}>{t<string>('crypto.accountActivate.completeAccount')}</H2>
				<Large data-testid={'section-description'}>{t<string>('crypto.accountActivate.desPI')}</Large>

				<form onSubmit={submitKyc}>
					{/*Name*/}
					<FormContainer header={t('crypto.accountActivate.fullLegal')}>
						{isMissing(MissingField.FIRST_NAME) && (
							<Input
								id='firstName'
								disableShadow
								label={t('inputLabel.firstName')}
								name='firstName'
								type='text'
								maxLength={40}
								error={!!firstNameError}
								helperText={firstNameError}
								value={firstName}
								onChange={(_, value) => setFirstName(value)}
								validate={value => validateFirstName(value as string, true)}
								validateOnInputDelay={1000}
								required
							/>
						)}
						{isMissing(MissingField.LAST_NAME) && (
							<Input
								id='lastName'
								disableShadow
								label={t('inputLabel.lastName')}
								name='lastName'
								type='text'
								maxLength={40}
								error={!!lastNameError}
								helperText={lastNameError}
								value={lastName}
								onChange={(_, value) => setLastName(value)}
								validate={value => validateLastName(value as string, true)}
								validateOnInputDelay={1000}
								required
							/>
						)}
					</FormContainer>

					{/*Contact Details*/}
					<FormContainer header={t('crypto.accountActivate.contactDetail')}>
						{isMissing(MissingField.EMAIL) && (
							<Input
								id='email'
								disableShadow
								label={t('inputLabel.emailAddress')}
								name='email'
								type='text'
								error={!!emailError}
								helperText={emailError}
								value={email}
								onChange={(_, value) => setEmail(value)}
								validate={value => validateEmail(value as string, true)}
								validateOnInputDelay={1000}
								required
							/>
						)}
						{isMissing(MissingField.PHONE) && (
							<Input
								id='phone'
								disableShadow
								label={t('inputLabel.phoneNumber')}
								name='phone'
								type='text'
								InputProps={{inputComponent: NumberFormatter}}
								error={!!phoneError}
								helperText={phoneError}
								value={phone}
								onChange={(_, value) => setPhone(value)}
								validate={value => validatePhone(value as string, true)}
								validateOnInputDelay={1000}
								required
							/>
						)}
					</FormContainer>

					{/*Home Address*/}
					{isMissing(MissingField.ADDRESS) && (
						<FormContainer
							header={t('crypto.accountActivate.yourHomeAddress')}
							text={t('crypto.accountActivate.addressDes')}
						>
							<Input
								id='streetLine1'
								disableShadow
								label={t('inputLabel.addressLine', {number: 1})}
								name='streetLine1'
								type='text'
								maxLength={255}
								error={!!streetLine1Error}
								helperText={streetLine1Error}
								value={streetLine1}
								onChange={(_, value) => setStreetLine1(value)}
								validate={value => validateStreetLine1(value as string, true)}
								validateOnInputDelay={1000}
								required
							/>
							<Input
								id='streetLine2'
								disableShadow
								label={t('inputLabel.addressLine', {number: 2})}
								name='streetLine2'
								type='text'
								maxLength={255}
								error={!!streetLine2Error}
								helperText={streetLine2Error}
								value={streetLine2}
								onChange={(_, value) => setStreetLine2(value)}
								validate={value => validateStreetLine2(value as string, true)}
								validateOnInputDelay={1000}
							/>
							<Input
								id='locality'
								disableShadow
								label={t('inputLabel.city')}
								name='locality'
								type='text'
								maxLength={40}
								error={!!localityError}
								helperText={localityError}
								value={locality}
								onChange={(_, value) => setLocality(value)}
								validate={value => validateLocality(value as string, true)}
								validateOnInputDelay={1000}
								required
							/>
							<Input
								id='region'
								disableShadow
								label={t('inputLabel.state')}
								name='region'
								type='text'
								maxLength={2}
								error={!!regionError}
								helperText={regionError}
								value={region}
								onChange={(_, value) => setRegion(value)}
								validate={value => validateRegion(value as string, true)}
								validateOnInputDelay={1000}
								required
							/>
							<Input
								id='postalCode'
								disableShadow
								label={t('inputLabel.zipCode')}
								name='postalCode'
								type='text'
								InputProps={{inputComponent: PostalCodeFormatter}}
								error={!!postalCodeError}
								helperText={postalCodeError}
								value={postalCode}
								onChange={(_, value) => setPostalCode(value)}
								validate={value => validatePostalCode(value as string, true)}
								validateOnInputDelay={1000}
								required
							/>
						</FormContainer>
					)}

					{/*Tax Info*/}
					<FormContainer header={t('crypto.accountActivate.taxInfo')}>
						{isMissing(MissingField.DATE_OF_BIRTH) && (
							<Input
								id='dateOfBirth'
								disableShadow
								label={t('inputLabel.dateOfBirth')}
								name='dateOfBirth'
								type='text'
								InputProps={{
									inputComponent: DateFormatter,
								}}
								error={!!dateOfBirthError}
								helperText={dateOfBirthError}
								value={formatDobForFrontend(dateOfBirth || '')}
								onChange={(_, value) => setDateOfBirth(value)}
								validate={value => validateDateOfBirth(value as string, true)}
								validateOnInputDelay={1000}
								required
							/>
						)}
						{isMissing(MissingField.TAX_ID_NUMBER) && (
							<Input
								id='taxIdentifier'
								disableShadow
								label={t('inputLabel.SSN')}
								name='taxIdentifier'
								type='text'
								InputProps={{
									inputComponent: SsnFormatter,
								}}
								error={!!taxIdentifierError}
								helperText={taxIdentifierError}
								value={taxIdentifier}
								onChange={(_, value) => setTaxIdentifier(value)}
								validate={value => validateTaxIdentifier(value as string, true)}
								validateOnInputDelay={1000}
								FormHelperTextProps={{role: 'alert'}}
								required
							/>
						)}
						{isMissing(MissingField.OCCUPATION) && (
							<Input
								id='occupation'
								disableShadow
								label={t('inputLabel.occupation')}
								name='occupation'
								type='text'
								error={!!occupationError}
								helperText={occupationError}
								value={occupation}
								onChange={(_, value) => setOccupation(value)}
								validate={value => validateOccupation(value as string, true)}
								validateOnInputDelay={1000}
								required
							/>
						)}
					</FormContainer>
					<BottomBox>
						<Button disabled={formHasErrors} id='personal-information-submit' aria-label='Continue'>
							{t<string>('bntContinue')}
						</Button>
						<ExitOnboardingButton style={{marginBottom: '50px'}} />
					</BottomBox>
				</form>
			</Main>
		</Page>
	)
}
