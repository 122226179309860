import {useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {showTransactionCryptoErrorDialog} from 'components/ErrorDialog'
import {RoutesName} from 'constants/routes'
import {useStore} from 'store'
import {useNavigate} from 'hooks'

const {fetchPartnerLimits} = embeddedPartnerApi

export const useFetchPartnerLimits = () => {
	const {request, response, error} = ApiHook(() => fetchPartnerLimits())
	const {selectedCryptoAccount} = useStore()
	const navigate = useNavigate()

	if (error) {
		showTransactionCryptoErrorDialog(selectedCryptoAccount?.accountBalance?.currency).finally(() => {
			navigate(`/${RoutesName.crypto.overview}`, {replace: true})
		})
	}

	useEffect(() => {
		request()
	}, [])

	return response
}
