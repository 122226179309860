import {Paper, makeStyles, useTheme, ThemeOptions} from '@material-ui/core'
import {ComponentType, ReactElement, ReactNode, useEffect} from 'react'
import {color, Separator} from 'src'
import {Spinner} from '../loading/Spinner'
import {Header} from './Header'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: '100%',
		background: theme?.palette?.background?.default || 'white',
		color: theme?.palette?.text?.primary || '#100722',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		maxWidth: 680,
		// styling for width of 720 and below.  down(number) is exclusive
		[theme.breakpoints.down(720)]: {
			flex: 1,
			maxWidth: 720,
		},
		// styling for width of width greater than 720.  up(number) is inclusive
		[theme.breakpoints.up(720)]: {
			padding: '40px 20px',
			marginBottom: 20,
			marginTop: 0,
		},
	},
	topHeader: {
		marginBottom: '20px',
		borderTop: `8px solid ${theme?.palette?.primary?.main || color.blueKolibri}`,
		[theme.breakpoints.down(720)]: {
			display: 'none',
		},
	},
	subHeader: {
		padding: 0,
		maxWidth: 720,
		[theme.breakpoints.down(720)]: {
			borderTop: `8px solid ${theme?.palette?.primary?.main || color.blueKolibri}`,
		},
	},
	center: {
		margin: 'auto',
	},
	footerMobileWrapper: {
		padding: '30px 10px 10px 10px',
		marginTop: '10px',
		background: theme?.palette?.type === 'dark' ? theme?.palette?.background?.default : '#FAFAFB',
	},
}))

export type PageProps = {
	backText?: string
	centerIcon?: (props: any) => JSX.Element
	children?: ReactNode
	loading?: boolean
	onIconPress?: () => void
	startIcon?: (props: any) => JSX.Element
	title?: string
	titleHeader?: string
	TitleHeaderComp?: ReactNode
	withBackIcon?: boolean
	withLogo?: boolean
	withSubHeaderLogo?: boolean
	withSetting?: boolean
	withPoweredBy?: boolean
	showJointLogo?: boolean
	isLoginScreen?: boolean
	Footer?: ComponentType
}

export const Page = (props: PageProps) => {
	const {children, loading, Footer} = props
	const styles = useStyles()
	const theme = useTheme() as ThemeOptions
	const onMobileScreen = useMediaQuery('(max-width:719px)')

	useEffect(() => {
		document.body.style.backgroundColor = theme?.palette?.background?.paper || '#ffffff'
	}, [])

	useEffect(() => {
		if (props.title || props.titleHeader) document.title = props.title || props.titleHeader || ''
	}, [props.title, props.titleHeader])

	return (
		<div id='page' role='main' className={styles.root}>
			{!props.isLoginScreen && (
				<Header
					{...props}
					centerIcon={undefined}
					className={styles.topHeader}
					onIconPress={undefined}
					titleHeader=' '
					withBackIcon={false}
					withPoweredBy
				/>
			)}
			<Header
				{...props}
				id='subheader'
				className={styles.subHeader}
				startIcon={undefined}
				withLogo={props.withSubHeaderLogo}
			/>

			<Paper className={styles.content} elevation={0}>
				{loading ? (
					<div className={styles.center}>
						<Spinner />
					</div>
				) : (
					<>{children}</>
				)}
				{Footer && onMobileScreen && (
					<>
						<div className={styles.footerMobileWrapper}>
							<Footer />
						</div>
					</>
				)}
			</Paper>
			{Footer && !onMobileScreen && <Footer />}
		</div>
	)
}
