import {useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {RoutesName} from 'constants/routes'
import {useNavigate} from 'hooks'
import {showUpdateProfileErrorDialog} from 'components/ErrorDialog'
import {useStore} from 'store'

const {updateParty} = embeddedPartnerApi

export const useUpdatePartyInfo = (ew9Signature: string) => {
	const navigate = useNavigate()
	const {partyUpdateProfileInfo} = useStore()

	const {request, response, loading, error} = ApiHook(() => updateParty({ew9Signature: ew9Signature}))

	useEffect(() => {
		if (error) showUpdateProfileErrorDialog()
	}, [error])

	useEffect(() => {
		if (response) {
			partyUpdateProfileInfo?.newFirstName !== null || partyUpdateProfileInfo?.newLastName !== null
				? updatePartyName()
				: updatePartyAddress()
		}
	}, [response])

	const updatePartyName = async () => {
		const response = await embeddedPartnerApi.updateNameAndAddress({
			firstName: partyUpdateProfileInfo?.newFirstName!,
			lastName: partyUpdateProfileInfo?.newLastName!,
		})
		if (response?.data?.success) {
			navigate(`/${RoutesName.home}`)
		} else {
			showUpdateProfileErrorDialog()
		}
	}

	const updatePartyAddress = async () => {
		const response = await embeddedPartnerApi.updateNameAndAddress({
			address: {
				locality: partyUpdateProfileInfo?.locality!,
				country: partyUpdateProfileInfo?.country!,
				postalCode: partyUpdateProfileInfo?.postalCode!,
				region: partyUpdateProfileInfo?.region!,
				streetLine1: partyUpdateProfileInfo?.streetLine1!,
			},
		})
		if (response?.data?.success) {
			navigate(`/${RoutesName.home}`)
		} else {
			showUpdateProfileErrorDialog()
		}
	}

	return {
		loading,
		updateParty: request,
		response,
		error,
	}
}
