import {BreakPointOptions, DEFAULT_BREAK_POINTS} from '../components/text/ResponsiveHeader'
import {Theme} from '@material-ui/core'

export interface GetTextWidthProps {
	text: string
	breakPoints?: BreakPointOptions[]
	theme?: Theme
}

export const getTextWidth = ({text, breakPoints = DEFAULT_BREAK_POINTS, theme}: GetTextWidthProps) => {
	const c = document.createElement('canvas')
	const ctx = c?.getContext('2d') || ({} as any)

	// Calculate fontSize and fontWeight based on breakpoints
	let fontSize = breakPoints[breakPoints.length - 1].style.fontSize
	let fontWeight = breakPoints[breakPoints.length - 1].style.fontWeight
	for (const breakPoint of breakPoints) {
		if (text.length <= breakPoint.threshold) {
			fontSize = breakPoint.style.fontSize
			fontWeight = breakPoint.style.fontWeight
			break
		}
	}

	ctx.font = `${fontWeight} ${fontSize}px ${theme?.typography?.fontFamily || 'FKGrotesk'}`
	const textWidth = Math.ceil(ctx?.measureText?.(text + ' ')?.width)
	c.remove()
	return textWidth
}
