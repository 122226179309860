import React from 'react'
import MUIRadio, {RadioProps as MUIRadioProps} from '@material-ui/core/Radio'
import {CheckedRadio, UncheckedRadio, InactiveRadioUnchecked, InactiveRadioChecked} from 'src/components/icons'
import {FormControlLabel} from 'src/components/formControlLabel'
import {makeStyles} from '@material-ui/core/styles'

export interface RadioProps extends MUIRadioProps {
	label: string
	value: string
	disabled?: boolean
}

const useStyles = makeStyles(() => ({
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
}))

export const Radio: React.FC<RadioProps> = (props: RadioProps) => {
	const {label, value, disabled, ...rest} = props
	const classes = useStyles()

	return (
		<FormControlLabel
			value={value}
			control={
				<MUIRadio
					classes={{root: classes.root}}
					icon={disabled ? <InactiveRadioUnchecked /> : <UncheckedRadio />}
					checkedIcon={disabled ? <InactiveRadioChecked /> : <CheckedRadio />}
					disabled={disabled}
					disableRipple={true}
					color='default'
					{...rest}
				/>
			}
			label={label}
			disabled={disabled}
		/>
	)
}
