import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
	Large,
	Light,
	H2,
	Checkbox,
	HBox,
	IconLabel,
	Icons,
	Separator,
	useWindowDimensions,
	Main,
	BottomBox,
} from '@bakkt/components'
import Button from 'components/Button'
import Page from 'components/Page'
import {showDialog} from 'components/Dialog'
// @ts-ignore
import signUpImage from 'images/signUp.png'
import {useStore} from 'store'
import {RoutesName} from 'constants/routes'
import {BakktTermsOfUse} from './BakktTermsOfUse'
import {PrivacyPolicy} from './PrivacyPolicy'
import {BakktLicensesAndDisclosures} from './LicensesAndDisclosures'
import {removeUnderScoreAndDeCapitalize} from 'utils/formatters'
import {logger} from 'utils/logger/datadogInit'
import ExitOnboardingButton from '../../../../components/ExitOnboardingButton'
import {useNavigate, useSpeedbumpDialog} from 'hooks'
import {Link} from 'components/Link'

export const displayPrivacyPolicyDialog = () => {
	logger.info('Activate Crypto page Privacy Policy link clicked', {
		text: 'Privacy Policy',
	})
	displayDialogWithWrappedComponent(PrivacyPolicy)
}

export const displayBakktTermsOfUseDialog = () => {
	logger.info('Activate Crypto page Terns of use link clicked', {
		text: 'Terms of Service',
	})
	displayDialogWithWrappedComponent(BakktTermsOfUse)
}

export const displayLicensesAndDisclosuresDialog = () => {
	logger.info('Activate Crypto page Licenses and Disclosures link clicked', {
		text: 'Licenses & Disclosures',
	})
	displayDialogWithWrappedComponent(BakktLicensesAndDisclosures)
}

const displayDialogWithWrappedComponent = (wrappedComponent: () => JSX.Element) => {
	showDialog({
		title: '',
		WrappedComponent: wrappedComponent,
		hideSave: true,
		hideCancel: true,
		skip: false,
		fullScreen: true,
	})
}

const SharePersonalInfo = () => {
	const navigate = useNavigate()
	const {t} = useTranslation()
	const {width} = useWindowDimensions()
	const {loadProfile, partyProfile = {}, partner, lastError} = useStore()
	const [termsAccepted, setTermsAccepted] = useState(false)
	useSpeedbumpDialog()

	useEffect(() => {
		logger.info('Activate Crypto page - initilized', {filename: 'index.tsx'})
		loadProfile()
	}, [])

	const availableFields = partyProfile?.fieldsAvailableFromPartner || []
	const missingFields = partyProfile?.missingFields || []

	const navigateToNextScreen = () => {
		logger.info('Activate Crypto page - next button clicked', {
			errorCode: lastError?.code,
			noOfMissingFields: missingFields?.length,
		})
		if (lastError?.code === 'ACCOUNT_EXISTS_ANOTHER_PARTNER') {
			navigate(RoutesName.onboarding.accountExists, {ignore: true})
			return
		}
		if (missingFields?.length > 0) {
			navigate(`${RoutesName.onboarding.home}${RoutesName.onboarding.kyc}`, {ignore: true})
		} else {
			navigate(`${RoutesName.onboarding.home}${RoutesName.onboarding.taxpayerCertification}`, {ignore: true})
		}
	}

	return (
		<Page
			loading={!partyProfile && !lastError}
			titleHeader={t('crypto.accountActivate.activateCrypto')}
			withSubHeaderLogo={false}
			showJointLogo={true}
		>
			<Main centerText>
				<img
					src={signUpImage}
					role='img'
					aria-label='Bakkt Logo'
					style={{paddingBottom: 0, width: '80%', marginBottom: 20, marginTop: 0, maxWidth: 180}}
					alt=''
				/>
				<H2 data-testid={'personal-info-title'}>{t('crypto.accountActivate.yourPersonInfo')}</H2>
				<Large data-testid={'personal-info-description'}>
					{t('crypto.accountActivate.toGetStarted', {partnerName: partner?.name})}
				</Large>
				{availableFields.length > 0 && (
					<>
						<Separator />
						<HBox
							style={{
								display: 'grid',
								gridTemplateColumns: `repeat(${width <= 305 ? 1 : 2}, 1fr)`,
								width: 'max-content',
								margin: 'auto',
								gap: '0 1em',
								marginLeft: 'auto',
								marginRight: 'auto',
							}}
						>
							{availableFields.map((item, index) => {
								return (
									<IconLabel
										key={item}
										data-testid={item.toLowerCase().replace(/_/g, '-')}
										style={{flex: '50%', textTransform: 'capitalize'}}
										iconStyle={{transform: 'scale(0.65)'}}
										icon={Icons.Check}
									>
										{removeUnderScoreAndDeCapitalize(item)}
									</IconLabel>
								)
							})}
						</HBox>
						<Separator />
					</>
				)}
			</Main>
			<BottomBox>
				<Checkbox
					checked={termsAccepted}
					inputProps={{
						'aria-label':
							'I am at least 18 years old and agree to Bakkt’s Terms of Use and Privacy Policy.',
					}}
					onChange={() => {
						logger.info('Activate Crypto page checkbox clicked', {value: !termsAccepted})
						setTermsAccepted(!termsAccepted)
					}}
					label={
						<Light style={{textAlign: 'left'}}>
							{`${t('crypto.accountActivate.enough18')} `}
							<Link
								data-testid={'terms-of-use-link'}
								aria-label='Terms of Use'
								onEvent={() => displayBakktTermsOfUseDialog()}
							>
								{t('more.termsOfUse')}
							</Link>
							{' and '}
							<Link
								data-testid={'privacy-policy-link'}
								aria-label='Privacy Policy'
								onEvent={() => displayPrivacyPolicyDialog()}
							>
								{t('more.privacyPolicy')}
							</Link>
							.
						</Light>
					}
					name='accept-terms'
					className={'checkbox'}
				/>
				<Button
					role='button'
					aria-label='Share With Bakkt'
					className={'sharePersonalInfoBtn'}
					disabled={!termsAccepted}
					onClick={navigateToNextScreen}
				>
					{t('crypto.accountActivate.bntShareWithBakkt')}
				</Button>
				<ExitOnboardingButton style={{marginBottom: '5px'}} />
			</BottomBox>
		</Page>
	)
}
export default SharePersonalInfo
