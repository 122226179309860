import React, {HTMLAttributes, useEffect, useState} from 'react'
import {ThemeOptions, useTheme} from '@material-ui/core'
import {color} from 'src/color/color'

export type LogoProps = {
	id?: string
	width?: string
	height?: string
	currencyDetail?: any
} & HTMLAttributes<HTMLImageElement>

export const Logo: React.FC<LogoProps> = ({id, width, height, currencyDetail, ...prop}) => {
	const theme = useTheme() as ThemeOptions
	const [logoUrl, setLogoUrl] = useState<string | undefined>(theme?.logoUrl)
	const defaultSize = '30'
	useEffect(() => {
		id && setLogoUrl(theme?.logoUrl)
	}, [theme?.logoUrl])

	return !currencyDetail ? (
		<img
			data-testid={`${id}-logo`}
			src={logoUrl}
			height={defaultSize}
			onError={() => {
				setLogoUrl('')
			}}
			{...prop}
		/>
	) : (
		<img
			data-testid={`${currencyDetail.currency}-logo`}
			src={currencyDetail.iconURL}
			width={width || defaultSize}
			height={height || width || defaultSize}
			style={{
				backgroundColor: currencyDetail?.bgColor,
				color: currencyDetail?.iconColor,
				borderRadius: '99px',
				border:
					currencyDetail?.bgColor?.toLowerCase() === theme?.palette?.background?.paper?.toLowerCase()
						? `1px solid ${color.grey100}`
						: 'none',
			}}
			alt={`${currencyDetail.currency}-logo`}
		/>
	)
}
