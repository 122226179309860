import * as React from 'react'
export type VBoxProps = {
	verticalGap?: number
} & React.HTMLProps<any>

export const VBox: React.FC<VBoxProps> = (props: VBoxProps) => {
	return (
		<div style={{...props.style, display: 'flex', flexDirection: 'column'}} className={props.className}>
			{React.Children.map(props.children, (child, i) => {
				if (!child) return
				else if (props.verticalGap == null || i == 0) return child
				return (
					<>
						<div style={{height: props.verticalGap}} />
						{child}
					</>
				)
			})}
		</div>
	)
}
