import {Main, Large, makeStyles, Icons, HistoryContext} from '@bakkt/components'
import {useStore} from 'store'
import Page from 'components/Page'
import {handleKeyPress} from 'utils/handleKeyPress'
import {useContext} from 'react'

const useStyles = makeStyles(() => ({
	closeIcon: {
		position: 'fixed',
		cursor: 'pointer',
		width: '100%',
		margin: '-20px -15px',
		maxWidth: 'inherit',
		textAlign: 'end',
	},
}))
const PartnerTermsOfUse = () => {
	const {partner} = useStore()
	const classes = useStyles()
	const history = useContext(HistoryContext)

	const onCancel = () => {
		history?.go(-1)
	}

	return (
		<Page titleHeader={partner?.speedbumpMessage?.speedbumpMessageTitle || ''} withBackIcon backText=''>
			{
				<div
					className={classes.closeIcon}
					onClick={onCancel}
					onKeyPress={event => handleKeyPress(event, onCancel)}
					tabIndex={0}
					aria-label='Close'
					data-testid='close-modal-icon'
					role='button'
				>
					<Icons.Close />
				</div>
			}
			<Main>
				<Large data-testid='speedbump-message' style={{whiteSpace: 'pre-wrap'}}>
					{partner?.speedbumpMessage?.speedbumpMessage || ''}
				</Large>
			</Main>
		</Page>
	)
}

export default PartnerTermsOfUse
