import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import './style.scss'
import './i18n/config'
import '@bakkt/components/src/font/font.css'
import {MemoryRouter} from 'components/MemoryRouter'
import {useStore} from 'store'
import {EmbeddedThemeProvider} from './EmbeddedThemeProvider'
import {getEnv} from 'utils/config'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
;(window as any)['store'] = useStore
const configurePaymentJs = () => {
	const REACT_APP_PAYMENTJS_ENV = getEnv('PAYMENTJS_ENV')
	let loader = document.createElement('script')
	loader.type = 'text/javascript'
	loader.async = true
	loader.src = `https://lib.paymentjs.firstdata.com/${REACT_APP_PAYMENTJS_ENV}/client-2.0.0.js`
	loader.onload = function () {}
	var scriptTag: HTMLScriptElement = document.getElementsByTagName('script')[0]
	scriptTag?.parentNode?.insertBefore(loader, scriptTag)
}

configurePaymentJs()

const configureCookieBanner = () => {
	const urlParams = new URLSearchParams(window.location.search)
	const tokenInitiatedStr = localStorage.getItem('tokenInitiated')
	const tokenInitiated = JSON.parse(tokenInitiatedStr || '{}')
	if (urlParams.get('token') == null && !tokenInitiated?.isToken) {
		const scriptTag = document.getElementsByTagName('script')[0]
		let loader = document.createElement('script')
		loader.src = 'https://cdn.cookielaw.org/consent/9a7c1e6a-5bed-4ddb-8ccc-c631a7760497/OtAutoBlock.js'
		loader.type = 'text/javascript'
		loader.async = true
		scriptTag?.parentNode?.insertBefore(loader, scriptTag)

		loader = document.createElement('script')
		loader.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
		loader.type = 'text/javascript'
		loader.async = true
		loader.charset = 'UTF-8'
		loader.setAttribute('data-domain-script', '9a7c1e6a-5bed-4ddb-8ccc-c631a7760497')

		scriptTag?.parentNode?.insertBefore(loader, scriptTag)
		loader = document.createElement('script')
		loader.type = 'text/javascript'
		loader.innerText = 'function OptanonWrapper() { }'

		scriptTag?.parentNode?.insertBefore(loader, scriptTag)
	} else {
		;(window as any).token_initiated = true
		localStorage.setItem('tokenInitiated', JSON.stringify({'isToken': true}))
	}
}
if (window.location.href.indexOf('webhook') < 0) {
	configureCookieBanner()
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
	<React.StrictMode>
		<MemoryRouter>
			<EmbeddedThemeProvider>
				<App />
			</EmbeddedThemeProvider>
		</MemoryRouter>
	</React.StrictMode>,
)
