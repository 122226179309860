import {useEffect} from 'react'
import {getEnv} from 'utils/config'
import {useGetTrackingId} from 'hooks/useGetTrackingId'

export const useSetupSardine = (sardinePartyRef?: string) => {
	const xTrackingId = useGetTrackingId()

	useEffect(() => {
		const configSardine = () => {
			const REACT_APP_SARDINE_CLIENT_ID = getEnv('SARDINE_CLIENT_ID')
			const REACT_APP_SARDINE_ENV = getEnv('SARDINE_ENV')
			var sardineHost = REACT_APP_SARDINE_ENV === 'sandbox' ? 'api.sandbox.sardine.ai' : 'api.sardine.ai' //please set isDev to true in Dev
			let loader = document.createElement('script')
			loader.type = 'text/javascript'
			loader.async = true
			loader.src = `https://${sardineHost}/assets/loader.min.js`
			loader.onload = function () {
				window._Sardine.createContext({
					clientId: REACT_APP_SARDINE_CLIENT_ID,
					sessionKey: xTrackingId,
					userIdHash: sardinePartyRef,
					flow: window.location.pathname,
					environment: REACT_APP_SARDINE_ENV,
					parentElement: document.body,
				})
			}
			var scriptTag: HTMLScriptElement = document.getElementsByTagName('script')[0]
			scriptTag?.parentNode?.insertBefore(loader, scriptTag)
		}
		if (xTrackingId) {
			configSardine()
		}
	}, [sardinePartyRef, xTrackingId])
}
