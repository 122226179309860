import React, {ReactNode, CSSProperties} from 'react'
import {Value} from '../value'
import {Label} from '../label/Label'
import {GridColumn} from './GridColumn'
import {color} from 'src/color/color'
import {Menu} from '../menu/Menu'
import {MenuItem} from '../menu/MenuItem'
import {CellContextMenuEvent} from 'ag-grid-community'
import {DataGrid} from './DataGrid'
import Tooltip from '@material-ui/core/Tooltip'
import {withStyles} from '@material-ui/core/styles'

interface Field {
	value?: ReactNode
	content?: ReactNode
	labelContent?: ReactNode
}

const Column: React.FunctionComponent<ColumnProps> = props => null

const ColumnField: React.FC<ColumnFieldProps> = () => null

ColumnField.defaultProps = {
	elementType: 'ColumnField',
}

const FullWidthRow: React.FC<ColumnFieldProps> = () => null

FullWidthRow.defaultProps = {
	elementType: 'FullWidthRow',
}

export interface IColumnLayoutDetailView2Props {
	isBottomBorder?: boolean
	children: JSX.Element | JSX.Element[]
}
interface ColumnProps {
	minWidth?: number
	width?: number
	children?: any
}

interface ColumnFieldProps {
	label: ReactNode
	labelContent?: ReactNode
	value: ReactNode
	valueContent?: ReactNode
	elementType?: string
}

const FullWidthRendererTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: `${color.white}`,
		color: `${color.blackLight}`,
		boxShadow: `3px 2px 10px rgba(0,0,0,0.5)`,
		fontSize: 14,
		maxWidth: 'none',
		borderRadius: 0,
		fontWeight: 400,
		fontFamily: 'BrownPro',
		padding: '.5rem',
		margin: 0,
		WebkitFontSmoothing: 'antialiased',
	},
}))(Tooltip)

function renderUsersMenus(e: CellContextMenuEvent): JSX.Element {
	return (
		<Menu>
			<MenuItem
				onClick={() => {
					if (e?.value?.value) {
						navigator.clipboard?.writeText(e?.value?.value)
					} else if (e?.value) {
						navigator.clipboard?.writeText(e?.value)
					} else if (e?.data?.fullWidthRowValue?.value) {
						navigator.clipboard?.writeText(e?.data?.fullWidthRowValue?.value)
					}
				}}
			>
				Copy
			</MenuItem>
		</Menu>
	)
}

const cellRendererTextOverflowStyle: CSSProperties = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
}

const findKeyContent = (params: any) => {
	const colIndex = params?.column?.colId.match(/\d+/)
	const columnValueKey = `column${colIndex}Value`
	return params.data[columnValueKey]?.labelContent || <Label>{params.value}</Label>
}
const cellRendererKey = (params: any) => {
	return <div style={{...cellRendererTextOverflowStyle}}>{findKeyContent(params)}</div>
}
const cellRendererValue = (params: any) => {
	return (
		<div style={{...cellRendererTextOverflowStyle}}>
			{(params && params?.value?.content) || (params.value && <Value>{params.value?.value || '--'}</Value>)}
		</div>
	)
}
const fullWidthCellRenderer = (params: any) => {
	const firstColumnWidth = params?.columnApi?.getColumn('column1Key')?.getActualWidth()
	return (
		<div>
			<FullWidthRendererTooltip title={<Value>{params?.data?.fullWidthRowKey}</Value>} placement='bottom'>
				<div
					style={{left: 0, paddingLeft: '10px', width: firstColumnWidth, ...cellRendererTextOverflowStyle}}
					className='ag-cell ag-cell-not-inline-editing ag-cell-auto-height ag-cell-value'
				>
					{params?.data?.fullWidthRowValue?.labelContent ? (
						params.data.fullWidthRowValue.labelContent
					) : (
						<Label>{params?.data?.fullWidthRowKey}</Label>
					)}
				</div>
			</FullWidthRendererTooltip>
			<FullWidthRendererTooltip
				title={<span className='ag-column-hover'>{params?.data?.fullWidthRowValue?.value}</span>}
				placement='bottom'
			>
				<div
					style={{left: firstColumnWidth}}
					className='ag-cell ag-cell-not-inline-editing ag-cell-auto-height'
				>
					{(params?.data?.fullWidthRowValue && params?.data?.fullWidthRowValue?.content) ||
						(params?.data?.fullWidthRowValue && (
							<Value>
								{params?.data?.fullWidthRowKey === null || params?.data?.fullWidthRowKey === undefined
									? ''
									: params?.data?.fullWidthRowValue?.value || '--'}
							</Value>
						))}
				</div>
			</FullWidthRendererTooltip>
		</div>
	)
}

const createValueProp = (field: Field) => {
	const {value, labelContent, content} = field
	return {
		...(value ? {value} : {}),
		...(labelContent ? {labelContent} : {}),
		...(content ? {content} : {}),
	}
}

const getRowData = (
	fieldType: string,
	fieldData: ColumnFieldProps,
	rowsData: any[],
	rowIndex: number,
	keyLabel: string,
	valueLabel: string,
) => {
	if (fieldType === 'ColumnField') {
		const {label, labelContent, value, valueContent} = fieldData
		const row = {
			[keyLabel]: label,
			[valueLabel]: createValueProp({value, labelContent, content: valueContent}),
		}

		if (rowsData[rowIndex]) {
			rowsData[rowIndex] = {...row, ...rowsData[rowIndex]}
		} else {
			rowsData.push(row)
		}
	}
}

const createColumnProp = (column: JSX.Element, rowsData: any[], gridData: any[], colIndex: number) => {
	if (column?.props?.elementType === 'FullWidthRow') {
		const {label, labelContent, value, valueContent} = column.props
		const fullWidthRow = {
			fullWidthRowKey: label,
			fullWidthRowValue: createValueProp({value, labelContent, content: valueContent}),
		}
		rowsData.push(fullWidthRow)
	} else {
		const keyLabel = `column${colIndex + 1}Key`,
			valueLabel = `column${colIndex + 1}Value`
		const colLabelData = {
			minWidth: column.props.minWidth || 100,
			width: column.props.width || 150,
			keyField: keyLabel,
		}
		const colValueData = {
			valueField: valueLabel,
		}
		gridData.push(colLabelData)
		gridData.push(colValueData)
		if (Array.isArray(column.props?.children)) {
			column.props.children.forEach((fieldData: JSX.Element, rowIndex: number) => {
				getRowData(fieldData?.props?.elementType, fieldData.props, rowsData, rowIndex, keyLabel, valueLabel)
			})
		} else {
			getRowData(
				column.props?.children?.props?.elementType,
				column.props?.children?.props,
				rowsData,
				0,
				keyLabel,
				valueLabel,
			)
		}
	}
}

const mapColumnData = (columnElement: JSX.Element | JSX.Element[]) => {
	const gridData: any[] = [],
		rowsData: any[] = []
	if (Array.isArray(columnElement)) {
		columnElement.forEach((column: JSX.Element, index: number) => {
			createColumnProp(column, rowsData, gridData, index)
		})
	} else {
		createColumnProp(columnElement, rowsData, gridData, 1)
	}
	return {gridData, rowsData}
}

export const ColumnLayoutDetailView2: React.FC<IColumnLayoutDetailView2Props> = (
	props: IColumnLayoutDetailView2Props,
) => {
	const {isBottomBorder = false, children} = props
	const {gridData, rowsData} = mapColumnData(children)
	return gridData && gridData.length > 0 ? (
		<DataGrid
			domLayout='autoHeight'
			suppressRowHoverHighlight
			suppressHorizontalScroll
			suppressRowClickSelection
			suppressCellSelection
			headerHeight={0}
			hideQuickFilter
			hideFilterConfiguration
			hideShadow
			hideHeaderBottomBorder
			fullWidthCellRendererFramework={fullWidthCellRenderer}
			isFullWidthCell={rowNode => {
				return rowNode?.data?.fullWidthRowKey != null
			}}
			getRowStyle={(params: any) =>
				params?.node?.rowIndex == params?.api?.rowModel?.rowsToDisplay?.length - 1 ? {borderBottom: 'none'} : {}
			}
			rowData={rowsData}
			onGridSizeChanged={e => {
				e?.api?.sizeColumnsToFit()
				const popOverState = localStorage.getItem('idPopOverState')
				const params = {
					rowNodes: e?.api?.getRenderedNodes()?.filter((n: any) => n?.data?.fullWidthRowKey != null),
				}
				if (popOverState === 'false') {
					e?.api?.redrawRows(params)
				}
			}}
			defaultColDef={{
				tooltipValueGetter: params => (params?.value ? `${params.value}` : ''),
			}}
			style={{
				width: '100%',
				padding: 0,
				transform: 'translateY(-1px)',
				borderBottom: isBottomBorder ? `5px solid ${color.grey100}` : 'none',
			}}
			renderPopupMenu={renderUsersMenus}
			align='left'
		>
			{gridData.map((col: any) => {
				return col.keyField ? (
					<GridColumn
						key={col.keyField}
						minWidth={col.minWidth}
						width={col.width}
						field={col.keyField}
						cellRendererFramework={(params: any) => cellRendererKey(params)}
						cellStyle={{textAlign: 'left', paddingLeft: '10px', marginLeft: 0}}
					/>
				) : (
					<GridColumn
						key={col.valueField}
						flex={1}
						field={col.valueField}
						cellRendererFramework={(params: any) => cellRendererValue(params)}
						tooltipValueGetter={params => params.value?.value}
					/>
				)
			})}
		</DataGrid>
	) : (
		<></>
	)
}

export {Column, ColumnField, FullWidthRow}
