import * as React from 'react'
export type HBoxProps = {
	horizontalGap?: number
} & React.HTMLProps<any>

export const HBox: React.FC<HBoxProps> = (props: HBoxProps) => {
	return (
		<div style={{display: 'flex', flexDirection: 'row', ...props.style}} className={props.className}>
			{React.Children.map(props.children, (child, i) => {
				if (props.horizontalGap == null || i == 0) return child
				return (
					<>
						<div style={{width: props.horizontalGap}} />
						{child}
					</>
				)
			})}
		</div>
	)
}
