import React, {FC} from 'react'
import {useTheme} from '@material-ui/core'

type RoundCheckedIconProps = {
	color?: string
	height?: string
	width?: string
}

export const RoundCheckedIcon: FC<RoundCheckedIconProps> = ({color, height = '19', width = '18'}) => {
	const theme = useTheme()

	return (
		<svg width={width} height={height} viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.80645 0.757812C3.94529 0.757812 0 4.7031 0 9.56426C0 14.4254 3.94529 18.3707 8.80645 18.3707C13.6676 18.3707 17.6129 14.4254 17.6129 9.56426C17.6129 4.7031 13.6676 0.757812 8.80645 0.757812Z'
				fill={color || theme?.palette?.secondary?.main || 'white'}
			/>
			<path
				d='M13.2096 5.8949C10.8695 7.83006 8.33721 10.2812 7.33182 13.2336H7.0444C6.60481 11.8993 5.504 10.8986 4.4032 10.3841'
				stroke={theme?.palette?.text?.secondary || 'white'}
				strokeWidth='1.10081'
			/>
		</svg>
	)
}
