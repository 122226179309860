import {useContext} from 'react'
import {Backward} from '../icons'
import {HistoryContext} from '../navigation/MemoryRouter'
import {makeStyles, useTheme} from '@material-ui/core'
import {BrownPro} from '../text/Text'
import {ThemeOptions} from '@material-ui/core'
import {Logo} from '../logo/Logo'
import React from 'react'

const useStyles = makeStyles(theme => ({
	main: {
		flex: 0.3,
		alignSelf: 'center',
		display: 'flex',
	},
	iconColor: {
		fill: theme?.palette?.text?.background || 'black',
		[theme.breakpoints.up(720)]: {
			fill: theme?.palette?.text?.externalBackground || 'black',
		},
	},
	backIcon: {
		paddingLeft: 20,
		cursor: 'pointer',
		fill: theme?.palette?.text?.background || 'black',
		[theme.breakpoints.up(720)]: {
			paddingLeft: 0,
			fill: theme?.palette?.text?.externalBackground || 'black',
		},
	},
	backText: {
		fontFamily: theme?.typography?.fontFamily || BrownPro,
		fontWeight: 400,
		marginTop: '3px',
		cursor: 'pointer',
		color: theme?.palette?.text?.background || 'black',
		[theme.breakpoints.up(720)]: {
			color: theme?.palette?.text?.externalBackground || 'black',
		},
		[theme.breakpoints.down(720)]: {
			display: 'none',
		},
	},
}))

export type HeaderRowStartProps = {
	backText?: string
	id?: string
	onIconPress?: () => void
	startIcon?: (props: any) => JSX.Element
	withBackIcon?: boolean
	withLogo?: boolean
}

export const HeaderRowStart = ({
	backText = 'Back',
	id = 'header',
	onIconPress,
	startIcon,
	withBackIcon,
	withLogo = true,
}: HeaderRowStartProps) => {
	const history = useContext(HistoryContext)
	const theme = useTheme() as ThemeOptions
	const styles = useStyles()

	const onIconClick = () => {
		if (!onIconPress && withBackIcon) history?.go(-1)
		else onIconPress?.()
	}

	const handleKeyPress = (event: any, value: any) => {
		if (event.key === 'Enter') {
			value()
		}
	}

	return (
		<div
			className={styles.main}
			onClick={onIconClick}
			onKeyPress={event => handleKeyPress(event, onIconClick)}
			id='Header_Row_Left_Icons'
			data-testid={withBackIcon ? 'header-back-navigation' : 'header-navigation-start'}
		>
			{withBackIcon && (
				<>
					<Backward className={styles.backIcon} tabIndex={0} aria-label={'Back'} role='button' />
					<span className={styles.backText}>{backText}</span>
				</>
			)}
			{startIcon &&
				!withLogo &&
				startIcon({
					className: styles.iconColor,
				})}
			{theme.logoUrl && withLogo && !withBackIcon && <Logo id={`${id}-left-aligned`} />}
		</div>
	)
}
