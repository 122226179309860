import {useMemo, useState} from 'react'
import {validatePassword} from 'validate'

import {useDelayedValidation} from '../useDelayedValidation'

export const usePassword = (value: string) => {
	const [error, setError] = useState('')
	const {valid: isValid} = useMemo(() => validatePassword(value), [value])
	useDelayedValidation({
		input: value,
		validate: () => {
			if (value && !isValid) {
				setError(validatePassword(value).error)
			}
		},
		clearError: () => setError(''),
	})

	return {
		isValid,
		error,
		setError,
	}
}
