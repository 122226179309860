import {
	ApiAssetActivity,
	ApiEmbeddedPartnerTransaction,
	ApiEmbeddedPartnerTransactionOperationTypeEnum,
	ApiEmbeddedPartnerTransactionSummary,
	ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum,
	ApiAssetActivityTypeEnum,
	ApiAmountCurrencyEnum,
} from '@bakkt/api'
import {Icons, Logo} from '@bakkt/components'
import ActivityRow from './ActivityRow'
import {
	ApiAssetActivityAssetTypeEnum,
	ApiAssetActivityDestinationAssetTypeEnum,
	ApiAssetActivitySourceAssetTypeEnum,
} from '@bakkt/api/api'
import {CryptoCurrencyConfig} from '../../../../../../store'
import {ApiPrincipalActivityDetail} from '../../../../../../../api/gen/api/api'
type OperationType =
	| ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum
	| ApiEmbeddedPartnerTransactionOperationTypeEnum
	| ApiAssetActivityTypeEnum
	| undefined
const OperationTypeEnum = {...ApiAssetActivityTypeEnum, ...ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum}
export interface PartnerTransaction extends ApiEmbeddedPartnerTransaction {
	assetType?: ApiAssetActivityAssetTypeEnum
	destinationAssetType?: ApiAssetActivityDestinationAssetTypeEnum
	sourceAssetType?: ApiAssetActivitySourceAssetTypeEnum
	sourceName?: string
	sourceDetails?: string
	destinationName?: string
	destinationDetails?: string
	principalActivityDetail?: ApiPrincipalActivityDetail
	message?: string
	unitPrice?: number
}

export const mapAssetActivityRecordToPartnerTransaction = (
	assetActivityRecord: ApiAssetActivity,
): PartnerTransaction => {
	const partnerTransaction = {} as PartnerTransaction
	partnerTransaction.created = assetActivityRecord.created as any
	partnerTransaction.target = {}
	partnerTransaction.target.amount = assetActivityRecord.destinationAmount
		? Math.abs(assetActivityRecord.destinationAmount)
		: undefined
	partnerTransaction.target.currency = assetActivityRecord.destinationCurrency as any
	partnerTransaction.fee = assetActivityRecord.fee as any
	partnerTransaction.partnerId = assetActivityRecord.partnerId as any
	partnerTransaction.partnerPartyRef = assetActivityRecord.partyId as any
	partnerTransaction.cryptoCurrencySource = assetActivityRecord.principalActivityDetail?.fundingType as any
	partnerTransaction.transactionId =
		assetActivityRecord.principalActivityDetail?.transactionId || (assetActivityRecord.externalRef as any)
	partnerTransaction.partnerTransactionRef = assetActivityRecord.principalActivityDetail?.transactionRef as any
	partnerTransaction.source = {}
	partnerTransaction.source.amount = assetActivityRecord.sourceAmount
		? Math.abs(assetActivityRecord.sourceAmount)
		: undefined
	partnerTransaction.source.currency = assetActivityRecord.sourceCurrency as any
	if (partnerTransaction.source.amount) {
		partnerTransaction.total = partnerTransaction.source.amount - (partnerTransaction.fee || 0)
	}
	partnerTransaction.operationType = assetActivityRecord.type as any
	partnerTransaction.assetType = assetActivityRecord.assetType
	partnerTransaction.destinationAssetType = assetActivityRecord.destinationAssetType
	partnerTransaction.sourceAssetType = assetActivityRecord.sourceAssetType
	partnerTransaction.sourceName = assetActivityRecord.sourceName
	partnerTransaction.sourceDetails = assetActivityRecord.sourceDetails
	partnerTransaction.destinationName = assetActivityRecord.destinationName
	partnerTransaction.destinationDetails = assetActivityRecord.destinationDetails
	partnerTransaction.principalActivityDetail = assetActivityRecord.principalActivityDetail
	partnerTransaction.message = assetActivityRecord.message
	partnerTransaction.unitPrice = assetActivityRecord.unitPrice

	return partnerTransaction
}

export const iconFor = (operationType: OperationType) => {
	switch (operationType) {
		case OperationTypeEnum.BUY:
			return Icons.BuyCrypto
		case OperationTypeEnum.SELL:
			return Icons.SellCrypto
		case OperationTypeEnum.SELL_ALL:
			return Icons.SellCrypto
		case OperationTypeEnum.INCENTIVE:
			return Icons.AssetRewards
		case OperationTypeEnum.WITHDRAW:
			return Icons.AssetActionWithdraw
		case OperationTypeEnum.DEPOSIT:
			return Icons.AssetActionDeposit
		case OperationTypeEnum.ADD:
			return Icons.GiftCard
		case OperationTypeEnum.CARD_PAYMENT:
			return Icons.BakktCard
		case OperationTypeEnum.SEND:
			return Icons.AssetActionSend
		case OperationTypeEnum.RECEIVE:
			return Icons.AssetActionRequest
		case OperationTypeEnum.LINK:
			return Icons.AssetActionLink
		case OperationTypeEnum.UNLINK:
			return Icons.AssetActionLink
	}
	return Icons.AssetActionRequest
}
export const textFor = (transactionInfo: ApiEmbeddedPartnerTransactionSummary | PartnerTransaction) => {
	switch (transactionInfo?.operationType as any) {
		case OperationTypeEnum.BUY:
			if ('assetType' in transactionInfo && transactionInfo.assetType === 'GIFT_CARD') {
				return 'You purchased a gift card'
			}
			return 'You purchased'
		case OperationTypeEnum.SELL:
			return 'You sold'
		case OperationTypeEnum.SELL_ALL:
			return 'You sold all'
		case OperationTypeEnum.INCENTIVE:
			return (
				'Received ' +
				(transactionInfo?.target?.currency || transactionInfo?.source?.currency || 'Incentive Reward')
			)
		case OperationTypeEnum.WITHDRAW:
			return 'You withdrew cash'
		case OperationTypeEnum.DEPOSIT:
			return 'You deposited cash'
		case OperationTypeEnum.ADD:
			return 'You added a gift card'
		case OperationTypeEnum.REMOVE:
			return 'You removed a gift card'
		case OperationTypeEnum.CARD_PAYMENT:
			return 'You purchased with Bakkt Card'
		case OperationTypeEnum.SEND:
			return 'You sent cash'
		case OperationTypeEnum.RECEIVE:
			return 'You received cash'
		case OperationTypeEnum.LINK:
			return 'You linked an account'
		case OperationTypeEnum.UNLINK:
			return 'You unlinked an account'
	}
	return 'You requested'
}

export const conversionAmountFor = (
	transactionInfo: ApiEmbeddedPartnerTransactionSummary | PartnerTransaction,
	fiatAmount: string | number | undefined,
) => {
	switch (transactionInfo?.operationType as any) {
		case OperationTypeEnum.BUY:
			return `For ${fiatAmount}`
		case OperationTypeEnum.SELL:
			return `For ${fiatAmount}`
		case OperationTypeEnum.SELL_ALL:
			return `For ${fiatAmount}`
		case OperationTypeEnum.INCENTIVE:
			return `${'message' in transactionInfo ? transactionInfo?.message : ''}`
		case OperationTypeEnum.WITHDRAW:
			return `${fiatAmount}`
		case OperationTypeEnum.DEPOSIT:
			return `${fiatAmount}`
		case OperationTypeEnum.ADD:
			return `${fiatAmount}`
		case OperationTypeEnum.CARD_PAYMENT:
			return `For ${fiatAmount}`
		case OperationTypeEnum.SEND:
			return `${fiatAmount}`
		case OperationTypeEnum.RECEIVE:
			return `${fiatAmount}`
	}
	return ''
}

export const assetLabelFor = (transactionInfo: any, currencyDetails: CryptoCurrencyConfig | null | undefined) => {
	const isGiftCardTransaction = transactionInfo?.assetType === 'GIFT_CARD'
	const defaultAssetLabel = (
		<>
			{currencyDetails?.iconURL && <Logo currencyDetail={currencyDetails} width='20' />}
			<span style={{marginLeft: '5px'}}> {currencyDetails?.name}</span>
		</>
	)

	switch (transactionInfo?.operationType as any) {
		case OperationTypeEnum.BUY:
			if (isGiftCardTransaction) {
				return 'Gift Card'
			}
			return defaultAssetLabel
		case OperationTypeEnum.WITHDRAW:
			return 'Cash'
		case OperationTypeEnum.DEPOSIT:
			return 'Cash'
		case OperationTypeEnum.ADD:
			if (isGiftCardTransaction) {
				return 'Gift Card'
			}
			return defaultAssetLabel
		case OperationTypeEnum.REMOVE:
			if (isGiftCardTransaction) {
				return 'Gift Card'
			}
			return defaultAssetLabel
		case OperationTypeEnum.SEND:
			return 'Cash'
		case OperationTypeEnum.RECEIVE:
			return 'Cash'
		case OperationTypeEnum.INCENTIVE:
			return transactionInfo?.target?.currency || transactionInfo?.source?.currency
	}
	return defaultAssetLabel
}

export const headerFor = (transactionInfo: ApiEmbeddedPartnerTransactionSummary | PartnerTransaction) => {
	switch (transactionInfo?.operationType as any) {
		case OperationTypeEnum.BUY:
			if ('assetType' in transactionInfo && transactionInfo.assetType === 'GIFT_CARD') {
				return 'Purchased Gift Card'
			}
			return 'Purchased'
		case OperationTypeEnum.SELL:
			return 'Sold'
		case OperationTypeEnum.SELL_ALL:
			return 'Sold all'
		case OperationTypeEnum.LINK:
			return 'Linked Account'
		case OperationTypeEnum.UNLINK:
			return 'Unlinked Account'
		case OperationTypeEnum.ADD:
			if ('sourceAssetType' in transactionInfo && transactionInfo.sourceAssetType === 'GIFT_CARD') {
				return 'Added Gift Card'
			}
			return 'Added Card'
		case OperationTypeEnum.REMOVE:
			if ('sourceAssetType' in transactionInfo && transactionInfo.sourceAssetType === 'GIFT_CARD') {
				return 'Removed Gift Card'
			}
			return 'Removed Card'
		case OperationTypeEnum.BUY:
			if ('destinationAssetType' in transactionInfo && transactionInfo.destinationAssetType === 'GIFT_CARD') {
				return 'Bought Gift Card'
			}
			return 'Bought Card'
		case OperationTypeEnum.SEND:
			return 'Sent Cash'
		case OperationTypeEnum.RECEIVE:
			return 'Received Cash'
		case OperationTypeEnum.INCENTIVE:
			return 'Incentive'
	}

	let formatted =
		transactionInfo?.operationType
			?.toLowerCase()
			.split('_')
			.map(word => word[0].toUpperCase() + word.slice(1))
			.join(' ') || 'Transaction'
	return formatted
}

export const toFiatAmount = (transactionInfo: ApiEmbeddedPartnerTransactionSummary | PartnerTransaction | null) => {
	switch (transactionInfo?.operationType as any) {
		case OperationTypeEnum.BUY:
			return transactionInfo?.source
		case OperationTypeEnum.SELL:
			return transactionInfo?.target
		case OperationTypeEnum.SELL_ALL:
			return transactionInfo?.target
		case OperationTypeEnum.INCENTIVE:
			// If reward was granted in partner rewards points, amount info is in Target
			if (transactionInfo?.target?.amount) {
				return transactionInfo.target
			}
			// If reward was granted in BTC/ETH, use BTC/ETH amount from Source and multiply by unit value
			else if (transactionInfo?.source?.amount && 'unitPrice' in transactionInfo && transactionInfo?.unitPrice) {
				return {
					amount: transactionInfo?.source?.amount * transactionInfo?.unitPrice,
					currency: ApiAmountCurrencyEnum.USD,
				}
			}
			return undefined
		case OperationTypeEnum.WITHDRAW:
			return transactionInfo?.target
		case OperationTypeEnum.DEPOSIT:
			return transactionInfo?.target
		case OperationTypeEnum.ADD:
			return transactionInfo?.source
	}
	return transactionInfo?.target
}

export const toCryptoAmount = (transactionInfo: ApiEmbeddedPartnerTransactionSummary | PartnerTransaction | null) => {
	switch (transactionInfo?.operationType) {
		case OperationTypeEnum.BUY:
			return transactionInfo?.target
		case OperationTypeEnum.SELL:
		case OperationTypeEnum.SELL_ALL:
			return transactionInfo?.source
		case OperationTypeEnum.INCENTIVE:
			return transactionInfo?.source?.amount ? transactionInfo?.source : null
	}
	return null
}

function toTitleCase(str: string) {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	})
}

export const renderPartnerActivityItem = (element: ApiEmbeddedPartnerTransactionSummary | PartnerTransaction) => {
	return (
		<ActivityRow
			transactionLabel={headerFor(element)}
			cryptoQuantity={toCryptoAmount(element)}
			fiatAmount={toFiatAmount(element)}
			showBorder={true}
			transactionTime={element.created}
		/>
	)
}
