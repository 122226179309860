import React, {useState} from 'react'
import Page from '../../../../components/Page'
import {Alert, Button, H1, HBox, Input, Main, VBox, BottomBox, MenuItem} from '@bakkt/components'
import {useStore} from 'store'
import {useAddress} from '../../../../hooks/auth/useAddress'
import {ApiAddressCountryEnum} from '@bakkt/api'
import {useNavigate} from 'react-router-dom'
import {RoutesName} from '../../../../constants/routes'

export const OPTIONS_STATE = [
	{value: 'AK', label: 'Alaska'},
	{value: 'AL', label: 'Alabama'},
	{value: 'AR', label: 'Arkansas'},
	{value: 'AZ', label: 'Arizona'},
	{value: 'CA', label: 'California'},
	{value: 'CO', label: 'Colorado'},
	{value: 'CT', label: 'Connecticut'},
	{value: 'DC', label: 'District of Columbia'},
	{value: 'DE', label: 'Delaware'},
	{value: 'FL', label: 'Florida'},
	{value: 'GA', label: 'Georgia'},
	{value: 'HI', label: 'Hawaii', secondaryText: '(Coming Soon)'},
	{value: 'IA', label: 'Iowa'},
	{value: 'ID', label: 'Idaho'},
	{value: 'IL', label: 'Illinois'},
	{value: 'IN', label: 'Indiana'},
	{value: 'KS', label: 'Kansas'},
	{value: 'KY', label: 'Kentucky'},
	{value: 'LA', label: 'Louisiana'},
	{value: 'MA', label: 'Massachusetts'},
	{value: 'MD', label: 'Maryland'},
	{value: 'ME', label: 'Maine'},
	{value: 'MI', label: 'Michigan'},
	{value: 'MN', label: 'Minnesota'},
	{value: 'MO', label: 'Missouri'},
	{value: 'MS', label: 'Mississippi'},
	{value: 'MT', label: 'Montana'},
	{value: 'NC', label: 'North Carolina'},
	{value: 'ND', label: 'North Dakota'},
	{value: 'NE', label: 'Nebraska'},
	{value: 'NH', label: 'New Hampshire'},
	{value: 'NJ', label: 'New Jersey'},
	{value: 'NM', label: 'New Mexico'},
	{value: 'NV', label: 'Nevada'},
	{value: 'NY', label: 'New York'},
	{value: 'OH', label: 'Ohio'},
	{value: 'OK', label: 'Oklahoma'},
	{value: 'OR', label: 'Oregon'},
	{value: 'PA', label: 'Pennsylvania'},
	{value: 'PR', label: 'Puerto Rico'},
	{value: 'RI', label: 'Rhode Island'},
	{value: 'SC', label: 'South Carolina'},
	{value: 'SD', label: 'South Dakota'},
	{value: 'TN', label: 'Tennessee'},
	{value: 'TX', label: 'Texas'},
	{value: 'UT', label: 'Utah'},
	{value: 'VA', label: 'Virginia'},
	{value: 'VT', label: 'Vermont'},
	{value: 'WA', label: 'Washington'},
	{value: 'WI', label: 'Wisconsin'},
	{value: 'WV', label: 'West Virginia'},
	{value: 'WY', label: 'Wyoming'},
]

const EditAccountAddress = () => {
	const {partyProfile, setPartyUpdateProfileInfo} = useStore()
	const [newStreetLine, setNewStreetLine] = useState(partyProfile?.Party?.address?.streetLine1!)
	const [newLocality, setNewLocality] = useState(partyProfile?.Party?.address?.locality!)
	const [newRegion, setNewRegion] = useState(partyProfile?.Party?.address?.region!)
	const [newPostalCode, setNewPostalCode] = useState(partyProfile?.Party?.address?.postalCode!)
	const {isValid: isZipCodeValid, error: newZipCodeError} = useAddress(newPostalCode, 'Zip Code')
	const {isValidState: isValidState, error: newStateError} = useAddress(newRegion, 'state')
	const [serverError, setServerError] = useState<string | null>(null)
	const navigate = useNavigate()

	const validateAddress = () => {
		if (
			(newStreetLine.toLowerCase() !== partyProfile?.Party?.address?.streetLine1.toLowerCase() ||
				newLocality.toLowerCase() !== partyProfile?.Party?.address?.locality.toLowerCase() ||
				newRegion.toLowerCase() !== partyProfile?.Party?.address?.region.toLowerCase() ||
				newPostalCode.toLowerCase() !== partyProfile?.Party?.address?.postalCode.toLowerCase()) &&
			isZipCodeValid &&
			isValidState
		) {
			return false
		}
		return true
	}

	const updateAddress = async () => {
		setPartyUpdateProfileInfo({
			newFirstName: null,
			newLastName: null,
			locality: newLocality,
			country: ApiAddressCountryEnum.USA,
			postalCode: newPostalCode,
			region: newRegion,
			streetLine1: newStreetLine,
		})
		navigate(`/${RoutesName.onboarding.taxpayerCertification}`)
	}

	return (
		<Page withBackIcon>
			<Main>
				{serverError && (
					<Alert severity='error' icon={false} onClose={() => setServerError(null)}>
						{serverError}
					</Alert>
				)}
				<VBox verticalGap={15}>
					<HBox style={{paddingLeft: '10px'}}>
						<H1>Edit Profile</H1>
					</HBox>
				</VBox>

				<Input
					type={'text'}
					label='Street'
					value={partyProfile?.Party?.address?.streetLine1}
					containerstyle={{width: '100%'}}
					disableClose={true}
					readOnly={false}
					onChange={(_, value) => setNewStreetLine(value)}
				/>
				<Input
					type={'text'}
					label='City'
					value={partyProfile?.Party?.address?.locality}
					containerstyle={{width: '100%'}}
					disableClose={true}
					readOnly={false}
					onChange={(_, value) => setNewLocality(value)}
				/>

				<div style={{display: 'flex', flexDirection: 'row'}}>
					<Input
						type={'text'}
						label={'State'}
						containerstyle={{width: '50%'}}
						disableClose={true}
						readOnly={false}
						select
						helperText={newStateError}
						error={!!newStateError}
						value={newRegion || ''}
						onChange={event => {
							setNewRegion(event.target.value)
						}}
					>
						{OPTIONS_STATE.map(option => (
							<MenuItem key={option.value} value={option.value} style={{color: 'black'}}>
								{option.label}
							</MenuItem>
						))}
					</Input>

					<Input
						type={'text'}
						label='Area Code'
						value={partyProfile?.Party?.address?.postalCode}
						containerstyle={{width: '50%'}}
						disableClose={true}
						readOnly={false}
						helperText={newZipCodeError}
						error={!!newZipCodeError}
						onChange={(_, value) => setNewPostalCode(value)}
					/>
				</div>

				<BottomBox style={{marginTop: '50px', marginBottom: '30px'}}>
					<VBox verticalGap={10} style={{width: '-webkit-fill-available'}}>
						<Button size='large' disabled={validateAddress()} onClick={updateAddress}>
							Continue
						</Button>
					</VBox>
				</BottomBox>
			</Main>
		</Page>
	)
}

export default EditAccountAddress
