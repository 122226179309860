import {useTranslation} from 'react-i18next'
import {Icons, color, HBox, useTheme, Light, Logo} from '@bakkt/components'
import {ReceiptItem} from 'components/ReceiptItem'
import {Receipt} from 'components/Receipt'
import {
	ApiEmbeddedPartnerTransactionOperationTypeEnum as CryptoCurrencyTransactTypeEnum,
	ApiAmount,
	FiatAmountCurrencyEnum,
} from '@bakkt/api'
import {formatCrypto, formatUSD} from 'utils/currencyFormatters'
import {FundingRow} from 'components/fundingRows/FundingRow'
import {formatDate} from 'utils/formatters'
import {Theme} from '@material-ui/core'
import {CryptoCurrencyConfig} from 'store'
import {assetLabelFor} from '../activity/utils'

interface DetailSectionProps {
	currencyDetails: CryptoCurrencyConfig | null | undefined
	transactionInfo: any | null | undefined
	fiatAmount: String | 0 | null | undefined
	crypto: ApiAmount | null | undefined
}

export const DetailSection = ({currencyDetails, transactionInfo, fiatAmount, crypto}: DetailSectionProps) => {
	const {t} = useTranslation()
	const theme = useTheme() as Theme
	const date = formatDate(transactionInfo?.created) || {}
	const transactionType = transactionInfo?.operationType
	const isDepositTransaction = transactionInfo?.operationType === 'DEPOSIT'
	const isWithdrawTransaction = transactionInfo?.operationType === 'WITHDRAW'
	const isGiftCardTransaction = transactionInfo?.assetType === 'GIFT_CARD'
	const isBakktCardTransaction = transactionInfo?.operationType === 'CARD_PAYMENT'
	const isSendCashTransaction = transactionInfo?.operationType === 'SEND'
	const isReceiveCashTransaction = transactionInfo?.operationType === 'RECEIVE'
	const isLinkTransaction = transactionInfo?.operationType === 'LINK'
	const isUnlinkTransaction = transactionInfo?.operationType === 'UNLINK'
	const isCryptoBuyTransaction =
		transactionInfo?.operationType === CryptoCurrencyTransactTypeEnum.BUY && transactionInfo?.assetType === 'CRYPTO'
	const isCryptoSellTransaction =
		transactionInfo?.operationType === CryptoCurrencyTransactTypeEnum.SELL &&
		transactionInfo?.assetType === 'CRYPTO'
	const isIncentiveTransaction = transactionInfo?.operationType === 'INCENTIVE'
	const isIncentiveTransactionCryptoReward = isIncentiveTransaction && transactionInfo?.source?.amount
	const isIncentiveTransactionPointsReward =
		transactionInfo?.operationType === 'INCENTIVE' && transactionInfo?.target?.amount

	function getTransactionTypeLabel() {
		if (isDepositTransaction) {
			return 'Cash Added'
		} else if (isWithdrawTransaction) {
			return 'Cash Removed'
		} else if (isBakktCardTransaction) {
			return 'Bakkt Card Payment'
		} else if (isIncentiveTransaction) {
			return 'Promotion'
		} else {
			return transactionType[0] + transactionType.slice(1, transactionType.length).toLowerCase()
		}
	}

	return (
		<>
			<Receipt>
				<>
					{isSendCashTransaction && (
						<ReceiptItem
							name={'To'}
							style={{
								padding: '0.4rem 0.1rem 0.8rem 0.1rem',
							}}
							value={transactionInfo?.destinationName + ', ' + transactionInfo?.destinationDetails}
						/>
					)}
				</>
				<>
					{isReceiveCashTransaction && (
						<ReceiptItem
							name={'From'}
							style={{
								padding: '0.4rem 0.1rem 0.8rem 0.1rem',
							}}
							value={transactionInfo?.sourceName + ', ' + transactionInfo?.sourceDetails}
						/>
					)}
				</>
				<>
					{!isBakktCardTransaction && !isLinkTransaction && !isUnlinkTransaction && (
						<ReceiptItem
							name={t('label.asset')}
							style={{
								padding: '0.4rem 0.1rem 0.8rem 0.1rem',
							}}
							value={<HBox horizontalGap={4}>{assetLabelFor(transactionInfo, currencyDetails)}</HBox>}
						/>
					)}
				</>
				<ReceiptItem
					name={t('label.transaction')}
					style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
					value={
						<HBox style={{alignItems: 'center'}} horizontalGap={2}>
							<div
								style={{
									backgroundColor: theme?.palette?.primary?.contrastText || color.blueKolibri,
									borderRadius: '50%',
									height: '20px',
									width: '20px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
									marginRight: 5,
								}}
							>
								{isCryptoBuyTransaction && (
									<Icons.BuyCrypto
										fill={theme?.palette?.primary?.main || color.white}
										height={18}
										width={18}
										viewBox='-10 -10 40 40'
									/>
								)}
								{isCryptoSellTransaction && (
									<Icons.SellCrypto
										fill={theme?.palette?.primary?.main || color.white}
										height={18}
										width={18}
										viewBox='-8 -10 40 40'
									/>
								)}
							</div>
							{getTransactionTypeLabel()}
						</HBox>
					}
				/>
				<>
					{(isGiftCardTransaction || isBakktCardTransaction) && (
						<ReceiptItem
							name={'Merchant Name'}
							style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
							value={transactionInfo?.destinationName || transactionInfo?.sourceName}
						/>
					)}
				</>
				<>
					{isBakktCardTransaction && (
						<ReceiptItem
							name={'Merchant Address'}
							style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
							value={transactionInfo?.destinationDetails}
						/>
					)}
				</>
				<>
					{isGiftCardTransaction && (
						<ReceiptItem
							name={'Card Number'}
							style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
							value={
								transactionInfo?.destinationDetails
									? '********' + transactionInfo?.destinationDetails.slice(-4)
									: transactionInfo?.sourceDetails
									? '********' + transactionInfo?.sourceDetails.slice(-4)
									: ''
							}
						/>
					)}
				</>
				<>
					{(isLinkTransaction || isUnlinkTransaction) && (
						<ReceiptItem
							name={'Account'}
							style={{
								padding: '0.4rem 0.1rem 0.8rem 0.1rem',
							}}
							value={transactionInfo?.sourceName + ', ' + transactionInfo?.sourceDetails}
						/>
					)}
				</>
				<ReceiptItem
					name={t('label.date')}
					value={date.formattedDate}
					style={{
						borderBottom: '1px solid',
						padding: '0.4rem 0.1rem 0.8rem 0.1rem',
					}}
				/>
				<ReceiptItem
					name={t('label.time')}
					value={`${date.formattedTime} ${date.timeZoneAbbr}`}
					style={{
						borderBottom: '5px solid',
						padding: '0.4rem 0.1rem 0.8rem 0.1rem',
					}}
				/>
			</Receipt>
			<Receipt>
				<>
					{(isCryptoBuyTransaction || isCryptoSellTransaction) && (
						<ReceiptItem
							name={t('label.amount')}
							style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
							value={formatCrypto(
								crypto?.amount || 0,
								crypto?.currency,
								currencyDetails?.supportedPrecision,
							)}
						/>
					)}
				</>
				<>
					{(isDepositTransaction ||
						isWithdrawTransaction ||
						isBakktCardTransaction ||
						isSendCashTransaction) && (
						<ReceiptItem
							name={t('label.amount')}
							style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
							value={fiatAmount}
						/>
					)}
				</>
				<>
					{!isLinkTransaction &&
						!isUnlinkTransaction &&
						!isBakktCardTransaction &&
						!isSendCashTransaction &&
						!isDepositTransaction &&
						!isReceiveCashTransaction &&
						!isWithdrawTransaction && (
							<ReceiptItem
								name={t('label.value')}
								style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}
								value={
									isGiftCardTransaction
										? formatUSD(
												transactionInfo?.target?.amount ||
													Math.abs(transactionInfo?.source?.amount),
												FiatAmountCurrencyEnum.USD,
										  )
										: fiatAmount
								}
							/>
						)}
				</>
				<>
					{transactionType !== 'ADD' &&
						transactionType !== 'REMOVE' &&
						!isIncentiveTransaction &&
						!isReceiveCashTransaction &&
						!isLinkTransaction &&
						!isUnlinkTransaction && (
							<ReceiptItem
								name={t('label.fee')}
								style={{padding: '0.4rem 0.1rem 1rem 0.1rem', borderBottom: '1px solid black'}}
								value={formatUSD(transactionInfo?.fee, FiatAmountCurrencyEnum.USD)}
							/>
						)}
				</>

				<>
					{transactionType !== 'ADD' &&
						transactionType !== 'REMOVE' &&
						!isIncentiveTransaction &&
						!isLinkTransaction &&
						!isUnlinkTransaction && (
							<ReceiptItem
								name={t('label.total')}
								value={
									<HBox
										style={{
											padding: '0.4rem 0.1rem 0.8rem 0.1rem',
											alignItems: 'center',
											fontWeight: 'bold',
										}}
										horizontalGap={2}
									>
										{fiatAmount}
									</HBox>
								}
								style={{
									borderBottom: '5px solid',
									fontWeight: 'bold',
									padding: '0.4rem 0.1rem 0.8rem 0.1rem',
								}}
							/>
						)}
				</>
			</Receipt>
			{transactionInfo?.fundingSource && (
				<FundingRow
					style={{
						borderBottom: '5px solid',
						padding: '0.4rem 0.1rem 0.8rem 0.1rem',
					}}
					label={isCryptoBuyTransaction ? t('label.fundingSource') : t('label.destination')}
					fundingName={transactionInfo?.fundingSource}
					fundingDetails={`********${transactionInfo?.formattedFundingAccountNumber}`}
					icon={Icons.BankCircle({
						fill: theme?.palette?.primary?.contrastText,
						stroke: theme?.palette?.primary?.main,
					})}
					dataTestId='funding'
				/>
			)}

			{(isDepositTransaction || isBakktCardTransaction) &&
				transactionInfo?.sourceName &&
				transactionInfo.sourceDetails && (
					<FundingRow
						style={{
							borderBottom: '5px solid',
							padding: '0.4rem 0.1rem 0.8rem 0.1rem',
						}}
						label={t('label.fundingSource')}
						fundingName={transactionInfo?.sourceName}
						fundingDetails={`****${transactionInfo?.sourceDetails}`}
						icon={Icons.BankCircle({
							fill: theme?.palette?.primary?.contrastText,
							stroke: theme?.palette?.primary?.main,
						})}
						dataTestId='funding'
					/>
				)}

			{isWithdrawTransaction && transactionInfo?.destinationName && transactionInfo.destinationDetails && (
				<FundingRow
					style={{
						borderBottom: '5px solid',
						padding: '0.4rem 0.1rem 0.8rem 0.1rem',
					}}
					label={t('label.destination')}
					fundingName={transactionInfo?.destinationName}
					fundingDetails={`****${transactionInfo?.destinationDetails}`}
					icon={Icons.BankCircle({
						fill: theme?.palette?.primary?.contrastText,
						stroke: theme?.palette?.primary?.main,
					})}
					dataTestId='funding'
				/>
			)}

			<Receipt>
				<HBox style={{padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}>
					<Light style={{margin: 0}}>{t('label.transactionId')}</Light>
				</HBox>
				<HBox style={{fontWeight: '600', padding: '0.4rem 0.1rem 0.8rem 0.1rem'}}>
					<Light style={{margin: 0}}>{transactionInfo?.transactionId}</Light>
				</HBox>
				<ReceiptItem style={{borderBottom: '5px solid'}} />
			</Receipt>
		</>
	)
}
