import styled from '@emotion/styled'
import {ReactNode} from 'react'
import {color} from '../../color/color'
import {Settings} from '../icons'
import {PoweredBy} from '../poweredBy/PoweredBy'
import {HeaderRowStart} from './HeaderRowStart'
import {HeaderRowCenter} from './HeaderRowCenter'
import {ThemeOptions, useMediaQuery, useTheme} from '@material-ui/core'
import {Icons} from '../../index'
import React from 'react'

export const HeaderContainer = styled.div`
	position: relative;
	width: 100%;
	padding: 0 2%;
	background: ${props => props?.theme?.palette?.background?.paper || color.blueKolibri};
	@media (min-width: 720px) {
		width: calc(100% - 10%);
		padding: 0 5%;
		background: ${props => props?.theme?.palette?.background?.default || color.blueKolibri};
	}
`

const HeaderRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-height: 3rem;
	padding-top: 20px;
	padding-bottom: 20px;
	@media (min-width: 720px) {
		padding-top: 20px;
		padding-bottom: 0;
	}
`

const HeaderRowEnd = styled.div`
	flex: 0.3;
	justify-content: flex-end;
	display: flex;
`

export type HeaderProps = {
	backText?: string
	centerIcon?: (props: any) => JSX.Element
	className?: string
	id?: string
	loading?: boolean
	onIconPress?: () => void
	startIcon?: (props: any) => JSX.Element
	titleHeader?: string
	TitleHeaderComp?: ReactNode
	withBackIcon?: boolean
	withLogo?: boolean
	withPoweredBy?: boolean
	withSetting?: boolean
	showJointLogo?: boolean
	isLoginScreen?: boolean
}

export const Header = (props: HeaderProps) => {
	const {className, loading, withPoweredBy, showJointLogo, withSetting} = props
	const onMobileScreen = useMediaQuery('(max-width:719px)')
	const theme = useTheme() as ThemeOptions

	return (
		<HeaderContainer className={className}>
			<HeaderRow id='header-navigation-container'>
				{!loading && (
					<>
						<HeaderRowStart {...props} />
						<HeaderRowCenter {...props} />

						<HeaderRowEnd>
							{withSetting && <Settings style={{marginTop: '10px'}} />}
							{withPoweredBy && showJointLogo && theme.logoUrl && <PoweredBy style={{margin: 0}} />}
							{!theme.logoUrl && onMobileScreen && !props.isLoginScreen && (
								<Icons.BakktB
									className={'bakktB'}
									aria-label='Bakkt Logo'
									style={{margin: '4px 20px 0 0'}}
								/>
							)}
						</HeaderRowEnd>
					</>
				)}
			</HeaderRow>
		</HeaderContainer>
	)
}
