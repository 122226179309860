import React from 'react'
import {IconProps, Icons, useTheme} from 'src'
import {Theme} from '@material-ui/core'

export const HandWithTheme: React.FC<IconProps> = (props: IconProps) => {
	const theme = useTheme() as Theme

	return (
		<Icons.Hand
			style={{
				margin: 'auto',
				transform: 'rotate(-4.91deg)',
			}}
			stroke={theme?.palette?.text?.background}
			{...props}
		></Icons.Hand>
	)
}
