import React, {HTMLAttributes} from 'react'
import {TagProps, Tag} from 'src/components/tag/Tag'
import {color} from 'src/color/color'
import styled from '@emotion/styled'
import {HBox} from '../container/HBox'
import {InfoCircle} from '../icons'

export interface InfoProps extends HTMLAttributes<HTMLDivElement> {
	text: string
	tag?: TagProps
}

const DefaultInfo = styled.div`
	background: ${props =>
		props?.theme?.palette?.type === 'dark' ? props?.theme?.palette?.background?.default : '#FAFAFB'};
	box-sizing: border-box;
	align-items: center;
	display: flex;
	justify-content: flex-start;
	padding: 20px 17px 20px 0;
	border-left: 4px solid ${props => props?.theme?.palette?.text?.background || '#000000'};
`

const Text = styled.span`
	font-family: ${props => props?.theme?.typography?.fontFamily || 'BrownPro'};
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17.92px;
	color: ${props => props?.theme?.palette?.text?.background || '#000000'};
`

export const Info: React.FC<InfoProps> = ({text = '', tag, ...props}: InfoProps) => (
	<DefaultInfo {...props}>
		<HBox style={{margin: 'auto 20px auto 20px'}}>
			<InfoCircle />
		</HBox>
		<HBox>
			{tag && <Tag {...tag} />}
			<Text>{text}</Text>
		</HBox>
	</DefaultInfo>
)
