export const color = {
	blue100: '#EBF8FF',
	blue200: '#C6EFFF',
	blue300: '#9DE9FF',
	blue400: '#66E2FF',
	blue500: '#25C1FF',
	blue600: '#069CFF',
	blue700: '#0567DE',
	blue800: '#082B9B',
	blue900: '#100722',
	blueKolibri: '#004879',
	blue153: '#153E6F',
	green25: '#F8FFF9',
	green100: '#E2FFE7',
	green200: '#ABF4C7',
	green300: '#75E6B0',
	green400: '#3FD899',
	green500: '#21BC8C',
	green600: '#039F7E',
	green700: '#077862',
	green800: '#0B5245',
	green900: '#0F2B29',
	grey20: '#FAFAFB',
	grey40: '#F5F5F6',
	grey100: '#E7E6E9',
	grey200: '#CFCDD3',
	grey300: '#B7B5BD',
	grey400: '#9F9CA7',
	grey500: '#878390',
	grey600: '#706A7A',
	$secondaryGrey: '#767676',
	grey700: '#585265',
	grey800: '#40394E',
	purple100: '#F2E6FF',
	purple200: '#DFC3FF',
	purple300: '#C79DFF',
	purple400: '#AB78FF',
	purple500: '#9451F3',
	purple600: '#8133E0',
	purple700: '#6E1EC1',
	purple800: '#53108B',
	purple900: '#190722',
	salmon400: '#FF837D',
	teal100: '#E6F5F2',
	blackLight: '#222222',
	black: '#000000',
	white: '#ffffff',
	error: '#D93C30',
	alert: '#D93C30',
	success: '#3FD899',
	info: '#25C1FF',
	warning: '#FFC37B',
	primary: '#3FD899',
	institutions: '#0567DE',
	crypto: '#66E2FF',
	rewards: '#AB78FF',
	bakktCash: '#3FD899',
	danger: '#EE8B80',
	overlay: '#071413',
}

export type Color = typeof color
