import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {DropzoneArea, DropzoneAreaProps} from 'material-ui-dropzone'
import {color} from 'src/color/color'
import {Upload} from '../icons'
import {XCircle} from '../icons'
import {ShadowContainer} from '../shadowContainer/ShadowContainer'

export interface FileUploadProps
	extends Omit<
		DropzoneAreaProps,
		| 'dropzoneText'
		| 'Icon'
		| 'showPreviewsInDropzone'
		| 'useChipsForPreview'
		| 'previewGridProps'
		| 'previewChipProps'
		| 'previewText'
	> {
	onDownload?: () => void
}

export const BAKKT_FONT =
	"'BrownPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen-Sans', 'Ubuntu', 'Cantarell', 'Helvetica Neue', sans-serif"

const useStyles = makeStyles(theme => ({
	dropzone: {
		minHeight: 0,
		borderWidth: '0.1rem',
		borderColor: color.grey500,
		borderRadius: '0.7rem',
		'& svg': {
			marginTop: '0.6rem',
			height: '1.5rem',
		},
		'& div': {
			margin: '1rem 3rem 1.25rem 3rem',
		},
	},
	dropzoneText: {
		fontSize: '1rem',
		fontFamily: BAKKT_FONT,
		marginTop: 0,
		marginBottom: 0,
	},
	previewChip: {
		justifyContent: 'flex-start',
		border: 'none',
		fontFamily: BAKKT_FONT,
		fontSize: '0.9rem',
		maxWidth: '25rem',
		'& svg': {
			height: '1.5rem',
		},
	},
	containerStyles: {
		maxWidth: '25rem',
	},
}))

export const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
	const classes = useStyles()
	const DROPZONE_TEXT = 'Drag and drop a file here or click to select a file to upload.'
	function onDownload() {
		props.onDownload && props.onDownload()
	}

	return (
		<ShadowContainer className={classes.containerStyles}>
			<DropzoneArea
				dropzoneClass={classes.dropzone}
				dropzoneParagraphClass={classes.dropzoneText}
				dropzoneText={DROPZONE_TEXT}
				Icon={Upload as any}
				showAlerts={['error']}
				disableRejectionFeedback={true}
				alertSnackbarProps={{anchorOrigin: {vertical: 'top', horizontal: 'center'}}}
				maxFileSize={1048576}
				showPreviews={props?.showPreviews != null ? props?.showPreviews : true}
				showPreviewsInDropzone={false}
				useChipsForPreview
				previewGridProps={{
					container: {spacing: 1, direction: 'column', alignContent: 'flex-start', alignItems: 'flex-start'},
				}}
				previewChipProps={{
					classes: {root: classes.previewChip},
					deleteIcon: <XCircle style={{height: 26, width: 60}} />,
					onClick: () => onDownload(),
				}}
				previewText=''
				{...props}
			/>
		</ShadowContainer>
	)
}
