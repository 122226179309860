import React from 'react'
import MUISwitch, {SwitchProps as MUISwitchProps} from '@material-ui/core/Switch'
import {SwitchOffInactive, SwitchOff, SwitchOnInactive, SwitchOn} from '../icons'
import {makeStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles'

export interface SwitchProps extends MUISwitchProps {
	disabled?: boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const useStyles = makeStyles(() => ({
	switchBase: {
		'&$checked': {
			transform: 'translateX(0px)',
		},
	},
	checked: {},
}))

const theme = createTheme({
	overrides: {
		MuiIconButton: {
			root: {
				'&:hover': {
					backgroundColor: 'transparent !important',
				},
			},
		},
	},
})

export const Switch: React.FC<SwitchProps> = (props: SwitchProps) => {
	const {disabled, onChange, ...rest} = props
	const classes = useStyles()

	return (
		<MuiThemeProvider theme={theme}>
			<MUISwitch
				classes={{switchBase: classes.switchBase, checked: classes.checked}}
				icon={disabled ? <SwitchOffInactive /> : <SwitchOff />}
				checkedIcon={disabled ? <SwitchOnInactive /> : <SwitchOn />}
				disabled={disabled}
				disableRipple={true}
				color='default'
				onChange={onChange}
				{...rest}
			/>
		</MuiThemeProvider>
	)
}
