import React, {HTMLAttributes} from 'react'
import styled from '@emotion/styled'
import {color} from 'src/color/color'

export interface SeparatorProps extends HTMLAttributes<HTMLDivElement> {
	color?: string
	orientation?: 'horizontal' | 'vertical'
	thickness?: number
}

const DefaultContainer = styled.div((props: SeparatorProps) => ({
	height: `${props.thickness ? props.thickness : 1}px`,
	background: props.color ? props.color : color.grey100,
	transform: `rotate(${props.orientation === 'vertical' ? 90 : 180}deg)`,
}))

export const Separator: React.FC<SeparatorProps> = prop => <DefaultContainer {...prop}></DefaultContainer>
