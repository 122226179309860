import {Router} from 'react-router-dom'
import {createMemoryHistory, Update, MemoryHistory} from 'history'
import {useState, useLayoutEffect, createElement, ReactNode, createContext, useEffect} from 'react'
import React from 'react'

type HistoryType = MemoryHistory & {updateLocationHistory: (object: Update | {action: 'IGNORE'}) => void}
type LocationHistoryType = {path: string; ignore?: boolean}[]

export const HistoryContext = createContext(undefined as HistoryType | undefined)

export const memoryRouter = ({children}: {children?: ReactNode}) => {
	const getLocationHistoryFromStorage = (): LocationHistoryType => {
		try {
			return (JSON.parse(window.sessionStorage.getItem('locationHistory') || '[""]') as string[]).map(x => ({
				path: x,
			}))
		} catch (error) {
			return []
		}
	}

	const [completeLocationHistory, setCompleteLocationHistory] = useState<LocationHistoryType>(
		getLocationHistoryFromStorage(),
	)

	const getRouterLocationHistory = (): string[] => completeLocationHistory.map(x => x.path)
	const getStorageLocationHistory = (): string[] => completeLocationHistory.filter(x => !x.ignore).map(x => x.path)

	const [routerLocationHistory, setRouterLocationHistory] = useState<string[]>(getRouterLocationHistory())
	const [storageLocationHistory, setStorageLocationHistory] = useState<string[]>(getStorageLocationHistory())

	const history = createMemoryHistory({
		initialEntries: routerLocationHistory,
	}) as HistoryType

	const [state, setState] = useState({
		action: history.action,
		location: history.location,
	})

	const updateLocationHistory = (obj: Update | {action: 'IGNORE'}) => {
		switch (obj.action) {
			case 'POP':
				setCompleteLocationHistory(history => {
					const newLocationHistory = [...history]
					if (newLocationHistory.length > 1) newLocationHistory.pop()
					return newLocationHistory
				})
				break
			case 'IGNORE':
				setCompleteLocationHistory(history => {
					const newLocationHistory = [...history]
					if (newLocationHistory.length > 1) newLocationHistory[newLocationHistory.length - 1].ignore = true
					return newLocationHistory
				})
				break
			case 'REPLACE':
				setCompleteLocationHistory(history => {
					const newLocationHistory = [...history]
					newLocationHistory.pop()
					newLocationHistory.push({path: obj.location.pathname})
					return newLocationHistory
				})
				break
			case 'PUSH':
			default:
				setCompleteLocationHistory(history => {
					const newLocationHistory = [...history]
					newLocationHistory.push({path: obj.location.pathname})
					return newLocationHistory
				})
		}
	}
	history.updateLocationHistory = updateLocationHistory

	useLayoutEffect(
		() =>
			history.listen((obj: any) => {
				setState(obj)
				updateLocationHistory(obj)
			}),
		[history],
	)

	useEffect(() => {
		window.sessionStorage.setItem('locationHistory', JSON.stringify(storageLocationHistory))
	}, [storageLocationHistory])

	useEffect(() => {
		setRouterLocationHistory(getRouterLocationHistory())
		setStorageLocationHistory(getStorageLocationHistory())
	}, [completeLocationHistory])

	return {
		children: <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>,
		location: state.location,
		navigationType: state.action,
		navigator: history,
	}
}

export const MemoryRouter = ({children}: {children?: ReactNode}) => {
	return createElement(Router, memoryRouter({children}))
}
