import {ApiAmountCurrencyEnum, ApiEmbeddedFundingTransferRequest, FiatAmountCurrencyEnum} from '@bakkt/api'
import {Main, BottomBox, Button, VBox, H2, Alert, Spinner, Medium, HBox, Light, Separator} from '@bakkt/components'
import {apiCall, embeddedBankLinkingApi, embeddedDebitLinkingApi, embeddedPartnerApi} from 'api'
import {showErrorDialog} from 'components/ErrorDialog'
import Page from 'components/Page'
import {RoutesName} from 'constants/routes'
import {useNavigate} from 'hooks'
import {t} from 'i18next'
import {useEffect, useState} from 'react'
import {useStore} from 'store'
import {formatUSD} from 'utils/currencyFormatters'
import {logger} from 'utils/logger/datadogInit'
import {useLocation} from 'react-router-dom'

const CashConfirm = () => {
	const navigate = useNavigate()
	const state: any = useLocation().state
	const isCashIn = state?.type === 'cash_in'
	const amount = state?.amount
	const [loading, setLoading] = useState(false)
	const {selectedFundingAccount, onShowToast, setFundingAccounts} = useStore()
	const [message, setMessage] = useState<{message: string; error: boolean} | null>(null)
	const achTransaction = selectedFundingAccount?.externalAccountRef.startsWith('BANK')
	const institutionName =
		selectedFundingAccount?.name && selectedFundingAccount?.name !== 'N/A'
			? selectedFundingAccount?.name
			: 'Account ending in'
	const accountNumber = selectedFundingAccount?.formattedAccountNumber?.match(/\d{4}/)

	useEffect(() => {
		if (achTransaction) {
			setMessage({message: `Your cash will post in 3-5 business days.`, error: false})
		} else {
			setMessage(null)
		}
	}, [achTransaction])

	const transactWithDebit = async (cashIn: boolean, value: number) => {
		const achType = cashIn ? 'DEPOSIT' : 'WITHDRAWAL'

		const transferRequest: ApiEmbeddedFundingTransferRequest = {
			externalAccountRef: selectedFundingAccount?.externalAccountRef || '',
			amount: {
				currency: FiatAmountCurrencyEnum.USD,
				amount: value,
			},
		}
		const {response, error} = await apiCall(() =>
			embeddedDebitLinkingApi.transactDebitCard(achType, transferRequest),
		)
		return error
	}

	const transactWithACH = async (cashIn: boolean, value: number) => {
		const achType = cashIn ? 'DEPOSIT' : 'WITHDRAWAL'

		const transferRequest: ApiEmbeddedFundingTransferRequest = {
			externalAccountRef: selectedFundingAccount?.externalAccountRef || '',
			amount: {
				currency: FiatAmountCurrencyEnum.USD,
				amount: value,
			},
		}
		const {response, error} = await apiCall(() =>
			embeddedBankLinkingApi.transactBankAccount(achType, transferRequest),
		)
		return error
	}

	const handleCashInCashOut = async (cashIn: boolean) => {
		const value = parseFloat(amount)
		logger.info(`Linked Account transfer - ${cashIn ? 'cashIn' : 'cashOut'}  clicked`)
		setLoading(true)
		const error = !achTransaction ? await transactWithDebit(cashIn, value) : await transactWithACH(cashIn, value)

		setLoading(false)
		if (error) {
			return showErrorDialog({
				message: t('errors.transactionError'),
				errorCode: error?.code,
			})
		}
		if (achTransaction) {
			onShowToast({
				toastMessage: `Your ${formatUSD(value, ApiAmountCurrencyEnum.USD)} ${
					cashIn
						? 'to Bakkt from your bank account. It will post in 3-5 business days.'
						: 'will arrive in 3-5 business days in your bank account.'
				}`,
				toastType: 'success',
			})
		}
		const fundingAccountsResponse = await embeddedPartnerApi.fetchFundingAccounts('USD')
		setFundingAccounts(fundingAccountsResponse?.data?.payload || [])
		navigate(RoutesName.home)
	}

	const handleCashIn = () => {
		handleCashInCashOut(isCashIn)
	}

	const handleCancel = () => {
		navigate(`/${RoutesName.bakktFunding.cashPage}`)
	}

	return (
		<Page
			withBackIcon
			onIconPress={() => navigate(RoutesName.bakktFunding.cashInput, {state: {type: state?.type}})}
			titleHeader={'Manage your cash balance'}
			loading={loading}
		>
			<Main>
				<VBox style={{alignItems: 'center'}}>
					{message && (
						<Alert
							severity={message.error ? 'error' : 'info'}
							icon={false}
							onClose={() => setMessage(null)}
						>
							{message.message}
						</Alert>
					)}
					<H2
						style={{
							margin: 0,
							fontSize: 48,
							lineHeight: '130.02%',
						}}
					>
						{formatUSD(amount, ApiAmountCurrencyEnum.USD)}
					</H2>
				</VBox>
				{loading && (
					<div style={{marginLeft: 'auto', marginRight: 'auto'}}>
						<Spinner />
					</div>
				)}
				{!isCashIn && (
					<HBox
						style={{
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: 10,
							marginTop: 70,
						}}
					>
						<Medium>Deposit to</Medium>
						<VBox style={{alignItems: 'flex-end'}}>
							<Light style={{margin: 0}}>
								<span>{institutionName}</span>
							</Light>
							{accountNumber && (
								<Light style={{margin: 0}}>
									<span>••••{accountNumber}</span>
								</Light>
							)}
						</VBox>
					</HBox>
				)}
				<Separator />
				{selectedFundingAccount?.accountType !== 'DEBIT' && (
					<Medium style={{marginTop: 20}}>Your transaction will process within 3-5 business days.</Medium>
				)}
			</Main>
			<BottomBox style={{marginTop: '50px', marginBottom: '30px'}}>
				<VBox verticalGap={10} style={{width: '-webkit-fill-available'}}>
					<Button size='large' onClick={handleCashIn}>
						{isCashIn ? 'Add Cash Now' : 'Cash Out Now'}
					</Button>
					<Button intent='link' size='large' onClick={handleCancel}>
						Cancel
					</Button>
				</VBox>
			</BottomBox>
		</Page>
	)
}

export default CashConfirm
