import {ApiEmbeddedPartnerTransactionSummary, PageApiEmbeddedPartnerTransactionSummary} from '@bakkt/api'
import {apiCall, embeddedPartnerApi} from 'api'
import {useEffect, useState} from 'react'
import {useStore} from 'store'
import {
	mapAssetActivityRecordToPartnerTransaction,
	PartnerTransaction,
} from '../apps/main/pages/crypto/transaction/activity/utils'

export const useActivityInformation = () => {
	const {isPartnerBakkt} = useStore()
	const [transactionSummaries, setTransactionSummaries] = useState<
		PartnerTransaction[] | ApiEmbeddedPartnerTransactionSummary[] | undefined
	>()

	const fetchTransaction = async () => {
		const {response} = await apiCall(() => embeddedPartnerApi.getTransactionSummary(undefined, undefined, 0, 4))
		setTransactionSummaries(response?.content)
	}

	const fetchAssetActivities = async () => {
		const {response} = await apiCall(() => embeddedPartnerApi.getAssetActivitiesHistory())
		if (response?.content) {
			setTransactionSummaries(response.content.map(mapAssetActivityRecordToPartnerTransaction))
		}
	}

	useEffect(() => {
		if (isPartnerBakkt) {
			fetchAssetActivities()
		} else {
			fetchTransaction()
		}
	}, [])

	return {transactionSummaries}
}
