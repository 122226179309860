import React from 'react'
import MUIRadioGroup, {RadioGroupProps as MUIRadioGroupProps} from '@material-ui/core/RadioGroup'
import {makeStyles} from '@material-ui/core/styles'

export interface RadioGroupProps extends MUIRadioGroupProps {}

const useStyles = makeStyles(() => ({
	root: {
		display: 'inline-flex',
	},
}))

export const RadioGroup: React.FC<RadioGroupProps> = ({children, ...props}: RadioGroupProps) => {
	const classes = useStyles()

	return (
		<MUIRadioGroup classes={{root: classes.root}} {...props}>
			{children}
		</MUIRadioGroup>
	)
}
