import * as ag from 'ag-grid-community'
import CSVExport from './CSVExport'
import moment from 'moment'
export default class GridsUtil {
	static applyPinned(e: ag.CellContextMenuEvent) {
		const columns: ag.Column[] = e.columnApi.getAllDisplayedColumns()
		const index = columns.indexOf(e.column)
		// @ts-ignore
		const lastPinnedColumn = columns.filter(c => c.isPinned()).sort((c1, c2) => c2.getLeft() - c1.getLeft())

		if (index >= 0) {
			if (lastPinnedColumn.length === 0 || columns[index] !== lastPinnedColumn[0]) {
				e.columnApi.setColumnsPinned(e.columnApi.getDisplayedLeftColumns(), null as any)
				e.columnApi.setColumnsPinned(columns.slice(0, index + 1), 'left')
			} else {
				e.columnApi.setColumnsPinned(e.columnApi.getDisplayedLeftColumns(), null as any)
			}
		}
	}
	static copy(e: ag.CellContextMenuEvent) {
		if (e.data) {
			const value = e.api.getValue(e.column, e.node)
			if (value != null) {
				const tempInput = document.createElement('input') as any
				tempInput.style = 'position: absolute; left: -1000px; top: -1000px'
				tempInput.value = value
				document.body.appendChild(tempInput)
				tempInput.select()
				document.execCommand('copy')
				document.body.removeChild(tempInput)
			}
		} else {
			e.api.copySelectedRangeToClipboard(false)
		}
	}

	static csvExport(
		gridInput: {api: ag.GridApi; columnApi: ag.ColumnApi; model: ag.IDatasource},
		fileName: string,
		exportHidden?: boolean,
	) {
		new CSVExport(gridInput, exportHidden).exportTo(fileName)
	}

	static dateComparator(date1: string, date2: string) {
		const momentA = moment(date1)
		const momentB = moment(date2)
		if (momentA.isBefore(momentB)) return 1
		else if (momentA.isAfter(momentB)) return -1
		else return 0
	}
}
