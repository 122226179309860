import {CircleIcon, VBox, Large, color, H2} from 'src'
import {useTheme} from '@material-ui/core'
import React from 'react'
export interface ActivityHeaderProps {
	icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
	activityText: string
	amount: string
	conversionAmount: string
}
export const ActivityHeader = ({icon, activityText, amount, conversionAmount}: ActivityHeaderProps) => {
	const theme = useTheme()

	return (
		<VBox
			style={{
				background: theme?.palette?.secondary?.main || '#ecf4ff',
				marginLeft: '-36px',
				marginRight: '-36px',
			}}
		>
			<VBox
				style={{
					marginTop: '20px',
					marginBottom: '20px',
					alignItems: 'center',
				}}
				verticalGap={8}
			>
				{icon && (
					<CircleIcon
						icon={icon}
						iconColor={theme?.palette.text?.background || color.blue900}
						style={{height: 40, width: 40, background: theme?.palette?.background?.paper || 'white'}}
					/>
				)}
				<Large
					data-testid={'transaction-description'}
					style={{
						color: theme?.palette?.secondary?.contrastText || color.blue900,
						fontSize: '16px',
						fontWeight: 400,
						margin: 0,
					}}
				>
					{activityText}
				</Large>
				<H2
					data-testid={'transaction-crypto-value'}
					style={{
						color: theme?.palette?.secondary?.contrastText || color.blue900,
						fontSize: '36px',
						fontWeight: 700,
						margin: 0,
					}}
				>
					{amount}
				</H2>
				<Large
					data-testid={'transaction-usd-value'}
					style={{
						color: theme?.palette?.secondary?.contrastText || color.blue900,
						fontSize: '16px',
						fontWeight: 400,
						margin: 0,
					}}
				>
					{conversionAmount}
				</Large>
			</VBox>
		</VBox>
	)
}
