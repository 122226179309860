import {useStore} from 'store'
import {useCryptoCurrencyDetails} from './useCryptoCurrencyDetails'

export const useSelectedCryptoAccountInfo = () => {
	const {selectedCryptoAccount} = useStore()
	const selectedCryptoCurrency = selectedCryptoAccount?.accountBalance?.currency
	const cryptoCurrencyDetails = useCryptoCurrencyDetails(selectedCryptoCurrency)

	const selectedCryptoCurrencyName = cryptoCurrencyDetails?.name || selectedCryptoCurrency
	const selectedCryptoCurrencyImage = cryptoCurrencyDetails?.iconURL
	const selectedCryptoCurrencyPrecision = cryptoCurrencyDetails?.supportedPrecision

	return {
		selectedCryptoCurrency,
		selectedCryptoCurrencyName,
		selectedCryptoCurrencyImage,
		selectedCryptoCurrencyPrecision,
	}
}
