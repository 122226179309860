import {useState} from 'react'
export interface DebitCardVerifyDetailsProps {
	amount1: string
	amount2: string
}
interface DebitCardValidate {
	cardVerifyDetails: DebitCardVerifyDetailsProps
	cardVerifyError: DebitCardVerifyDetailsProps
	isValid: boolean
	validateVerifyDebitCard: (evt: React.ChangeEvent<HTMLInputElement>) => void
}

const errorMessage = {
	amount: 'Incorrect Amount',
}

const initialVerifyDetailsState: DebitCardVerifyDetailsProps = {
	amount1: '',
	amount2: '',
}

export const stringToNumber = (currency: string) => Number(currency.replace(/[^0-9.-]+/g, ''))

export const useVerifyDebitCard = (): DebitCardValidate => {
	const [cardVerifyDetails, setCardVerifyDetails] = useState<DebitCardVerifyDetailsProps>({
		...initialVerifyDetailsState,
	})
	const [cardVerifyError, setCardVerifyError] = useState<DebitCardVerifyDetailsProps>({
		...initialVerifyDetailsState,
	})

	const validateAmountText = (value: string) => {
		let onlyNums = value.replace(/[^0-9\.]/g, '')
		if (onlyNums?.length === 2 && !onlyNums.includes('.')) {
			onlyNums = `${onlyNums.charAt(0)}.${onlyNums.charAt(1)}`
		}
		onlyNums = onlyNums ? `$${onlyNums}` : onlyNums
		return {
			value: onlyNums,
			isValid: !!onlyNums && !isNaN(stringToNumber(onlyNums)),
		}
	}
	const validateVerifyDebitCard = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const {value, isValid} = validateAmountText(evt.target.value)
		evt.target.value = value
		setCardVerifyDetails({...cardVerifyDetails, [evt.target.name]: value})
		setCardVerifyError({
			...cardVerifyError,
			[evt.target.name]: isValid ? '' : errorMessage.amount,
		})
	}

	const isValid: boolean =
		!cardVerifyError.amount1 &&
		!cardVerifyError.amount2 &&
		!!cardVerifyDetails.amount1 &&
		!!cardVerifyDetails.amount2

	return {
		cardVerifyDetails,
		cardVerifyError,
		isValid,
		validateVerifyDebitCard,
	}
}
