import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {H3, Large, BottomBox, Main} from '@bakkt/components'
import Button from 'components/Button'
import Page from 'components/Page'
import {RoundLockIcon} from 'components/icons/RoundLockIcon'
import {useStore} from 'store'
import {logger} from 'utils/logger/datadogInit'
import {returnToPartner} from 'utils/returnToPartner'

export const TokenSessionExpired = () => {
	const {t} = useTranslation()
	const {loadOnStartup} = useStore()
	const {partner} = useStore()
	const savedPartnerStr = localStorage.getItem('savedPartner')
	localStorage.setItem('savedPartner', '')
	const savedPartner = JSON.parse(savedPartnerStr || '{}')
	localStorage.setItem('sessionExpired', JSON.stringify({sessionExpired: true}))

	useEffect(() => {
		logger.info('TokenSessionExpired - initilized', {
			filename: 'TokenSessionExpired.tsx',
			message: 'Session Expired',
		})
	}, [])

	const handleClick = () => {
		logger.info('Token expired', {call: 'loadOnStartup'})
		if (!partner && Object.keys(savedPartner).length != 0) {
			returnToPartner(true, savedPartner)
		} else {
			returnToPartner(
				true,
				(window as any).error_code == 'LINK_EXPIRED' ? 'link_expired' : 'session_expired',
				partner,
			)
		}
		localStorage.setItem('sessionExpired', JSON.stringify({sessionExpired: false}))
	}

	return (
		<Page titleHeader={'Session Expired'} withSubHeaderLogo={false}>
			<Main style={{flexGrow: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
				<div style={{position: 'relative', margin: 0, display: 'flex', justifyContent: 'center'}}>
					<RoundLockIcon height='104px' width='104px' />
				</div>
				<div style={{textAlign: 'center', margin: '26px 0px 0px 0px', maxWidth: '100%', paddingBottom: '1em'}}>
					<H3 style={{marginBottom: '0px', marginTop: '0px'}}>Session Expired!</H3>
					<Large style={{marginTop: '0.5em', marginBottom: '36px'}}>
						Your session has expired. Please close this window and try again.
					</Large>
				</div>
			</Main>
			<BottomBox className='activationSuccessBottomBox' style={{paddingTop: '30px', marginBottom: '30px'}}>
				<Button
					data-dma='Close'
					data-testid='session-expired-state-return-button'
					style={{marginBottom: '10px'}}
					onClick={handleClick}
				>
					{t('crypto.existingAccountErrorPage.returnToButton', {linkedPartnerName: partner?.name})}
				</Button>
			</BottomBox>
		</Page>
	)
}
