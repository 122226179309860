import React from 'react'
import {CopyText} from '../icons'

export const CopyTextView = (props: {value: string}) => {
	return (
		<span title='click to copy' onClick={() => navigator.clipboard.writeText(props.value)}>
			<CopyText style={{marginLeft: 5}} />
		</span>
	)
}
