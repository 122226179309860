export const RoutesName = {
	home: '/',
	termsAndConditions: '/termsAndCondtions',
	auth: {
		login: 'login',
		forgetPassword: 'forgetPassword',
		resetPassword: 'resetPassword',
		verifyOtp: 'verify-otp',
		resetNewPassword: 'reset-new-password',
		session_expired: 'session_expired',
	},
	onboarding: {
		home: '/onboarding/',
		kyc: 'kyc',
		taxpayerCertification: 'taxpayer/certification',
		success: 'success',
		accountExists: 'accountExists',
		hawaiiError: 'hawaiiError',
	},
	crypto: {
		buy: 'buy',
		confirm: 'confirm',
		success: 'success',
		sell: 'sell',
		funding: 'funding',
		overview: 'overview',
		transaction: {
			detail: 'detail',
			all: 'all',
		},
	},
	bakktFunding: {
		cashPage: 'cashPage',
		cashInput: '/cashInput',
		cashConfirm: '/cashConfirm',
		fundingTermsAndCondition: '/fundingTermsAndCondition',
	},
	bank: {
		link: 'link',
	},
	debitCard: {
		link: 'link-debit-card',
		verifyTransactions: 'verify-transactions',
		main: 'debit-card',
		child: {
			general: 'general',
			shippingAddress: 'shipping-address',
			cryptoDebitDetail: 'crypto-debit-detail',
		},
	},
	payout: {
		confirm: 'confirm',
	},
	cryptoActivated: 'crypto-activated',
	activity: {
		main: 'activity',
		child: {
			boughtBTC: 'bought-btc',
		},
	},
	myCrypto: 'my-crypto',
	partnerTermsOfUse: '/terms-of-use',
	bakktTermsOfUse: '/bakkt-terms-of-use',
	taxes: '/taxes',
	viewAccountDetails: '/viewAccountDetails',
	editAccountEmail: '/editAccountEmail',
	editAccountPhone: '/editAccountPhone',
	editAccountName: '/editAccountName',
	editAccountAddress: '/editAccountAddress',
}
