import React, {useEffect} from 'react'
import {Alert} from '@bakkt/components'
import {PageProps as BakktPageProps} from '@bakkt/components/types/components/layout/Page'
import {useMediaQuery} from '@material-ui/core'
import {useStore} from 'store'
import {useLocation} from 'react-router-dom'

type Props = {} & BakktPageProps

const toastStyle = {
	zIndex: 100,
} as const
const mobileToastStyle = {
	...toastStyle,
	marginLeft: '15px',
	marginRight: '15px',
} as const
const desktopToastStyle = {
	...toastStyle,
	width: 640,
	marginTop: '-12px',
	marginLeft: 'auto',
	marginRight: 'auto',
	left: 0,
	right: 0,
} as const

const ToastManager = ({children, ...props}: Props) => {
	const {toastMessage, toastType, onCloseToast} = useStore()
	const onMobileScreen = useMediaQuery('(max-width:719px)')
	const pathName = useLocation().pathname

	useEffect(() => {
		if (pathName !== '/' || '/cashConfirm') {
			onCloseToast()
		}
	}, [pathName])

	return (
		<>
			{toastMessage && (
				<Alert
					style={{
						...(onMobileScreen ? mobileToastStyle : desktopToastStyle),
						transform: toastMessage ? 'scaleY(1)' : 'scaleY(0)',
					}}
					severity={toastType}
					onClose={onCloseToast}
				>
					{toastMessage}
				</Alert>
			)}
			{children}
		</>
	)
}

export default ToastManager
