import {BottomBox, Button, H1, HBox, Input, Main, color, VBox, Link, H4, Medium, Alert, Light} from '@bakkt/components'
import {embeddedBakktAuthApi} from 'api'
import Page from 'components/Page'
import {RoutesName} from 'constants/routes'
import {useOTP} from 'hooks/auth/useOTP'
import React, {useCallback, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useStore} from 'store'
import {decodeBase64} from '../../utils/formatters'
import {logger} from 'utils/logger/datadogInit'
import {t} from 'i18next'
import {showErrorDialog} from '../../components/ErrorDialog'

const VerifyOTP = () => {
	const [otp, setOtp] = useState('')
	const {
		userIdentity,
		identifier,
		setIdentifier,
		setChangePasswordRequired,
		changePasswordRequired,
		redirectUrlAfterOtpValidation,
	} = useStore()
	const [isMismatch, setIsMismatch] = useState(false)
	const {error, isValid} = useOTP(otp)
	const navigate = useNavigate()
	const params = new URLSearchParams(window.document.location.search)
	const code = params.get('code')
	const [isSendOtpCodeAfterLinkExpiry, setIsSendOtpCodeAfterLinkExpiry] = useState(true)

	useEffect(() => {
		if (code != null && changePasswordRequired) {
			const decode = decodeBase64(code)
			const identifierToken = decode?.split(':')[0]
			const otpCode = decode?.split(':')[1]
			setOtp(otpCode!)
			setIdentifier({identifierToken: identifierToken})
			setChangePasswordRequired(false)
		}
	}, [])

	const onChangeOTP = useCallback((event: {target: {value: string}}) => {
		setOtp(event.target.value)
	}, [])

	const handleResendCode = async () => {
		const response = await embeddedBakktAuthApi.resend({identifierToken: identifier?.identifierToken})
		setOtp('')
		setIsMismatch(false)
		setIdentifier(response.data.payload)
	}

	const handleSendOtpCodeAfterLinkExpiry = async () => {
		setIsSendOtpCodeAfterLinkExpiry(false)
		setOtp('')
		const response = await embeddedBakktAuthApi.resetPasswordPhone({
			identifierToken: identifier?.identifierToken,
		})
		if (response.data.success) {
			setIdentifier(response.data.payload)
			setIsMismatch(false)
		}
	}

	useEffect(() => {
		const validate = async () => {
			try {
				const response = await embeddedBakktAuthApi.otp({
					identifierToken: identifier?.identifierToken,
					validationCode: otp,
				})
				const success = response.data.success
				if (success) {
					setIdentifier(response.data.payload)
					setIsMismatch(false)
					if (redirectUrlAfterOtpValidation != null) {
						return navigate(redirectUrlAfterOtpValidation)
					}
					return navigate(`/${RoutesName.auth.resetNewPassword}`)
				} else if (code != null && isSendOtpCodeAfterLinkExpiry) {
					return handleSendOtpCodeAfterLinkExpiry()
				}

				logger.error(response)
				if (response.data.error?.code === 'DATA_MISMATCH') {
					setIsMismatch(true)
				} else {
					setIsMismatch(false)
					showErrorDialog({
						message: t('errors.unableUpdateIdentifier', {userIdentity}) + ' ' + t('errors.contactInfo'),
						navigate: redirectUrlAfterOtpValidation != null ? navigate : null,
						errorCode: response?.data?.error?.code,
					})
				}
			} catch (error) {
				logger.error(error)
				setIsMismatch(false)
				showErrorDialog({
					message: t('errors.unableUpdateIdentifier', {userIdentity}) + t('errors.contactInfo'),
					navigate: redirectUrlAfterOtpValidation != null ? navigate : null,
					errorCode: 'unknown_error',
				})
			}
		}
		if (isValid) {
			validate()
		}
	}, [isValid])

	return (
		<Page withBackIcon titleHeader='' withSubHeaderLogo={false} showJointLogo={true}>
			{isMismatch && (
				<Alert
					severity='error'
					icon={false}
					onClose={() => setIsMismatch(false)}
					data-testid='signin-error-alert'
				>
					{`t('errors.otpMismatch')`}
				</Alert>
			)}
			<Main>
				<VBox verticalGap={15}>
					<HBox style={{paddingLeft: '10px'}}>
						<H1>Verify Identifier</H1>
					</HBox>
				</VBox>
				<HBox style={{paddingLeft: '15px'}}>
					<Light>Please enter the code sent to {userIdentity}</Light>
				</HBox>
				<VBox verticalGap={15}>
					<Input
						error={!!error}
						helperText={error}
						label='OTP'
						type='otp'
						value={otp}
						onChange={onChangeOTP}
					/>
					<Link onClick={handleResendCode}>Resend Code</Link>
					{identifier?.otp ? <Medium>Test OTP: {identifier?.otp}</Medium> : ''}
				</VBox>
			</Main>
		</Page>
	)
}

export default VerifyOTP
