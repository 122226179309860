import {RowNode} from 'ag-grid-community'
import React from 'react'
import moment from 'moment'
import {Utils} from 'src'
import FilterConfiguration from './FilterConfiguration'
import {Filter, FilterField, SearchCriteria} from './FilterModel'

function findProp(obj: any, val: string) {
	const defaultValue = null
	const prop = val.split('.')
	for (let i = 0; i < prop.length; i++) {
		if (typeof obj[prop[i]] === 'undefined') {
			return defaultValue
		}
		obj = obj[prop[i]]
	}
	return obj
}

export const doesExternalFilterPass = (node: RowNode, filters: Filter[]) => {
	let filtered = true
	for (const filter of filters) {
		const value = findProp(node.data, filter.fieldName)
		const stringValue = (value ? '' + value : '').toLowerCase()
		const searchValue = filter.fieldValues && filter.fieldValues[0] && filter.fieldValues[0].toLowerCase()
		let matched = null
		switch (filter.searchCriteria) {
			case SearchCriteria.AFTER:
				matched = moment(stringValue).isAfter(searchValue)
				break
			case SearchCriteria.AFTERORNULL:
				matched = !stringValue || moment(stringValue).isAfter(searchValue)
				break
			case SearchCriteria.BEFORE:
				matched = moment(stringValue).isBefore(searchValue)
				break
			case SearchCriteria.BEFOREORNULL:
				matched = !stringValue || moment(stringValue).isBefore(searchValue)
				break
			case SearchCriteria.BETWEEN:
				matched = moment(stringValue).isBetween(searchValue, filter.fieldValues[1])
				break
			case SearchCriteria.CONTAINS:
				matched = stringValue.indexOf(searchValue) >= 0
				break
			case SearchCriteria.ENDSWITH:
				matched = stringValue.slice(-searchValue.length) === searchValue
				break
			case SearchCriteria.EQUALS:
				matched = stringValue === searchValue
				break
			case SearchCriteria.GREATERTHAN:
				matched = value > searchValue
				break
			case SearchCriteria.GREATERTHANOREQUAL:
				matched = value >= searchValue
				break
			case SearchCriteria.IN:
				matched = filter.fieldValues.find(searchValue => searchValue === value) != null
				break
			case SearchCriteria.INRANGE:
				matched = value >= searchValue && value <= filter.fieldValues[1]
				break
			case SearchCriteria.LESSTHAN:
				matched = value < searchValue
				break
			case SearchCriteria.LESSTHANOREQUAL:
				matched = value <= searchValue
				break
			case SearchCriteria.NOTCONTAINS:
				matched = stringValue.indexOf(searchValue) < 0
				break
			case SearchCriteria.NOTEQUALS:
				matched = stringValue !== searchValue
				break
			case SearchCriteria.STARTSWITH:
				matched = stringValue.indexOf(searchValue) === 0
				break
		}
		if (matched != null) {
			filtered = filtered && matched
		}
	}

	return filtered
}

export const configureFilters = (columns: FilterField[], preSelectedFilter?: Filter): Promise<Filter> => {
	return Utils.showFuncDialog('Filter Configuration', (props: any) => (
		<FilterConfiguration columns={columns} preSelectedFilter={preSelectedFilter} {...props} />
	))
}
