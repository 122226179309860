import {useState, useMemo} from 'react'
import {isMobileCharactersValid, isValidUserId} from 'validate'

import {useDelayedValidation} from '../useDelayedValidation'

export const useUserId = (value: string) => {
	const [error, setError] = useState('')
	const isMobileNumber = useMemo(() => isMobileCharactersValid(value), [value])
	const isValid = useMemo(() => isValidUserId(value, isMobileNumber), [value, isMobileNumber])

	useDelayedValidation({
		input: value,
		validate: () => {
			if (value && !isValid) {
				setError(isMobileNumber ? 'Invalid mobile number' : 'Invalid email address')
			}
		},
		clearError: () => setError(''),
	})

	return {
		isMobileNumber,
		isValid,
		error,
		setError,
	}
}
