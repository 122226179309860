(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("BakktComponentLib", ["react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["BakktComponentLib"] = factory(require("react"), require("react-dom"));
	else
		root["BakktComponentLib"] = factory(root["react"], root["react-dom"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__47111__) {
return 