import {ButtonHTMLAttributes} from 'react'
import styled from '@emotion/styled'
import {useTheme} from '@material-ui/core'
import {color} from 'src/color/color'
import React from 'react'
export type CircleIconProps = ButtonHTMLAttributes<HTMLDivElement> & {
	icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
	iconColor?: string
}

const IconContainer = styled.div`
	background: ${props => props?.theme?.palette?.secondary?.main || color.blueKolibri};
	padding: 5px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
`

export const CircleIcon = ({icon, iconColor, ...props}: CircleIconProps) => {
	const theme = useTheme()
	return (
		<IconContainer {...props}>
			{icon({fill: iconColor || theme?.palette?.secondary?.contrastText || 'white'})}
		</IconContainer>
	)
}
