import {AgGridReact} from 'ag-grid-react'
import {ICellRendererParams, IDatasource} from 'ag-grid-community'
import {Main, Page, VBox} from '@bakkt/components'
import {useMediaQuery} from '@material-ui/core'
import {apiCall, embeddedPartnerApi} from 'api'
import Footer from 'components/Footer'
import {useNavigate} from 'hooks'
import {useState} from 'react'
import {useStore} from 'store'
import {handleKeyPress} from 'utils/handleKeyPress'
import {mapAssetActivityRecordToPartnerTransaction, PartnerTransaction, renderPartnerActivityItem} from './utils'
import {logger} from '../../../../../../utils/logger/datadogInit'

const TransactionActivity = () => {
	const {isPartnerBakkt, setSelectedPartnerTransaction} = useStore()
	const [loading, setLoading] = useState<boolean>(true)
	const {setActivePartnerTransactionId} = useStore()

	// Needed workaround for useNavigate not working in AgGrid cell renderer
	// @ts-ignore
	window['navigate'] = useNavigate()

	const fetchTransaction = async (currentPage: number, size: number) => {
		setLoading(false)
		const {response} = await apiCall(() =>
			// @ts-ignore
			isPartnerBakkt
				? embeddedPartnerApi.getAssetActivitiesHistory(
						undefined,
						undefined,
						undefined,
						undefined,
						undefined,
						undefined,
						undefined,
						currentPage,
						size,
				  )
				: embeddedPartnerApi.getTransactionSummary(undefined, undefined, currentPage, size),
		)
		setLoading(false)
		return response
	}

	const handleActivityRowClick = (transactionRecord?: any) => {
		logger.info('Transaction activity - Select activity item')
		if (isPartnerBakkt) {
			setSelectedPartnerTransaction(transactionRecord)
			setActivePartnerTransactionId(transactionRecord?.principalActivityDetail?.transactionId)
		} else {
			setActivePartnerTransactionId(transactionRecord.transactionId)
		}
		// @ts-ignore
		window.navigate('/detail')
	}

	const onMobileScreen = useMediaQuery('(max-width:719px)')
	const FullWidthCellRenderer = (params: ICellRendererParams) => {
		return (
			<div
				className='transactionHistory'
				style={{cursor: 'pointer', paddingLeft: 3, paddingRight: 3}}
				onClick={() => {
					handleActivityRowClick(params.node?.data)
				}}
				key={params.node?.data?.transactionId}
				tabIndex={0}
				onKeyPress={event => handleKeyPress(event, handleActivityRowClick(params.node?.data))}
			>
				{params.node?.data && renderPartnerActivityItem(params.node?.data)}
			</div>
		)
	}

	const datasource: IDatasource = {
		rowCount: undefined,
		getRows: async params => {
			const response = await fetchTransaction(
				datasource.rowCount ? Math.floor(params.startRow / (params.endRow - params.startRow)) : 0,
				params.endRow - params.startRow,
			)
			datasource.rowCount = response?.totalElements
			params.successCallback(
				response?.content?.map(mapAssetActivityRecordToPartnerTransaction) || [],
				response?.totalElements,
			)
		},
	}

	return (
		<Page
			withBackIcon
			loading={false}
			titleHeader='Activity'
			Footer={!onMobileScreen ? Footer : undefined}
			isLoginScreen
		>
			<Main>
				<VBox verticalGap={15}>
					<div className='activityWrapper' style={{height: '60vh'}}>
						<AgGridReact
							rowModelType={'infinite'}
							rowSelection = {'single'}
							headerHeight={0}
							cacheBlockSize={20}
							rowHeight={75}
							rowBuffer={0}
							datasource={datasource}
							isFullWidthCell={() => true}
							cacheOverflowSize = {1}
							maxConcurrentDatasourceRequests = {1}
							infiniteInitialRowCount = {1}
							frameworkComponents={{
								fullWidthCellRenderer: FullWidthCellRenderer,
							}}
							fullWidthCellRenderer='fullWidthCellRenderer'
						></AgGridReact>
					</div>
				</VBox>
			</Main>
		</Page>
	)
}

export default TransactionActivity
