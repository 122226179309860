import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Input, Utils, RadioGroup, Radio} from '../..'
import {VBox} from '../container/VBox'
import {Select} from '../dropdown/Select'
import {Filter, FilterField, FilterType, SearchCriteria, RelativeTime} from './FilterModel'
import {appendTimeZoneToDatetime} from 'src/utils/DateUtils'
import Application from 'src/utils/Application'

interface FilterConfigurationProps {
	columns: FilterField[]
	preSelectedFilter?: Filter
	onValidation?: (valid: boolean) => void
}

export default (props: FilterConfigurationProps) => {
	const initialSelectedFilterField = props.columns.find(c => c.dataField === props.preSelectedFilter?.fieldName)
	const [selectedFilterField, setSelectedFilterField] = useState<FilterField | undefined>(initialSelectedFilterField)
	const [selectedCriteria, setSelectedCriteria] = useState<SearchCriteria | null | undefined | string>(
		props.preSelectedFilter?.searchCriteria,
	)
	const [filterFieldValues, setFilterFieldValues] = useState<string[] | undefined>()
	const [preselectedFilterValues, setPreselectedFilterValues] = useState<string[] | undefined>(
		props.preSelectedFilter?.fieldValues,
	)
	const [filterStartDate, setFilterStartDate] = useState<Date | string | undefined>(
		props.preSelectedFilter?.fieldValues[0],
	)
	const [filterEndDate, setFilterEndDate] = useState<Date | string | undefined>(
		props.preSelectedFilter?.fieldValues[1],
	)
	const [filterBeginRange, setFilterBeginRange] = useState<number | string | undefined>(
		props.preSelectedFilter?.fieldValues[0],
	)
	const [filterEndRange, setFilterEndRange] = useState<number | string | undefined>(
		props.preSelectedFilter?.fieldValues[1],
	)

	const availableCriteria =
		selectedFilterField == null
			? []
			: selectedFilterField.filterCriterias || FilterType[selectedFilterField.filterType]

	const handleColumnChange = (event: any, value: any) => {
		const selectedColumn = props.columns.find(c => c.headerText === value.label)
		setFilterFieldValues(undefined)
		setSelectedFilterField(selectedColumn)
		const availableCriteria =
			selectedColumn == null ? [] : selectedColumn.filterCriterias || FilterType[selectedColumn.filterType]

		if (value.filterType === 'datetime' || value.filterType === 'date') {
			setSelectedCriteria(
				availableCriteria.length == 1
					? availableCriteria[0]
					: localStorage.getItem('date.filter.default.criteria') || SearchCriteria.BETWEEN,
			)
		} else {
			setSelectedCriteria(availableCriteria.length == 1 ? availableCriteria[0] : null)
		}
	}
	const getFirstFieldValue = () => {
		if (filterFieldValues && filterFieldValues.length === 1) {
			if (!filterFieldValues[0].includes('LAST_')) {
				setFilterFieldValues(undefined)
			}
			return filterFieldValues[0]
		}
		return props.preSelectedFilter?.fieldValues[0]
	}
	const getFieldValues = () => {
		if (
			(selectedFilterField?.filterType === 'date' || selectedFilterField?.filterType === 'datetime') &&
			selectedCriteria === SearchCriteria.BETWEEN
		) {
			return [filterStartDate, filterEndDate]
		} else if (selectedFilterField?.filterType === 'number' && selectedCriteria === SearchCriteria.INRANGE) {
			return [filterBeginRange, filterEndRange]
		} else {
			return filterFieldValues || props.preSelectedFilter?.fieldValues || []
		}
	}

	const isAddButtonDisabled = () => {
		if (selectedFilterField?.filterType === 'date' || selectedFilterField?.filterType === 'datetime') {
			if (selectedCriteria === SearchCriteria.BETWEEN) {
				if (!getFieldValues() || !getFieldValues()[0] || !getFieldValues()[1]) {
					return true
				}
				if (moment(getFieldValues()[0]).isAfter(getFieldValues()[1])) {
					return true
				}
				return false
			} else if (selectedCriteria !== SearchCriteria.RELATIVETIME) {
				if (getFieldValues() && getFieldValues()[0] && moment(getFieldValues()[0]).isSameOrBefore(moment())) {
					return false
				}
				return true
			} else {
				return !getFieldValues() || !getFieldValues()[0]
			}
		} else if (selectedFilterField?.filterType === 'number' && selectedCriteria === SearchCriteria.INRANGE) {
			return !getFieldValues() || !getFieldValues()[0] || !getFieldValues()[1]
		} else if (selectedFilterField?.filterType === 'guid') {
			const uuidRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/
			return !uuidRegExp.test(getFieldValues().toString())
		} else {
			return !getFieldValues() || !getFieldValues()[0]
		}
	}

	useEffect(() => {
		props.onValidation && props.onValidation(!isAddButtonDisabled())
		props.onValidation &&
			selectedFilterField?.dateRangeValidatorFn &&
			selectedCriteria &&
			selectedCriteria === SearchCriteria.BETWEEN &&
			props.onValidation(selectedFilterField?.dateRangeValidatorFn(filterStartDate, filterEndDate))
	}, [
		selectedFilterField,
		selectedCriteria,
		filterFieldValues,
		filterStartDate,
		filterEndDate,
		filterBeginRange,
		filterEndRange,
	])

	Utils.setState(props, () => ({
		id: Math.random(),
		fieldName: selectedFilterField?.dataField,
		fieldValues: getFieldValues(),
		searchCriteria: selectedCriteria,
		filterType: selectedFilterField?.filterType,
	}))

	// These functions are required since TextField will only render initial value if timezone portion of date/datetime string is removed
	function formatDateForDatetimeInput(dateString: string | undefined): string | undefined {
		return dateString && moment.parseZone(dateString).format('YYYY-MM-DDTHH:mm:ss')
	}
	function formatDateForDateInput(dateString: string | undefined): string | undefined {
		return dateString && moment.parseZone(dateString).format('YYYY-MM-DD')
	}

	return (
		<VBox verticalGap={10}>
			<Select
				options={props.columns.map(c => ({
					label: c.headerText,
					value: c.dataField,
					filterType: c.filterType,
				}))}
				labelFunction={item => item.label}
				optionFunction={item => item.label}
				onChange={handleColumnChange}
				multiple={false}
				disableSearchable={true}
				disableClearable={true}
				value={selectedFilterField?.headerText}
				label='Column'
			/>
			{availableCriteria.length != 1 && (
				<Select
					options={availableCriteria}
					onChange={(event, value) => {
						const selectedCriteria = Object.values(SearchCriteria).find(sc => sc === value)
						if (selectedFilterField?.filterType === 'datetime') {
							if (selectedCriteria !== undefined) {
								localStorage.setItem('date.filter.default.criteria', selectedCriteria)
							}
							setSelectedCriteria(selectedCriteria)
						} else {
							setSelectedCriteria(selectedCriteria)
						}
					}}
					multiple={false}
					disableSearchable={false}
					disableClearable={true}
					value={selectedCriteria}
					key={selectedCriteria}
					label='Filter Criteria'
				/>
			)}
			{selectedCriteria &&
				selectedFilterField &&
				selectedFilterField.filterType === 'set' &&
				selectedCriteria === SearchCriteria.IN && (
					<Select
						options={selectedFilterField.filterValues || []}
						onChange={(event, value) => {
							setPreselectedFilterValues(value)
							setFilterFieldValues(value)
						}}
						multiple={true}
						disableSearchable={false}
						disableClearable={true}
						value={preselectedFilterValues}
						key={selectedFilterField.dataField}
						label='Filter Criteria'
					/>
				)}
			{selectedCriteria &&
				selectedFilterField &&
				selectedFilterField.filterType === 'set' &&
				selectedCriteria !== SearchCriteria.IN && (
					<Select
						options={selectedFilterField.filterValues || []}
						onChange={(event, value) => {
							setFilterFieldValues([value])
						}}
						multiple={false}
						disableSearchable={false}
						disableClearable={true}
						value={props.preSelectedFilter?.fieldValues[0] || filterFieldValues}
						key={selectedFilterField.dataField}
						label='Filter Criteria'
					/>
				)}
			{selectedCriteria && selectedFilterField && selectedCriteria === SearchCriteria.RELATIVETIME && (
				<Select
					options={Object.values(RelativeTime)}
					onChange={(event, value) => {
						setFilterFieldValues([value])
					}}
					multiple={false}
					disableSearchable={false}
					disableClearable={true}
					value={getFirstFieldValue()}
					key={selectedFilterField.dataField}
					label='Filter Time Frame'
				/>
			)}
			{selectedCriteria && selectedFilterField && selectedFilterField.filterType === 'text' && (
				<Input
					type={'text'}
					disableClose={true}
					value={props.preSelectedFilter?.fieldValues[0] || undefined}
					onChange={event => setFilterFieldValues([event.target.value])}
					label='Filter Value (Text)'
				/>
			)}
			{selectedCriteria && selectedFilterField && selectedFilterField.filterType === 'guid' && (
				<Input
					type={'text'}
					disableClose={true}
					value={props.preSelectedFilter?.fieldValues[0] || undefined}
					onChange={event => setFilterFieldValues([event.target.value])}
					label='Filter Value (guid)'
				/>
			)}
			{selectedCriteria &&
				selectedFilterField &&
				selectedFilterField.filterType === 'number' &&
				selectedCriteria === SearchCriteria.INRANGE && (
					<VBox>
						<Input
							type={'number'}
							disableClose={true}
							value={props.preSelectedFilter?.fieldValues[0]}
							onChange={event => setFilterBeginRange(event.target.value)}
							label='Filter Value (Number) - Begin'
						/>
						<Input
							type={'number'}
							disableClose={true}
							value={props.preSelectedFilter?.fieldValues[1]}
							onChange={event => setFilterEndRange(event.target.value)}
							label='Filter Value (Number) - End'
						/>
					</VBox>
				)}
			{selectedCriteria &&
				selectedFilterField &&
				selectedFilterField.filterType === 'number' &&
				selectedCriteria !== SearchCriteria.INRANGE && (
					<Input
						type={'number'}
						disableClose={true}
						value={props.preSelectedFilter?.fieldValues[0]}
						onChange={event => setFilterFieldValues([event.target.value])}
						label='Filter Value (Number)'
					/>
				)}
			{selectedCriteria &&
				selectedFilterField &&
				selectedFilterField.filterType === 'date' &&
				selectedCriteria === SearchCriteria.BETWEEN && (
					<VBox>
						<Input
							type={'date'}
							disableClose={true}
							onChange={event => setFilterStartDate(event.target.value)}
							label='Start Date'
							value={formatDateForDateInput(props.preSelectedFilter?.fieldValues[0])}
							InputLabelProps={{
								shrink: true,
								focused: true,
							}}
						/>
						<Input
							type={'date'}
							disableClose={true}
							onChange={event => setFilterEndDate(event.target.value)}
							label='End Date'
							value={formatDateForDateInput(props.preSelectedFilter?.fieldValues[1])}
							InputLabelProps={{
								shrink: true,
								focused: true,
							}}
						/>
					</VBox>
				)}
			{selectedCriteria &&
				selectedFilterField &&
				selectedFilterField.filterType === 'date' &&
				selectedCriteria !== SearchCriteria.BETWEEN &&
				selectedCriteria !== SearchCriteria.RELATIVETIME && (
					<Input
						type={'date'}
						disableClose={true}
						onChange={event => setFilterFieldValues([event.target.value])}
						label='Date'
						value={formatDateForDateInput(props.preSelectedFilter?.fieldValues[0])}
						InputLabelProps={{
							shrink: true,
							focused: true,
						}}
					/>
				)}
			{selectedCriteria &&
				selectedFilterField &&
				selectedFilterField.filterType === 'datetime' &&
				selectedCriteria === SearchCriteria.BETWEEN && (
					<VBox>
						<Input
							type={'datetime-local'}
							disableClose={true}
							onChange={(event, value) => {
								const datetime = appendTimeZoneToDatetime(value, Application.timezone).format()
								setFilterStartDate(datetime)
							}}
							label='Start Date'
							value={formatDateForDatetimeInput(props.preSelectedFilter?.fieldValues[0])}
							InputLabelProps={{
								shrink: true,
								focused: true,
							}}
						/>
						<Input
							type={'datetime-local'}
							disableClose={true}
							onChange={(event, value) => {
								const datetime = appendTimeZoneToDatetime(value, Application.timezone).format()
								setFilterEndDate(datetime)
							}}
							label='End Date'
							value={formatDateForDatetimeInput(props.preSelectedFilter?.fieldValues[1])}
							containerstyle={{marginTop: 10}}
							InputLabelProps={{
								shrink: true,
								focused: true,
							}}
						/>
					</VBox>
				)}
			{selectedCriteria &&
				selectedFilterField &&
				selectedFilterField.filterType === 'datetime' &&
				selectedCriteria !== SearchCriteria.BETWEEN &&
				selectedCriteria !== SearchCriteria.RELATIVETIME && (
					<Input
						type={'datetime-local'}
						disableClose={true}
						onChange={(event, value) => {
							const datetime = appendTimeZoneToDatetime(value, Application.timezone).format()
							setFilterFieldValues([datetime])
						}}
						label='Date Time'
						value={formatDateForDatetimeInput(props.preSelectedFilter?.fieldValues[0])}
						InputLabelProps={{
							shrink: true,
							focused: true,
						}}
					/>
				)}
		</VBox>
	)
}
