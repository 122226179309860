import React from 'react'
import MUIFormControlLabel, {
	FormControlLabelProps as MUIFormControlLabelProps,
} from '@material-ui/core/FormControlLabel'
import {makeStyles} from '@material-ui/core/styles'
import {color} from 'src/color/color'

const useStyles = makeStyles(() => ({
	label: {
		fontFamily: 'BrownPro',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '130.02%',
		color: color.grey700,
	},
	disabled: {
		color: color.grey100,
	},
}))

export const FormControlLabel: React.FC<MUIFormControlLabelProps> = (props: MUIFormControlLabelProps) => {
	const classes = useStyles()

	return <MUIFormControlLabel classes={{label: classes.label, disabled: classes.disabled}} {...props} />
}
