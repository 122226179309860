import {color, Link as BakktLink, LinkProps as BakktLinkProps} from '@bakkt/components'
import {CSSProperties} from 'react'
import {Link as ReactRouterLink} from 'react-router-dom'
import {handleKeyPress} from 'utils/handleKeyPress'

export type LinkProps = {
	onEvent?: () => void
	withTextDecoration?: boolean
} & BakktLinkProps

export const Link = ({children, onEvent = () => {}, withTextDecoration, ...rest}: LinkProps) => (
	<BakktLink
		style={{
			textDecoration: withTextDecoration ? 'underline' : 'none',
			fontWeight: 'bold',
			paddingLeft: '2px',
			paddingRight: '2px',
		}}
		onClick={() => onEvent()}
		onKeyPress={event => handleKeyPress(event, onEvent())}
		tabIndex={0}
		{...rest}
	>
		{children}
	</BakktLink>
)

interface RouterLinkProps {
	id?: string
	to: string
	ariaLabel?: string
	text: string
	style?: CSSProperties
}

export const RouterLink = ({id, to, ariaLabel, text, style}: RouterLinkProps) => (
	<ReactRouterLink
		id={id}
		to={to}
		aria-label={ariaLabel}
		style={{
			marginTop: '28px',
			marginBottom: '5px',
			color: color.blue700,
			textDecoration: 'none',
			fontSize: '16px',
			...style,
		}}
	>
		{text}
	</ReactRouterLink>
)
