import React from 'react'
import Chip, {ChipProps} from '@material-ui/core/Chip'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {color} from 'src/color/color'

export interface BadgeProps extends ChipProps {
	backgroundColor?: string
	textColor?: string
	maxWidth?: string
	closable?: boolean
}

// eslint-disable-next-line
const styles: {[key: string]: any} = {
	default: {
		color: color.blue900,
	},
}

interface StyleProps {
	textColor: string
}

const useStyles = makeStyles<Theme, BadgeProps>({
	root: {
		boxShadow: '0px 5px 18px rgba(15, 43, 41, 0.15)',
		'& .MuiChip-label': {
			...styles['default'],
			fontFamily: 'BrownPro',
			fontSize: '16px',
			fontStyle: 'normal',
			fontWeight: 400,
			color: props => props.textColor || color.blue900,
		},
		'& .MuiChip-deleteIcon': {
			color: color.grey200,
		},
	},
})

export const Badge: React.FC<BadgeProps> = (props: BadgeProps) => {
	const {backgroundColor = color.white, textColor = color.blue900, style, maxWidth, ...rest} = props
	const classes = useStyles(props)

	return (
		<Chip
			classes={{root: classes.root}}
			icon={props.icon}
			{...rest}
			style={{...style, maxWidth, backgroundColor: backgroundColor}}
		/>
	)
}
