import {BottomBox, color, Button, H1, HBox, Input, Main, Separator, VBox, Alert} from '@bakkt/components'
import Page from 'components/Page'
import {useCallback, useState} from 'react'

import {embeddedBakktAuthApi} from 'api'
import {RoutesName} from 'constants/routes'
import {useUserId} from 'hooks/auth/useUserId'
import {useNavigate} from 'react-router-dom'
import {useStore} from 'store'
import {cleanEmail, cleanPhoneNumber} from 'validate'

const ResetPassword = () => {
	const [userId, setUserId] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [isValidIdentifier, setIsValidIdentifier] = useState(true)
	const navigate = useNavigate()
	const {setIdentifier, setUserIdentity} = useStore()

	const handleChangeUserId = useCallback((event: {target: {value: string}}) => {
		setUserId(cleanEmail(event.target.value))
	}, [])

	const {isMobileNumber, isValid: isUserIdValid, error: userIdError} = useUserId(userId)

	const handleSubmit = async () => {
		const response = await embeddedBakktAuthApi.resetPassword({
			identifier: {
				email: !isMobileNumber ? userId : undefined,
				phoneNumber: isMobileNumber ? cleanPhoneNumber(userId) : undefined,
			},
		})
		if (response?.data?.success) {
			setIdentifier(response.data.payload)
			setUserIdentity(userId)
			navigate(`/${RoutesName.auth.verifyOtp}`)
		} else if (response?.data.error?.code === 'INVALID_FIELD_VALUE') {
			setIsValidIdentifier(false)
			setErrorMessage(response?.data.error?.message!)
		} else {
			navigate(`/${RoutesName.auth.verifyOtp}`)
		}
	}
	const handleCancel = () => {
		setIdentifier(undefined)
		navigate(`/${RoutesName.auth.login}`)
	}

	return (
		<Page withBackIcon titleHeader='Verify your identity'>
			{!isValidIdentifier && (
				<Alert
					severity='error'
					icon={false}
					onClose={() => setIsValidIdentifier(true)}
					data-testid='signin-error-alert'
				>
					{errorMessage}
				</Alert>
			)}
			<Main className={'boxMain'}>
				<VBox verticalGap={15}>
					<HBox style={{margin: 'auto'}}>
						<H1>Verify your identity with your email or mobile number</H1>
					</HBox>
					<Input
						helperText={userIdError}
						type={'text'}
						error={!!userIdError}
						variant='filled'
						label='Mobile or Email'
						value={userId}
						onChange={handleChangeUserId}
					/>
					<div style={{height: 30}} />
				</VBox>
			</Main>
			<BottomBox>
				<Button
					fullWidth
					style={{maxWidth: 'none'}}
					disabled={!isUserIdValid || !!userIdError}
					size='large'
					onClick={handleSubmit}
				>
					Continue
				</Button>
				<Button intent='link' onClick={handleCancel}>
					Cancel
				</Button>
			</BottomBox>
		</Page>
	)
}

export default ResetPassword
