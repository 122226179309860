import {
	initializeAnalytics,
	Analytics as AnalyticsType,
	setUserProperties as setAnalyticUserProperties,
} from 'firebase/analytics'
import {AnalyticsProperties} from './analyticsProperties'
import Firebase from './firebase'

export default class Analytics {
	static analytics: AnalyticsType

	static getAnalytics = () => {
		if (!this.analytics) this.initializeAnalytics()
		return this.analytics
	}

	static initializeAnalytics = () => {
		this.analytics = initializeAnalytics(Firebase.getApp())
	}
}

export const setUserProperties = (properties: Partial<AnalyticsProperties>) => {
	setAnalyticUserProperties(Analytics.getAnalytics(), properties)
}
