import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import ReactDOM from 'react-dom'

export default forwardRef((props: {filterChangedCallback: () => void; valueGetter: (props: any) => string}, ref) => {
	const [text, setText] = useState('')
	const refInput = useRef(null)

	useEffect(() => {
		props.filterChangedCallback()
	}, [text])

	useImperativeHandle(ref, () => {
		return {
			isFilterActive() {
				return text != null && text !== ''
			},

			doesFilterPass(params: any) {
				return text
					.toLowerCase()
					.split(' ')
					.every(
						filterWord => props.valueGetter(params.node).toString().toLowerCase().indexOf(filterWord) >= 0,
					)
			},

			getModel() {
				return {value: text}
			},

			setModel(model: any) {
				setText(model ? model.value : '')
			},

			afterGuiAttached(params: any) {
				focus()
			},

			componentMethod(message: any) {
				alert(`Alert from PartialMatchFilterComponent: ${message}`)
			},
		}
	})

	const focus = () => {
		// window.setTimeout(() => {
		// 	const container: any = ReactDOM.findDOMNode(refInput.current)
		// 	if (container) {
		// 		container.focus()
		// 	}
		// })
	}

	const onChange = (event: any) => {
		const newValue = event.target.value
		if (text !== newValue) {
			setText(newValue)
		}
	}

	const style = {
		border: '2px solid #22ff22',
		borderRadius: '5px',
		backgroundColor: '#bbffbb',
		width: '200px',
		height: '50px',
	}

	return (
		<div style={style}>
			Filter:
			<input style={{height: '20px'}} ref={refInput} value={text} onChange={onChange} className='form-control' />
		</div>
	)
})
