import {useEffect} from 'react'
import {getEnv} from 'utils/config'
import {useGetTrackingId} from './useGetTrackingId'

export const useSift = (siftPartyRef?: string) => {
	const xTrackingId = useGetTrackingId()

	useEffect(() => {
		const REACT_APP_SIFT_BEACON_KEY = getEnv('SIFT_BEACON_KEY')
		const configSift = () => {
			const loader = document.createElement('script')
			loader.type = 'text/javascript'
			loader.async = true
			loader.src = 'https://cdn.sift.com/s.js'
			loader.onload = function () {
				const sift = (window._sift = window._sift || [])
				sift.push(['_setAccount', REACT_APP_SIFT_BEACON_KEY])
				sift.push(['_setUserId', siftPartyRef])
				sift.push(['_setSessionId', xTrackingId])
				sift.push(['_trackPageview'])
			}
			const scriptTag: HTMLScriptElement = document.getElementsByTagName('script')[0]
			scriptTag?.parentNode?.insertBefore(loader, scriptTag)
		}
		if (siftPartyRef) {
			configSift()
		}
	}, [siftPartyRef, xTrackingId])
}
