import {Button as BakktButton, ButtonProps as BakktButtonProps, color} from '@bakkt/components'
import {handleKeyPress} from 'utils/handleKeyPress'

type ButtonProps = {
	onAction?: () => void
} & BakktButtonProps

const Button = ({onAction, children, ...props}: ButtonProps) => (
	<BakktButton
		fullWidth
		size='large'
		onClick={onAction}
		onKeyPress={event => handleKeyPress(event, onAction)}
		{...props}
	>
		{children}
	</BakktButton>
)

export default Button
