import React, {CSSProperties, useMemo} from 'react'
import {H1} from './Text'

export type BreakPointOptions = {threshold: number; style: CSSProperties}
export const DEFAULT_BREAK_POINTS = [
	{threshold: 5, style: {fontSize: 48, fontWeight: 700}},
	{threshold: 6, style: {fontSize: 40, fontWeight: 700}},
	{threshold: 9, style: {fontSize: 40, fontWeight: 700}},
	{threshold: 10, style: {fontSize: 32, fontWeight: 700}},
]

type ResponsiveHeaderProps = {
	value: string
	id?: string
	style?: CSSProperties
	breakPoints?: BreakPointOptions[]
}

export const responsiveStyler = (value: string, breakPoints?: BreakPointOptions[]) => {
	if (!breakPoints || breakPoints.length === 0) return {}

	if (value) {
		for (const i in breakPoints) {
			if (value.length <= breakPoints[i].threshold) {
				return breakPoints[i].style
			}
		}
	}

	return breakPoints[breakPoints.length - 1].style || {}
}

export const ResponsiveHeader = ({id, value, style, breakPoints = DEFAULT_BREAK_POINTS}: ResponsiveHeaderProps) => {
	const responsiveStyle = useMemo(() => responsiveStyler(value, breakPoints), [value, breakPoints])

	return (
		<H1 id={id} style={{...style, ...responsiveStyle}}>
			{value}
		</H1>
	)
}
