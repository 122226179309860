import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Page as BakktPage, VBox} from '@bakkt/components'
import {PageProps as BakktPageProps} from '@bakkt/components/types/components/layout/Page'
import Footer from './Footer'
import {useStore} from '../store'
import ToastManager from './ToastManager'

export type PageProps = {
	title?: string
} & BakktPageProps

const Page = ({children, title, titleHeader, ...props}: PageProps) => {
	const onMobileScreen = useMediaQuery('(max-width:719px)')
	const {partnerPartyLink} = useStore()
	const isLoggedIn = partnerPartyLink ? true : false

	const getTitle = () => {
		if (title) {
			return `Bakkt - ${title}`
		} else if (titleHeader) {
			return `Bakkt - ${titleHeader}`
		} else {
			return 'Bakkt'
		}
	}

	return (
		<BakktPage
			title={getTitle()}
			titleHeader={titleHeader}
			{...(!onMobileScreen && isLoggedIn && {Footer: Footer})}
			{...props}
		>
			<VBox style={{marginBottom: '15px'}}>
				<ToastManager />
			</VBox>
			{children}
		</BakktPage>
	)
}

export default Page
