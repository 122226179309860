import * as ag from 'ag-grid-community'
import Utils from '../../utils/Utils'
export default class CSVExport {
	api: ag.GridApi
	columnApi: ag.ColumnApi
	model: ag.IDatasource
	exportHidden: boolean

	constructor(gridInput: {api: ag.GridApi; columnApi: ag.ColumnApi; model: ag.IDatasource}, exportHidden?: boolean) {
		this.api = gridInput.api
		this.columnApi = gridInput.columnApi
		this.model = gridInput.model
		this.exportHidden = exportHidden || false
	}

	async exportTo(fileName: string) {
		const content = await this.exportRows()
		Utils.downloadFile(fileName, content, 'text/csv')
	}

	exportRows(): Promise<string> {
		return new Promise((resolve, reject) => {
			this.model.getRows({
				startRow: 0,
				endRow: 1000000,
				sortModel: this.api.getSortModel(),
				filterModel: this.api.getFilterModel(),
				successCallback: this.onDataLoadForExport.bind(this, resolve),
				context: null,
				failCallback: () => {
					reject(new Error('Getting rows'))
				},
			})
		})
	}

	onDataLoadForExport(resolve: (data: string) => void, rows: any[]) {
		const fields = this.columnApi
			.getAllGridColumns()
			.filter(c => (this.exportHidden ? true : c.isVisible()))
			.map(c => ({
				dataField: c.getColId(),
				headerText: c.getColDef().headerName,
				valueGetter: (typeof c.getColDef().valueGetter !== 'string'
					? c.getColDef().valueGetter
					: null) as (params: {data: any}) => string,
			}))
		const exportData = [
			fields.map(c => c.headerText),
			...rows.map(row =>
				fields.map(c => ((c.valueGetter as any) ? c.valueGetter({data: row}) : row[c.dataField])),
			),
		]
		const data = exportData
			.map(row =>
				row
					.map(c => '' + (c || ''))
					.map(c => `"${c.replace(/"/g, '""')}"`)
					.join(','),
			)
			.join('\n')
		resolve(data)
	}
}
