import React, {FC, ReactNode, HTMLAttributes} from 'react'
import styled from '@emotion/styled'
import {color} from 'src/color/color'

export interface ListItemProps extends HTMLAttributes<HTMLDivElement> {
	id: string
	primaryText: string
	secondaryTextTop?: string
	secondaryTextBottom?: string
	icon?: ReactNode | string
	contentRight?: ReactNode | string
	contentRightProps?: HTMLAttributes<HTMLDivElement>
	disabled?: boolean
}

const Container = styled.div`
	display: flex;
	align-items: center;
	padding: 12px;
	font-family: ${props => props?.theme?.typography?.fontFamily || 'BrownPro'};
	font-style: normal;
	font-weight: 400;
	color: ${props => props.theme?.palette?.text?.background || color.grey700};
`

const IconContainer = styled.div`
	margin-right: 8px;
`

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
`

const PrimaryText = styled.span`
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0em;
`

const SecondaryTextTop = styled.span`
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.0025em;
`

const SecondaryTextBottom = styled.span`
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.0025em;
`

const RightItemsContainer = styled.div`
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: right;
	margin-left: auto;
`

export const ListItem: FC<ListItemProps> = (props: ListItemProps) => {
	const {primaryText, secondaryTextTop, secondaryTextBottom, icon, contentRight} = props
	const dataTestId = primaryText.toLowerCase().replace(/\s/g, '-')
	return (
		<Container {...props}>
			{icon && <IconContainer>{icon}</IconContainer>}
			<TextContainer>
				{secondaryTextTop && (
					<SecondaryTextTop data-testid={`${dataTestId}-secondary-text-top`}>
						{secondaryTextTop}
					</SecondaryTextTop>
				)}
				<PrimaryText data-testid={`${dataTestId}-primary-text`}>{primaryText}</PrimaryText>
				{secondaryTextBottom && (
					<SecondaryTextBottom data-testid={`${dataTestId}-secondary-text-bottom`}>
						{secondaryTextBottom}
					</SecondaryTextBottom>
				)}
			</TextContainer>
			{contentRight && (
				<RightItemsContainer data-testid={`${dataTestId}-right-content`} {...props.contentRightProps}>
					{contentRight}
				</RightItemsContainer>
			)}
		</Container>
	)
}
