import React, {useState} from 'react'
import Page from 'components/Page'
import {BottomBox, Checkbox, Light, Button, InlineBtn} from '@bakkt/components'
import {useTranslation} from 'react-i18next'
import {displayBakktTermsOfUseDialog, displayPrivacyPolicyDialog} from '../../onboarding/pages/Consent'
import {useNavigate} from 'react-router-dom'
import {RoutesName} from 'constants/routes'
import {showErrorDialog} from 'components/ErrorDialog'
import {useStore} from 'store'
import {embeddedPartnerApi} from 'api'

export const TermsAndConditionsAcceptance = () => {
	const [termsAccepted, setTermsAccepted] = useState(false)
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {loadParty} = useStore()

	const handleTermsAndConditionsAccepted = () => {
		updateTermsAndConditions()
	}

	const updateTermsAndConditions = async () => {
		const response = await embeddedPartnerApi.updatePartyTermsAndConditions()

		if (response.data.success) {
			await loadParty()
			navigate(RoutesName.home)
		} else {
			return showErrorDialog({
				message: t('errors.t&cAcceptanceError'),
				errorCode: response?.data?.error?.code,
			})
		}
	}

	return (
		<Page titleHeader={'Terms of Service'} loading={false}>
			<div style={{margin: 'auto'}}>
				<Checkbox
					checked={termsAccepted}
					inputProps={{
						'aria-label':
							'I am at least 18 years old and agree to Bakkt’s Terms of Service and Privacy Policy.',
					}}
					onChange={() => {
						setTermsAccepted(!termsAccepted)
					}}
					label={
						<Light style={{textAlign: 'left'}}>
							{`${t('crypto.accountActivate.enough18')} `}
							<InlineBtn
								style={{fontSize: '14px', textDecoration: 'underline'}}
								data-testid={'terms-of-use-link'}
								aria-label='Terms of Use'
								onClick={() => {
									displayBakktTermsOfUseDialog()
								}}
							>
								{t('more.termsOfUse')}
							</InlineBtn>
							{' and '}
							<InlineBtn
								style={{fontSize: '14px', textDecoration: 'underline'}}
								data-testid={'privacy-policy-link'}
								aria-label='Privacy Policy'
								onClick={() => displayPrivacyPolicyDialog()}
							>
								{t('more.privacyPolicy')}
							</InlineBtn>
							.
						</Light>
					}
					name='accept-terms'
					className={'checkbox'}
				/>
			</div>
			<BottomBox>
				<Button disabled={!termsAccepted} onClick={handleTermsAndConditionsAccepted}>
					Continue
				</Button>
			</BottomBox>
			)
		</Page>
	)
}
