import {parsePhoneNumber} from 'libphonenumber-js'

// Email Validation - Regex from https://emailregex.com/
const emailRegex =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const isValidEmail = (email: string) => emailRegex.test(email)
export const filterMobile = (mobile: string, national = false) => {
	try {
		const phoneNumberProto = parsePhoneNumber(mobile, 'US')
		return national ? `${phoneNumberProto.nationalNumber}` : `${phoneNumberProto.number}`
	} catch (e) {
		return ''
	}
}
const mobileRegex = /^\+?[0-9]+$/
export const isMobileCharactersValid = (mobile: string) => {
	const mobileStripped = mobile?.replace(/\(|\)|\s|-/g, '')
	return mobileRegex.test(mobileStripped)
}

export const isValidMobile = (mobile: string) => {
	if (isMobileCharactersValid(mobile)) {
		const filteredMobile = filterMobile(mobile)
		return filteredMobile.length === 12 && filteredMobile[0] === '+'
	}
	return false
}

export const cleanPhoneNumber = (phoneNumber: string) => parsePhoneNumber(phoneNumber, 'US').nationalNumber

export const cleanEmail = (email: string) => (!email?.length ? '' : email.trim())

// Bakkt ID Validation
export const bakktIDRegex = /^[a-z0-9_]+$/
const bakktIDCaseInsensitiveRegex = /^[a-zA-Z0-9_]+$/
export const bakktNameRegx = /^[a-zA-Z0-9 ]{2,32}$/
export const zipCodeRegx = /(^\d{5}$)|(^\d{5}-\d{4}$)/
export const isBakktIdCharactersValid = (id: string) => bakktIDRegex.test(id)
export const isBakktIdCharactersValidCaseInsensitive = (id: string) => bakktIDCaseInsensitiveRegex.test(id)
export const isBakktIdLengthValid = (id: string) => id.length >= 8 && id.length <= 16
export const isBakktIdValid = (id: string) => isBakktIdCharactersValid(id) && isBakktIdLengthValid(id)
export const isValidName = (id: string) => bakktNameRegx.test(id)
export const isValidZipCode = (id: string) => zipCodeRegx.test(id)

export const isValidUserId = (userId: string, isMobile: boolean) => {
	if (userId?.length === 0) {
		return false
	}
	return isMobile ? isValidMobile(userId) : isValidEmail(userId)
}

export const isValidOTP = (otp?: string) => {
	return otp && otp.length === 6 && /^\d+$/.test(otp)
}

const passwordCharactersRegex = /[\w@$#%^&*!\-+=\[\]{}|\\:`’,.?\/~“();]/
export const isPasswordCharactersValid = (password: string) => passwordCharactersRegex.test(password)

const MIN_PASSWORD_LENGTH = 10
const MAX_PASSWORD_LENGTH = 32

export const validatePassword = (text: string) => {
	let valid = false
	let error = ''

	if (!isPasswordCharactersValid(text)) {
		error = 'Invalid password'
	} else if (text.length < MIN_PASSWORD_LENGTH) {
		error = 'Minimum 10 characters'
	} else if (text.length > MAX_PASSWORD_LENGTH) {
		error = 'Maximum 32 characters'
	} else {
		error = ''
		valid = true
	}

	return {
		valid,
		error,
	}
}
