import {useState, useEffect} from 'react'
import RemoteConfig from 'utils/firebase/remoteConfig'
import {getValue, fetchAndActivate} from 'firebase/remote-config'
import {
	RemoteConfigDataType,
	RemoteConfigFlags,
	RemoteConfigValues,
	RemoteConfigValueTypes,
} from 'utils/firebase/remoteConfigProperties'

export const useRemoteConfigValue = <Key extends RemoteConfigFlags>(key: Key) => {
	const [result, setResult] = useState<RemoteConfigValueTypes[Key]>()

	const getResult = () => {
		const remoteConfig = RemoteConfig.getRemoteConfig()

		if (!RemoteConfig.activated)
			return fetchAndActivate(remoteConfig).finally(() => {
				RemoteConfig.activated = true
				getResult()
			})

		const value = getValue(remoteConfig, key)
		switch (RemoteConfigValues[key]) {
			case RemoteConfigDataType.BOOLEAN:
				return setResult(value.asBoolean() as RemoteConfigValueTypes[Key])
			case RemoteConfigDataType.NUMBER:
				return setResult(value.asNumber() as RemoteConfigValueTypes[Key])
			case RemoteConfigDataType.JSON:
				const stringResult = value.asString()
				return setResult(stringResult ? JSON.parse(stringResult) : stringResult)
			case RemoteConfigDataType.STRING:
			default:
				return setResult(value.asString() as RemoteConfigValueTypes[Key])
		}
	}

	useEffect(() => {
		getResult()
	}, [])

	return result
}
