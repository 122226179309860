import React, {ReactNode, CSSProperties} from 'react'
import {Value} from '../value'
import {Label} from '../label/Label'
import {GridColumn} from './GridColumn'
import {color} from 'src/color/color'
import {Menu} from '../menu/Menu'
import {MenuItem} from '../menu/MenuItem'
import {CellContextMenuEvent} from 'ag-grid-community'
import {DataGrid} from './DataGrid'
import Tooltip from '@material-ui/core/Tooltip'
import {withStyles} from '@material-ui/core/styles'

interface Field {
	value?: string
	content?: ReactNode
	keyContent?: ReactNode
}

const FullWidthRendererTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: `${color.white}`,
		color: `${color.blackLight}`,
		boxShadow: `3px 2px 10px rgba(0,0,0,0.5)`,
		fontSize: 14,
		maxWidth: 'none',
		borderRadius: 0,
		fontWeight: 400,
		fontFamily: 'BrownPro',
		padding: '.5rem',
		margin: 0,
		WebkitFontSmoothing: 'antialiased',
	},
}))(Tooltip)

export interface IColumnLayoutDetailViewProps {
	column1?: {[key: string]: Field}
	column2?: {[key: string]: Field}
	column3?: {[key: string]: Field}
	fullWidthRows?: {[key: string]: Field}
	isBottomBorder?: boolean
}
function renderUsersMenus(e: CellContextMenuEvent): JSX.Element {
	return (
		<Menu>
			<MenuItem
				onClick={() => {
					if (e?.value?.value) {
						navigator.clipboard?.writeText(e?.value?.value)
					} else if (e?.value) {
						navigator.clipboard?.writeText(e?.value)
					} else if (e?.data?.fullWidthRowValue?.value) {
						navigator.clipboard?.writeText(e?.data?.fullWidthRowValue?.value)
					}
				}}
			>
				Copy
			</MenuItem>
		</Menu>
	)
}
const cellRendererTextOverflowStyle: CSSProperties = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
}
const findKeyContent = (params: any) => {
	if (params.value) {
		if (params.data.column1 === params.value) {
			return params.data.column1Value?.keyContent || <Label>{params.value}</Label>
		} else if (params.data.column2 === params.value) {
			return params.data.column2Value?.keyContent || <Label>{params.value}</Label>
		} else if (params.data.column3 === params.value) {
			return params.data.column3Value?.keyContent || <Label>{params.value}</Label>
		}
	}
	return <Label>{params.value}</Label>
}
const cellRendererKey = (params: any) => {
	return <div style={{...cellRendererTextOverflowStyle}}>{findKeyContent(params)}</div>
}
const cellRendererValue = (params: any) => {
	return (
		<div style={{...cellRendererTextOverflowStyle}}>
			{(params && params.value && params.value?.content) ||
				(params.value && (
					<Value>
						{params.value?.value === '' ||
						params.value?.value === 'undefined' ||
						params.value?.value === 'null'
							? '--'
							: params.value?.value}
					</Value>
				))}
		</div>
	)
}
const fullWidthCellRenderer = (params: any) => {
	const firstColumnWidth = params?.columnApi?.getColumn('column1')?.getActualWidth()
	return (
		<div>
			<FullWidthRendererTooltip title={<span>{params?.data?.fullWidthRowKey}</span>} placement='bottom'>
				<div
					style={{left: 0, paddingLeft: '10px', width: firstColumnWidth, ...cellRendererTextOverflowStyle}}
					className='ag-cell ag-cell-not-inline-editing ag-cell-auto-height ag-cell-value'
				>
					<Label>{params?.data?.fullWidthRowKey}</Label>
				</div>
			</FullWidthRendererTooltip>
			<FullWidthRendererTooltip
				title={<span className='ag-column-hover'>{params?.data?.fullWidthRowValue?.value}</span>}
				placement='bottom'
			>
				<div
					style={{left: firstColumnWidth}}
					className='ag-cell ag-cell-not-inline-editing ag-cell-auto-height'
				>
					{(params?.data?.fullWidthRowValue && params?.data?.fullWidthRowValue?.content) ||
						(params?.data?.fullWidthRowValue && (
							<Value>
								{params?.data?.fullWidthRowKey === null || params?.data?.fullWidthRowKey === undefined
									? ''
									: 	params?.data?.fullWidthRowValue?.value === '' ||
										params?.data?.fullWidthRowValue?.value === 'null' ||
										params?.data?.fullWidthRowValue?.value === 'undefined'
									? '--'
									: params?.data?.fullWidthRowValue?.value}
							</Value>
						))}
				</div>
			</FullWidthRendererTooltip>
		</div>
	)
}
export const ColumnLayoutDetailView = (props: IColumnLayoutDetailViewProps) => {
	const {column1, column2, column3, fullWidthRows, isBottomBorder = false} = props
	const keys = (group: {[key: string]: Field} | undefined) => {
		if (!group) return []
		return Object.keys(group).filter(key => group[key])
	}
	const column1Keys = keys(column1)
	const column2Keys = keys(column2)
	const column3Keys = keys(column3)
	const fullWidthRowsKeys = keys(fullWidthRows)
	const columnWithMaxRows = [column1Keys, column2Keys, column3Keys]
		.sort((g1, g2) => g2.length - g1.length)
		.find(arr => true)

	const columnKeysRowLevelGrouping = columnWithMaxRows
		? columnWithMaxRows.map((g, index) => ({
				column1: index < column1Keys.length ? column1Keys[index] : null,
				column1Value: index < column1Keys.length ? column1 && column1[column1Keys[index]] : null,
				column2: index < column2Keys.length ? column2Keys[index] : null,
				column2Value: index < column2Keys.length ? column2 && column2[column2Keys[index]] : null,
				column3: index < column3Keys.length ? column3Keys[index] : null,
				column3Value: index < column3Keys.length ? column3 && column3[column3Keys[index]] : null,
			}))
		: []
	const rows = [
		...columnKeysRowLevelGrouping,
		...fullWidthRowsKeys.map(d => ({fullWidthRowKey: d, fullWidthRowValue: fullWidthRows && fullWidthRows[d]})),
	]

	return (
		<DataGrid
			domLayout='autoHeight'
			suppressRowHoverHighlight
			suppressHorizontalScroll
			suppressRowClickSelection
			suppressCellSelection
			headerHeight={0}
			hideQuickFilter
			hideFilterConfiguration
			hideShadow
			hideHeaderBottomBorder
			fullWidthCellRendererFramework={fullWidthCellRenderer}
			isFullWidthCell={rowNode => rowNode?.data?.fullWidthRowKey != null}
			getRowStyle={(params: any) =>
				params?.node?.rowIndex == params?.api?.rowModel?.rowsToDisplay?.length - 1 ? {borderBottom: 'none'} : {}
			}
			rowData={rows}
			onGridSizeChanged={e => {
				e?.api?.sizeColumnsToFit()
				const popOverState = localStorage.getItem('idPopOverState')
				const params = {
					rowNodes: e?.api?.getRenderedNodes()?.filter((n: any) => n?.data?.fullWidthRowKey != null),
				}
				if (popOverState === 'false') {
					e?.api?.redrawRows(params)
				}
			}}
			defaultColDef={{
				tooltipValueGetter: params => (params?.value ? `${params.value}` : ''),
			}}
			style={{
				width: '100%',
				padding: 0,
				transform: 'translateY(-1px)',
				borderBottom: isBottomBorder ? `5px solid ${color.grey100}` : 'none',
			}}
			renderPopupMenu={renderUsersMenus}
			align='left'
		>
			<GridColumn
				minWidth={150}
				width={150}
				field={'column1'}
				cellRendererFramework={(params: any) => cellRendererKey(params)}
				cellStyle={{textAlign: 'left', paddingLeft: '10px', marginLeft: 0}}
			/>
			<GridColumn
				flex={1}
				field={'column1Value'}
				cellRendererFramework={(params: any) => cellRendererValue(params)}
				tooltipValueGetter={params => params.value?.value}
			/>
			<GridColumn
				minWidth={130}
				width={130}
				field={'column2'}
				cellRendererFramework={(params: any) => cellRendererKey(params)}
			/>
			<GridColumn
				flex={1}
				field={'column2Value'}
				cellRendererFramework={(params: any) => cellRendererValue(params)}
				tooltipValueGetter={params => params.value?.value}
			/>
			<GridColumn
				minWidth={110}
				width={110}
				field={'column3'}
				cellRendererFramework={(params: any) => cellRendererKey(params)}
			/>
			<GridColumn
				flex={1}
				field={'column3Value'}
				cellRendererFramework={(params: any) => cellRendererValue(params)}
				tooltipValueGetter={params => params.value?.value}
			/>
		</DataGrid>
	)
}
