import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {H3, Large, BottomBox, Main} from '@bakkt/components'
import Button from 'components/Button'
import Page from 'components/Page'
import {RoundLockIcon} from 'components/icons/RoundLockIcon'
import {useStore} from 'store'
import {logger} from 'utils/logger/datadogInit'
import {returnToPartner} from 'utils/returnToPartner'

export const HawaiiStateErrorPage = () => {
	const {t} = useTranslation()
	const {loadOnStartup, partner} = useStore()

	useEffect(() => {
		logger.info('Hawaii state error page - initilized', {
			filename: 'HawaiiStateError.tsx',
			message: t('crypto.accountActivate.comingSoon'),
		})
	}, [])

	const handleClick = () => {
		logger.info('Hawaii state error page - return partner button clicked', {call: 'loadOnStartup'})
		loadOnStartup()
		returnToPartner(true, 'bakkt_bank_link_failure', partner)
	}

	return (
		<Page titleHeader={t('auth.getStarted')} withSubHeaderLogo={false}>
			<Main style={{flexGrow: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
				<div style={{position: 'relative', margin: 0, display: 'flex', justifyContent: 'center'}}>
					<RoundLockIcon height='104px' width='104px' />
				</div>
				<div style={{textAlign: 'center', margin: '26px 0px 0px 0px', maxWidth: '100%', paddingBottom: '1em'}}>
					<H3 style={{marginBottom: '0px', marginTop: '0px'}}>{t('crypto.accountActivate.comingSoon')}</H3>
					<Large style={{marginTop: '0.5em', marginBottom: '36px'}}>
						{t('crypto.accountActivate.hawaiiStateMessage', {partnerName: partner?.name})}
					</Large>
				</div>
			</Main>
			<BottomBox className='activationSuccessBottomBox' style={{paddingTop: '30px', marginBottom: '30px'}}>
				<Button
					data-dma='Close'
					data-testid='hawaii-state-return-button'
					aria-label={t('crypto.existingAccountErrorPage.returnToButton', {
						linkedPartnerName: partner?.name,
					})}
					style={{marginBottom: '10px'}}
					onClick={handleClick}
				>
					{t('crypto.existingAccountErrorPage.returnToButton', {linkedPartnerName: partner?.name})}
				</Button>
			</BottomBox>
		</Page>
	)
}
