import {useMemo, useState} from 'react'
import {isValidOTP} from 'validate'

import {useDelayedValidation} from '../useDelayedValidation'

export const useOTP = (value: string) => {
	const [error, setError] = useState('')
	const isValid = useMemo(() => isValidOTP(value), [value])

	useDelayedValidation({
		input: value,
		validate: () => {
			if (value && !isValid) {
				setError('Invalid OTP')
			}
		},
		clearError: () => setError(''),
	})

	return {
		isValid,
		error,
		setError,
	}
}
