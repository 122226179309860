let logger = window.DD_LOGS?.logger

if (!logger) {
	// If datadog script is blocked by browser adblocker and fails to load, provide empty info function so components do not fail
	logger = {
		info: () => {},
	}
}

export {logger}
