import {useTranslation} from 'react-i18next'
import {H1, H2, H3, LI, Light, Link, Medium} from '@bakkt/components'

export const PrivacyPolicy = () => {
	const {t} = useTranslation()
	return (
		<div className='fullScreenModalContent'>
			<div>
				<Light>Effective Date: March 3, 2023</Light>

				<H1 className='title'>Bakkt Marketplace Privacy Notice</H1>

				<Medium>
					In this Privacy Notice ("Notice"), Bakkt Marketplace, LLC (also referred to as "Bakkt," "we," "us,"
					or "our") describes the types of Personal Information we may collect from you in connection with
					your interaction with us, including when you visit our websites or mobile application, or use our
					online products, services or content (the "Services").
				</Medium>

				<a id='personal-info-we-collect'></a>

				<H3>1. PERSONAL INFORMATION WE COLLECT</H3>
				<Medium>
					<b>a. Personal Information We Collect Directly from You</b>
				</Medium>
				<Medium>
					We collect Personal Information from you when you use our Services and interact with us (such as
					speaking with Bakkt representatives). The information we collect may include your name, state or
					country of residence, date of birth, address, phone number, social security number, account number,
					job title, company, email address, marketing and communications preferences, and other information
					you choose to provide us, including information provided when you register with us. In some
					circumstances, we may collect information such as a government-issued ID, proof of address, or
					photograph(s) (if you elect to provide them to us). We may also collect information relating to:
				</Medium>
				<ul>
					<LI>Records and copies of your correspondence (including email addresses), if you contact us.</LI>
					<LI>
						Your responses to surveys, such as those that we might ask you to complete for research
						purposes.
					</LI>
					<LI>
						Details of transactions you carry out using our Services. You may be required to provide
						financial information before using our Services.
					</LI>
				</ul>
				<Medium>
					<b>b. Personal Information We Collect Automatically</b>
				</Medium>
				<Medium>
					We collect certain information automatically when you interact with our Services. For example, we
					may use cookies, web beacons, and other similar technologies to collect information about you as you
					use our Services. Examples of this type of information include the dates and times of your use of
					the Services, your IP (Internet Protocol) address, your browser type, your operating system, device
					identifiers, and the webpages or content to or from which you navigate.
				</Medium>
				<Medium>
					Third parties may use cookies or similar technologies on our Services. For example, these
					technologies may be used to provide content and advertisements. Other parties may collect Personal
					Information about your online activities over time and across different websites when you use our
					Services.
				</Medium>
				<Medium>
					<b> c. Personal Information We Collect From Third Parties</b>
				</Medium>
				<Medium>
					We also collect information about you from third parties, such as money laundering and fraud
					prevention information providers, marketing agencies, identity and creditworthiness verification
					services, and analytics and information providers. We may combine information we collect about you
					with information from third parties.
				</Medium>
			</div>

			{/** PERSONAL INFORMATION WE COLLECT **/}

			<a id='how-we-use-your-info'></a>

			<div>
				<H3> 2. HOW WE USE YOUR INFORMATION </H3>
				<Medium>
					We use the information we gather to provide services to you and to respond to your inquiries. This
					includes:
				</Medium>
				<ul>
					<LI>
						Providing Services, including to:
						<ul className='sublist'>
							<LI> Register, create, and maintain your account; </LI>
							<LI> Authenticate your identity and/or your access to an account; </LI>
							<LI> Initiate, facilitate, process, and/or execute transactions; </LI>
							<LI> Communicate with you regarding your account or any Services you use; </LI>
							<LI> Perform creditworthiness, fraud prevention or other similar reviews; </LI>
							<LI> Evaluate applications; or </LI>
							<LI> Compare information for accuracy and verification purposes. </LI>
						</ul>
					</LI>
					<LI>Managing our business and protecting ourselves, you, other persons, and the Services.</LI>
					<LI>Providing a personalized experience and implementing your preferences.</LI>
					<LI>Better understanding our customers and how they use and interact with the Services.</LI>
					<LI>
						Personalizing our Services and providing offers and promotions for our Services via our websites
						and third-party websites.
					</LI>
					<LI>Providing you with location-specific options, functionalities, and offers.</LI>
					<LI>
						Complying with our policies and obligations, including but not limited to, disclosures made in
						response to any requests from law enforcement authorities and/or regulators in accordance with
						any applicable law, rule, regulation, judicial or governmental order, regulatory authority of
						competent jurisdiction, discovery request, advice of counsel or similar legal process.
					</LI>
					<LI>Resolving disputes, collecting fees, or troubleshooting problems.</LI>
					<LI>Providing customer service to you or otherwise communicating with you.</LI>
				</ul>
				<Medium>
					We may also process your Personal Information to fulfill the purposes for which you provide it, or
					with your consent.
				</Medium>
			</div>

			<a id='how-we-share-data'></a>

			{/** HOW WE USE YOUR INFORMATION **/}
			<div>
				<H3>3. HOW WE SHARE INFORMATION </H3>
				<Medium>
					We may disclose the information we gather to our affiliates and to third parties. For example, we
					may share information with:
				</Medium>
				<ul>
					<LI>
						<b>Service providers and/or data processors:</b> We may share Personal Information with third
						party service providers that perform services and functions at our direction and on our behalf.
						These third party service providers may, for example, provide you with services, verify your
						identity, assist us to comply with law or combat fraud, assist in processing transactions, or
						provide customer support.
					</LI>
					<LI>
						<b>Other parties to transactions:</b> We may share information with the other parties to your
						transactions, including retailers with whom we have partnered to provide the Services.
					</LI>
					<LI>
						<b>Financial Institutions and Credit Bureaus:</b> We may share information with financial
						institutions and credit bureaus involved in supporting transactions in which you engage or
						assessing creditworthiness. For example, we may share information with your bank when you link
						your bank account to our Services.
					</LI>
					<LI>
						<b>Other third parties:</b> We may share information with other third parties for our business
						purposes or as permitted or required by law, including:
						<ul className='sublist'>
							<LI>
								To comply with any legal, regulatory or contractual obligation, or with any legal or
								regulatory process (such as a valid court order or subpoena) or with the advice of
								counsel;
							</LI>
							<LI> To market our or their products or services to you; </LI>
							<LI> To establish, exercise, or defend legal claims or our policies; </LI>
							<LI>
								If we believe disclosure is necessary or appropriate to protect the rights, property, or
								safety of ourselves, our customers, or others. This includes exchanging information with
								other companies and organizations for the purposes of fraud prevention and credit risk
								reduction; or
							</LI>
							<LI>
								In connection with the purchase, sale, merger, consolidation or transfer of all or part
								of Bakkt's business.
							</LI>
						</ul>
					</LI>
				</ul>
				<Medium>
					We may also disclose data to fulfill the purposes for which you provide it, or with your consent.
				</Medium>
			</div>
			{/** HOW WE SHARE INFORMATION **/}
			<div>
				<H3>4. CHANGES TO THIS PRIVACY NOTICE </H3>
				<Medium>
					We will notify you of changes to this Notice by revising this Notice and updating the Effective Date
					above. If you have an account with us, we may notify you of material changes to this notice by
					e-mail.
				</Medium>
			</div>
			{/** CHANGES TO THIS PRIVACY **/}
			<div>
				<H3>5. YOUR CHOICES </H3>
				<Medium>
					If you wish to stop receiving marketing communications, you can opt out by emailing us at{' '}
					<Link href='mailto:help@bakkt.com'>help@bakkt.com</Link> or{' '}
					<Link href='mailto:privacy@Bakkt.com'>privacy@Bakkt.com</Link>. We may continue to send you
					non-marketing messages, such as messages relating to your transactions with us.
				</Medium>
				<Medium>
					As of the Effective Date listed above, We participate in a service called{' '}
					<Link href='https://globalprivacycontrol.org/' target='_blank'>
						Global Privacy Control
					</Link>{' '}
					(GPC) that lets you set a "Do Not Sell or Share" preference at the browser level.
				</Medium>
				<Medium>
					First, you'll need a GPC-enabled browser or browser extension. Next, you'll activate or turn on the
					GPC setting in the browser. When you visit a website, your browser will automatically send the site
					a "Do Not Sell or Share" signal and participating websites, like ours, will honor your preference.
				</Medium>
				<Medium>
					<b>Note:</b> Not all browsers and extensions offer a GPC setting. To download a GPC-enabled browser,
					go to the{' '}
					<Link href='https://globalprivacycontrol.org/' target='_blank'>
						Global Privacy Control
					</Link>{' '}
					website.
				</Medium>
				<Medium>
					If you are a registered user of the Services, you may access your personal account information
					online and make changes by logging into your account.
				</Medium>
				<Medium>
					You may control how your browser accepts cookies. If you reject cookies, you may still use our
					website, but your ability to use some features or areas of our website may be limited. Please see
					our{' '}
					<Link href='https://bakkt.com/cookie-policy' target='_blank'>
						cookie policy
					</Link>{' '}
					for more information.
				</Medium>
			</div>
			{/** YOUR CHOICES **/}
			<div>
				<H3>6. SECURITY </H3>
				<Medium>
					Bakkt has implemented administrative, physical and technical safeguards designed to protect your
					Personal Information. Still, Bakkt cannot guarantee the security or confidentiality of information
					you transmit to us or receive from us.
				</Medium>

				<Medium>For Vermont Customers.</Medium>

				<ul>
					<LI>
						We will not disclose information about your creditworthiness to our affiliates and will not
						disclose your personal information, financial information, credit report, or health information
						to nonaffiliated third parties to market to you, other than as permitted by Vermont law, unless
						you authorize us to make those disclosures.
					</LI>
					<LI>
						Additional information concerning our privacy policies can be found at{' '}
						<Link href='https://www.bakkt.com/privacy-policy' target='_blank'>
							https://www.bakkt.com/privacy-policy
						</Link>
						.
					</LI>
				</ul>
			</div>
			{/** SECURITY **/}
			<div>
				<H3>7. CALIFORNIA PRIVACY PRACTICES AND YOUR CALIFORNIA RIGHTS </H3>
				<Medium>
					If you are a California resident, see the Bakkt California Privacy Rights Addendum below for more
					information about our privacy practices and your rights under California law.
				</Medium>
			</div>
			{/** CALIFORNIA PRIVACY PRACTICES AND YOUR CALIFORNIA RIGHTS  **/}
			<div>
				<H3>8. CONTACT US </H3>
				<Medium>
					We take your privacy concerns seriously. If you have any questions about this Notice or if you
					believe that we have not complied with this Notice with respect to your Personal Information, you
					may write to <Link href='mailto:Privacy@Bakkt.com'>Privacy@Bakkt.com</Link>
				</Medium>
			</div>
			{/** CONTACT US **/}
			<div>
				<Medium>
					<b>Bakkt California Privacy Rights Addendum</b>
				</Medium>
				<Medium>
					The following provisions apply to individuals who are residents of California and whose personal
					information we collect (such as website visitors). For such residents, the provisions of this
					California Privacy Rights Addendum ("Addendum") prevail over any conflicting provisions of this
					Notice. If you use one of our financial products or services for personal or household use, our
					collection of your personal information in connection with such product or service is subject to a
					separate{' '}
					<Link
						href='https://22005419.fs1.hubspotusercontent-na1.net/hubfs/22005419/Privacy/Privacy%20of%20Consumer%20Financial%20Information_GLBA.pdf'
						target='_blank'
					>
						privacy notice
					</Link>{' '}
					that we are required by federal law to provide, and is not subject to this Notice or this Addendum.
				</Medium>
				<Medium>
					<b>Personal Information We Collect</b>
				</Medium>
				<Medium>
					Within the last 12 months, we may have collected the following categories of Personal Information
					about you:
				</Medium>
				<ul>
					<LI>
						<b>A. Identifiers.</b>
					</LI>
				</ul>
				<Medium>
					Real name, alias, postal address, unique personal identifier, online identifier, IP address, email
					address, account name, social security number, driver's license number, passport number, or other
					similar identifiers.
				</Medium>

				<ul>
					<LI>
						<b>B. Information under the California Customer Records statute.</b>
					</LI>
				</ul>
				<Medium>
					Personal Information that identifies, relates to, describes, or is capable of being associated with,
					a particular individual, including, but not limited to their name, signature, physical
					characteristics or description, address, telephone number, passport number, driver's license or
					state identification card number, bank account number, credit card number, debit card number, or any
					other financial information.
				</Medium>

				<ul>
					<LI>
						<b>C. Characteristics of protected classifications under California or federal law.</b>
					</LI>
				</ul>
				<Medium>
					Characteristics of protected classifications under California or federal law (such as race, gender,
					age, disability status).
				</Medium>

				<ul>
					<LI>
						<b>D. Commercial information.</b>
					</LI>
				</ul>
				<Medium>
					Records of personal property, products or services purchased, obtained, or considered, or other
					purchasing or consuming histories or tendencies.
				</Medium>

				<ul>
					<LI>
						<b>E. Biometric information</b>
					</LI>
				</ul>
				<Medium>
					Images, including facial data. We may also require you to provide fingerprint data (e.g., through
					your device to verify your identity).
				</Medium>

				<ul>
					<LI>
						<b>F. Internet or other similar network activity.</b>
					</LI>
				</ul>
				<Medium>
					Internet or other electronic network activity information, including, but not limited to, browsing
					history, search history, and information regarding a consumer's interaction with a website,
					application or advertisement.
				</Medium>

				<ul>
					<LI>
						<b>G. Geolocation information.</b>
					</LI>
				</ul>
				<Medium>Geolocation data.</Medium>

				<ul>
					<LI>
						<b>H. Sensory data.</b>
					</LI>
				</ul>
				<Medium>Audio, electronic, visual, or similar information.</Medium>

				<ul>
					<LI>
						<b>I. Inferences drawn from other Personal Information.</b>
					</LI>
				</ul>
				<Medium>
					Inferences drawn from any of the Personal Information we collect to create a profile about you
					reflecting your preferences, characteristics, behavior, and attitudes.
				</Medium>

				<ul>
					<LI>
						<b>J. Sensitive Personal Information</b>
					</LI>
				</ul>
				<Medium>
					We collect Sensitive Information (which includes social security, driver's license, state
					identification card, or passport number, precise geolocation, racial or ethnic origin), to the
					extent noted above.
				</Medium>
				<Medium>
					We obtain Personal Information from a variety of sources. These sources include: yourself with
					respect to both online and offline interactions you may have with us or our service providers; other
					entities with whom you transact business; others with whom you maintain relationships who may deal
					with us on your behalf; the devices you use to access our websites, mobile applications, and online
					services; credit bureaus; identity verification and fraud prevention services; marketing and
					analytics providers; public databases; social media platforms; and others consistent with this
					Notice and Addendum. For more information, please see the{' '}
					<Link href='#personal-info-we-collect'>PERSONAL INFORMATION WE COLLECT</Link> section of this
					Privacy Notice.
				</Medium>

				<Medium>
					<b>
						Disclosures of Personal Information for Monetary or Other Valuable Consideration, for Behavioral
						Advertising, or for Business Purposes
					</b>
				</Medium>
				<Medium>
					Bakkt does not sell or disclose personal information for money or other valuable consideration.
					However, as is common practice among businesses that operate Internet websites and mobile apps,
					within the last 12 months, we may have disclosed through cookies or similar technologies certain
					identifiers such as email addresses, pseudonymized identifiers and internet activity, information
					about the use of our websites and apps, and inferences drawn about you to our social media,
					analytics, and advertising partners for targeted marketing purposes.
				</Medium>
				<Medium>
					Within the last 12 months, we have disclosed Personal Information identified in the above categories
					(A)-(J) and/or <Link href='#personal-info-we-collect'>PERSONAL INFORMATION WE COLLECT</Link> for our
					business purposes. To learn more about the categories of third parties with whom we share such
					information, please see <Link href='#how-we-share-data'> HOW WE SHARE DATA</Link> section of this
					Notice.
				</Medium>

				<Medium>
					<b>Sales or Sharing of Minors' Personal Information</b>
				</Medium>
				<Medium>
					We do not sell personal information of individuals we know to be under the age of 16, or share such
					personal information with third parties for cross-context behavioral advertising.
				</Medium>

				<Medium>
					<b>Use of Personal Information</b>
				</Medium>
				<Medium>
					For each of the above categories, we use the Personal Information we collect for the business
					purposes disclosed in <Link href='#how-we-use-your-info'>HOW WE USE YOUR INFORMATION</Link> section
					of this Notice. Please note that the business purposes for which we use your information may
					include:
				</Medium>

				<ul>
					<LI>
						Audits and reporting relating to particular transactions and interactions, including online
						interactions, you may have with us or others on our behalf;
					</LI>
					<LI>
						Detecting and protecting against security incidents and malicious, deceptive, fraudulent or
						illegal activity, and prosecuting the same;
					</LI>
					<LI>Debugging to identify and repair errors in our systems;</LI>
					<LI>Short-term, transient use including contextual customization of ads;</LI>
					<LI>
						Providing services on our behalf or on behalf of another, including maintaining or servicing
						accounts, providing customer service, fulfilling transactions, verifying identity information,
						processing payments, and other services;
					</LI>
					<LI>Conducting internal research to develop and demonstrate technology; and</LI>
					<LI>
						Conducting activity to verify, enhance, and maintain the quality or safety of services or
						devices which we may own, control, or provide.
					</LI>
				</ul>

				<Medium>
					We may also use the information we collect for our own or our service providers' other operational
					purposes, purposes for which we provide you additional notice, or for purposes compatible with the
					context in which the Personal Information was collected.
				</Medium>

				<Medium>
					<b>How Long We Keep Personal Information</b>
				</Medium>
				<Medium>
					The amount of time we retain a particular category of personal information will vary depending on
					the purpose for which it was collected, our business need for it, and our legal obligations to
					retain it. We retain your personal information for the time needed to fulfill the purpose for which
					that information was collected and as required pursuant to our data retention policies, which
					reflect applicable statute of limitation periods and legal requirements. In determining the
					retention period for personal information, we consider the nature and sensitivity of your personal
					information, the potential risk of harm from unauthorized use or disclosure of your personal
					information, the purposes for which we collect, use and maintain your personal information and our
					legal requirements to retain such information.
				</Medium>
			</div>

			{/**Bakkt California Privacy Rights Addendum**/}
			<div>
				<Medium>
					<b>Your California Rights</b>
				</Medium>
				<Medium>
					If you are a California resident, you have certain rights related to your Personal Information,
					including:
				</Medium>
				<ul>
					<LI>
						<b>Right to Access and Right to Know.</b> You have the right to request that we disclose the
						following to you:
						<ul className='sublist'>
							<LI>Specific pieces of Personal Information we have collected;</LI>
							<LI>Categories of Personal Information we have collected about you;</LI>
							<LI>Categories of sources from which the Personal Information is collected;</LI>
							<LI>
								Our business or commercial purpose for collecting ,selling, or sharing Personal
								Information; and
							</LI>
							<LI>Categories of third parties with whom we share Personal Information;</LI>
						</ul>
					</LI>
					<LI>
						<b>Right to Deletion.</b> You have the right to request that we delete Personal Information
						about you that we have collected from you.
					</LI>
					<LI>
						<b>Right to Correction.</b> You have the right to request that we correct inaccurate Personal
						Information we may have about you.
					</LI>
					<LI>
						The Right to Opt-Out of Sales or Sharing. The CCPA gives you the right to opt-out of the
						disclosure of Personal Information about you for monetary or other valuable consideration
						("sale") and/or the disclosure of personal information for purposes of behavioral advertising
						("sharing"). Our use of tracking technologies may be considered a "sale" or "sharing" under
						California law. To opt-out, select the{' '}
						<Link
							href='https://privacyportal.onetrust.com/webform/b425c274-845f-46ec-9205-bd91a06334ef/c8a19e9c-0212-4144-8154-d33ec726b58a'
							target='_blank'
						>
							Bakkt Privacy Request form
						</Link>
						, then complete the form by selecting the option Do Not Sell or Share My Personal Information,
						or by broadcasting an Opt-Out Preference Signal, such as the Global Privacy Control (GPC) (on
						the browsers and/or browser extensions that support such a signal).
						<Medium>
							We collect certain sensitive personal information (as discussed above in the "Personal
							Information We Collect" section). However, we do not use or disclose sensitive information
							in a manner that requires us to offer a right to limit such use under the CCPA.
						</Medium>
					</LI>
				</ul>

				<Medium>You may exercise these rights by:</Medium>

				<ul>
					<LI>Logging in to your account (if you have an account) and submitting a request;</LI>
					<LI>
						Emailing us at <Link href='mailto:privacy@Bakkt.com'>privacy@Bakkt.com</Link>;
					</LI>
					<LI>Calling our toll-free number 1-800-322-1719; or</LI>
					<LI>
						Completing our{' '}
						<Link
							href='https://privacyportal.onetrust.com/webform/b425c274-845f-46ec-9205-bd91a06334ef/c8a19e9c-0212-4144-8154-d33ec726b58a'
							target='_blank'
						>
							Privacy requests form
						</Link>
						.
					</LI>
				</ul>

				<Medium>
					<b>Verification</b>
				</Medium>

				<Medium>
					As required under applicable law, please note that we may take steps to verify your identity before
					granting you access to information or acting on your request to exercise your rights. We may require
					you to provide additional Personal Information including name, physical address, email address,
					contact information, account login credentials, and other information about your previous
					transactions with us to verify your identity in response to exercising requests of the above type.
					We may limit our response to your exercise of rights as permitted under applicable law.
				</Medium>

				<Medium>
					<b>Nondiscrimination</b>
				</Medium>

				<Medium>
					Subject to applicable law, we may not discriminate against you because of your exercise of any of
					the above rights, or any other rights under the California Consumer Privacy Act, including by:
				</Medium>

				<ul>
					<LI>Denying you goods or services;</LI>
					<LI>
						Charging different prices or rates for goods or services, including through the use of discounts
						or other benefits or by imposing penalties;
					</LI>
					<LI>Providing you a different level or quality of goods or services; or</LI>
					<LI>
						Suggesting that you will receive a different price or rate for goods or services or a different
						level or quality of goods or services.
					</LI>
				</ul>

				<Medium>
					We may, however, charge different prices or rates, or provide a different level or quality of goods
					or services, if that difference is reasonably related to the value of the Personal Information
					provided to us and to the extent permitted by law.
				</Medium>

				<Medium>
					<b>Agent Authorization</b>
				</Medium>

				<Medium>
					Under California law, you may designate an authorized agent to make a request on your behalf. You
					may make such a designation by providing the agent with written permission, signed by you, to act on
					your behalf. Your agent may contact us via the information provided in the "How to Contact Us"
					section below to make a request on your behalf. As permitted by law, we may require verification of
					the agent's authorization to act on your behalf, require you to confirm you have authorized the
					agent to act on your behalf, or require you to verify your own identity. We may deny a request by an
					agent if they fail to submit proof that they act on your behalf.
				</Medium>

				<Medium>
					<b>How to Contact Us</b>
				</Medium>

				<Medium>
					You may contact us with questions and concerns about our privacy policies or practices by contacting
					us at <Link href='mailto:privacy@Bakkt.com'>privacy@Bakkt.com</Link> or at 1-800-322-1719.
				</Medium>
			</div>
		</div>
	)
}
