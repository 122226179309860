import {H4} from '../text/Text'
import React from 'react'
import {color} from 'src/color/color'

export interface BreadCrumbViewProps {
	entities: {name: string; id: string}[]
	handleBreadcrumbclick: (id: string | null) => void
}

export const BreadCrumb: React.FC<BreadCrumbViewProps> = props => {
	const {entities, handleBreadcrumbclick} = props

	const ellipsisStyle = {
		maxWidth: '150px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'white-space': 'nowrap',
	}

	return (
		<div style={{display: 'flex', flexDirection: 'row'}}>
			{' '}
			{entities.map((crumb, idx) => {
				return (
					<div key={idx}>
						{idx !== entities.length - 1 ? (
							<H4
								style={{color: color.green600, display: 'flex'}}
								onClick={() => handleBreadcrumbclick(crumb.id || null)}
							>
								<span style={{cursor: 'pointer', ...ellipsisStyle}}>{crumb.name}</span>
								<span style={{color: 'black'}}>&nbsp;|&nbsp;</span>
							</H4>
						) : (
							<H4>{crumb.name} </H4>
						)}
					</div>
				)
			})}
		</div>
	)
}
