import {StateCreator, create} from 'zustand'
import {createJSONStorage, devtools, persist, PersistOptions} from 'zustand/middleware'
import {
	ApiAmount,
	ApiAmountCurrencyEnum,
	ApiCryptoCurrencyConfig,
	ApiEmbeddedCryptoTransactionRequestTransactTypeEnum,
	ApiEmbeddedCryptoTransactionResponse,
	ApiEmbeddedFundingAccount,
	ApiEmbeddedPartnerPartyAccount,
	ApiEmbeddedPartnerPartyProfileResponse,
	ApiEmbeddedPartnerTransactionOperationTypeEnum,
	ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum,
	ApiError,
	ApiPartnerPartyEnrollmentData,
	ApiPendingBankAccount,
	BasicPartnerInfo,
	BasicPartnerPartyLink,
	BasicPartyInfo,
	CryptoOrFiatAmountCurrencyEnum,
	FiatAmount,
	FiatAmountCurrencyEnum,
	Identifier,
	ApiAddressCountryEnum,
} from '@bakkt/api'
import {setUserProperties} from 'utils/firebase/analytics'

import {Application as BakktComponentApplication} from '@bakkt/components'
import {apiCall, embeddedPartnerApi, loadOnStartup} from '../api'
import {AnalyticsFlags} from 'utils/firebase/analyticsProperties'
import {showOnboardingErrorDialog, showSuspendedErrorDialog} from 'components/ErrorDialog'
import {SessionStorageKeys} from 'utils/sessionStorageProperties'
import {PartnerTransaction} from '../apps/main/pages/crypto/transaction/activity/utils'

export type Amount = ApiAmount | FiatAmount | {amount?: number; currency?: CurrencyEnum}

export type TransactTypeEnum =
	| ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum
	| ApiEmbeddedCryptoTransactionRequestTransactTypeEnum
	| ApiEmbeddedPartnerTransactionOperationTypeEnum
export const TransactType = ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum
export type FundingAccount = ApiEmbeddedFundingAccount
export type PendingBankAccount = ApiPendingBankAccount
export type PartnerPartyProfile = ApiEmbeddedPartnerPartyProfileResponse
export type PartnerPartyAccount = ApiEmbeddedPartnerPartyAccount & {isDisabled?: boolean}
export type CurrencyEnum =
	| ApiAmountCurrencyEnum
	| ApiAmountCurrencyEnum
	| FiatAmountCurrencyEnum
	| CryptoOrFiatAmountCurrencyEnum
	| undefined

export type CryptoCurrencyConfig = ApiCryptoCurrencyConfig
export const Currency = ApiAmountCurrencyEnum || ApiAmountCurrencyEnum || FiatAmountCurrencyEnum
export interface CryptoTransactionConfirm {
	price: Amount
	quantity: Amount
	transactType?: TransactTypeEnum
	unitPrice?: Amount
	transactionFee: Amount
	totalPrice: Amount
}

export interface PartyUpdateInformationProps {
	newFirstName: string | null
	newLastName: string | null
	locality: string | null
	country: ApiAddressCountryEnum | null
	postalCode: string | null
	region: string | null
	streetLine1: string | null
}

export type CryptoStatus = {currency: string; status: boolean}

export type HistoricalPeriod = 'YEAR' | 'MONTH' | 'WEEK' | 'DAY' | 'HOUR'

type NULL = null | undefined
export type EmbeddedWebState = {
	userIdentity: string | NULL
	identifier: Identifier | NULL
	partner: BasicPartnerInfo | NULL
	partnerPartyLink: BasicPartnerPartyLink | NULL
	party: BasicPartyInfo | NULL
	partyProfile: PartnerPartyProfile | NULL
	lastError: ApiError | NULL
	loadProfile: () => void
	loadParty: () => void
	loadOnStartup: () => void
	changePasswordRequired: boolean
	setChangePasswordRequired: (bakktLoginRequired: boolean) => void
	missingProfileFields: Partial<ApiPartnerPartyEnrollmentData> | NULL
	updateMissingProfileFields: (missingProfileFields: Partial<ApiPartnerPartyEnrollmentData>) => void
	logout: () => void
	fundingAccounts: FundingAccount[]
	setFundingAccounts: (fundingAccounts: FundingAccount[]) => void
	pendingBankAccount: PendingBankAccount | null
	setPendingBankAccount: (pendingBankAccount: PendingBankAccount) => void
	partyUpdateProfileInfo: PartyUpdateInformationProps | null
	setPartyUpdateProfileInfo: (partyUpdateProfileInfo: PartyUpdateInformationProps | null) => void
	selectedFundingAccount: FundingAccount | null
	setSelectedFundingAccount: (selectedFundingAccount: FundingAccount | null) => void
	selectedFundingAccountId: string | null
	setSelectedFundingAccountId: (selectedFundingAccountId: string | null) => void
	cryptoAccounts: PartnerPartyAccount[]
	setCryptoAccounts: (cryptoAccounts: PartnerPartyAccount[]) => void
	cryptoCurrencyDetails: CryptoCurrencyConfig[] | undefined
	setCryptoCurrencyDetails: (cryptoCurrencyDetails: CryptoCurrencyConfig[]) => void
	isEnrolled: Boolean
	fiatCurrencyPreference: CurrencyEnum
	selectedCryptoAccount: PartnerPartyAccount | null
	setSelectedCryptoAccount: (selectedCryptoAccount: PartnerPartyAccount) => void
	selectedCryptoAccountCurrency: string | null
	setSelectedCryptoAccountCurrency: (selectedCryptoAccountCurrency: string) => void
	partnerTransaction: PartnerTransaction | null
	setSelectedPartnerTransaction: (partnerTransaction: PartnerTransaction | null) => void
	cryptoTransaction: ApiEmbeddedCryptoTransactionResponse | null
	setCryptoTransaction: (cryptoTransaction: ApiEmbeddedCryptoTransactionResponse | null) => void
	cryptoTransactionConfirm: CryptoTransactionConfirm | null
	setCryptoTransactionConfirm: (cryptoTransactionConfirm: CryptoTransactionConfirm | null) => void
	resetToHome: () => void
	isSuspended: Boolean
	setIsSuspended: (isSuspended: boolean) => void
	bakktLoginRequired: boolean
	setBakktLoginRequired: (bakktLoginRequired: boolean) => void
	loadDefaultFundingSource: () => void
	activePartnerTransactionId: string | null
	setActivePartnerTransactionId: (activePartnerTransactionId: string | null) => void
	timezone: string
	syncExecutePrices: boolean
	setSyncExecutePrices: (syncExecutePrices: boolean) => void
	setIdentifier: (identifier: Identifier | undefined) => void
	setUserIdentity: (identifier: string | undefined) => void
	isPartnerBakkt: boolean
	partnerServiceName: string | null
	toastMessage?: string
	toastType?: 'success' | 'info' | 'warning' | 'error'
	onCloseToast: () => void
	onShowToast: (params: {toastMessage?: string; toastType?: 'success' | 'info' | 'warning' | 'error'}) => void
	redirectUrlAfterOtpValidation: string | null
	setRedirectUrlAfterOtpValidation: (redirectUrlAfterOtpValidation: string) => void
	cryptoStates: CryptoStatus[]
	addCryptoState: (currency: string, status: boolean) => void
	isAnyCryptoDown: boolean
	isCryptoPriceDown: (currency: string) => boolean
	setAnyCryptoDown: (isAnyCryptoDown: boolean) => void
}

const store: StateCreator<EmbeddedWebState> = (
	set: (input: Partial<EmbeddedWebState>) => void,
	get: () => EmbeddedWebState,
) => ({
	bakktLoginRequired: false,
	setBakktLoginRequired: (bakktLoginRequired: boolean) => set({bakktLoginRequired}),
	changePasswordRequired: false,
	setChangePasswordRequired: (changePasswordRequired: boolean) => set({changePasswordRequired}),
	syncExecutePrices: false,
	setSyncExecutePrices: (syncExecutePrices: boolean) => set({syncExecutePrices}),
	partner: null,
	partnerPartyLink: null,
	party: null,
	isPartnerBakkt: false,
	partnerServiceName: null,
	loadOnStartup: async () => {
		const partyInfo = await loadOnStartup()
		setUserProperties({
			[AnalyticsFlags.USERNAME]: partyInfo?.partnerPartyLink?.userName,
			[AnalyticsFlags.PARTY_PARTNER_LINK_ID]: partyInfo?.partnerPartyLink?.partnerPartyLink?.id,
			[AnalyticsFlags.PARTNER_ID]: partyInfo?.partner?.id,
			[AnalyticsFlags.PARTNER_NAME]: partyInfo?.partner?.name,
			[AnalyticsFlags.PARTY_ID]: partyInfo?.party?.id,
		})
		const TIME_ZONE = 'America/New_York'
		BakktComponentApplication.timezone = TIME_ZONE

		const isEnrolled = !!partyInfo?.partnerPartyLink?.partnerPartyLink
		if (isEnrolled) {
			get().loadDefaultFundingSource()
		}

		set({
			partner: partyInfo?.partner,
			partnerPartyLink: partyInfo?.partnerPartyLink,
			party: partyInfo?.party,
			isEnrolled,
			timezone: TIME_ZONE,
			cryptoCurrencyDetails: partyInfo?.currencyConfigs,
			isPartnerBakkt: (sessionStorage.getItem(SessionStorageKeys.PARTNER) || '"bakkt"') === '"bakkt"', //why double quote?
			partnerServiceName: sessionStorage.getItem(SessionStorageKeys.PARTNER),
		})
	},
	timezone: '',
	partyProfile: null,
	lastError: null,
	isEnrolled: false, //TODO this is derived data, so make it function
	loadProfile: async () => {
		const {response, error}: any = await apiCall(embeddedPartnerApi.fetchPartnerPartyProfile)
		if (error && !(error.code === 'PARTY_SUSPENDED' || error.code === 'ACCOUNT_EXISTS_ANOTHER_PARTNER')) {
			showOnboardingErrorDialog(get().partner)
		}

		set({
			partyProfile: response,
			lastError: error,
		})
	},
	loadParty: async () => {
		const {response, error}: any = await apiCall(embeddedPartnerApi.getPartnerPartyLinkInfo)
		if (error && !(error.code === 'PARTY_SUSPENDED' || error.code === 'ACCOUNT_EXISTS_ANOTHER_PARTNER')) {
			showOnboardingErrorDialog(get().partner)
		}

		set({
			party: response?.party,
			lastError: error,
		})
	},
	missingProfileFields: null,
	updateMissingProfileFields: (missingProfileFields: Partial<ApiPartnerPartyEnrollmentData>) => {
		set({
			missingProfileFields,
		})
	},
	logout: () =>
		set({
			partner: null,
			partnerPartyLink: null,
			selectedCryptoAccount: null,
			selectedCryptoAccountCurrency: null,
			fundingAccounts: [],
			selectedFundingAccount: null,
			cryptoAccounts: [],
			cryptoCurrencyDetails: [],
		}),
	fundingAccounts: [],
	setFundingAccounts: (fundingAccounts: FundingAccount[]) => set({fundingAccounts}),
	pendingBankAccount: null,
	setPendingBankAccount: (pendingBankAccount: PendingBankAccount) => set({pendingBankAccount}),
	partyUpdateProfileInfo: null,
	setPartyUpdateProfileInfo: (partyUpdateProfileInfo: PartyUpdateInformationProps | null) =>
		set({partyUpdateProfileInfo}),
	selectedFundingAccount: null,
	setSelectedFundingAccount: (selectedFundingAccount: FundingAccount | null) => set({selectedFundingAccount}),
	selectedFundingAccountId: null,
	setSelectedFundingAccountId: (selectedFundingAccountId: string | null) => set({selectedFundingAccountId}),
	selectedCashFundingAccount: null,
	cryptoAccounts: [],
	setCryptoAccounts: (cryptoAccounts: PartnerPartyAccount[]) => set({cryptoAccounts}),
	cryptoCurrencyDetails: [],
	setCryptoCurrencyDetails: (cryptoCurrencyDetails: CryptoCurrencyConfig[]) => set({cryptoCurrencyDetails}),
	selectedCryptoAccount: null,
	setSelectedCryptoAccount: (selectedCryptoAccount: PartnerPartyAccount) => set({selectedCryptoAccount}),
	selectedCryptoAccountCurrency: null,
	setSelectedCryptoAccountCurrency: (selectedCryptoAccountCurrency: string) => set({selectedCryptoAccountCurrency}),
	fiatCurrencyPreference: ApiAmountCurrencyEnum.USD,
	partnerTransaction: null,
	setSelectedPartnerTransaction: (partnerTransaction: PartnerTransaction | null) => {
		set({
			partnerTransaction,
		})
	},
	cryptoTransaction: null,
	setCryptoTransaction: (cryptoTransaction: ApiEmbeddedCryptoTransactionResponse | null) => {
		set({
			cryptoTransaction,
		})
	},
	activePartnerTransactionId: null,
	setActivePartnerTransactionId: (activePartnerTransactionId: string | null) => {
		set({
			activePartnerTransactionId,
		})
	},
	cryptoTransactionConfirm: null,
	setCryptoTransactionConfirm: (cryptoTransactionConfirm: CryptoTransactionConfirm | null) => {
		set({
			cryptoTransactionConfirm,
		})
	},
	resetToHome: async () => {
		get().loadDefaultFundingSource()

		set({
			cryptoTransactionConfirm: null,
			cryptoTransaction: null,
		})
	},
	isSuspended: false,
	setIsSuspended: (isSuspended: boolean) => set({isSuspended}),
	loadDefaultFundingSource: async () => {
		const {selectedFundingAccountId, partner} = get()
		const {response, error} = await apiCall(() =>
			embeddedPartnerApi.fetchFundingAccounts(ApiAmountCurrencyEnum.USD),
		)
		if (error && error.code !== 'PARTY_SUSPENDED') {
			showSuspendedErrorDialog(partner)
		}

		const selectedAccount = (response || []).find(
			account => account.externalAccountRef === selectedFundingAccountId,
		)

		set({
			fundingAccounts: response || [],
			selectedFundingAccount: selectedAccount || response?.[0] || null,
			selectedFundingAccountId: selectedAccount?.externalAccountRef || null,
		})
	},
	identifier: null,
	setIdentifier: (identifier: Identifier | undefined) => set({identifier}),
	userIdentity: null,
	setUserIdentity: (userIdentity: string | undefined) => set({userIdentity}),
	onCloseToast: () => set({toastMessage: undefined, toastType: undefined}),
	onShowToast: (params: any) => set(params),
	redirectUrlAfterOtpValidation: null,
	setRedirectUrlAfterOtpValidation: (redirectUrlAfterOtpValidation: string) => set({redirectUrlAfterOtpValidation}),
	cryptoStates: [],
	addCryptoState: (currency: string, status: boolean) => {
		let cryptoStates = get().cryptoStates
		const existingCurrency = cryptoStates.find(obj => obj.currency === currency)
		if (existingCurrency) {
			existingCurrency.status = status
		} else {
			cryptoStates.push({currency: currency, status: status})
		}

		set({
			cryptoStates: cryptoStates,
		})
	},
	isCryptoPriceDown: (currency: string) => {
		const cryptoState = get().cryptoStates.filter(acc => acc.currency === currency)[0]
		if (cryptoState != undefined && cryptoState.status) {
			return false
		}
		return true
	},
	isAnyCryptoDown: false,
	setAnyCryptoDown: (isAnyCryptoDown: boolean) => set({isAnyCryptoDown}),
})

export const useStore = create(
	devtools(
		persist(store, {
			name: 'bakkt-embedded-web-storage',
			storage: createJSONStorage(() => sessionStorage),
			partialize: state => ({
				selectedCryptoAccount: state.selectedCryptoAccount,
				selectedCryptoAccountCurrency: state.selectedCryptoAccountCurrency,
				selectedFundingAccountId: state.selectedFundingAccountId,
			}),
		}),
	),
)
