import {ApiAmount} from '@bakkt/api'
import {CircleIcon, color, HBox, Large, Light, VBox} from '@bakkt/components'
import {useCryptoCurrencyDetails} from 'hooks/useCryptoCurrencyDetails'
import {formatCrypto, formatUSD} from 'utils/currencyFormatters'
import {formatDate} from 'utils/formatters'

export type ActivityRowProps = {
	icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
	transactionLabel: string
	cryptoQuantity?: ApiAmount | null
	fiatAmount?: ApiAmount | undefined
	showBorder?: boolean
	transactionTime: string
	activityStatus?: string
}

export const ActivityRow = ({
	icon,
	transactionLabel,
	cryptoQuantity,
	fiatAmount,
	showBorder,
	transactionTime,
	activityStatus,
}: ActivityRowProps) => {
	const currencyDetails = useCryptoCurrencyDetails(cryptoQuantity?.currency)
	return (
		<HBox
			style={{
				alignItems: 'center',
				padding: '16px 0px',
				borderBottom: showBorder ? '1px solid #e7e6e9' : undefined,
			}}
		>
			<HBox style={{alignItems: 'center'}}>
				{icon && (
					<CircleIcon
						data-testid={'activity-icon'}
						icon={icon}
						iconColor={color.black}
						style={{background: color.crypto, marginRight: 8, padding: 10}}
					/>
				)}
				<VBox verticalGap={-5}>
					<Large
						data-testid={'activity-description'}
						style={{fontSize: '13px', lineHeight: '18.2px', fontWeight: '400', margin: 0}}
					>{`${transactionLabel} ${currencyDetails?.name || ''}`}</Large>
					<Light
						data-testid={'activity-date'}
						style={{
							margin: 0,
							textAlign: 'left',
							fontSize: '8px',
						}}
					>
						{formatDate(transactionTime).formattedDate}
					</Light>
				</VBox>
			</HBox>

			<VBox style={{marginLeft: 'auto'}}>
				{cryptoQuantity && (
					<Light
						data-testid={'activity-value-crypto'}
						style={{
							margin: 0,
							fontSize: '13px',
							fontWeight: '400',
							lineHeight: '18.2px',
							textAlign: 'right',
							paddingRight: '10px',
						}}
					>
						{cryptoQuantity?.currency == 'USD' ? '$' : ''}

						{formatCrypto(
							cryptoQuantity?.amount || 0,
							cryptoQuantity?.currency,
							currencyDetails?.supportedPrecision,
						)}
					</Light>
				)}
				{fiatAmount && (
					<Light
						data-testid={'activity-value-usd'}
						style={{
							margin: 0,
							textAlign: 'right',
							paddingRight: '10px',
							fontSize: '11px',
							fontWeight: '400',
							lineHeight: '13.13px',
						}}
					>
						{fiatAmount?.amount && formatUSD(Math.abs(fiatAmount.amount), fiatAmount.currency)}
					</Light>
				)}
				{(activityStatus == 'CANCELED' || activityStatus == 'PENDING') && (
					<Light
						data-testid={'activity-status'}
						style={{
							margin: 0,
							textAlign: 'right',
							paddingRight: '10px',
							fontSize: '11px',
							fontWeight: '400',
							lineHeight: '13.13px',
							color: 'red',
						}}
					>
						{activityStatus == 'CANCELED' ? 'Failed' : 'Pending'}
					</Light>
				)}
			</VBox>
		</HBox>
	)
}

export default ActivityRow
