export enum RemoteConfigFlags {
	SHOW_TEST_DIALOG = 'show_test_dialog',
	TEST_DIALOG_STRING = 'test_dialog_string',
	TEST_DIALOG_NUMBER = 'test_dialog_number',
	TEST_DIALOG_OBJECT = 'test_dialog_object',
}

export enum RemoteConfigDataType {
	BOOLEAN = 'boolean',
	NUMBER = 'number',
	STRING = 'string',
	JSON = 'json',
}

export type RemoteConfigProperties = {
	[Flag in RemoteConfigFlags]: RemoteConfigDataType
}

export const RemoteConfigValues: RemoteConfigProperties = {
	[RemoteConfigFlags.SHOW_TEST_DIALOG]: RemoteConfigDataType.BOOLEAN,
	[RemoteConfigFlags.TEST_DIALOG_STRING]: RemoteConfigDataType.STRING,
	[RemoteConfigFlags.TEST_DIALOG_NUMBER]: RemoteConfigDataType.NUMBER,
	[RemoteConfigFlags.TEST_DIALOG_OBJECT]: RemoteConfigDataType.JSON,
}

export type RemoteConfigValueTypes = {
	[RemoteConfigFlags.SHOW_TEST_DIALOG]?: boolean
	[RemoteConfigFlags.TEST_DIALOG_STRING]?: string
	[RemoteConfigFlags.TEST_DIALOG_NUMBER]?: number
	[RemoteConfigFlags.TEST_DIALOG_OBJECT]?: {testValue: string}
}
