import {
	ActivatedCryptoDebitCard,
	SessionStorageDataType,
	SessionStorageKeys,
	SessionStorageKeysDefaults,
	SessionStorageValues,
} from './sessionStorageProperties'

const tryParse = (key: SessionStorageKeys, data: string | undefined | null): string | object | undefined | null => {
	if (!data) return null
	try {
		switch (SessionStorageValues[key]) {
			case SessionStorageDataType.STRING:
				return data
			case SessionStorageDataType.JSON:
				return JSON.parse(data)
		}
	} catch (error: any) {
		console.warn(`Could not get ${key} from session storage: ${error.message}`)
	}
}

export const getSessionStorageKey = (key: SessionStorageKeys) => {
	try {
		const data = sessionStorage.getItem(key)
		return tryParse(key, data)
	} catch (error: any) {
		console.warn(`Could not get ${key} from session storage: ${error.message}`)
	}
	return tryParse(key, SessionStorageKeysDefaults[key])
}

export const setSessionStorageKey = (key: SessionStorageKeys, data: any) => {
	try {
		const stringifiedData = JSON.stringify(data)
		sessionStorage.setItem(key, stringifiedData)
	} catch (error: any) {
		console.warn(`Could not save ${data} to session storage: ${error.message}`)
	}
}

export const getActivateCryptDebitCard = (key: ActivatedCryptoDebitCard) => {
	try {
		const data = sessionStorage.getItem(key)
		if (data) {
			const parsedData = JSON.parse(data)
			return parsedData
		} else return null
	} catch (error: any) {
		console.warn(`Could not get ${key} from session storage: ${error.message}`)
	}
}

export const setActivateCryptDebitCard = (key: ActivatedCryptoDebitCard, data: any) => {
	try {
		const stringifiedData = JSON.stringify(data)
		sessionStorage.setItem(key, stringifiedData)
	} catch (error: any) {
		console.warn(`Could not save ${data} to session storage: ${error.message}`)
	}
}
