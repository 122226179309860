import React, {SVGAttributes} from 'react'
import {Icons, useTheme} from 'src'
import {Theme} from '@material-ui/core'

export interface IconProps extends SVGAttributes<SVGElement> {}
export const BakktLogoThemed: React.FC<IconProps> = (props: IconProps) => {
	const theme = useTheme() as Theme

	return (
		<Icons.BakktLogo
			style={{
				margin: 'auto',
			}}
			fill={theme?.palette?.text?.background}
			{...props}
		></Icons.BakktLogo>
	)
}
