import {ApiAmountCurrencyEnum, BasicPartyInfoLevelEnum} from '@bakkt/api'
import {Main, Button, Large, VBox, H2, H6, Alert, color, DialogText, useTheme} from '@bakkt/components'
import {Theme} from '@material-ui/core'
import {embeddedBankLinkingApi} from 'api'
import {LinkedAccount} from 'components/LinkedAccount'
import {showDialog} from 'components/Dialog'
import Page from 'components/Page'
import {RoutesName} from 'constants/routes'
import {useNavigate} from 'hooks'
import {useState} from 'react'
import {useStore} from 'store'
import {formatUSD} from 'utils/currencyFormatters'
import {SessionStorageKeys} from 'utils/sessionStorageProperties'
import PlaidBankLink from './bank/PlaidBankLink'
import {embeddedDebitLinkingApi} from 'api'

const CashPage = () => {
	const navigate = useNavigate()
	const theme = useTheme() as Theme
	const {
		fundingAccounts,
		partner,
		party,
		setSelectedFundingAccount,
		selectedFundingAccount,
		setFundingAccounts,
		onShowToast,
	} = useStore()
	const cashFundingAccount = fundingAccounts.find(account => account.accountType == 'CASH')
	const [message, setMessage] = useState<{message: string; error: boolean} | null>(null)
	const linkedAccounts = fundingAccounts?.filter(account => account.accountType != 'CASH') || []
	const linkedAccountsExists = linkedAccounts.length > 0
	const partyDebitCardEnabled = localStorage.getItem(SessionStorageKeys.PARTY_DEBIT_CARD_ENABLED) === 'true'
	const partnerDebitCardEnabled = partner?.featureFlag?.debitCardLinkEnabled! || partyDebitCardEnabled
	const partnerAchEnabled = partner?.featureFlag?.achLinkEnabled

	const pendingDepositAmount = formatUSD(cashFundingAccount?.pendingDepositAmount?.amount, ApiAmountCurrencyEnum.USD)
	const totalAvailableAmount = cashFundingAccount?.availableBalance?.amount || 0
	const debitAccount = fundingAccounts.find(account => account.externalAccountRef.startsWith('DEBIT:'))
	const pendingVerification = debitAccount?.status == 'PENDING_VERIFICATION'

	const linkedAchAccount =
		linkedAccountsExists && partnerAchEnabled ? linkedAccounts.filter(acc => acc.accountType !== 'DEBIT') : []

	const linkedDebitCard =
		linkedAccountsExists && (partnerDebitCardEnabled || partyDebitCardEnabled)
			? linkedAccounts.filter(acc => acc.accountType === 'DEBIT')
			: []

	const isLinkedAchAccountActive =
		linkedAccountsExists && partnerAchEnabled
			? linkedAccounts.filter(acc => acc.accountType !== 'DEBIT' && acc.status === 'ACTIVE').length > 0
			: false

	const isLinkedDebitCardActive =
		linkedAccountsExists && (partnerDebitCardEnabled || partyDebitCardEnabled)
			? linkedAccounts.filter(acc => acc.accountType === 'DEBIT' && acc.status === 'ACTIVE').length > 0
			: false

	const isAddCashButtonEnabled = isLinkedAchAccountActive || isLinkedDebitCardActive

	const setDefaultFundingAccount = () => {
		const accountType = selectedFundingAccount?.accountType
		if ((!selectedFundingAccount || accountType === 'CASH') && linkedAccountsExists) {
			setSelectedFundingAccount(linkedAccounts[0])
		}
	}

	const handleCashIn = () => {
		setDefaultFundingAccount()
		navigate(RoutesName.bakktFunding.cashInput, {state: {type: 'cash_in'}})
	}

	const handleCashOut = () => {
		setDefaultFundingAccount()
		navigate(RoutesName.bakktFunding.cashInput, {state: {type: 'cash_out'}})
	}

	const handleBankLinking = async () => {
		if (
			[BasicPartyInfoLevelEnum.LEVEL_1, BasicPartyInfoLevelEnum.LEVEL_1_LIQUIDATED].includes(
				party?.level || BasicPartyInfoLevelEnum.CLOSED,
			)
		) {
			showDialog({
				title: `KYC Needed`,
				WrappedComponent: () => (
					<DialogText
						style={{
							textAlign: 'center',
						}}
					>{`Would like to know more about you. Come back again to complete KYC before you can link funding accounts.`}</DialogText>
				),
				hideSave: true,
				cancelText: 'Close',
			})
			return
		} else {
			const response = await embeddedBankLinkingApi.getPendingBankAccounts()
			const pendingBankAccount = response?.data?.payload?.find(ac => true)
			if (pendingBankAccount?.verificationStatus! === 'PENDING') {
				showDialog({
					title: `Pending Bank Account`,
					WrappedComponent: () => (
						<DialogText
							style={{
								textAlign: 'center',
							}}
						>{`Your ${pendingBankAccount?.institutionName} Bank account ending with ${pendingBankAccount?.mask} is pending verification.`}</DialogText>
					),
					hideSave: true,
					cancelText: 'Close',
				})
			} else {
				showDialog({
					title: `Loading...`,
					WrappedComponent: () => (
						<PlaidBankLink
							callBackSuccess={() => {
								navigate(RoutesName.home)
								// Below line fixes page scroll after plaid linking
								document.body.style.overflow = 'auto'
							}}
						/>
					),
					hideSave: true,
					hideCancel: true,
				})
			}
		}
	}

	const handleBankUnlink = async (externalAccountRef: string) => {
		const onSave = async () => {
			const response = await embeddedBankLinkingApi.removeBankAccountByExternalAccountRef(
				externalAccountRef || '',
			)
			if (response.status === 200) {
				const accountBeingUnlinkedIndex = fundingAccounts?.findIndex(
					account => account.externalAccountRef === externalAccountRef,
				)
				fundingAccounts?.splice(accountBeingUnlinkedIndex, 1)
				setFundingAccounts(fundingAccounts)

				onShowToast({
					toastMessage: (
						<span style={{textAlign: 'center'}}>
							<H6 style={{fontWeight: 'bold'}}>Bank account unlinked</H6>
						</span>
					) as any,
					toastType: 'success',
				})
			}
		}
		showDialog({
			title: `Unlink Account`,
			WrappedComponent: () => (
				<DialogText style={{textAlign: 'center'}}>This action unlinks your bank account from Bakkt.</DialogText>
			),
			onSave,
			saveText: 'Unlink',
		})
	}

	const handleLinkDebit = () => {
		if (
			[BasicPartyInfoLevelEnum.LEVEL_1, BasicPartyInfoLevelEnum.LEVEL_1_LIQUIDATED].includes(
				party?.level || BasicPartyInfoLevelEnum.CLOSED,
			)
		) {
			showDialog({
				title: `KYC Needed`,
				WrappedComponent: () => (
					<DialogText
						style={{
							textAlign: 'center',
						}}
					>{`Would like to know more about you. Come back again to complete KYC before you can link funding accounts.`}</DialogText>
				),
				hideSave: true,
				cancelText: 'Close',
			})
			return
		} else {
			if (pendingVerification) {
				navigate(RoutesName.home + RoutesName.debitCard.verifyTransactions)
			} else {
				navigate(RoutesName.home + RoutesName.debitCard.link)
			}
		}
	}

	const handleDebitUnlink = async (externalAccountRef: string) => {
		const onSave = async () => {
			const response = await embeddedDebitLinkingApi.removeDebitCard(externalAccountRef || '')
			if (response.status === 200) {
				const accountBeingUnlinkedIndex = fundingAccounts?.findIndex(
					account => account.externalAccountRef === externalAccountRef,
				)
				fundingAccounts?.splice(accountBeingUnlinkedIndex, 1)
				setFundingAccounts(fundingAccounts)

				onShowToast({
					toastMessage: (
						<span style={{textAlign: 'center'}}>
							<H6 style={{fontWeight: 'bold'}}>Debit Card unlinked</H6>
						</span>
					) as any,
					toastType: 'success',
				})
			}
		}
		showDialog({
			title: `Unlink Account`,
			WrappedComponent: () => (
				<DialogText style={{textAlign: 'center'}}>This action will unlink debit account from Bakkt</DialogText>
			),
			onSave,
			saveText: 'Unlink',
		})
	}

	return (
		<Page withBackIcon onIconPress={() => navigate(`/${RoutesName.home}`)} titleHeader={'Manage your cash balance'}>
			<Main style={{flexGrow: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
				<VBox>
					<VBox style={{alignItems: 'center'}}>
						<H2
							style={{
								margin: 0,
								fontSize: 48,
								lineHeight: '130.02%',
							}}
						>
							{formatUSD(totalAvailableAmount, ApiAmountCurrencyEnum.USD)}
						</H2>
						<H6 style={{fontWeight: 'bold', lineHeight: 0}}>Total Cash Available</H6>
						{pendingDepositAmount && pendingDepositAmount != '$0.00' && (
							<Large
								style={{
									margin: 10,
									padding: 10,
									backgroundColor:
										theme?.palette.type === 'dark' ? theme?.palette?.text?.primary : color.green100,
								}}
							>
								{`${pendingDepositAmount} Cash pending. Arriving in 3-5 days.`}
							</Large>
						)}
						{message && (
							<Alert
								severity={message.error ? 'error' : 'info'}
								icon={false}
								onClose={() => setMessage(null)}
							>
								{message.message}
							</Alert>
						)}
					</VBox>
					<VBox verticalGap={10} style={{width: '-webkit-fill-available', marginTop: 20}}>
						<Button
							size='large'
							onClick={handleCashIn}
							disabled={party?.level?.endsWith('_LIQUIDATED') || !isAddCashButtonEnabled}
						>
							Add Cash
						</Button>
						<Button
							size='large'
							disabled={totalAvailableAmount <= 0 || !isAddCashButtonEnabled}
							onClick={handleCashOut}
						>
							Cash Out
						</Button>
					</VBox>
				</VBox>
				<VBox style={{margin: '40px 0 20px 0'}}>
					<H6 style={{fontWeight: 'bold', textAlign: 'center', marginBottom: 20}}>Manage Accounts</H6>

					{linkedAchAccount.length > 0 && (
						<LinkedAccount
							key={linkedAchAccount[0].externalAccountRef}
							source={linkedAchAccount[0]}
							onClick={() => {
								if (linkedAchAccount[0].externalAccountRef.startsWith('BANK')) {
									linkedAchAccount[0].status !== 'ACTIVE'
										? handleBankLinking()
										: handleBankUnlink(linkedAchAccount[0].externalAccountRef)
								} else {
									handleBankUnlink(linkedAchAccount[0].externalAccountRef)
								}
							}}
						/>
					)}
					{linkedDebitCard.length > 0 && !pendingVerification && (
						<LinkedAccount
							key={linkedDebitCard[0].externalAccountRef}
							source={linkedDebitCard[0]}
							onClick={() => {
								if (linkedDebitCard[0].externalAccountRef.startsWith('BANK')) {
									linkedDebitCard[0].status !== 'ACTIVE'
										? handleLinkDebit()
										: handleDebitUnlink(linkedDebitCard[0].externalAccountRef)
								} else {
									handleDebitUnlink(linkedDebitCard[0].externalAccountRef)
								}
							}}
						/>
					)}
					{partnerAchEnabled && linkedAchAccount.length < 1 && (
						<LinkedAccount
							source={{name: 'Link Bank Account', accountType: 'link_account'} as any}
							onClick={handleBankLinking}
						/>
					)}
					{partnerDebitCardEnabled && (linkedDebitCard.length < 1 || pendingVerification) && (
						<LinkedAccount
							source={
								{
									name: 'Link Debit Card',
									accountType: pendingVerification ? 'pending_account' : 'link_account',
								} as any
							}
							onClick={handleLinkDebit}
						/>
					)}
				</VBox>
			</Main>
		</Page>
	)
}

export default CashPage
