export enum AnalyticsFlags {
	USERNAME = 'username',
	PARTNER_ID = 'partner_id',
	PARTNER_NAME = 'partner_name',
	PARTY_ID = 'party_id',
	PARTY_PARTNER_LINK_ID = 'party_partner_link_id',
}

export type AnalyticsProperties = {
	[Flag in AnalyticsFlags]: string | undefined
}
