class Application {
	private _timezone!: string

	constructor() {}

	get timezone(): string {
		return this._timezone
	}

	set timezone(timezone: string) {
		if (timezone) {
			this._timezone = timezone
		}
	}
}

export default new Application()
