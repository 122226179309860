import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate} from 'react-router-dom'
import {ApiEmbeddedPartnerTransactionOperationTypeEnum as TransactionType} from '@bakkt/api'
import {VBox, Large, H4, RoundCheckedIcon, Main, BottomBox, toTitleCase} from '@bakkt/components'
import Button from 'components/Button'
import Page from 'components/Page'
import {RoutesName} from 'constants/routes'
import {useStore} from 'store'
import {formatCrypto, formatCurrency} from 'utils/currencyFormatters'
import {logger} from 'utils/logger/datadogInit'
import {useSelectedCryptoAccountInfo} from 'hooks/useSelectedCryptoAccountInfo'
import {useNavigate} from 'hooks'

type Props = {
	transactionType: TransactionType
}
export const CryptoTransactionSuccess = ({transactionType}: Props) => {
	const {t} = useTranslation()
	const {selectedCryptoCurrencyName, selectedCryptoCurrency, selectedCryptoCurrencyPrecision} =
		useSelectedCryptoAccountInfo()
	const navigate = useNavigate()
	const {cryptoTransaction, resetToHome} = useStore()
	const cryptoQuantity = cryptoTransaction?.quantity

	useEffect(() => {
		logger.info('Crypto transaction success page - initilized', {
			filename: 'CryptoTransactionSuccess.tsx',
			currencyName: selectedCryptoCurrencyName,
			transactionType,
		})
	}, [])

	if (!cryptoQuantity) {
		return (
			<Navigate
				to={`${
					transactionType === TransactionType.BUY ? `/${RoutesName.crypto.buy}` : `/${RoutesName.crypto.sell}`
				}`}
				replace
			/>
		)
	}
	return (
		<Page
			titleHeader={
				transactionType === TransactionType.SELL
					? `Sell ${selectedCryptoCurrencyName}`
					: `Buy ${selectedCryptoCurrencyName}`
			}
			title={t('titles.transaction.success', {
				type: toTitleCase(transactionType),
				coin: selectedCryptoCurrencyName,
			})}
			withSubHeaderLogo={false}
		>
			<Main center style={{marginBottom: '100px'}}>
				<div style={{display: 'flex', justifyContent: 'center', marginBottom: '24px'}}>
					<RoundCheckedIcon height='104px' width='104px' />
				</div>

				<H4 data-testid={'section-title'} style={{margin: '0 0'}} role={'status'} aria-live={'polite'}>
					{t('crypto.transaction.success')}
				</H4>
				<VBox style={{marginTop: '0.6rem'}}>
					<Large style={{margin: 0}} data-testid={'section-description'}>
						{transactionType === TransactionType.BUY ? t('crypto.buy.purchase') : t('crypto.sell.sold')}
					</Large>

					<Large style={{margin: 0}}>
						<b data-testid={'transaction-crypto-amount'}>
							{formatCrypto(
								cryptoQuantity?.amount || 0,
								cryptoQuantity?.currency,
								selectedCryptoCurrencyPrecision,
							)}
						</b>
						{' for '}
						<b data-testid={'transaction-usd-value'}>{`${
							cryptoTransaction?.price?.amount &&
							cryptoTransaction?.price?.currency &&
							formatCurrency(
								cryptoTransaction?.price?.amount || 0,
								cryptoTransaction?.price?.currency,
								selectedCryptoCurrencyPrecision,
							)
						}`}</b>
					</Large>
				</VBox>
			</Main>
			<BottomBox className={'bottomBox'}>
				<Button
					aria-label={`View My ${selectedCryptoCurrencyName}`}
					className={'viewCryptoAccountsBtn'}
					onClick={() => {
						logger.info(`Crypto transaction success page - View my ${selectedCryptoCurrencyName} clicked`, {
							currencyName: selectedCryptoCurrencyName,
							transactionType,
						})
						resetToHome()
						navigate(`/${RoutesName.home}`, {replace: true, ignore: true})
					}}
				>
					{t('bntViewCrypto')}
				</Button>
			</BottomBox>
		</Page>
	)
}
