import {useTranslation} from 'react-i18next'
import {Light, H3, Scrollbar, HBox, Spinner} from '@bakkt/components'
import {FundingAccount} from 'components/FundingAccount'
import {useStore, TransactTypeEnum, TransactType} from 'store'
import {useEffect} from 'react'

type Props = {
	transactionType: TransactTypeEnum
}

export const SelectFundingAccount = ({transactionType}: Props) => {
	const {t} = useTranslation()
	const {
		fundingAccounts,
		isPartnerBakkt,
		selectedFundingAccount,
		setSelectedFundingAccount,
		setSelectedFundingAccountId,
	} = useStore()

	useEffect(() => {
		if (fundingAccounts && fundingAccounts.length > 0) {
			let filter = fundingAccounts.filter(account => (isPartnerBakkt ? account.accountType === 'CASH' : account))
			if (filter && filter.length > 0) {
				setSelectedFundingAccountId(filter[0].externalAccountRef)
				setSelectedFundingAccount(filter[0])
			} else {
				setSelectedFundingAccountId(null)
				setSelectedFundingAccount(null)
			}
		} else {
			setSelectedFundingAccountId(null)
			setSelectedFundingAccount(null)
		}
	}, [selectedFundingAccount])

	return (
		<>
			<H3 data-testid={'section-title'} style={{marginTop: 50, marginBottom: 20}}>
				{transactionType === TransactType.SELL ? t('label.destination') : t('label.fundingSource')}
			</H3>

			{(() => {
				if (fundingAccounts && fundingAccounts.length > 0) {
					return (
						<Scrollbar height={(fundingAccounts?.length || 0) > 3 ? '250px' : '100vh'}>
							{fundingAccounts
								?.filter(acc => (isPartnerBakkt ? acc.accountType === 'CASH' : acc))
								.map(account => (
									<FundingAccount
										transactionType={transactionType}
										key={account.externalAccountRef}
										source={account}
									/>
								))}
						</Scrollbar>
					)
				} else {
					return <Light style={{textAlign: 'center'}}>{t('crypto.noFundingAccounts')}</Light>
				}
			})()}
		</>
	)
}
