import {useState} from 'react'
import * as api from '@bakkt/api'
import {ApiError} from '@bakkt/api'
import axios, {AxiosPromise, AxiosError, AxiosResponse} from 'axios'
import {getSessionStorageKey, setSessionStorageKey} from 'utils/sessionStorage'
import {SessionStorageKeys} from 'utils/sessionStorageProperties'
import {getEnv} from 'utils/config'
import {DeviceUUID} from 'device-uuid'
import {useStore} from 'store'
import qs from 'qs'
import {showErrorDialog} from 'components/ErrorDialog'
import {Consumer, ISessionAuth} from 'apps/funding/debit/Tokenizer'

const global: any = window
const uuid = new DeviceUUID()
let refreshIntervalId: number

const SERVER_URL = getEnv('SERVER_URL')
const baseUrl = SERVER_URL

export const logout = async () => {
	try {
		const partner = getSessionStorageKey(SessionStorageKeys.PARTNER)
		if (refreshIntervalId) {
			clearInterval(refreshIntervalId)
		}
		await axios.post(`${SERVER_URL}/partnerauth/${partner}/logout`)
	} catch (error) {
		console.error(error)
	}
}

global.submitSamlPayload = async (payload: string) => {
	const partner = getSessionStorageKey(SessionStorageKeys.PARTNER)
	const response = await axios.post(
		`${SERVER_URL}/partnerauth/${partner}/token-callback`,
		qs.stringify({SAMLResponse: payload}),
	)
	window.location = window.location
}

export const redirectToLogin = () => {
	const partner = getSessionStorageKey(SessionStorageKeys.PARTNER)
	const {setBakktLoginRequired, setChangePasswordRequired} = useStore.getState()
	if (params.get('code') !== null) {
		setChangePasswordRequired(true)
	}
	if (partner == 'bakkt') {
		setBakktLoginRequired(true)
	}
	if (partner != 'bakkt' && (partner || '').toString().indexOf('fiserv') < 0) {
		global.location = `${SERVER_URL}/partnerauth/${partner}/login-redirect`
	}
}

const params = new URLSearchParams(window.document.location.search)

if (!getSessionStorageKey(SessionStorageKeys.PARTNER)) {
	setSessionStorageKey(SessionStorageKeys.PARTNER, params.get('partner') || 'bakkt')
} else if (getSessionStorageKey(SessionStorageKeys.PARTNER) !== (params.get('partner') || 'bakkt')) {
	logout().then(() => {
		setSessionStorageKey(SessionStorageKeys.PARTNER, params.get('partner') || 'bakkt')
		redirectToLogin()
	})
}

setSessionStorageKey(
	SessionStorageKeys.PARTNER,
	params.get('partner') || getSessionStorageKey(SessionStorageKeys.PARTNER),
)
if (params.get('theme')) {
	setSessionStorageKey(
		SessionStorageKeys.URL_THEME,
		params.get('theme') || getSessionStorageKey(SessionStorageKeys.URL_THEME),
	)
}

// send session cookie
axios.defaults.withCredentials = true

axios.interceptors.request.use(req => {
	const fakeError = getSessionStorageKey(SessionStorageKeys.FAKE_ERROR)
	const fakeErrorCode = getSessionStorageKey(SessionStorageKeys.FAKE_ERROR_CODE)

	if (fakeError) req.headers['X-Fake-Error'] = fakeError
	if (fakeErrorCode) req.headers['X-Fake-Error-Code'] = fakeErrorCode

	const deriveDeviceType = () => {
		const {isAndroid, isiPad, isiPhone} = uuid.parse()

		if (isiPhone) return 'iphone'
		else if (isiPad) return 'ipad'
		else if (isAndroid) return 'android'
		else return 'browser'
	}

	req.headers['X-User-Agent'] = uuid.parse().source
	req.headers['X-Device-Type'] = deriveDeviceType()
	req.headers['X-Device-ID'] = uuid.get()

	return req
})

axios.interceptors.response.use(
	res => {
		if (res?.data?.error?.code === 'PARTY_SUSPENDED') {
			const {setIsSuspended} = useStore.getState()
			setIsSuspended(true)
		}
		return res
	},
	error => {
		if (error.response && error.response.status === 403) {
			if (error.response.data.message === 'PARTY_SUSPENDED') {
				showErrorDialog({
					title: `Party Suspended`,
					message: `Unable to log you in at this time.  Please contact customer service at +18003221719 or help@bakkt.com`,
				})
			}
		}

		if (error.response && error.response.status === 401) {
			console.log('error', JSON.stringify(error.response))
			;(window as any).error_code = error.response?.data?.error?.code
			redirectToLogin()
		}
		return Promise.reject({...error})
	},
)

const configuration = new api.Configuration()

export const embeddedBakktAuthApi = api.EmbeddedPartnerSharedWalletAuthAPIApiFactory(configuration, baseUrl)
export const embeddedPartnerApi = api.EmbeddedPartnerAPIApiFactory(configuration, baseUrl)
export const embeddedBankLinkingApi = api.PartnerPartyBankAccountApiFactory(configuration, baseUrl)
export const embeddedDebitLinkingApi = api.EmbeddedPartnerDebitCardAPIApiFactory(configuration, baseUrl)

const {getPartnerPartyLinkInfo} = embeddedPartnerApi

export const loadOnStartup = async () => {
	try {
		const partyInfo = await getPartnerPartyLinkInfo({headers: {token: params.get('token')}})
		if (params.get('token') != null) {
			window.history.replaceState(null, '', window.location.pathname)
			global.accessed_through_token = true
		}
		const partner = getSessionStorageKey(SessionStorageKeys.PARTNER)
		if (
			(partner || '').toString().indexOf('fiserv') < 0 &&
			partyInfo.data.payload?.partnerPartyLink?.tenant != 'partner'
		) {
			//hack for webull partner
			refreshIntervalId = setInterval(() => {
				const partner = getSessionStorageKey(SessionStorageKeys.PARTNER)
				axios.get(`${SERVER_URL}/partnerauth/${partner}/refresh-token`)
			}, 60000) as any
		}

		return partyInfo.data?.payload
	} catch (error) {
		console.error(error)
		return null
	}
}

type ApiHook<Response> = {
	loading: Boolean
	request: (...input: (string | object)[]) => void
	response: Response
	error: object | string | null
}

type Api<T> = () => AxiosPromise<{error?: ApiError; message?: string; payload?: T; success?: boolean}>

export function ApiHook<T>(api: Api<T>) {
	const [loading, setLoading] = useState(false)
	const [response, setResponse] = useState(null)
	const [error, setError] = useState<any>(null)
	const request = async () => {
		if (loading) return {}
		setLoading(true)
		try {
			const response = await api()
			if (response.data.success) {
				setResponse((response?.data?.payload as any) || true)
			} else {
				setError(response?.data?.error)
			}
		} catch (err) {
			setError(err)
		} finally {
			setLoading(false)
		}
	}
	const responseWithType: T | null = response as any

	return {
		loading,
		request,
		response: responseWithType,
		error,
		setLoading,
	}
}

type apiCall<Response> = {
	response: Response
	error: object | string | null
}

export async function apiCall<T>(api: Api<T>): Promise<{response?: T; error?: ApiError}> {
	try {
		const apiResponse = await api()
		if (apiResponse.data.success) {
			return {response: apiResponse?.data?.payload}
		} else {
			return {error: apiResponse.data.error}
		}
	} catch (err) {
		console.error(err)
		return {error: {code: 'UNKNOWN', message: 'Unknown Error'}}
	}
}

export const isErrorMessage = (res: any, title: string) => {
	if (res && res.error) {
		throw new Error(`${title} Error`)
	} else {
		return res
	}
}

export const tokenizeAuth = (cb: Consumer<ISessionAuth>): void => {
	axios
		.post(`${SERVER_URL}/debit/authorize-session`)
		.then((res: AxiosResponse) => isErrorMessage(res.data, 'Authorize Client'))
		.then(cb)
		.catch((err: AxiosError) => {
			throw new Error('Session Authorization Error')
		})
}

export const fetchProfileForDebitLinking = async (callback: any) => {
	const response = await embeddedPartnerApi.getParty()
	const payload = response.data.payload
	if (payload) {
		callback({
			address1: payload.address?.streetLine1,
			address2: payload.address?.streetLine2,
			city: payload.address?.locality,
			country: payload.address?.country,
			postalCode: payload.address?.postalCode,
			region: payload.address?.region,
		})
	}
}

export const fakeWebhook = async (clientToken: string) => {
	if (localStorage.getItem('tokenize-webhook-fake')) {
		const resp = await axios.post(
			`${SERVER_URL}/debit/tokenize-webhook-fake`,
			JSON.parse(localStorage.getItem('tokenize-webhook-fake') || ''),
			{
				headers: {'Client-Token': clientToken},
			},
		)
		console.log('fake-response', resp)
	}
}

export const linkDebitCard = async (clientToken: string) => {
	try {
		const resp = await axios.post(`${SERVER_URL}/debit/link-card`, {clientToken})
		return resp.data
	} catch {
		return undefined
	}
}

export const tokenizeCard = () => {
	const BASE_URL = 'https://api.paysecure.acculynk.net'
	const LOCAL_TEST_URL = localStorage.getItem('PAY_SECURE_BASE_URL')
	const baseUrl = LOCAL_TEST_URL != null ? LOCAL_TEST_URL : BASE_URL

	const tokenizeCard = (input: {
		SessionId: string
		CardNumber: string
		Expiration: string
		CVN: string
		AVS: {Zip: string}
	}): AxiosPromise<any> => {
		return axios.post(baseUrl + '/ClientTokenizeCard', input)
	}

	return {tokenizeCard}
}

export const isDevEnv = () => {
	return (
		SERVER_URL.toString().indexOf('localhost') > 0 ||
		SERVER_URL.toString().indexOf('nxt') > 0 ||
		SERVER_URL.toString().indexOf('dev') > 0
	)
}
export const fetchVerifyDebitAmount = () => {
	if (!isDevEnv()) {
		return null
	}
	return axios.get(`${SERVER_URL}/partnerauth/evil/verification-amounts`)
}
