import {HistoryContext} from '@bakkt/components'
import {Update} from 'history'
import {useContext} from 'react'
import {NavigateOptions, To, useNavigate as useRouterNavigate} from 'react-router-dom'

interface NavigateFunction {
	(to: To, options?: NavigateOptions & {ignore?: boolean}): void
	(delta: number): void
}

export const useNavigate = (): NavigateFunction => {
	const routerNavigate = useRouterNavigate()
	const history = useContext(HistoryContext)

	const navigate = (to: To | number, options?: NavigateOptions & {ignore?: boolean}) => {
		routerNavigate(to as To, options)

		if (options?.ignore) {
			history?.updateLocationHistory({
				action: 'IGNORE',
			} as any | {action: 'IGNORE'})
		}
	}

	return navigate
}
